import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../../environment/Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/Assetlist.css";

function Assetlist() {
  const [responseData, setResponseData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [branch, setbranch] = useState([]);

  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  const [addData, setAddData] = useState({
    branch_id: "",
    name: "",
    type: "",
    brandname: "",
    config: "",
    status: "",
    towhom: "",
  });

  useEffect(() => {
    fetchData();
    fetchDataed();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/assetlist", {
        params: { data: localStorage.getItem("branch_id") },
      });
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };                                                                                                                        

  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    name: "",
    type: "",
    brandname: "",
    config: "",
    status: "",
    towhom: "",
  });

  useEffect(() => {
    fetchDatas();
    gettoken();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/asset_type");
      console.log("Response from server type:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);

      setroleid(response.data.detail.rolename);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const handleNewButtonClick = () => {
  //   if (previllages.add || previllages.all) {
  //     setShowModal(true);
  //   } else {
  //     toast.error("You don't have the permission to add");
  //   }
  // };

  //   const handlesubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //       const formData = {
  //         branch_id: addData.branch_id,
  //         name: addData.name,
  //         type: addData.type,
  //         brandname: addData.brandname,
  //         config: addData.config,
  //         status: addData.status,
  //         towhom: addData.towhom,
  //       };

  //       const response = await axios.post(apiUrl + "/assetlist", formData);
  //       console.log("Updated:", response);
  //       setShowModal(false);
  //       toast.success("added successfully!");
  //       fetchData();
  //       setAddData({
  //         branch_id: "",
  //         name: "",
  //         type: "",
  //         brandname: "",
  //         config: "",
  //         status: "",
  //         towhom: "",
  //       });
  //     } catch (error) {
  //       console.error("Error updating data:", error.message);
  //     }
  //   };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log(" the data to updata", data);
      setUpdateShowModal(true);
      setUpdateData({
        branch_id: data.branch_id,
        name: data.name,
        type: data.type,
        brandname: data.brandname,
        config: data.config,
        status: data.status,
        towhom: data.towhom,
      });
    } else {
      toast.error("You don't have the permission to update");
    }
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        name: updateData.name,
        type: updateData.type,
        brandname: updateData.brandname,
        config: updateData.config,
        status: updateData.status,
        towhom: updateData.towhom,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/assetlist/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(apiUrl + `/assetlist/${getdeleteid}`);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, name) => {
    if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", name);
      setdeleteid(_id);
      setdeletename(name);
      setDeleteShowModals(true);
    } else {
      toast.error("You don't have the permission to delete");
    }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const navigate = useNavigate();

  const handleAssetType = () => {
    navigate("/assettype");
  };

  //   const [addData, setAddData] = useState({
  //     branch_id: '',
  //     name: '',
  //     type: '',
  //     brandname: '',
  //     config: '',
  //     towhom: '',
  //     status: ''
  //   });

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.branch_id) {
      tempErrors.branch_id = "Branch is required.";
      isValid = false;
    }
    if (!addData.name) {
      tempErrors.name = "Name is required.";
      isValid = false;
    }
    if (!addData.type) {
      tempErrors.type = "Type is required.";
      isValid = false;
    }
    if (!addData.brandname) {
      tempErrors.brandname = "Brand Name is required.";
      isValid = false;
    }
    if (!addData.config) {
      tempErrors.config = "Config is required.";
      isValid = false;
    }
    if (!addData.towhom) {
      tempErrors.towhom = "Towhom is required.";
      isValid = false;
    }
    if (!addData.status) {
      tempErrors.status = "Status is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  //   const handlesubmit = () => {
  //     if (validateFields()) {
  //       // Your submit logic here
  //       console.log("Form submitted", addData);
  //       // Reset form and close modal if needed
  //       setShowModal(false);
  //     }
  //   };

  const handlesubmit = async (e) => {
    e.preventDefault();

    // Validate the fields before proceeding
    if (!validateFields()) {
      return; // Exit if validation fails
    }

    try {
      const formData = {
        branch_id: addData.branch_id,
        name: addData.name,
        type: addData.type,
        brandname: addData.brandname,
        config: addData.config,
        status: addData.status,
        towhom: addData.towhom,
      };

      const response = await axios.post(apiUrl + "/assetlist", formData);
      console.log("Added:", response);

      // Reset the form and close the modal
      setShowModal(false);
      toast.success("Added successfully!");
      fetchData(); // Fetch updated data
      setAddData({
        branch_id: "",
        name: "",
        type: "",
        brandname: "",
        config: "",
        status: "",
        towhom: "",
      });
    } catch (error) {
      console.error("Error adding data:", error.message);
      toast.error("Failed to add asset!"); // Optionally show error message
    }
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Asset List</b>
              </Link>
              {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10">
            <div className="d-flex">
              <h5 className="mt-2">Asset List</h5>
              <div className="mx-3">
              {previllages.add && (
              // <button className="btn  add"  onClick={setShowModal(true)}>
              // setShowModal(true)
              //   Add{" "}
              // </button>
              <button className='btn add' onClick={() => setShowModal(true)}>Add</button>

            )}

              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <button className="btn btn-secondary" onClick={handleAssetType}>
              Asset Type
            </button>
          </div>
        </div>

        {/* ------------ search and per page ----------------------------*/}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* --------------------------------end------------------------------------------- */}

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Branch</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Brand Name</th>
                  <th>Config</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.branch_id.branch_name}</td>
                    <td>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.brandname}</td>
                    <td>{item.config}</td>
                    {/* <td>{item.status}</td> */}
                    {item.status == "Active" ? (
                      <td className="text-center hideres ">
                        <p className=" text-white green-label">{item.status}</p>
                      </td>
                    ) : (
                      <td className="text-center hideres">
                        <p className="text-white red-label">{item.status}</p>
                      </td>
                    )}

                    <td className="text-center">
                    {previllages.edit && (
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleUpdate(item._id, item)}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </button>
                    )}
                      {previllages.delete && (
                      <button
                        className="btn delete"
                        onClick={() => handleOpenModal(item._id, item.name)}
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </button>)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Asset List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <label className="form-label">
                      <b></b>
                    </label>
                    <select
                      className="form-select"
                      value={addData.branch_id}
                      onChange={(e) =>
                        setAddData({ ...addData, branch_id: e.target.value })
                      }
                      required
                    >
                      <option value="">Select Branch </option>
                      {branch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))}
                    </select>
                    <label
                      className={addData.branch_id ? "filled" : ""}
                    ></label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.name}
                      onChange={(e) =>
                        setAddData({ ...addData, name: e.target.value })
                      }
                      required
                    />
                    <label className={addData.name ? "filled" : ""}>Name</label>
                  </div>

                  <div className="floating-label">
                    <label className="form-label">
                      <b></b>
                    </label>
                    <select
                      className="form-select"
                      placeholder=""
                      value={addData.type}
                      onChange={(e) =>
                        setAddData({ ...addData, type: e.target.value })
                      }
                    >
                      <option value="">select type</option>
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>

                    <label className={addData.type ? "filled" : ""}>
                      Select type
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.brandname}
                      onChange={(e) =>
                        setAddData({ ...addData, brandname: e.target.value })
                      }
                      required
                    />
                    <label className={addData.brandname ? "filled" : ""}>
                      Brand Name
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={addData.config}
                      onChange={(e) =>
                        setAddData({ ...addData, config: e.target.value })
                      }
                      required
                    />
                    <label className={addData.config ? "filled" : ""}>
                      Config
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={addData.towhom}
                      onChange={(e) =>
                        setAddData({ ...addData, towhom: e.target.value })
                      }
                      required
                    />
                    <label className={addData.towhom ? "filled" : ""}>
                      Towhom
                    </label>
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-control"
                      value={addData.status}
                      placeholder="Select status"
                      onChange={(e) =>
                        setAddData({ ...addData, status: e.target.value })
                      }
                    >
                      <option value=""></option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                    <label className={addData.status ? "filled" : ""}>
                      Select status
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handlesubmit}
            >
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </Modal> */}

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Asset List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={addData.branch_id}
                      onChange={(e) =>
                        setAddData({ ...addData, branch_id: e.target.value })
                      }
                      required
                    >
                      <option value="">Select Branch</option>
                      {branch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_name}
                        </option>
                      ))}
                    </select>
                    <label className={addData.branch_id ? "filled" : ""}>
                      Select Branch
                    </label>
                    {errors.branch_id && (
                      <div className="error-text">{errors.branch_id}</div>
                    )}
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      value={addData.name}
                      onChange={(e) =>
                        setAddData({ ...addData, name: e.target.value })
                      }
                      required
                    />
                    <label className={addData.name ? "filled" : ""}>Name</label>
                    {errors.name && (
                      <div className="error-text">{errors.name}</div>
                    )}
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={addData.type}
                      onChange={(e) =>
                        setAddData({ ...addData, type: e.target.value })
                      }
                    >
                      <option value="">Select Type</option>
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <label className={addData.type ? "filled" : ""}>
                      Select Type
                    </label>
                    {errors.type && (
                      <div className="error-text">{errors.type}</div>
                    )}
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      value={addData.brandname}
                      onChange={(e) =>
                        setAddData({ ...addData, brandname: e.target.value })
                      }
                      required
                    />
                    <label className={addData.brandname ? "filled" : ""}>
                      Brand Name
                    </label>
                    {errors.brandname && (
                      <div className="error-text">{errors.brandname}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      value={addData.config}
                      onChange={(e) =>
                        setAddData({ ...addData, config: e.target.value })
                      }
                      required
                    />
                    <label className={addData.config ? "filled" : ""}>
                      Config
                    </label>
                    {errors.config && (
                      <div className="error-text">{errors.config}</div>
                    )}
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      value={addData.towhom}
                      onChange={(e) =>
                        setAddData({ ...addData, towhom: e.target.value })
                      }
                      required
                    />
                    <label className={addData.towhom ? "filled" : ""}>
                      Towhom
                    </label>
                    {errors.towhom && (
                      <div className="error-text">{errors.towhom}</div>
                    )}
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-control"
                      value={addData.status}
                      onChange={(e) =>
                        setAddData({ ...addData, status: e.target.value })
                      }
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                    <label className={addData.status ? "filled" : ""}>
                      Select Status
                    </label>
                    {errors.status && (
                      <div className="error-text">{errors.status}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handlesubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isUpdateshowModal}
          onHide={() => setUpdateShowModal(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Asset List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={updateData.branch_id.branch_name}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          branch_id: e.target.value,
                        })
                      }
                      required
                    />
                    <label
                      className={
                        updateData.branch_id.branch_name ? "filled" : ""
                      }
                    >
                      Branch
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.name}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, name: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.name ? "filled" : ""}>
                      Name
                    </label>
                  </div>

                  {/* ///////////////// */}
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={updateData.type}
                      placeholder=""
                      onChange={(e) =>
                        setUpdateData({ ...updateData, type: e.target.value })
                      }
                    >
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <label className={updateData.type ? "filled" : ""}>
                      Type
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.brandname}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          brandname: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.brandname ? "filled" : ""}>
                      Brand Name
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.config}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, config: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.config ? "filled" : ""}>
                      Config
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={updateData.towhom}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, towhom: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.towhom ? "filled" : ""}>
                      Towhom
                    </label>
                  </div>

                  <div className="floating-label">
                    <select
                      className="form-control"
                      value={updateData.status}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, status: e.target.value })
                      }
                    >
                      <option value="">Select status</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                    <label
                      className={updateData.status ? "filled" : ""}
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={() => setUpdateShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleupdatesubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1">Name :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* _------------------------------pagination button -------------------------------- */}
        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li
                    className={` page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={goToPrevPage}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li
                      key={i}
                      className={`mx-2 page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link "
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link " onClick={goToNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">
                  Page {currentPage} of {totalPages}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Assetlist;
