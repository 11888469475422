import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../environment/Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


function Studyingstudents() {
  const [responseData, setResponseData] = useState([]);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [deleteShowModals, setDeleteShowModals] = useState(false);

  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});



  const [filteredDatacompleted, setFilteredDatacompleted] = useState([]);
  const navigate = useNavigate();

  const updateinfo = (id) => {
    navigate(`/StudentInfo/${id}`);
  };

  const [updateData, setUpdateData] = useState({
    student_name: "",
    total_fees: "",
    paid: "",
    balance: "",
    status: "",
    completed: "",
    completed_date: "",
    roll_no: "",
    photo_upload: "",
    id_proof: "",
  });

  useEffect(() => {
    fetchData();
    gettoken();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/student", {
        params: { data: localStorage.getItem("branch_id") },
      });

      console.log("Response from server:", response.data.reverse());


      const filteredDatass = response.data.filter(
        (item) => item.course_status !== "Completed"
      );
      setFilteredDatacompleted(filteredDatass)
      console.error("Error fetching data: filteredDatass",filteredDatass);

      const filteredData = response.data.filter(
        (item) => item.status === "Converted" && item.callbackdate !== null
      );
      setResponseData(response.data);
      setFilteredData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

 

   
  const gettoken = async () => {
    const token = localStorage.getItem('token');
    // console.log("the toekn in the localStorage is : ",token);
    try {
        const response = await axios.post(apiUrl + "/get_detail", { "token": token });
        console.log("token detail from server:", response.data);
        // setResponseData(response.data);

        console.log("The role id is : ", response.data.detail.rolename);
        setroleid(response.data.detail.rolename);
        console.log("the role_id is : ", role_id);

        localStorage.setItem('role_id', response.data.detail.rolename);

        const id = response.data.detail.rolename

        // getpages();

        if (id) {
            try {
                const response = await axios.get(apiUrl + `/role_2/${id}`);

                console.log("the reponse list in brach is : ", response.data.modules);

                const modules = response.data.modules;

                const filtered_access = modules.filter(item => item.name == "Branch")

                console.log("the modules is : ", filtered_access[0].actions);

                setprevillages(filtered_access[0].actions);

                console.log("the assigned previllages is : ", previllages.add);

            } catch (error) {
                console.error("Error fetching data:", error.message);
            }
        }

    } catch (error) {
        console.error("Error fetching data:", error.message);
    }
}


  const [updateid, setUpdateId] = useState([]);

  const handleUpdate = async (_id) => {

    setUpdateShowModal(true);
    const responseid = await axios.get(`${apiUrl}/student/${_id}`);
    console.log(responseid.data[0], "responseid--", _id);
    const data = responseid.data[0];
    setUpdateId({
      _id: _id,
      student_name: data.student_name_lead,
      roll_no: data.roll_no,
      fees: data.fees,
      course_status: data.course_status,
    });

  };
  function handleUpdateRegister(id) {
    if (previllages.edit || previllages.all) {
      navigate(`/Registrationupdate/${id}`);
    } else {
      toast.error("You don't have the permission to update");
    }
  }


  const handleupdatesubmit = async (e, id) => {
    e.preventDefault(); // This prevents the default form submission behavior

    try {
      const formDataupdate = {
        // student_name: updateData.student_name,
        // total_fees: updateData.total_fees,
        // paid: updateData.paid,
        // balance: updateData.balance,
        // status: updateData.status,
        // completed: updateData.completed,
        // completed_date: updateData.completed_date,
        // roll_no: updateData.roll_no,
        // photo_upload: updateData.photo_upload,
        // id_proof: updateData.id_proof,
        course_status: updateid.course_status,
      };
      console.log("the update submit data", id, typeof updateid.course_status);

      // if (updateData.photo_upload) formData.append('photo_upload', updateData.photo_upload);
      // if (updateData.id_proof) formData.append('id_proof', updateData.id_proof);

      const response = await axios.put(
        apiUrl + `/student/${String(id)}`,
        formDataupdate,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (_id) => {
    try {
      const response = await axios.delete(apiUrl + `/student/${getdeleteid}`);
      console.log("delete data ", response);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = async (_id, student_name) => {
    if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the studentname", student_name);
      setdeleteid(_id);
      setdeletename(student_name);
      setDeleteShowModals(true);
    } else {
      toast.error("You don't have the permission to delete");
    }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDatacompleted.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  //////////// complate date show code /////////////////////

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    console.log("the select status", selectedStatus);
    setUpdateData({ ...updateData, completed: selectedStatus });

    if (selectedStatus === "Completed") {
      setShowCallbackDate(true);
    } else {
      setShowCallbackDate(false);
      setUpdateData((prevData) => ({
        ...prevData,
        selectedStatus: "",
      }));
    }
  };

  const [showCallbackDate, setShowCallbackDate] = useState(false);

  return (
    <div className="top">
      <div className="" style={{ marginTop: '-80px' }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-secondary dashBoard_text">
              <b>Enrollment / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Studying Student</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className=" col-lg-9 d-flex">
          <h5>Studying Student</h5>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}

      {/* Table */}
      <div className=" card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Student</th>
                <th>Course</th>
                <th>Mobile</th>
                <th>Fees</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {item.roll_no} &nbsp; - &nbsp;
                    {item.student_name_lead}
                  </td>
                  <td>{item.Prefered_course_lead}</td>
                  <td>{item.mobile_no_lead}</td>
                  <td>{item.fees}</td>

                  <td className="text-center">
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i class="fa-regular fa-address-book"></i>
                    </button>
                    {/* <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdateRegister(item._id)}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                      className="btn delete"
                      onClick={() =>
                        handleOpenModal(
                          item._id,
                          item.leads_id[0]?.student_name
                        )
                      }
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button> */}
                     {previllages.edit && (
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleUpdateRegister(item._id)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    )}
{/*                     
                    {previllages.delete && (
                      <button
                         className="btn delete"
                        onClick={() =>
                          handleOpenModal(
                            item._id,
                            item.leads_id[0]?.student_name
                          )
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    )} */}

                    <button
                      className="btn "
                      onClick={() => updateinfo(item._id)}
                    >
                      <i class="fa-solid fa-circle-info"></i>
                    </button>

                    {/* <button className='btn btn-secondary mx-3'
                                        > <i class="fa-solid fa-file-invoice-dollar"></i></button>
                                        <button className='btn btn-info'
                                        > <i class="fa-solid fa-user-tag"></i></button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* ----------------update */}
      {console.log("updateid77", updateid)}

      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Update Studying Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="floating-label">
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                disabled
                placeholder=""
                value={updateid.student_name}
                onChange={(e) =>
                  setUpdateData({ ...updateData, student_name_lead: e.target.value })
                }
                required
              />
              <label className={updateData.student_name_lead ? "filled" : ""}>
                Student name
              </label>
            </div>
            
            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                disabled
                placeholder=""
                value={updateid.roll_no}
                onChange={(e) =>
                  setUpdateData({ ...updateData, roll_no: e.target.value })
                }
                required
              />
              <label className={updateData.roll_no ? "filled" : ""}>
                Roll No
              </label>
            </div>

            <div className="floating-label">
              <input
                className="form-control"
                type="text"
                disabled
                placeholder=""
                value={updateid.fees}
                onChange={(e) =>
                  setUpdateData({ ...updateData, paid: e.target.value })
                }
                required
              />
              <label className={updateData.paid ? "filled" : ""}>Fees</label>
            </div>

            <div className="floating-label">
              <select
                className="form-control"
                value={updateid.course_status || ""}
                onChange={(e) =>
                  setUpdateId({ ...updateid, course_status: e.target.value })
                }
                required
              >
                <option value="">Select status</option>
                <option value="Completed">Completed</option>
                {/* <option value="Studying">Studying</option> */}
                <option value="Discontinue">Discontinue</option>
              </select>

              <label className={updateid.course_status ? "filled" : ""}>
                Selected Status
              </label>
            </div>

            {/* <div className="floating-label">
                            {showCallbackDate && ( // Show the callback date field if 'Callback' status is selected
                                <div className="form-group mt-2">

                                    <input className="form-control" type="date" value={updateData.completed_date} onChange={(e) => setUpdateData({ ...updateData, completed_date: e.target.value })} required />
                                </div>
                            )}

                            <label className={updateData.completed_date ? "filled" : ""}>
                            </label>
                        </div>


 */}

            {/* <div className="floating-label">
                            <input
                                className="form-control"
                                type="file"
                                onChange={(e) => setUpdateData({ ...updateData, photo_upload: e.target.files[0] })}
                                required
                            />
                            <label className={updateData.photo_upload ? "filled" : ""}>
                                Upload Photo
                            </label>
                        </div> */}

            {/* <div className="floating-label">
                            <input
                                className="form-control"
                                type="file"
                                onChange={(e) => setUpdateData({ ...updateData, photo_upload: e.target.files[0] })}
                                required
                            />
                            <label className={updateData.photo_upload ? "filled" : ""}>
                                Upload Photo
                            </label>
                        </div> */}

            {/* <div className="floating-label">
                            <input
                                className="form-control"
                                type="file"
                                // accept=".pdf,.doc,.docx,.txt"
                                onChange={(e) => setUpdateData({ ...updateData, id_proof: e.target.files[0] })}
                                required
                            />
                            <label className={updateData.id_proof ? "filled" : ""}>
                                Upload ID Proof (Document)
                            </label>
                        </div> */}

            {/* Display file name after uploading */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={(e) => handleupdatesubmit(e, updateid._id)}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1"> Student Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}
export default Studyingstudents;
