// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const Notfounded = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <Link to="/dashboard" style={{ fontSize: '18px', color: 'blue' }}>
        Go back to Home
      </Link>
    </div>
  );
  
};

export default Notfounded;
