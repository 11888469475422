// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import apiUrl from "../../environment/Api";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import "../../assets/css/updatestaff.css";

// function Updatestaff() {
//   const { id } = useParams();
//   const navigator = useNavigate();
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [responseSchuduleData, setResponseSchuduleData] = useState([]);
//   const [branch, setbranch] = useState([]);

//   useEffect(() => {
//     fetchDataed();
//   }, []);

//   const fetchDataed = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/Branch");
//       console.log("Response from server:", response.data);
//       setbranch(response.data); // Ensure response.data is an array of branches
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const navigate = useNavigate();
//   const handleCanceled = () => {
//     navigate("/staff");
//   };

//   const [getdatas, setdatas] = useState({
//     branch_id: "",
//     empid: "",
//     name: "",
//     email: "",
//     official_email: "",
//     fathername: "",
//     mobile: "",
//     personal_Mobileno: "",
//     dateofbirth: "",
//     interview_date: "",
//     join_date: "",
//     address: "",
//     Permanent_Address: "",
//     password: "",
//     salary: "",
//     department: "",
//     designation: "",
//     qualification: "",
//     notes: "",
//     timings: "",
//     status: "",
//     marital_status: "",
//     gender: "",
//     relive_date: "",
//     documents: "",
//     photo_upload: "",
//     deviceUserId: "",
//     aadhar_number: "",
//   });
//   const [image, setImage] = useState(null);
//   const [imageFile, setImageFile] = useState(null);

//   useEffect(() => {
//     fetchDataSchudule();
//     fetchData();

//     console.log("it is worked or not useeffect", getdatas);
//     if (getdatas.length > 0) {
//       const photo_upload = getdatas[0]?.photo_upload;
//       const documents = getdatas[0]?.documents;
//       console.log("the first photo upload", photo_upload);
//       setImage(
//         photo_upload
//           ? `${apiUrl}/api/uploads/${getdatas.photo_upload}`
//           : null
//       );
//       setImageFile(
//         documents
//           ? `${apiUrl}/api/uploads/${getdatas.documents}`
//           : null
//       );
//     }
//   }, []);

//   const fetchDataSchudule = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/schedule");
//       console.log("Response from server type:", response.data);
//       setResponseSchuduleData(response.data);
//       console.log("schudule", response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };
//   const [iamdeadd, setimageadd] = useState(null)
//   const handlePhotoUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const fileURL = URL.createObjectURL(file); // Create a temporary URL for preview
//       setImage(fileURL); // Set the photo preview URL
//       setdatas({ ...getdatas, photo_upload: file }); // Store the file in the state for further use (e.g., upload)
//     }
//   };


//   // Handle document upload
//   // const handleDocumentUpload = (e) => {
//   //   const file = e.target.files[0];
//   //   if (file) {
//   //     const fileURL = URL.createObjectURL(file); // Create a temporary URL for preview
//   //     setImageFile(fileURL); // Set the document preview URL
//   //     setdatas({ ...getdatas, documents: file.name }); // Store the document file name or path in the state
//   //   }
//   // };
//   const handleDocumentUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const fileURL = URL.createObjectURL(file);// Preview only if image
//       setImageFile(fileURL); // Set the document preview URL (if image)
//       setdatas({ ...getdatas, documents: file }); // Store the file object in the state
//     }
//   };
//   useEffect(() => {
//     setTimeout(() => {
//       console.log("assigned or not ", getdatas);
//     }, 5000);
//   }, []);

//   const fetchData = async () => {
//     try {
//       console.log("hello", id);
//       const response = await axios.get(apiUrl + `/staff/${id}`);
//       console.log("update response.data[0]", response.data[0]);
//       setdatas(response.data[0]);
//       console.log("it is worked or not fetch data", getdatas);
//        console.log("it is worked or not fetch data", getdatas.designation[0]?._id);
//       const selectedStatus = response.data[0].status;

//       if (selectedStatus === "Relive") {
//         setShowReliveDate(true);
//       } else {
//         setShowReliveDate(false);
//         setdatas((prevData) => ({
//           ...prevData,
//           relive_date: "",
//         }));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchDatas();
//   }, []);
//   const fetchDatas = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/designation");
//       console.log("Response from server type in fetchDatas:", response.data);
//       setResponseDatas(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const [updateData, setUpdateData] = useState([]);
//   const [files, setFiles] = useState([]);

//   const handleupdatesubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const formData = new FormData();
//       formData.append("password", getdatas.password);
//       formData.append("branch_id", getdatas.branch_id._id || null);
//       formData.append("empid", getdatas.empid);
//       formData.append("name", getdatas.name);
//       formData.append("email", getdatas.email);
//       formData.append("official_email", getdatas.official_email);
//       formData.append("fathername", getdatas.fathername);
//       formData.append("mobile", getdatas.mobile);
//       formData.append("personal_Mobileno", getdatas.personal_Mobileno);
//       formData.append("dateofbirth", getdatas.dateofbirth);
//       formData.append("interview_date", getdatas.interview_date || "");
//       formData.append("join_date", getdatas.join_date || "");
//       formData.append("address", getdatas.address);
//       formData.append("Permanent_Address", getdatas.Permanent_Address);
//       formData.append("salary", getdatas.salary);
//       formData.append("designation", getdatas.designation);
//       formData.append("department", getdatas.department);
//       formData.append("qualification", getdatas.qualification);
//       formData.append("notes", getdatas.notes);
//       formData.append("timings", getdatas.timings);
//       formData.append("status", getdatas.status);
//       formData.append("marital_status", getdatas.marital_status);
//       formData.append("gender", getdatas.gender);
//       formData.append("relive_date", getdatas.relive_date || "");
//       formData.append("deviceUserId", getdatas.deviceUserId);
//       formData.append("aadhar_number", getdatas.aadhar_number);
//       formData.append("photo_upload", getdatas.photo_upload);
//       formData.append("documents", getdatas.documents);

//       console.log("Photo Upload:", getdatas.photo_upload);

//       console.log("Documents:", formData.get("documents").name);

//       console.log("In the final submit  : ", getdatas.designation[0]._id);
//       const updatedata = await axios.put(`${apiUrl}/staff/${id}`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       console.log("Updated data:", updatedata);
//       toast.success("Update successfully!");
//       navigator("/staff");
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const [selectedFiless, setSelectedFiless] = useState([]);
//   const [selectedFiles, setSelectedFiles] = useState([]);

//   const handleFileChange = (e) => {
//     const files = e.target.files;

//     if (files && files.length > 0) {
//       setSelectedFiles(files); // files is a FileList object
//     } else {
//       setSelectedFiles([]); // If no files selected, set it as an empty array
//     }
//   };

//   const [showReliveDate, setShowReliveDate] = useState(false);

//   const handleStatusChangeupdate = (e) => {
//     const selectedStatus = e.target.value;
//     setUpdateData({ ...updateData, status: selectedStatus });

//     if (selectedStatus === "Relive") {
//       setShowReliveDate(true);
//     } else {
//       setShowReliveDate(false);
//       setUpdateData((prevData) => ({
//         ...prevData,
//         relive_date: "",
//       }));
//     }
//   };

//   const convertToNormalTime = (railwayTime) => {
//     if (!railwayTime || !railwayTime.includes(":")) {
//       console.error("Invalid time format:", railwayTime);
//       return "";
//     }

//     const [hours, minutes] = railwayTime.split(":");
//     const hour = parseInt(hours, 10);
//     const minute = parseInt(minutes, 10);
//     const ampm = hour >= 12 ? "PM" : "AM";
//     const hour12 = hour % 12 || 12;

//     return `${hour12}:${minutes} ${ampm}`;
//   };

//   const [activeStep, setActiveStep] = useState(0);

//   const handleNext = () => {
//     if (activeStep < 1) setActiveStep(activeStep + 1);
//   };

//   const handleBack = () => {
//     if (activeStep > 0) setActiveStep(activeStep - 1);
//   };

//   const handleCancell = () => {
//     console.log("Form cancelled");
//     setActiveStep(0); // Optionally reset to step 1
//   };

//   function handeledesgination(e) {
//     const selectvalue = e.target.value;
//     console.log("Selected designation value:", selectvalue);

//     // Update state with the selected designation ID
//     setdatas((prevState) => ({
//       ...prevState,
//       designation: selectvalue,
//     }));
//   }


//   const getDepartmentName = (departmentId) => {
//     if (Array.isArray(getdatas.designation)) {
//       const department = getdatas.designation.find(
//         (designation) => designation._id === departmentId
//       );

//       return department ? department.department : "";
//     }
//     return "";
//   };

//   const getDesignationName = (designationId) => {
//     if (Array.isArray(getdatas.designation)) {
//       const designation = getdatas.designation.find(
//         (item) => item._id === designationId // Find the designation with matching _id
//       );

//       return designation ? designation.designation : ""; // Return the designation name or empty string
//     }
//     return "";
//   };

//   return (
//     <div className="style mb-5" style={{ marginTop: "-80px" }}>
//       <div className="insermodal">
//         <div>
//           <h5>Edit Staff Detail</h5>
//           <div className="row mt-5">
//             {/* Step 1 */}
//             {activeStep === 0 && (
//               <div className="col-lg-12" style={{ marginTop: "-25px" }}>
//                 <div className="card p-4 pb-0">
//                   <h4 className="mt-1 mb-3">Step 1</h4>
//                   <div className="form-group">
//                     <div className="row">
//                       <div className="col-lg-6">
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <select
//                               className="form-select"
//                               value={getdatas.branch_id[0]?._id}
//                               onChange={(e) =>
//                                 setdatas((prevData) => ({
//                                   ...prevData,
//                                   branch_id: e.target.value,
//                                 }))
//                               }
//                               required
//                             >
//                               <option value="">Branch</option>
//                               {branch.map((item, index) => (
//                                 <option key={index} value={item._id}>
//                                   {item.branch_name}
//                                 </option>
//                               ))}
//                             </select>
//                             <label
//                               className={getdatas.branch_id ? "filled" : ""}
//                             >
//                               Branch
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.empid}
//                               onChange={(e) =>
//                                 setdatas({ ...getdatas, empid: e.target.value })
//                               }
//                               required
//                             />
//                             <label>Staff ID</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.name}
//                               onChange={(e) =>
//                                 setdatas({ ...getdatas, name: e.target.value })
//                               }
//                               required
//                             />
//                             <label> Name</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.fathername}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   fathername: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Father Name</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <select
//                               className="form-select"
//                               value={getdatas.department || ""} // Bind department ID to the value
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   department: e.target.value, // Update with department ID
//                                 })
//                               }
//                               required
//                             >
//                               <option value="" disabled>
//                                 Select Department
//                               </option>
//                               {responseDatas.length > 0 ? (
//                                 responseDatas.map((item, index) => (
//                                   <option key={index} value={item._id}>
//                                     {item.department}{" "}
//                                   </option>
//                                 ))
//                               ) : (
//                                 <option disabled>
//                                   No department available
//                                 </option>
//                               )}
//                             </select>
//                             <label
//                               className={getdatas.department ? "filled" : ""}
//                             >
//                               Select Department
//                             </label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             {/* <select
//                               className="form-select"
//                               value={getdatas.designation || ""}
//                               onChange={(e) => handeledesgination(e)}
//                               required
//                             >
//                               <option value="" disabled>
//                                 Select Designation
//                               </option>
//                               {responseDatas.length > 0 ? (
//                                 responseDatas.map((item, index) => (
//                                   <option key={index} value={item._id}>
//                                     {" "}
//                                     {item.designation}{" "}
//                                   </option>
//                                 ))
//                               ) : (
//                                 <option disabled>
//                                   No designations available
//                                 </option>
//                               )}
//                             </select> */}
//                                             <select
//   className="form-select"
//   value={getdatas.designation || ""}
//   onChange={handeledesgination}
//   required
// >
//   <option value="" disabled>
//     Select Designation
//   </option>
//   {responseDatas.length > 0 ? (
//     responseDatas.map((item, index) => (
//       <option key={index} value={item._id}>
//         {item.designation}
//       </option>
//     ))
//   ) : (
//     <option disabled>No designations available</option>
//   )}
// </select>
//                             <label
//                               className={getdatas.designation ? "filled" : ""}
//                             >
//                               Select Designation
//                             </label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.aadhar_number}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   aadhar_number: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Aadhar Number</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.qualification}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   qualification: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Qualification</label>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.mobile}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   mobile: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Official Mobile No</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.personal_Mobileno}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   personal_Mobileno: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Personal Mobile No</label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.official_email}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   official_email: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Official Email</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.email}
//                               onChange={(e) =>
//                                 setdatas({ ...getdatas, email: e.target.value })
//                               }
//                               required
//                             />
//                             <label>Personal Email</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="date"
//                               placeholder=""
//                               value={
//                                 getdatas.dateofbirth
//                                   ? getdatas.dateofbirth.substring(0, 10)
//                                   : ""
//                               }
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   dateofbirth: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Date of Birth</label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.Permanent_Address}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   Permanent_Address: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Permanent Address</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.address}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   address: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Current Address</label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.deviceUserId}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   deviceUserId: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Device User Id</label>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="d-flex justify-content-between mt-3">
//                   <button className="btn btn-danger" onClick={handleCanceled}>
//                     Cancel
//                   </button>
//                   <button className="btn btn-primary" onClick={handleNext}>
//                     Next
//                   </button>
//                 </div>
//               </div>
//             )}

//             {/* Step 2 */}
//             {activeStep === 1 && (
//               <div className="col-lg-12" style={{ marginTop: "-25px" }}>
//                 <div className="card p-4 pb-0">
//                   <h4 className="mt-2 mb-4">Step 2</h4>
//                   <div className="form-group">
//                     <div className="row">
//                       <div className="col-lg-6">
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="date"
//                               placeholder=""
//                               value={
//                                 getdatas.interview_date
//                                   ? getdatas.interview_date.substring(0, 10)
//                                   : ""
//                               }
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   interview_date: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Interview date</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="date"
//                               placeholder=""
//                               value={
//                                 getdatas.join_date
//                                   ? getdatas.join_date.substring(0, 10)
//                                   : ""
//                               }
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   join_date: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>join date</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <select
//                               className="form-control"
//                               value={getdatas.marital_status}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   marital_status: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               <option value="Married">Married</option>
//                               <option value="Unmarried">Unmarried</option>
//                             </select>
//                             <label
//                               className={
//                                 getdatas.marital_status ? "filled" : ""
//                               }
//                             >
//                               Marital Status
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <select
//                               className="form-control"
//                               value={getdatas.gender}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   gender: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               <option value=""> Gender</option>
//                               <option value="Male">Male</option>
//                               <option value="Female">Female</option>
//                             </select>
//                             <label className={getdatas.gender ? "filled" : ""}>
//                               Gender
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="file"
//                               onChange={handlePhotoUpload} // Store the file object
//                               required
//                             />
//                             <label className={getdatas.photo_upload ? "filled" : ""}>
//                               ID Proof
//                             </label>

//                             {/* Show a preview if the image is available */}
//                             {image && (
//                               <div>
//                                 <p>Your Image</p>
//                                 <img
//                                   src={image} // Show the preview of the uploaded image
//                                   alt="Uploaded or Captured"
//                                   width="200"
//                                   height="200"
//                                   className="object-fit-cover"
//                                 />
//                               </div>
//                             )}

//                             {/* Show server image if no local preview is available */}
//                             {getdatas.photo_upload && !image && (
//                               <div>
//                                 <p>Your Image</p>
//                                 <img
//                                   src={`${apiUrl}/uploads/${getdatas.photo_upload}`} // Use server file path
//                                   alt="Uploaded or Captured"
//                                   width="200"
//                                   height="200"
//                                   className="object-fit-cover"
//                                 />
//                               </div>
//                             )}

//                             {/* Display the file name, not the file object */}
//                             {getdatas.photo_upload && (
//                               <p>{getdatas.photo_upload.name}</p>
//                             )}
//                           </div>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.salary}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   salary: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label>Salary</label>
//                           </div>
//                         </div>
//                         <div className="col-lg-12">
//                           {/* <div className="floating-label">
//                             <select
//                               className="form-control"
//                               value={getdatas.timings}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   timings: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               <option value="" disabled>
//                                 Select Timing
//                               </option>

//                               {responseSchuduleData.map((item, index) => (
//                                 <option
//                                   key={index}
//                                   value={`${item.start_time}-${item.end_time}`}
//                                 >
//                                   {convertToNormalTime(item.start_time)} -{" "}
//                                   {convertToNormalTime(item.end_time)}
//                                 </option>
//                               ))}

//                               <option value="9:30 Am to 6:30 Pm">
//                                 9:30 Am to 6:30 Pm
//                               </option>
//                               <option value="10:00 Am to 7:00 Pm">
//                                 10:00 Am to 7:00 Pm
//                               </option>
//                               <option value="11:00 Am to 8:00 Pm">
//                                 11:00 Am to 8:00 Pm
//                               </option>
//                             </select>
//                             <label></label>
//                           </div> */}


//                           <div className="floating-label">
//                             <select
//                               id="timing-select"
//                               className="form-control"
//                               value={getdatas.timings}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   timings: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               <option value="" disabled>
//                                 Select Timing
//                               </option>
//                               <option value="9:30 Am to 6:30 Pm">9:30 Am to 6:30 Pm</option>
//                               <option value="10:00 Am to 7:00 Pm">10:00 Am to 7:00 Pm</option>
//                               <option value="11:00 Am to 8:00 Pm">11:00 Am to 8:00 Pm</option>
//                             </select>
//                             <label htmlFor=""></label>
//                           </div>


//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={getdatas.notes}
//                               onChange={(e) =>
//                                 setdatas({ ...getdatas, notes: e.target.value })
//                               }
//                               required
//                             />
//                             <label>Notes</label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <select
//                               className="form-control"
//                               value={getdatas.status}
//                               onChange={(e) =>
//                                 setdatas({
//                                   ...getdatas,
//                                   status: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               <option value="Active">Active</option>
//                               <option value="Inactive">Inactive</option>
//                               <option value="Relive">Relive</option>
//                             </select>
//                             <label className={getdatas.status ? "filled" : ""}>
//                               Select status
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-12">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="file"
//                               accept="application/pdf,image/*" // Restrict to documents or images
//                               onChange={handleDocumentUpload}
//                               required
//                             />
//                             <label className={getdatas.documents ? "filled" : ""}>Documents</label>

//                             {/* Show a preview if an image file is uploaded */}
//                             {imageFile && (
//                               <div>
//                                 <p>Your Document Preview</p>
//                                 <img
//                                   src={imageFile} // Show the preview of the uploaded document (if image)
//                                   alt="Uploaded Document"
//                                   width="200"
//                                   height="200"
//                                   className="object-fit-cover"
//                                 />
//                               </div>
//                             )}

//                             {getdatas.documents && !imageFile && (
//                               <div>
//                                 <p>Your Image</p>
//                                 <img
//                                   src={`${apiUrl}/uploads/${getdatas.documents}`} // Use server file path
//                                   alt="Uploaded or Captured"
//                                   width="200"
//                                   height="200"
//                                   className="object-fit-cover"
//                                 />
//                               </div>
//                             )}

//                             {/* Show the uploaded file's name if no preview is available */}
//                             {getdatas.documents && (
//                               <div>
//                                 <p>Your Uploaded File: {getdatas.documents.name}</p>
//                               </div>
//                             )}
//                           </div>
//                         </div>

//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="d-flex justify-content-between mt-3">
//                   <button className="btn btn-secondary" onClick={handleBack}>
//                     Back
//                   </button>
//                   <button
//                     className="btn btn-primary"
//                     onClick={handleupdatesubmit}
//                   >
//                     Update
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Updatestaff;




import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/updatestaff.css";

function Updatestaff() {
  const { id } = useParams();
  const navigator = useNavigate();
  const [responseDatas, setResponseDatas] = useState([]);
  const [responseSchuduleData, setResponseSchuduleData] = useState([]);
  const [branch, setbranch] = useState([]);

  useEffect(() => {
    fetchDataed();
  }, []);

  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data); // Ensure response.data is an array of branches
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const navigate = useNavigate();
  const handleCanceled = () => {
    navigate("/staff");
  };

  const [getdatas, setdatas] = useState({
    branch_id: "",
    empid: "",
    name: "",
    email: "",
    official_email: "",
    fathername: "",
    mobile: "",
    personal_Mobileno: "",
    dateofbirth: "",
    interview_date: "",
    join_date: "",
    address: "",
    Permanent_Address: "",
    password: "",
    salary: "",
    department: "",
    designation: "",
    qualification: "",
    notes: "",
    timings: "",
    status: "",
    marital_status: "",
    gender: "",
    relive_date: "",
    documents: "",
    photo_upload: "",
    deviceUserId: "",
    aadhar_number: "",
  });
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    fetchDataSchudule();
    fetchData();

    console.log("it is worked or not useeffect", getdatas);
    if (getdatas.length > 0) {
      const photo_upload = getdatas[0]?.photo_upload;
      const documents = getdatas[0]?.documents;
      console.log("the first photo upload", photo_upload);
      setImage(
        photo_upload ? `${apiUrl}/api/uploads/${getdatas.photo_upload}` : null
      );
      setImageFile(
        documents ? `${apiUrl}/api/uploads/${getdatas.documents}` : null
      );
    }
  }, []);

  const fetchDataSchudule = async () => {
    try {
      const response = await axios.get(apiUrl + "/schedule");
      console.log("Response from server type:", response.data);
      setResponseSchuduleData(response.data);
      console.log("schudule", response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const [iamdeadd, setimageadd] = useState(null);
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file); // Create a temporary URL for preview
      setImage(fileURL); // Set the photo preview URL
      setdatas({ ...getdatas, photo_upload: file }); // Store the file in the state for further use (e.g., upload)
    }
  };

  const handleDocumentUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file); // Preview only if image
      setImageFile(fileURL); // Set the document preview URL (if image)
      setdatas({ ...getdatas, documents: file }); // Store the file object in the state
    }
  };
  useEffect(() => {
    setTimeout(() => {
      console.log("assigned or not ", getdatas);
    }, 5000);
  }, []);

  const fetchData = async () => {
    try {
      console.log("hello", id);
      const response = await axios.get(apiUrl + `/staff/${id}`);
      console.log("update response.data[0]", response.data[0]);
      setdatas(response.data[0]);
      console.log("it is worked or not fetch data", getdatas);
      console.log(
        "it is worked or not fetch data",
        getdatas.designation[0]?._id
      );
      const selectedStatus = response.data[0].status;

      if (selectedStatus === "Relive") {
        setShowReliveDate(true);
      } else {
        setShowReliveDate(false);
        setdatas((prevData) => ({
          ...prevData,
          relive_date: "",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDatas();
  }, []);
  // const fetchDatas = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/designation");
  //     console.log("Response from server type in fetchDatas:", response.data);
  //     setResponseDatas(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };
  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/designation");
      if (Array.isArray(response.data)) {
        console.log("Response from server type in fetchDatas:", response.data);
        setResponseDatas(response.data);
      } else {
        console.error("Unexpected data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState([]);
  const [files, setFiles] = useState([]);

  const handleupdatesubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("password", getdatas.password);
      formData.append("branch_id", getdatas.branch_id._id || null);
      formData.append("empid", getdatas.empid);
      formData.append("name", getdatas.name);
      formData.append("email", getdatas.email);
      formData.append("official_email", getdatas.official_email);
      formData.append("fathername", getdatas.fathername);
      formData.append("mobile", getdatas.mobile);
      formData.append("personal_Mobileno", getdatas.personal_Mobileno);
      formData.append("dateofbirth", getdatas.dateofbirth);
      formData.append("interview_date", getdatas.interview_date || "");
      formData.append("join_date", getdatas.join_date || "");
      formData.append("address", getdatas.address);
      formData.append("Permanent_Address", getdatas.Permanent_Address);
      formData.append("salary", getdatas.salary);
      formData.append("designation", getdatas.designation[0]._id);
      formData.append("department", getdatas.department);
      formData.append("qualification", getdatas.qualification);
      formData.append("notes", getdatas.notes);
      formData.append("timings", getdatas.timings);
      formData.append("status", getdatas.status);
      formData.append("marital_status", getdatas.marital_status);
      formData.append("gender", getdatas.gender);
      formData.append("relive_date", getdatas.relive_date || "");
      formData.append("deviceUserId", getdatas.deviceUserId);
      formData.append("aadhar_number", getdatas.aadhar_number);
      formData.append("photo_upload", getdatas.photo_upload);
      formData.append("documents", getdatas.documents);

      console.log("Photo Upload:", getdatas.photo_upload);

      console.log("Documents:", formData.get("documents").name);

      console.log("In the final submit  : ", getdatas.designation._id);
      const updatedata = await axios.put(`${apiUrl}/staff/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Updated data:", updatedata);
      toast.success("Update successfully!");
      navigator("/staff");
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [selectedFiless, setSelectedFiless] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      setSelectedFiles(files); // files is a FileList object
    } else {
      setSelectedFiles([]); // If no files selected, set it as an empty array
    }
  };

  const [showReliveDate, setShowReliveDate] = useState(false);

  const handleStatusChangeupdate = (e) => {
    const selectedStatus = e.target.value;
    setUpdateData({ ...updateData, status: selectedStatus });

    if (selectedStatus === "Relive") {
      setShowReliveDate(true);
    } else {
      setShowReliveDate(false);
      setUpdateData((prevData) => ({
        ...prevData,
        relive_date: "",
      }));
    }
  };

  const convertToNormalTime = (railwayTime) => {
    if (!railwayTime || !railwayTime.includes(":")) {
      console.error("Invalid time format:", railwayTime);
      return "";
    }

    const [hours, minutes] = railwayTime.split(":");
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;

    return `${hour12}:${minutes} ${ampm}`;
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < 1) setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const handleCancell = () => {
    console.log("Form cancelled");
    setActiveStep(0); // Optionally reset to step 1
  };

  // function handeledesgination(e) {
  //   const selectvalue = e.target.value;
  //   console.log("Selected designation value:", selectvalue);

  //   // Update state with the selected designation ID
  //   setdatas((prevState) => ({
  //     ...prevState,
  //     designation: selectvalue,
  //   }));
  // }

  function handeledesgination(e) {
    const selectvalue = e.target.value;
    const selectedDesignation = responseDatas.find(
      (item) => item._id === selectvalue
    );
    console.log("Selected designation value:", selectvalue);

    setdatas((prevState) => ({
      ...prevState,
      designation: selectedDesignation ? [selectedDesignation] : [],
    }));
  }

  const getDepartmentName = (departmentId) => {
    if (Array.isArray(getdatas.designation)) {
      const department = getdatas.designation.find(
        (designation) => designation._id === departmentId
      );

      return department ? department.department : "";
    }
    return "";
  };

  const getDesignationName = (designationId) => {
    if (Array.isArray(getdatas.designation)) {
      const designation = getdatas.designation.find(
        (item) => item._id === designationId // Find the designation with matching _id
      );

      return designation ? designation.designation : ""; // Return the designation name or empty string
    }
    return "";
  };

  return (
    <div className="style mb-5" style={{ marginTop: "-80px" }}>
      <div className="insermodal">
        <div>
          <h5>Edit Staff Detail</h5>
          <div className="row mt-5">
            {/* Step 1 */}
            {activeStep === 0 && (
              <div className="col-lg-12" style={{ marginTop: "-25px" }}>
                <div className="card p-4 pb-0">
                  <h4 className="mt-1 mb-3">Step 1</h4>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={getdatas.branch_id[0]?._id}
                              onChange={(e) =>
                                setdatas((prevData) => ({
                                  ...prevData,
                                  branch_id: e.target.value,
                                }))
                              }
                              required
                            >
                              <option value="">Branch</option>
                              {branch.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.branch_name}
                                </option>
                              ))}
                            </select>
                            <label
                              className={getdatas.branch_id ? "filled" : ""}
                            >
                              Branch
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.empid}
                              onChange={(e) =>
                                setdatas({ ...getdatas, empid: e.target.value })
                              }
                              required
                            />
                            <label>Staff ID</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.name}
                              onChange={(e) =>
                                setdatas({ ...getdatas, name: e.target.value })
                              }
                              required
                            />
                            <label> Name</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.fathername}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  fathername: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Father Name</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={getdatas.department || ""} // Bind department ID to the value
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  department: e.target.value, // Update with department ID
                                })
                              }
                              required
                            >
                              <option value="" disabled>
                                Select Department
                              </option>
                              {responseDatas.length > 0 ? (
                                responseDatas.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.department}{" "}
                                  </option>
                                ))
                              ) : (
                                <option disabled>
                                  No department available
                                </option>
                              )}
                            </select>
                            <label
                              className={getdatas.department ? "filled" : ""}
                            >
                              Select Department
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            {/* <select
                              className="form-select"
                              value={getdatas.designation || ""}
                              onChange={handeledesgination}
                              required
                            >
                              <option value="" disabled>
                                Select Designation
                              </option>
                              {responseDatas.length > 0 ? (
                                responseDatas.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.designation}
                                  </option>
                                ))
                              ) : (
                                <option disabled>
                                  No designations available
                                </option>
                              )}
                            </select> */}

                            <select
                              className="form-select"
                              value={getdatas.designation[0]?._id || ""}
                              onChange={handeledesgination}
                              required
                            >
                              <option value="" disabled>
                                Select Designation
                              </option>
                              {responseDatas.length > 0 ? (
                                responseDatas.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.designation}
                                  </option>
                                ))
                              ) : (
                                <option disabled>
                                  No designations available
                                </option>
                              )}
                            </select>

                            <label
                              className={getdatas.designation ? "filled" : ""}
                            >
                              Select Designation
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.aadhar_number}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  aadhar_number: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Aadhar Number</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.qualification}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  qualification: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Qualification</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.mobile}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  mobile: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Official Mobile No</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.personal_Mobileno}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  personal_Mobileno: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Personal Mobile No</label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.official_email}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  official_email: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Official Email</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.email}
                              onChange={(e) =>
                                setdatas({ ...getdatas, email: e.target.value })
                              }
                              required
                            />
                            <label>Personal Email</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              placeholder=""
                              value={
                                getdatas.dateofbirth
                                  ? getdatas.dateofbirth.substring(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  dateofbirth: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Date of Birth</label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.Permanent_Address}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  Permanent_Address: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Permanent Address</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.address}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  address: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Current Address</label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.deviceUserId}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  deviceUserId: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Device User Id</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <button className="btn btn-danger" onClick={handleCanceled}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Step 2 */}
            {activeStep === 1 && (
              <div className="col-lg-12" style={{ marginTop: "-25px" }}>
                <div className="card p-4 pb-0">
                  <h4 className="mt-2 mb-4">Step 2</h4>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              placeholder=""
                              value={
                                getdatas.interview_date
                                  ? getdatas.interview_date.substring(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  interview_date: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Interview date</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              placeholder=""
                              value={
                                getdatas.join_date
                                  ? getdatas.join_date.substring(0, 10)
                                  : ""
                              }
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  join_date: e.target.value,
                                })
                              }
                              required
                            />
                            <label>join date</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <select
                              className="form-control"
                              value={getdatas.marital_status}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  marital_status: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="Married">Married</option>
                              <option value="Unmarried">Unmarried</option>
                            </select>
                            <label
                              className={
                                getdatas.marital_status ? "filled" : ""
                              }
                            >
                              Marital Status
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <select
                              className="form-control"
                              value={getdatas.gender}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  gender: e.target.value,
                                })
                              }
                              required
                            >
                              <option value=""> Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            <label className={getdatas.gender ? "filled" : ""}>
                              Gender
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handlePhotoUpload} // Store the file object
                              required
                            />
                            <label
                              className={getdatas.photo_upload ? "filled" : ""}
                            >
                              ID Proof
                            </label>

                            {/* Show a preview if the image is available */}
                            {image && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={image} // Show the preview of the uploaded image
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}

                            {/* Show server image if no local preview is available */}
                            {getdatas.photo_upload && !image && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={`${apiUrl}/uploads/${getdatas.photo_upload}`} // Use server file path
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}

                            {/* Display the file name, not the file object */}
                            {getdatas.photo_upload && (
                              <p>{getdatas.photo_upload.name}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.salary}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  salary: e.target.value,
                                })
                              }
                              required
                            />
                            <label>Salary</label>
                          </div>
                        </div>
                        <div className="col-lg-12">

                        <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.password}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  password: e.target.value,
                                })
                              }
                              required
                            />
                            <label>password</label>
                          </div>


                          <div className="col-lg-12">
                            <div className="floating-label">
                              <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={getdatas.timings}
                                onChange={(e) =>
                                  setdatas({
                                    ...getdatas,
                                    timings: e.target.value,
                                  })
                                }
                                required
                              />
                              <label>Timings</label>
                            </div>
                          </div>

                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={getdatas.notes}
                              onChange={(e) =>
                                setdatas({ ...getdatas, notes: e.target.value })
                              }
                              required
                            />
                            <label>Notes</label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <select
                              className="form-control"
                              value={getdatas.status}
                              onChange={(e) =>
                                setdatas({
                                  ...getdatas,
                                  status: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                              <option value="Relive">Relive</option>
                            </select>
                            <label className={getdatas.status ? "filled" : ""}>
                              Select status
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="file"
                              accept="application/pdf,image/*" // Restrict to documents or images
                              onChange={handleDocumentUpload}
                              required
                            />
                            <label
                              className={getdatas.documents ? "filled" : ""}
                            >
                              Documents
                            </label>

                            {/* Show a preview if an image file is uploaded */}
                            {imageFile && (
                              <div>
                                <p>Your Document Preview</p>
                                <img
                                  src={imageFile} // Show the preview of the uploaded document (if image)
                                  alt="Uploaded Document"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}

                            {getdatas.documents && !imageFile && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={`${apiUrl}/uploads/${getdatas.documents}`} // Use server file path
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}

                            {/* Show the uploaded file's name if no preview is available */}
                            {getdatas.documents && (
                              <div>
                                <p>
                                  Your Uploaded File: {getdatas.documents.name}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <button className="btn btn-secondary" onClick={handleBack}>
                    Back
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleupdatesubmit}
                  >
                    Update
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


export default Updatestaff;

