import axios from "axios";
import React, { useEffect, useState } from "react";
import apiUrl from "../../environment/Api";
import { useNavigate, useParams } from "react-router-dom";
import "../../assets/css/Addrole.css";

const modules = [
  {
    name: "Dashboard",
    id: 1,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Roles",
    id: 2,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Users",
    id: 3,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Branch",
    id: 4,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "New enquiry",
    id: 5,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry",
    id: 6,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Followups",
    id: 7,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Funnel",
    id: 8,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Walkin-Details",
    id: 9,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Studying Students",
    id: 10,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Students Dairy",
    id: 11,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Completed",
    id: 12,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Course",
    id: 13,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Schedule",
    id: 14,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff",
    id: 15,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance",
    id: 16,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Designation",
    id: 17,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Leave",
    id: 18,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "New Promotions",
    id: 19,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },

  {
    name: "Asset Type",
    id: 20,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Asset List",
    id: 21,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Type",
    id: 22,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense List",
    id: 23,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Type",
    id: 24,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income List",
    id: 25,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "invoice",
    id: 26,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Daily Report",
    id: 27,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Enquiry Report",
    id: 28,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Attendance Report",
    id: 29,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Expense Report",
    id: 30,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Student Report",
    id: 31,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Staff Report",
    id: 32,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
  {
    name: "Income Report",
    id: 33,
    actions: {
      all: false,
      add: false,
      edit: false,
      delete: false,
      view: false,
      download: false,
    },
  },
];

function UpdateRole() {
  const { id } = useParams();
  console.log("params id", id);

  const [moduleActions, setModuleActions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + `/role/${id}`);
        if (response && response.data) {
          const { rolename, modules } = response.data;
          console.log("modules", modules);
          setUpdateRoleData({
            rolename: rolename,
            modules: modules.map((module) => ({
              id: module.id,
              name: module.name,
              actions: { ...module.actions },
            })),
          });
          setModuleActions(
            modules.map((module) => ({
              id: module.id,
              actions: { ...module.actions },
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching update data:", error);
      }
    };

    if (id) {
      fetchData();
    } else {
      setModuleActions(
        modules.map((module) => ({
          id: module.id,
          actions: { ...module.actions },
        }))
      );
    }
  }, [id]);

  const navigate = useNavigate();

  const [updateRoleData, setUpdateRoleData] = useState({
    branch_id: "66d00c5f0d2fcb921b0186da",
    rolename: "",
    modules: modules.map((module) => ({
      id: module.id,
      name: module.name,
      actions: {
        all: false,
        add: false,
        edit: false,
        delete: false,
        view: false,
        download: false,
      },
    })),
  });

  console.log(updateRoleData);

  //   const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     setRoleData({
  //       ...roleData,
  //       [name]: value,
  //     });
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Debugging output
    console.log("Role data to submit:", updateRoleData);
    console.log("API URL:", `192.168.1.36/role_2/${id || ""}`);

    try {
      let response;

      if (id) {
        // If an ID is present, update the role
        console.log("Updating role with ID:", id);
        response = await axios.put(`${apiUrl}/role/${id}`, updateRoleData);
      } else {
        // If no ID, create a new role
        console.log("Creating new role");
        // response = await axios.post(`${apiUrl}/role`, roleData);
        response = await axios.post(
          `http://192.168.1.38:8000/api/role_2`,
          updateRoleData
        );
      }

      // Handle successful response
      if (response) {
        // Reset form data
        setUpdateRoleData({
          rolename: "",
          modules: modules.map((module) => ({
            id: module.id,
            name: module.name,
            actions: {
              all: false,
              add: false,
              edit: false,
              delete: false,
              view: false,
              download: false,
            },
          })),
        });
        navigate("/role");
      }

      console.log("Server response:", response.data);
    } catch (err) {
      console.error("Error during submit:", err);
      alert(
        "An error occurred while processing your request. Please try again."
      );
    }
  };

  const handleCheckboxChange = (moduleId, action) => {
    setModuleActions((prevModuleActions) =>
      prevModuleActions.map((module) => {
        if (module.id === moduleId) {
          if (action === "all") {
            const updatedActions = Object.keys(module.actions).reduce(
              (acc, key) => {
                acc[key] = !module.actions.all;
                return acc;
              },
              {}
            );
            return {
              ...module,
              actions: updatedActions,
            };
          } else {
            return {
              ...module,
              actions: {
                ...module.actions,
                [action]: !module.actions[action],
              },
            };
          }
        }
        return module;
      })
    );

    // Update roleData with selected module actions
    setUpdateRoleData((prevRoleData) => ({
      ...prevRoleData,
      modules: prevRoleData.modules.map((module) => {
        if (module.id === moduleId) {
          if (action === "all") {
            const updatedActions = Object.keys(module.actions).reduce(
              (acc, key) => {
                acc[key] = !module.actions.all;
                return acc;
              },
              {}
            );
            return {
              ...module,
              actions: updatedActions,
            };
          } else {
            return {
              ...module,
              actions: {
                ...module.actions,
                [action]: !module.actions[action],
              },
            };
          }
        }
        return module;
      }),
    }));
  };

  return (
    <>
      <div className="tops">
        <div className="admin-roles-main">
          {id ? (
            <h1 className="text-center">Update Roles</h1>
          ) : (
            <h4 className="">Update Roles</h4>
          )}
        </div>
        <div
          className="card p-3 mt-3"
          style={{ padding: "10px 10px", marginBottom: "20px" }}
        >
          <form onSubmit={handleSubmit}>
            {/* <h3 style={{ fontWeight: 600 }}>Admin Roles</h3> */}

            <div className="row mt-2">
              <div className="form-label">
                <b> Name:</b>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  id="role"
                  className="form-control"
                  placeholder="role name"
                  name="role"
                  value={updateRoleData.rolename}
                  onChange={(e) =>
                    setUpdateRoleData({
                      ...updateRoleData,
                      rolename: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mt-4">
                <div className="card-container table-responsive">
                  <table className=" admin-role-card">
                    <thead>
                      <tr className="text-center">
                        <th colSpan="7">Privilages</th>
                      </tr>
                      <tr className="text-center">
                        <th>Module</th>
                        <th>All</th>
                        <th>Add</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>View</th>
                        <th>Download</th>
                      </tr>
                    </thead>
                    <tbody>
                      {moduleActions.map((module, index) => (
                        <tr className="text-center" key={index}>
                          <td className="role-data-name">
                            {modules.find((item) => item.id === module.id).name}
                          </td>
                          <td className="role-data-all">
                            <input
                              type="checkbox"
                              checked={module.actions.all}
                              onChange={() =>
                                handleCheckboxChange(module.id, "all")
                              }
                            />
                          </td>
                          <td className="role-data-add">
                            <input
                              type="checkbox"
                              checked={module.actions.add}
                              onChange={() =>
                                handleCheckboxChange(module.id, "add")
                              }
                            />
                          </td>
                          <td className="role-data-edit">
                            <input
                              type="checkbox"
                              checked={module.actions.edit}
                              onChange={() =>
                                handleCheckboxChange(module.id, "edit")
                              }
                            />
                          </td>
                          <td className="role-data-delete">
                            <input
                              type="checkbox"
                              checked={module.actions.delete}
                              onChange={() =>
                                handleCheckboxChange(module.id, "delete")
                              }
                            />
                          </td>
                          <td className="role-data-view">
                            <input
                              type="checkbox"
                              checked={module.actions.view}
                              onChange={() =>
                                handleCheckboxChange(module.id, "view")
                              }
                            />
                          </td>
                          <td className="role-data-download">
                            <input
                              type="checkbox"
                              checked={module.actions.download}
                              onChange={() =>
                                handleCheckboxChange(module.id, "download")
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button
                className="btn btn-secondary"
                onClick={() => navigate("/role")}
              >
                Back
              </button>
              {id ? (
                <button type="submit" className="btn btn-info mx-4">
                  Update
                </button>
              ) : (
                <button type="submit" className="btn btn-info mx-4">
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateRole;
