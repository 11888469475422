import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import "../../assets/css/Studentinfo.css";

function StudentInfo() {
  const [activeTab, setActiveTab] = useState("profile");
  const [student, setStudent] = useState([]); // Initialize as null
  const [studentBranch, setStudentBranch] = useState(null);
  const [studentinvoice, setstudentinvoice] = useState([])
  const [paymentDetails, setpaymentDetails] = useState([]);
  const [paymentoverallDetails, setpaymentoverallDetails] = useState([]);
  const [studentDariry, setStudentDairy] = useState([]);

  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { id } = useParams();

  useEffect(() => {
    fetchDatas();
  }, [id]); // Fetch data whenever id changes

  const fetchDatas = async () => {
    try {
      console.log("fetchDatas called");
      console.log("id is: ", id);

      // Fetching student data
      const responseStudent = await axios.get(apiUrl + `/students/${id}`);
      console.log("from the server:", responseStudent.data);
      setStudent(responseStudent.data);

      const responsedairy = await axios.get(
        apiUrl + `/student_dairy_studentid/${id}`
      );
      console.log("from the server:", responsedairy.data);
      setStudentDairy(responsedairy.data);
      // Fetching student branch data
      const responseBranch = await axios.get(
        apiUrl + `/get_student_branch/${id}`
      );
      console.log("branch data from the server:", responseBranch.data);
      setStudentBranch(responseBranch.data);

      const response = await axios.get(
        apiUrl + `/studinginvoice/${id}`
      );
      console.log("studinginvoice data from the server:", response.data[0].father_name);
      console.log("studinginvoice data from the server:", response.data);
      setstudentinvoice(response.data);



    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Log student data whenever it changes
  useEffect(() => {
    if (student) {
      console.log("The student data is: ", student);
    }
  }, [student]);

  return (
    <div className="marginup">
      {/* Breadcrumbs */}
      <div className="row">
        <div className="col-lg-11">
          <Link to="/dashboard" className="text-secondary dashBoard_text">
            <b>Dashboard / </b>
          </Link>
          <Link
            to="/Studyingstudents"
            className="text-secondary dashBoard_text"
          >
            <b>Studying Students / </b>
          </Link>
          <Link to="" className="text-danger dashBoard_text">
            <b>Profile</b>
          </Link>
        </div>
      </div>

      {/* Tabs */}
      <div className="row mt-2 ">
        <div className="col-lg-6">
          <h5>
            <b>Student Profile</b>
          </h5>
        </div>
        {/* <div className="col-lg-2"></div> */}
        <div className="col-lg-5 ">
          <div className="row">
            <div className="col-lg-3">
              <div
                className={`card cardeds ${activeTab === "profile" ? "bg-success" : "bg-secondary"
                  }`}
                onClick={() => handleTabClick("profile")}
              >
                <button className="btn text-white timeline">
                  <i className="fa-solid fa-user mx-1"></i> Profile
                </button>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className={`card cardeds ${activeTab === "fees" ? "bg-success" : "bg-secondary"
                  }`}
                onClick={() => handleTabClick("fees")}
              >
                <button className="btn text-white timeline">
                  <i className="fa-solid fa-indian-rupee-sign mx-1"></i> Fees
                  Details
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className={`card cardeds ${activeTab === "timeline" ? "bg-success" : "bg-secondary"
                  }`}
                onClick={() => handleTabClick("timeline")}
              >
                <button className="btn text-white timeline">
                  <i className="fa-solid fa-hourglass-start mx-1"></i> Timeline
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-2">
          <button className="btn btn-danger">ID Proof</button>
        </div> */}
      </div>

      {/* Student Details */}
      <div className="row mt-4 mb-4">
        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-2">
            <div className="imgdetails">
              <img
                src={`${apiUrl}/uploads/${student.photo_upload || ""}`}
                alt=""
                className="img-fluid"
                style={{ height: "200px", width: "100%", objectFit: "contain" }}
              />
            </div>
            <div className="text-left mt-2 text-white">
              <h6 className="profile-details">
                <div className="row">
                  <div className="col-lg-2">
                    <i class="fa-solid fa-user"></i>
                  </div>
                  <div className="col-lg-10">
                    <b>{student.student_name_lead || ""}</b>
                  </div>
                </div>
              </h6>
              <h6 className="profile-details ">
                <div className="row ">
                  <div className="col-lg-2">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <div className="col-lg-10">
                    {student.mobile_no_lead || ""}
                  </div>
                </div>
              </h6>
              <h6 className="profile-details">
                <div className="row">
                  <div className="col-lg-2">
                    <i class="fa-solid fa-id-card"></i>
                  </div>
                  <div className="col-lg-10">{student.roll_no || ""}</div>
                </div>
              </h6>
              <h6 className="profile-details">
                <div className="row">
                  <div className="col-lg-2">
                    <i class="fa-solid fa-book"></i>
                  </div>
                  <div className="col-lg-10">
                    {student.Prefered_course_lead || ""}
                  </div>
                </div>
              </h6>
            </div>
          </div>
        </div>

        {/* Profile Information */}
        <div
          className={`${activeTab === "profile" ? "col-lg-6 col-sm-6 col-md-6" : "d-none"
            }`}
        >
          <div className="card cardborder p-1">
            <div className="row p-1">
              <div className="col-lg-6 col-md-6 col-sm-6 text-danger">
                <b>Profile Info</b>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 text-end editdetails">
                <b>+ Edit Details</b>
              </div>
            </div>
          </div>
          <div className="card cardborder">
            <div className="lightblue p-2">
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Branch </strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.branch_lead?.branch_name || ""}
                </div>
              </div>
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Registration No</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.register_no || ""}
                </div>
              </div>
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Father Name </strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.father_name || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Qualification</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.qualification || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Course Category</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.category || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Batch Timings</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.batch_timings || ""}
                </div>
              </div>

              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Admission Date</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.admission_date
                    ? new Date(student.admission_date).toLocaleDateString(
                      "en-CA"
                    )
                    : ""}
                </div>
              </div>
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Date of Birth</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.date_of_birth
                    ? new Date(student.date_of_birth).toLocaleDateString(
                      "en-CA"
                    )
                    : ""}
                </div>
              </div>

              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Enquiry Date</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.Enquiry_date_lead
                    ? new Date(
                      student[0]?.Enquiry_date_lead
                    ).toLocaleDateString("en-CA")
                    : ""}
                </div>
              </div>
              <div className="row p-1">
                <div className="col-lg-5">
                  <strong>Email</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.email_lead || ""}
                </div>
              </div>
              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Enquiry Purpose</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.purpose_enquiry_lead || ""}
                </div>
              </div>

              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Source Type</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.Source_type_lead || ""}
                </div>
              </div>

              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Trainer</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.trainer || ""}
                </div>
              </div>

              <div className="row mt-1 p-1">
                <div className="col-lg-5">
                  <strong>Placements</strong>
                </div>
                <div className="col-lg-6 text-secondary">
                  {student.placement || ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Fees and Timeline tabs (Placeholder) */}
        <div
          className={`${activeTab === "fees" ? "col-lg-6 col-sm-6 col-md-6" : "d-none"
            }`}
        >
          <div className="row cardborder ">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>Fees Details</b>
                </div>
              </div>
            </div>
            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date </th>
                    <th>Paid</th>
                    <th>Pay Type</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentDetails.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatDatefitching(item.date)}</td>
                        <td>
                          {item.course_id}-{item.details}
                        </td>

                        <td>{item.paid}</td>
                        <td>{item.paytype}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          className={`${activeTab === "timeline" ? "col-lg-6 col-sm-6 col-md-6" : "d-none"
            }`}
        >
          <div className="row cardborder ">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>Timeline</b>
                </div>
              </div>
            </div>
            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date </th>
                    <th>Login</th>
                    <th>logOut</th>
                    <th>Topic</th>
                  </tr>
                </thead>
                <tbody>
                  {studentDariry.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatDatefitching(item.date)}</td>
                        <td>{item.login}</td>

                        <td>{item.logout}</td>
                        <td>{item.topic_covered}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-1">
            <div className="imgdetails">
              <img
                src={`${apiUrl}/uploads/${student.id_proof || ""}`}
                alt=""
                className="img-fluid"
                style={{ height: "200px", width: "100%", objectFit: "contain" }}
              />
            </div>
          </div>


          <div className="card carddetails p-1">
            <div className="row p-1">
              <div className="col-lg-12 text-danger">
                <b>Payment/Duration</b>
              </div>
            </div>
          </div>
          <div className="card carddetails details-data_overall p-1">
            <div className="row details-data">
              <div className="col-lg-6 mt-1">
                <b>Total Fees</b>
              </div>
              <div className="col-lg-6 text-secondary">
                {student.fees || ""}
              </div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Paid</b>
              </div>
              <div className="col-lg-6 text-secondary">
                {studentinvoice[0]?.invoice_details[0]?.paid}

              </div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Balance</b>
              </div>
              <div className="col-lg-6 text-secondary">
                {studentinvoice[0]?.invoice_details[0]?.balance}
              </div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Discount</b>
              </div>
              <div className="col-lg-6 text-secondary">
                {student.discount || ""}
              </div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Duration</b>
              </div>
              <div className="col-lg-6 text-secondary">
                {student.duraction || ""}
              </div>
            </div>
            <div className="row mt-2 details-data">
              <div className="col-lg-6 mt-1">
                <b>Course Duration</b>
              </div>
              <div className="col-lg-6 text-secondary">
                {student.duraction_time || "0"}
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
            <div className="card cardborder ">
              <b className="text-danger p-1">Topics Covered</b>
            </div>
            <div className="card cardborder p-1 Completed-profile">
              {studentDariry.map((item, index) => (
                <>
                  <tr key={index}>
                    <td>{item.topic_covered}</td>
                  </tr>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentInfo;
