import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../../environment/Api';
import { useNavigate, useParams } from "react-router-dom";
import '../../assets/css/Student.css';
import '../../assets/css/Addstudent.css';
import { toast } from 'react-toastify';

function Updatestudents() {

    const [responseData, setResponseData] = useState([]);
    const [responseDatas, setResponseDatas] = useState([]);
    const [branch, setbranch] = useState([]);

    const navigate = useNavigate();
    const handlecancel = () => {
        navigate("/student");
    };




    const { id } = useParams();





    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [updateData, setUpdateData] = useState({

        branch_id: '',
        selectcourse: '',
        student_name: '',
        father_name: '',
        timings: '',
        date: formatDate(new Date()),
        dateofbirth: '',
        qualification: '',
        mobile_no: '',
        email: '',
        notes: '',
        status: '',
        location: '',
        callback_date: '',
        // convertedstatus: '',
        converted_date: '',
        completed_date: '',
        completed: '',
        student_type: '',
        source_type:'',
        completed: '',
        totalfees: '',
        paid: '',
        balance: '',
        roll_no: '',
        photo_upload: '',
        id_proof: '',
    })






    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + `/student/${id}`);
            console.log("Response from server-------:", response.data);
            if (response && response.data) {
                const data = response.data[0];

                setUpdateData({

                    branch_id: data.branch_id,
                    student_name: data.student_name,
                    father_name: data.father_name,
                    qualification: data.qualification,
                    selectcourse: data.selectcourse,
                    timings: data.timings,
                    date: data.date,
                    dateofbirth: data.dateofbirth,
                    mobile_no: data.mobile_no,
                    email: data.email,
                    notes: data.notes,
                    status: data.status,
                    location: data.location,
                    student_type: data.student_type,
                    source_type: data.source_type,
                    callback_date: data.callback_date,
                    
                    // convertedstatus: data.convertedstatus,
                    converted_date: data.converted_date,
                    completed_date: data.completed_date,
                    completed: data.completed,
                    totalfees: '',
                    paid: '',
                    balance: '',
                    roll_no: data.roll_no,
                    photo_upload: data.photo_upload,
                    id_proof: data.id_proof,

                })

                const filteredData = data.filter(item => item.status !== 'Converted');
                setResponseData(filteredData);
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };



    useEffect(() => {
        fetchDatas();
        fetchDataed();
    }, []);


    const fetchDataed = async () => {
        try {
            const response = await axios.get(apiUrl + "/Branch");
            console.log("Response from server:", response.data);
            setbranch(response.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };


    const fetchDatas = async () => {
        try {
            const response = await axios.get(apiUrl + "/course");
            console.log("Response from server:", response.data);

            if (response && response.data) {
                const data = response.data;

                setResponseDatas(data);
            } else {
                console.error("No data received from server.");
            }
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };







    const [updateid, setUpdateId] = useState("");

    const handleUpdate = (id, data) => {
        setUpdateId(id)
        console.log(" the data to updata", data)
        // setUpdateShowModal(true);
        setUpdateData({

            branch_id: data.branch_id,
            student_name: data.student_name,
            father_name: data.father_name,
            qualification: data.qualification,
            selectcourse: data.selectcourse,
            timings: data.timings,
            date: data.date,
            dateofbirth: data.dateofbirth,
            mobile_no: data.mobile_no,
            email: data.email,
            notes: data.notes,
            status: data.status,
            location: data.location,
            student_type: data.student_type,
            source_type: data.source_type,
            callback_date: data.callback_date,
            // convertedstatus: data.convertedstatus,
            converted_date: data.converted_date,
            completed_date: data.completed_date,
            completed: data.completed,
            totalfees: '',
            paid: '',
            balance: '',
            roll_no: data.roll_no,
            photo_upload: data.completed,
            id_proof: data.id_proof,

        })

    }
    // ///////////////////////////////////////

    const handleUpdated = async (e) => {

        e.preventDefault();
        try {
            const formData = {

                branch_id: updateData.branch_id,
                student_name: updateData.student_name,
                father_name: updateData.father_name,
                qualification: updateData.qualification,
                selectcourse: updateData.selectcourse,
                timings: updateData.timings,
                date: updateData.date,
                dateofbirth: updateData.dateofbirth,
                mobile_no: updateData.mobile_no,
                email: updateData.email,
                notes: updateData.notes,
                status: updateData.status,
                location: updateData.location,
                callback_date: updateData.callback_date,
                // convertedstatus: updateData.convertedstatus,
                student_type: updateData.student_type,
                source_type:updateData.source_type,
                converted_date: updateData.converted_date,
                completed_date: updateData.completed_date,
                completed: updateData.completed,
                totalfees: '',
                paid: '',
                balance: '',
                roll_no: updateData.roll_no,
                photo_upload: updateData.photo_upload,
                id_proof: updateData.id_proof,
            }
            console.log("the update submit data", formData)

            if (updateData.photo_upload) formData.append('photo_upload', updateData.photo_upload);
            if (updateData.id_proof) formData.append('id_proof', updateData.id_proof);

            const response = await axios.put(apiUrl + `/student/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            console.log("response for update", response)

            toast.success("Update successfully!");

            fetchData();
            navigate("/student");
        }
        catch (error) {
            console.error("Error updating data:", error.message);
        }

    }





    const handleStatusChangeupdate = (e) => {
        const selectedStatus = e.target.value;
        setUpdateData({ ...updateData, status: selectedStatus });

        if (selectedStatus === "Callback") {
            setShowCallbackDate(true);
        }
        else {
            setShowCallbackDate(false);
            setUpdateData(prevData => ({
                ...prevData,
                selectedStatus: ''
            }));
        }
    };


    const [showCallbackDate, setShowCallbackDate] = useState(false);


    return (
        <div className='style' style={{ marginTop: '-50px' }}>
            <h3> Update Student Details</h3>

            <div className='row pt-3'>
                <div className='col-lg-6'>
                    <div className='card p-2'>
                        <div className="form-group">


                            <div className="floating-label">
                                <label className="form-label">
                                    <b></b>
                                </label>
                                <select
                                    className="form-select"
                                    value={updateData.branch_id}
                                    onChange={(e) => setUpdateData({ ...updateData, branch_id: e.target.value })}
                                    required
                                >
                                    <option value="">Select Branch </option>
                                    {branch.map((item, index) => (
                                        <option key={index} value={item._id}>
                                            {item.branch_id}
                                        </option>
                                    ))}
                                </select>
                                <label className={updateData.branch_id ? "filled" : ""}>

                                </label>
                            </div>





                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={updateData.student_name} onChange={(e) => setUpdateData({ ...updateData, student_name: e.target.value })} required />
                                <label className={updateData.student_name ? "filled" : ""}>
                                    Student Name
                                </label>
                            </div>





                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={updateData.father_name} onChange={(e) => setUpdateData({ ...updateData, father_name: e.target.value })} required />
                                <label className={updateData.father_name ? "filled" : ""}>
                                    Father Name
                                </label>
                            </div>



                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={updateData.qualification} onChange={(e) => setUpdateData({ ...updateData, qualification: e.target.value })} />
                                <label className={updateData.qualification ? "filled" : ""}>
                                    Qualification
                                </label>
                            </div>


                            {/* Other input fields */}


                            <div className="floating-label">
                                <input className="form-control" type="text" placeholder='' value={updateData.location} onChange={(e) => setUpdateData({ ...updateData, location: e.target.value })} />
                                <label className={updateData.location ? "filled" : ""}>
                                    Location
                                </label>
                            </div>




                            <div className="floating-label">
                                <select className="form-select" value={updateData.selectcourse} placeholder='' onChange={(e) => setUpdateData({ ...updateData, selectcourse: e.target.value })} required>
                                    {responseDatas.map((item, index) => (
                                        <option key={index} value={item.course_name
                                        }>{item.course_name
                                            }</option>
                                    ))}
                                </select>

                                <label className={updateData.selectcourse ? "filled" : ""}>
                                    Prefered Course
                                </label>
                            </div>








                            <div className="floating-label">
                                <input
                                    className="form-control"
                                    type="date"
                                    value={updateData.date ? new Date(updateData.date).toISOString().split('T')[0] : ''}
                                    onChange={(e) => setUpdateData({ ...updateData, date: e.target.value })}
                                    required />
                                <label className={updateData.date ? "filled" : ""}>
                                    Date
                                </label>
                            </div>



                        </div>
                    </div>
                </div>
                <div className='col-lg-6 '>
                    <div className='card p-2'>

                        <div className="floating-label  mt-1 pb-4">
                            <select
                                className="form-control" value={updateData.timings}
                                onChange={(e) => setUpdateData({ ...updateData, timings: e.target.value })} required>
                                <option value="10.00am-11.30am">10.00am - 11.30am</option>
                                <option value="11.30am-1.00pm">11.30am - 01.00pm</option>
                                <option value="04.30pm-06.00pm">04.30pm - 06.00pm</option>
                                <option value="06.00pm-07.30pm">06.00pm - 07.30pm</option>
                            </select>
                            <label className={updateData.timings ? "filled" : ""}>
                                Batch Timing
                            </label>
                        </div>



                        <div className="floating-label">
                            <input
                                className="form-control"
                                type="date"
                                value={updateData.dateofbirth ? new Date(updateData.dateofbirth).toISOString().split('T')[0] : ''}
                                onChange={(e) => setUpdateData({ ...updateData, dateofbirth: e.target.value })} />
                            <label className={updateData.dateofbirth ? "filled" : ""}>
                                Date Of Birth
                            </label>
                        </div>


                        <div className="floating-label">
                            <input className="form-control" type="tel" placeholder='' value={updateData.mobile_no} onChange={(e) => setUpdateData({ ...updateData, mobile_no: e.target.value })} required />
                            <label className={updateData.mobile_no ? "filled" : ""}>
                                Mobile No
                            </label>
                        </div>


                        <div className="floating-label">
                            <input className="form-control" type="email" placeholder='' value={updateData.email} onChange={(e) => setUpdateData({ ...updateData, email: e.target.value })} required />

                            <label className={updateData.email ? "filled" : ""}>
                                Email
                            </label>
                        </div>


                        <div className="floating-label">
                            <input className="form-control" type="text" placeholder='' value={updateData.notes} onChange={(e) => setUpdateData({ ...updateData, notes: e.target.value })} />
                            <label className={updateData.notes ? "filled" : ""}>
                                Notes
                            </label>
                        </div>



                        <div className="floating-label">
                            <select
                                className="form-control"
                                value={updateData.source_type}
                               
                                onChange={(e) => setUpdateData({ ...updateData, source_type: e.target.value })}
                                required>
                                
                                <option value="google Ad">google Ad</option>
                                <option value="Linkedln">Instagram</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Naukri">clg program</option>
                                <option value="Referal">Referal</option>


                            </select>
                            <label className={updateData.source_type ? "filled" : ""}>
                            Source type
                            </label>
                        </div>







                        <div className="floating-label">
                            <select
                                className="form-control"
                                onChange={(e) => setUpdateData({ ...updateData, student_type: e.target.value })}
                                required>

                                <option value="Studying">Studying</option>
                                <option value="Working">Working</option>
                                <option value="Jobseeking">Jobseeking</option>

                            </select>
                            <label className={updateData.student_type ? "filled" : ""}>
                                Student Type
                            </label>
                        </div>



                        <div className="floating-label">
                            <select
                                className="form-control"
                                value={updateData.status}
                                onChange={handleStatusChangeupdate}
                                required  >
                                <option value="">Select status</option>

                                       
                                        <option value="New">New</option>
                                        <option value="contacted">contacted</option>
                                        <option value="Followed Scheduled">Followup Scheduled</option>        
                                        <option value="Converted">Converted</option>
                            </select>
                            <label >

                            </label>
                        </div>







                        {showCallbackDate && ( // Show the callback date field if 'Callback' status is selected
                            <div className="form-group mt-2">
                                <label className="form-label my-1"><b>Callback Date:</b></label>
                                <input className="form-control" type="date" value={updateData.callback_date} onChange={(e) => setUpdateData({ ...updateData, callback_date: e.target.value })} required />
                            </div>
                        )}


                    </div>
                </div>


                <div className="row mt-5 d-flex justify-content-end mb-5">
                    <div className="col-lg-6 text-center d-flex justify-content-start">
                        <button className='Cancelbut' variant="secondary" onClick={handlecancel}>Cancel</button>
                    </div>
                    <div className="col-lg-6 text-center d-flex justify-content-end">
                        <button className='Submitbut' type='submit' variant="primary" onClick={handleUpdated}>Update</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Updatestudents
