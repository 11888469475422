
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import apiUrl from "../src/environment/Api";
import axios from "axios";
import "./App.css";
import Sidebar from "./components/sidebar/Sidebar";
import Sidebarstaff from "./components/staffadmin/sidebar/Sidebarstaff";
import Navbar from "./components/Navbar";
import Navbarstaff from "./components/staffadmin/sidebar/Navbarstaff";
import Dashboard from "./components/pages/Dashboard";
import Role from "./components/pages/Role";
import Addrole from "./components/pages/Addrole";
import User from "./components/pages/User";
import Course from "./components/pages/Course";
import Student from "./components/pages/Student";
import Studyingstudents from "./components/pages/Studyingstudent";
import Completed from "./components/pages/Completed";

import Staff from "./components/pages/Staff";
import StaffInfo from "./components/pages/StaffInfo";
import Addstaff from "./components/pages/Addstaff";
import Updatestaff from "./components/pages/Updatestaff";
import Attendance from "./components/pages/Attendance";
import Assettype from "./components/pages/Assettype";
import Assetlist from "./components/pages/Assetlist";
import Expensetype from "./components/pages/Expensetype";
import Expenselist from "./components/pages/Expenselist";
import Incometype from "./components/pages/Incometype";
import Incomelist from "./components/pages/Incomelist";
import Designation from "./components/pages/Designation";
import Login from "./components/login/Login";
import Invoice from "./components/pages/Invoice";
import Leave from "./components/pages/Leave";

// Sraff admin

import Task from "././components/staffadmin/pages/Task";
import Stafflogin from "./components/staffadmin/stafflogin/Stafflogin";
import Profile from "./components/staffadmin/pages/Profile";
import Attendancestaff from "./components/staffadmin/pages/Attendancestaff";
import Leavestaff from "./components/staffadmin/pages/Leavestaff";
import Schedule from "./components/pages/schedule";
import Addinvoice from "./components/pages/Addinvoice";
import Print from "./components/pages/Print";
import Footer from "./components/footer/Footer";
import ProtectedRoute from "./components/pages/Helper";
import Editrole from "./components/pages/Editrole";
import Branch from "./components/pages/Branch";
import Addstudent from "./components/pages/Addstudent";
import Updatestudents from "./components/pages/Updatestudents";
import Updateinvoice from "./components/pages/Updateinvoice";
import StudentInfo from "./components/pages/StudentInfo";
import Followups from "./components/pages/Followups";
import Walkin from "./components/pages/Walkin";
import Sourcetype from "./components/pages/Sourcetype";

import Studentdairy from "./components/pages/Studentdairy";
import DailyReport from "./components/pages/DailyReport";
import AttendanceReport from "./components/pages/AttendanceReport";
import EnquiryReport from "./components/pages/EnquiryReport";
import Leadhistroy from "./components/pages/Leadhistroy";
import Registration from "./components/pages/Registration";
import RegistrationUpdate from "./components/pages/RegistrationUpdate";
import Enquiry from "./components/pages/Enquiry";
import Funnel from "./components/pages/Funnel";
import CourseInfo from "./components/pages/CourseInfo";
import NewPromotions from "./components/pages/NewPromotions";
import ExpenseReport from "./components/pages/ExpenseReport";
import StudentReport from "./components/pages/StudentReport";
import IncomeReport from "./components/pages/IncomeReport";
import StaffReport from "./components/pages/StaffReport";
import CoursePreview from "./components/pages/CoursePreview";
import UpdateRole from "./components/pages/UpdateRole";
import Salary from "./components/pages/Salary";
import Job from "./components/pages/Job";
import JobMemeberInfo from "./components/pages/JobMemberInfo";
import Category from "./components/pages/Category";

import Jobportal from "./components/pages/Jobportal";
import JobDetails from "./components/pages/JobDetails";

import Feeduefollowups from "./components/pages/Feeduefollowups";

import Notfounded from "./components/pages/Notfounded";
import Enquirypurpose from "./components/pages/Enquirypurpose";

import Pay_type from "./components/pages/Pay_type";
import VendarDetails from "./components/pages/VendarDetails";
import CourseImagePreview from "./components/pages/CourseImagePreview";

import InvoiceReport from "./components/pages/InvoiceReport";
import Studentinvoice from "./components/pages/Studentinvoice";



import HolidayLeave from "./components/pages/HolidayLeave";
import Calender from "./components/pages/Calender";
import Attendanceoverall from "./components/pages/Attendanceoverall";



const App = () => {



  // const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  // const inactivityLimit = 1 * 60 * 1000; // 10 minutes in milliseconds


  // // Function to log out the user
  // const logoutUser = () => {
  //   // Remove any session or authentication token
  //   localStorage.removeItem("userToken");
  //   sessionStorage.removeItem("userToken");
  //   window.location.href = "/";  // Redirect to the login page
  // };

  // // Reset inactivity timer on user interaction
  // const resetInactivityTimer = () => {
  //   setLastActivityTime(Date.now());
  // };

  // useEffect(() => {
  //   // Check inactivity every minute
  //   const interval = setInterval(() => {
  //     if (Date.now() - lastActivityTime >= inactivityLimit) {
  //       logoutUser();  // Log out the user if inactive for 10 minutes
  //     }
  //   }, 60000);  // Check every 1 minute

  //   // Add event listeners to detect user interaction
  //   const events = ["mousemove", "keydown", "click"];
  //   events.forEach(event => window.addEventListener(event, resetInactivityTimer));

  //   // Cleanup event listeners and interval on component unmount
  //   return () => {
  //     clearInterval(interval);  // Clear the inactivity timer interval
  //     events.forEach(event => window.removeEventListener(event, resetInactivityTimer));
  //   };
  // }, [lastActivityTime]);


  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const inactivityLimit = 600 * 60 * 1000; // 10 minutes in milliseconds
  // const navigate = useNavigate();  // Now useNavigate works because we are inside Router context

  // Function to log out the user and handle backend logout
  const logoutUser = async () => {
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");

    // Check if token and username exist
    if (token && username) {
      try {
        // Make the API request to log out the user from the backend
        const response = await axios.post(apiUrl + '/logoutadmin', { username });

        if (response.data && response.data.message) {
          console.log(response.data.message); // Log the backend response
        } else {
          console.error("Logout failed. Please try again.");
        }

        // Clear localStorage items after successful logout
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("branch_code");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("branch_name");
        localStorage.removeItem("role_id");
        localStorage.removeItem("type");
        localStorage.removeItem("username");

        // Redirect to the login page
        // navigate("/login"); // Redirect to the login page
        // window.location.reload(); 
        window.location.href = "/";  // Reload the page to reset any remaining state
      } catch (error) {
        console.error("Error during backend logout:", error);
      }
    } else {
      console.error("No token or username found, already logged out.");
    }
  };

  // Reset inactivity timer on user interaction (mousemove, keydown, click)
  const resetInactivityTimer = () => {
    setLastActivityTime(Date.now());
  };

  useEffect(() => {
    // Check inactivity every minute
    const interval = setInterval(() => {
      const token = localStorage.getItem("token");

      // Only check for inactivity if token exists
      if (token && Date.now() - lastActivityTime >= inactivityLimit) {
        logoutUser();  // Log out the user if inactive for 10 minutes
      }
    }, 60000);  // Check every 1 minute

    // Add event listeners to detect user interaction
    const events = ["mousemove", "keydown", "click"];
    events.forEach(event => window.addEventListener(event, resetInactivityTimer));

    // Cleanup event listeners and interval on component unmount
    return () => {
      clearInterval(interval);  // Clear the inactivity timer interval
      events.forEach(event => window.removeEventListener(event, resetInactivityTimer));
    };
  }, [lastActivityTime]);





  return (
    <Router>
      <Routes>
        {/* user admin */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Dashboard /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/role"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Role /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/addrole"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Addrole />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />


        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Notfounded />
            </ProtectedRoute>
          }
        />

        <Route
          path="/updaterole"
          element={
            <ProtectedRoute>
              <Sidebar>
                <UpdateRole />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/user"
          element={
            <ProtectedRoute>
              <Sidebar>
                <User /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/branch"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Branch />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/course"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Course />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/category"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Category />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/courseinfo/:id/:course_id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <CourseInfo />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/coursepreview/:id/:course_id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <CoursePreview />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/newpromotions"
          element={
            <ProtectedRoute>
              <Sidebar>
                <NewPromotions />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/student"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Student />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Enquiry"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Enquiry />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/studyingstudents"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Studyingstudents />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/completed"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Completed />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/courseimagepreview/:id/:course_id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <CourseImagePreview />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/addstudent"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Addstudent />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/updatestudents/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Updatestudents /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staff"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Staff />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staffinfo/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <StaffInfo />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/studentinvoice/:id"
          element={
            <ProtectedRoute>

              <Studentinvoice />


            </ProtectedRoute>
          }
        />
        <Route
          path="/addstaff"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Addstaff />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Studentdairy"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Studentdairy />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Studentinfo/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <StudentInfo />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Followups"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Followups />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Funnel"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Funnel />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Walkin"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Walkin />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Sourcetype"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Sourcetype />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Enquirypurpose"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Enquirypurpose />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/paytype"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Pay_type />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
          <Route
          path="/Calender/:empid"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Calender />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/job"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Job />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobportal"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Jobportal />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/JobDetails/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <JobDetails />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/jobMemberinfo"
          element={
            <ProtectedRoute>
              <Sidebar>
                <JobMemeberInfo />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Registration/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Registration />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Registrationupdate/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <RegistrationUpdate />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/updatestaff/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Updatestaff />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Leadhistroy/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Leadhistroy />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/attendance"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Attendance />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />

<Route
          path="/attendanceoverall"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Attendanceoverall />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/assettype"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Assettype />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/assetlist"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Assetlist />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/expensetype"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Expensetype />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/expenselist"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Expenselist />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendarDetails"
          element={
            <ProtectedRoute>
              <Sidebar>
                <VendarDetails />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/incometype"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Incometype />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/incomelist"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Incomelist />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/holiday"
          element={
            <ProtectedRoute>
              <Sidebar>
                <HolidayLeave />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/designation"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Designation />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoice"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Invoice />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dailyreport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <DailyReport />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/AttendanceReport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <AttendanceReport /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />

        <Route
          path="/invoiceReport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <InvoiceReport /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ExpenseReport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <ExpenseReport /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staffreport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <StaffReport /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/StudentReport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <StudentReport /> <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/incomereport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <IncomeReport />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Enquiryreport"
          element={
            <ProtectedRoute>
              <Sidebar>
                {" "}
                <EnquiryReport />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/leave"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Leave />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/salary"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Salary />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Schedule />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Addinvoice"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Addinvoice />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route
          path="/updateinvoice/:id"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Updateinvoice />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />
        <Route path="/Print/:id" element={<Print />} />
        {/* <Route path="/admin" element={<Login />} /> */}
        <Route path="/" element={<Login />} />
        <Route
          path="/edit"
          element={
            <ProtectedRoute>
              <Sidebar>
                <Editrole />
                <Footer />
              </Sidebar>
            </ProtectedRoute>
          }
        />

        {/* staffadmin */}
        <Route
          path="/leavestaff"
          element={
            <ProtectedRoute>
              {" "}
              <Sidebarstaff>
                <Leavestaff />
                <Footer />
              </Sidebarstaff>
            </ProtectedRoute>
          }
        />
        <Route
          path="/task"
          element={
            <ProtectedRoute>
              <Sidebarstaff>
                <Task />
              </Sidebarstaff>
              <Footer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:id"
          element={
            <ProtectedRoute>
              {" "}
              <Sidebarstaff>
                <Profile />
                <Footer />
              </Sidebarstaff>
            </ProtectedRoute>
          }
        />
        <Route
          path="/attendancestaff"
          element={
            <ProtectedRoute>
              {" "}
              <Sidebarstaff>
                <Attendancestaff />
                <Footer />
              </Sidebarstaff>
            </ProtectedRoute>
          }
        />
        <Route
          path="/feeduefollowups"
          element={
            <ProtectedRoute>
              <Sidebarstaff>
                <Feeduefollowups />
              </Sidebarstaff>
              <Footer />
            </ProtectedRoute>
          }
        />
        {/* <Route path="/" element={<Stafflogin />} /> */}
        <Route path="/stafflogin" element={<Stafflogin />} />
      </Routes>
    </Router>
  );
};

export default App;
