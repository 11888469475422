import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../../environment/Api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";

function Attendance() {
  const [responseData, setResponseData] = useState([]);
  const [attendance_overall, setattendance_overall] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateShowModal, setUpdateShowModal] = useState(false);
  const [responseDatas, setResponseDatas] = useState([]);
  const [addDatass, setAddDatass] = useState({ empid: "", name: "" });
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [branch, setbranch] = useState([]);




  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  const navigate = useNavigate();

  const handleCourseInfo = (empid) => {
    navigate(`/Calender/${empid}`);
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [addData, setAddData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    date: formatDate(new Date()),
    login: "",
    lunch_out: "",
    lunch_in: "",
    logout: "",
    totalhour: "",
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    empid: "",
    name: "",
    date: formatDate(new Date()),
    login: "",
    lunch_out: "",
    lunch_in: "",
    logout: "",
    totalhour: "",
  });

  const handleDateChange = (e) => {
    setAddData((prevData) => ({
      ...prevData,
      date: e.target.value,
    }));
  };
  const [rolename, setrolename] = useState([]);
  useEffect(() => {
    fetchData();
    const rolename = localStorage.getItem("rolename");
    setrolename(rolename);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/attendance", {
        params: { data: localStorage.getItem("branch_id") },
      });

      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
      
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchData2 = async () => {
    try {
      const responseovelldata = await axios.get(apiUrl + "/attendance_overall");
      console.log("Response from server with :", responseovelldata.data);
      setattendance_overall(responseovelldata.data);

    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData2();
    fetchDatas();
    fetchDataed();
    gettoken();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/staff");
      console.log("Response from:", response.data.reverse());
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);

      setroleid(response.data.detail.rolename);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;
      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const railwayToNormalTime = (railwayTime) => {
    const [hour, minute] = railwayTime.split(":");
    const parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);
    if (parsedHour < 12) {
      return `${parsedHour}:${minute} `;
    } else if (parsedHour === 12) {
      return `${parsedHour}:${minute} `;
    } else {
      return `${parsedHour - 12}:${minute} `;
    }
  };

  const calculateWorkHours = (login, logout) => {
    const loginTime = new Date(`1970-01-01T${login}:00`);
    const logoutTime = new Date(`1970-01-01T${logout}:00`);

    const totalWorkTime = logoutTime - loginTime;

    // Check for valid time difference
    if (totalWorkTime < 0) {
      console.error("Logout time must be after login time");
      return "00:00"; // Return default value if times are invalid
    }

    const hours = Math.floor(totalWorkTime / (1000 * 60 * 60));
    const minutes = Math.floor(
      (totalWorkTime % (1000 * 60 * 60)) / (1000 * 60)
    );

    console.log("the return value", loginTime, logoutTime);
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }
    const workhour = calculateWorkHours(addData.login, addData.logout);

    try {
      const formData = {
        branch_id: addData.branch_id,
        empid: addData.empid,
        name: addData.name,
        date: addData.date,
        login: addData.login,
        lunch_out: addData.lunch_out,
        lunch_in: addData.lunch_in,
        logout: addData.logout,
        workhour: workhour,
      };

      const response = await axios.post(apiUrl + "/attendance", formData);
      console.log("Added:", response);
      setShowModal(false);
      fetchData();
      toast.success("Added successfully!");
      setAddData({
        branch_id: "",
        empid: "",
        name: "",
        date: "",
        login: "",
        lunch_out: "",
        lunch_in: "",
        logout: "",
        workhour: "",
      });
    } catch (error) {
      console.error("Error adding data:", error.message);
    }
  };

  const [updateId, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log("The data to update", data);
      setUpdateShowModal(true);
      setUpdateData({
        branch_id: data.branch_id,
        empid: data.empid,
        name: data.name,
        date: data.date,
        login: data.login,
        logout: data.logout,
        workhour: data.workhour,
      });
    } else {
      toast.error("You don't have the permission to update");
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const workhour = calculateWorkHours(updateData.login, updateData.logout);
    try {
      const formData = {
        branch_id: updateData.branch_id,
        empid: updateData.empid,
        name: updateData.name,
        date: updateData.date,
        login: updateData.login,
        logout: updateData.logout,
        workhour: workhour,
      };
      console.log("The update submit data111", formData);
      const response = await axios.put(
        apiUrl + `/attendance/${updateId}`,
        formData
      );
      console.log("Response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        apiUrl + `/attendance/${getdeleteid}`
      );

      console.log("Deleted data", response);

      toast.error("Deleted successfully!");
      setDeleteShowModals(false);
      fetchData();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, name) => {
    if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", name);
      setdeleteid(_id);
      setdeletename(name);
      setDeleteShowModals(true);
    } else {
      toast.error("You don't have the permission to delete");
    }
  };

  // Pagination and search logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  const handleEmpolye = (e) => {
    const selectedEmpData = responseDatas[e.target.selectedIndex - 1];
    setAddData({
      ...addData,
      empid: e.target.value,
      name: selectedEmpData.name,
    });
  };

  useEffect(() => {
    console.log(addData);
  }, [addData]);

  const formatDatefitching = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };

  const calculateTotalHours = () => {
    const { login, logout } = updateData;

    if (login && logout) {
      const loginTime = new Date(`1970-01-01T${login}`);
      const logoutTime = new Date(`1970-01-01T${logout}`);
      const totalHours = (logoutTime - loginTime) / (1000 * 60 * 60); // Convert ms to hours

      setUpdateData((prevData) => ({
        ...prevData,
        totalhour: totalHours >= 0 ? totalHours.toFixed(2) : "",
      }));
    } else {
      setUpdateData((prevData) => ({
        ...prevData,
        totalhour: "",
      }));
    }
  };

  // Effect to recalculate total hours when login or logout changes
  useEffect(() => {
    calculateTotalHours();
  }, [updateData.login, updateData.logout]);

  function overallattendance() {
    setResponseData(attendance_overall);
    setattendance_overall(responseData);
    // setResponseData(responseData)
    // setattendance_overall(attendance_overall)
  }
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Correct the axios call
        const response = await axios.post(
          apiUrl + "/staff_attendance_upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for file upload
            },
          }
        );

        // Handle the response (assuming the backend sends JSON)
        if (response.status === 200) {
          console.log("File uploaded successfully:", response.data);
        } else {
          console.error("File upload failed:", response.data);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };



  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.branch_id) {
      tempErrors.branch_id = "Branch is required.";
      isValid = false;
    }

    if (!addData.empid) {
      tempErrors.empid = "StaffId is required.";
      isValid = false;
    }

    if (!addData.name) {
      tempErrors.name = "Name is required.";
      isValid = false;
    }

    if (!addData.login) {
      tempErrors.login = "Login  is required.";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  }











  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Attendance</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-9">
          <div className="d-flex ">
            <h4 className="mt-2">Attendance</h4>
            <div className="mx-3">
              {previllages.add && (
                <button
                  className="btn btn-success add mx-3"
                  onClick={() => setShowModal(true)}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-1">
          <button className="btn  btn-danger" onClick={() => navigate("/attendanceoverall")}>
            Overall
          </button>
        </div>

        <div className="col-lg-2">
          <div>
            <input
              type="file"
              id="file-upload"
              accept=".xls,.xlsx"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <label htmlFor="file-upload" className="btn btn-secondary">
              File Upload
            </label>
          </div>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                {rolename === "superadmin" && <th>Branch</th>}
                <th>Staff Id </th>
                <th>Name </th>
                <th>Date</th>
                <th>Log In</th>
                <th>Log Out</th>
                <th>Working Hour</th>
                <th className="text-center" id="tablebutton">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                  {rolename === "superadmin" && (
                    <td>
                      {item.branch_id ? item.branch_id.branch_name : "N/A"}
                    </td>
                  )}
                  <td>{item.empid}</td>
                  <td>{item.name}</td>
                  <td>{new Date(item.date).toLocaleDateString()}</td>
                  <td>{railwayToNormalTime(item.login || "")}</td>
                  <td>{railwayToNormalTime(item.logout || "")}</td>
                  <td>{item.workhour} hours</td>

                  <td className="text-center">
                    {previllages.edit && (
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleUpdate(item._id, item)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    )}
                    {previllages.delete && (
                      <button
                        className="btn delete "
                        onClick={() => handleOpenModal(item._id, item.empid)}
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    )}



                    {previllages.view && (
                      <button
                        className="btn "
                        onClick={() => handleCourseInfo(item.empid)}
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={addData.branch_id}
                      onChange={(e) =>
                        setAddData({ ...addData, branch_id: e.target.value })
                      }

                    >
                      <option value="">Select Branch</option>
                      {branch.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.branch_id} - {item.branch_name}
                        </option>
                      ))}
                    </select>
                    <label className={addData.branch_id ? "filled" : ""}>
                      Select Branch
                    </label>
                    {errors.branch_id && (
                      <div className="error-text">{errors.branch_id}</div>
                    )}

                  </div>


                  <div className="floating-label">
                    <label className="form-label"></label>
                    <select
                      className="form-select"
                      value={addData.empid}
                      onChange={(e) => handleEmpolye(e)}
                    >
                      <option value="">Select Staff Type</option>
                      {responseDatas.map((item, index) => (
                        <option key={index} value={item.empid}>
                          {item.empid} - {item.name}
                        </option>
                      ))}
                    </select>
                    {errors.empid && (
                      <div className="error-text">{errors.empid}</div>
                    )}
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="text"
                      value={addData.name}
                      onChange={(e) =>
                        setAddData({ ...addData, name: e.target.value })
                      }

                    />
                    <label className={addData.name ? "filled" : ""}>Name</label>
                    {errors.name && (
                      <div className="error-text">{errors.name}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <div className="floating-label">
                    <input
                      className="form-control"
                      type="date"
                      value={addData.date}
                      onChange={(e) =>
                        setAddData({ ...addData, date: e.target.value })
                      }
                      required
                    />
                    <label className={addData.date ? "filled" : ""}>Date</label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="time"
                      className="form-control"
                      value={addData.login}
                      onChange={(e) =>
                        setAddData({ ...addData, login: e.target.value })
                      }

                    />
                    <label className={addData.login ? "filled" : ""}>
                      Log In
                    </label>
                    {errors.login && (
                      <div className="error-text">{errors.login}</div>
                    )}
                  </div>

                  {/* Logout Time */}

                  <div className="floating-label">
                    <input
                      type="time"
                      className="form-control"
                      value={addData.logout}
                      onChange={(e) =>
                        setAddData({ ...addData, logout: e.target.value })
                      }
                      disabled
                      required
                    />
                    <label className={addData.logout ? "filled" : ""}>
                      Logout
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="modalbutton">
              <Button
                variant="secondary"
                className="mt-3 cancelbut"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <button
                type="submit"
                className="btn btn-primary mt-3 mx-2 submitbut"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Update Modal */}
      <Modal
        className="custom-modal"
        show={isUpdateShowModal}
        onHide={() => setUpdateShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpdateSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={updateData.empid}
                    readOnly
                  />
                  <label className={updateData.empid ? "filled" : ""}>
                    Staff ID
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.branch_id.branch_name}
                    onChange={(e) =>
                      setAddData({ ...updateData, branch_id: e.target.value })
                    }
                    required
                  />
                  <label
                    className={updateData.branch_id.branch_name ? "filled" : ""}
                  >
                    Branch
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    type="type"
                    className="form-control"
                    placeholder=""
                    value={updateData.name}
                  />
                  <label className={updateData.name ? "filled" : ""}>
                    Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    type="date"
                    className="form-control"
                    placeholder=""
                    value={formatDatefitching(updateData.date)}
                    onChange={handleDateChange}
                  />
                  <label className={updateData.date ? "filled" : ""}>
                    Date
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    type="time"
                    className="form-control"
                    placeholder=""
                    value={updateData.login}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, login: e.target.value })
                    }
                  />
                  <label className={updateData.login ? "filled" : ""}>
                    Log In
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    type="time"
                    className="form-control"
                    placeholder=""
                    value={updateData.logout}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, logout: e.target.value })
                    }
                  />
                  <label className={updateData.logout ? "filled" : ""}>
                    Log Out
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    placeholder=""
                    value={updateData.workhour}
                    readOnly
                  />
                  <label className={updateData.workhour ? "filled" : ""}>
                    Total Hour
                  </label>
                </div>
              </div>
            </div>

            <div className="modalbutton">
              <Button
                variant="secondary"
                className="mt-3 cancelbut mx-2"
                onClick={() => setUpdateShowModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" className="btn btn-primary mt-3  submitbut">
                Update
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Employee Id :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}

export default Attendance;
