import React, { useState, useEffect } from "react";
//import { useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function InvoiceReport() {

    // const { id } = useParams();
    const [filtered, setFiltered] = useState([]);
    const [courses, setCourses] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCourse, setSelectedCourse] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const leadsResponse = await axios.get(apiUrl + "/invoice_details_current");
                setFiltered(leadsResponse.data);

                const uniqueStatuses = Array.from(
                    new Set(leadsResponse.data.map((item) => item.name))
                ).map((status) => ({ name: status }));

                setStatuses(uniqueStatuses);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const formatDatefitching = (date) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

    // const filteredData = filtered.filter((item) => {
    //   const isSearchMatch = searchTerm
    //     ? [
    //         item.branch_id?.[0]?.branch_name,
    //         item.mobile_no,
    //         item.notes,
    //         item.from,
    //         item.to,
    //         item.paytype,
    //       ].some(
    //         (field) =>
    //           field &&
    //           String(field).toLowerCase().includes(searchTerm.toLowerCase())
    //       )
    //     : true;

    //   return isSearchMatch;
    // });

    const filteredData = filtered.filter((item) => {
        // Search filter logic
        const isSearchMatch = searchTerm
            ? [
                item.invoice_no,
                item.branch_name,
                item.date,
                item.dependent,
                item.name,
                item.nettotal,
                item.paid,
                item.balance,
            ].some(
                (field) =>
                    field &&
                    String(field).toLowerCase().includes(searchTerm.toLowerCase())
            )
            : true;
    
        // Status (name) filter logic: This will filter by the selected name
        const isNameMatch = selectedStatus
            ? item.name.toLowerCase() === selectedStatus.toLowerCase()
            : true; // Only filter by name if it's selected
    
        return isSearchMatch && isNameMatch; // Apply both filters
    });
    



    const currentEntries = filteredData.slice(
        indexOfFirstEntry,
        indexOfLastEntry
    );

    const handleEntriesPerPageChange = (event) => {
        setEntriesPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
        setCurrentPage(1);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
        setCurrentPage(1);
    };

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleClearFilters = () => {
        setSelectedCourse("");
        setSelectedStatus("");
        setFromDate("");
        setToDate("");
        setSearchTerm("");
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    const handleCopy = () => {
        const tableElement = document.getElementById("tableId");
        if (tableElement) {
            const tableData = tableElement.innerText;
            navigator.clipboard
                .writeText(tableData)
                .then(() => {
                    alert("Expense Table data copied to clipboard!");
                })
                .catch((err) => {
                    console.error("Failed to copy text: ", err);
                });
        } else {
            console.error("Table with ID 'tableId' not found");
        }
    };

    const handleCSVExport = () => {
        const csvHeaders = [
            "S.No",
            "Invoice No ",
            "Branch",
            "Date ",
            "Course",
            "name",
            "Total",
            "paid",
            "Balance",

        ];
        const csvData = filteredData.map((item, index) => [
            index + 1,
            item.invoice_no,
            item.branch_name,
            formatDatefitching(item.date),
            item.name,
            item.dependent,
            item.nettotal,
            item.paid,
            item.balance,


        ]);

        const csvContent = [
            csvHeaders.join(","), // Headers
            ...csvData.map((row) => row.join(",")), // Data rows
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "Invoice_report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleExport = () => {
        const tableElement = document.getElementById("tableId");
        if (tableElement) {
            const worksheet = XLSX.utils.table_to_sheet(tableElement);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "Invoice_table_data.xlsx");
        } else {
            console.error(
                "Table with ID 'tableId' not found. Check if the table is rendered in the DOM."
            );
        }
    };

    const handleGeneratePDF = () => {
        const tableElement = document.getElementById("tableId");
        if (!tableElement) {
            console.error("Table with ID 'tableId' not found");
            return;
        }
        html2canvas(tableElement)
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF();
                pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
                pdf.save("Invoice_report.pdf");
            })
            .catch((error) => {
                console.error("Error generating PDF: ", error);
            });
    };

    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        const tableElement = document.getElementById("tableId");
        if (tableElement) {
            printWindow.document.write(`
        <html>
          <head>
            <title>Print Invoice Table</title>
            <style>
              table { width: 100%; border-collapse: collapse; }
              th, td { border: 1px solid #000; padding: 8px; text-align: left; }
              th { background-color: #f2f2f2; }
            </style>
          </head>
          <body>
            <h1>Invoice Report</h1>
            <div>${tableElement.outerHTML}</div>
          </body>
        </html>
      `);
            printWindow.document.close();
            printWindow.print();
        } else {
            console.error("Table with ID 'tableId' not found");
        }
    };

    return (
        <div className="container-fluid mb-3">
            <div
                className="card p-2"
                style={{
                    marginTop: "-80px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
            >
                <div className="row">
                    <h6 className="text-center">
                        <b>Invoice Report</b>
                    </h6>
                </div>
                <hr />
                <div className="row p-2">
                    <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
                        <input
                            type="date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            className="form-control"
                        />
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12">
                        <input
                            type="date"
                            value={toDate}
                            onChange={handleToDateChange}
                            className="form-control"
                        />
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-12">
                        <select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            className="form-control"
                        >
                            <option value="">Select Name</option>
                            {filteredData.map((item, index) =>
                                item.name.length > 0 ? (
                                    <option key={index} value={item.name}>
                                        {item.name}
                                    </option>
                                ) : null
                            )}
                        </select>
                    </div>

                    {/* <div className="col-lg-2 col-md-3 col-sm-12">
                        <select className="form-control">
                            <option value="">From</option>
                            {currentEntries.map((expense) => (
              <option key={expense._id} value={expense.from}>
                {expense.from}
              </option>
            ))}
                        </select>
                    </div> */}
                    {/* <div className="col-lg-2 col-md-3 col-sm-12">
                        <select className="form-control">
                            <option value=""> To</option>
                            {currentEntries.map((expense) => (
              <option key={expense._id} value={expense.to}>
                {expense.to}
              </option>
            ))}
                        </select>
                    </div> */}
                    {/* <div className="col-lg-2 col-md-3 col-sm-12">
          <select
            value={selectedCourse}
            onChange={handleCourseChange}
            className="form-control"
          >
            <option value="">Select Course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.course_name}>
                {course.course_name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-12">
          <select
            value={selectedStatus}
            onChange={handleStatusChange}
            className="form-control"
          >
            <option value="">Select Status</option>
           
              <option key={index} value=''>
              </option>
           
          </select>
        </div> */}

                    <div className="col-lg-1 col-md-2 col-sm-12">
                        <button className="btn btn-danger" onClick={handleClearFilters}>
                            Clear
                        </button>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-3 d-flex align-items-center">
                        <p className="mb-0 mr-2">Show per page </p>
                        <select
                            value={entriesPerPage}
                            onChange={handleEntriesPerPageChange}
                            className="form-control mx-2"
                            style={{ width: "50px" }}
                        >
                            {/* <option value={5}>5</option> */}
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={filteredData.length}>All</option>
                        </select>
                    </div>

                    <div className="col-md-6">
                        <button className="btn btn-secondary2 mx-2" onClick={handleCopy}>
                            Copy
                        </button>
                        <button className="btn btn-success2 mx-2" onClick={handleCSVExport}>
                            CSV
                        </button>
                        <button className="btn btn-excel2 mx-2" onClick={handleExport}>
                            Excel
                        </button>
                        <button className="btn btn-info2 mx-2" onClick={handleGeneratePDF}>
                            PDF
                        </button>
                        <button className="btn btn-warning2 mx-2" onClick={handlePrint}>
                            Print
                        </button>
                    </div>

                    <div className="col-md-3 d-flex align-items-center">
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <table id="tableId" className="table table-striped table-bordered mt-2">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Invoice No</th>
                            <th>Branch</th>
                            <th>Date</th>
                            <th>Course</th>
                            <th>Name</th>
                            <th>Total</th>
                            <th>Paid</th>
                            <th>Balance</th>

                        </tr>
                    </thead>
                    <tbody>
                        {currentEntries.map((item, index) => (
                            <tr key={item.id}>
                                <td>{index + 1 + (currentPage - 1) * entriesPerPage}</td>
                                <td>{item.invoice_no}</td>
                                <td>{item.branch_name}</td>
                                <td>{formatDatefitching(item.date)}</td>
                                <td>{item.dependent}</td>
                                <td>{item.name}</td>
                                <td>{item.nettotal}</td>
                                <td>{item.paid}</td>
                                <td>{item.balance}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="dataTables_info" role="status" aria-live="polite">
                            Showing {indexOfFirstEntry + 1} to{" "}
                            {Math.min(indexOfLastEntry, filtered.length)} of {filtered.length}{" "}
                            entries
                        </div>
                    </div>
                    <div className="col-lg-5"></div>
                    <div className="col-lg-3">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                                <li
                                    className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                                >
                                    <a
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Previous
                                    </a>
                                </li>
                                {[...Array(totalPages)].map((_, i) => (
                                    <li
                                        key={i}
                                        className={`page-item ${currentPage === i + 1 ? "active" : ""
                                            }`}
                                    >
                                        <a
                                            className="page-link"
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </a>
                                    </li>
                                ))}
                                <li
                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                        }`}
                                >
                                    <a
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default InvoiceReport
