import React, { useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "../../environment/Api";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function DailyReport() {
  const [enquiryData, setEnquiryData] = useState([]);
  const [studentData, setStudentData] = useState([]);

  // State for Enquiry
  const [searchTermEnquiry, setSearchTermEnquiry] = useState("");
  const [entriesPerPageEnquiry, setEntriesPerPageEnquiry] = useState(10);
  const [currentPageEnquiry, setCurrentPageEnquiry] = useState(1);

  // State for Student
  // const [searchTermStudent, setSearchTermStudent] = useState("");
  // const [entriesPerPageStudent, setEntriesPerPageStudent] = useState(10);
  // const [currentPageStudent, setCurrentPageStudent] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          enquiryResponse,
          expenseResponse,
          incomeResponse,
          studentResponse,
        ] = await Promise.all([
          axios.get(`${apiUrl}/leadsreport`),

          axios.get(`${apiUrl}/expense_listreport`),
          axios.get(`${apiUrl}/income_listreport`),
          axios.get(`${apiUrl}/studingstudentreport`),
        ]);

        setEnquiryData(enquiryResponse.data);

        setExpenseData(expenseResponse.data);
        setIncomeData(incomeResponse.data);
        setStudentData(studentResponse.data);
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    fetchData();
  }, []);
  const [selectedDate, setSelectedDate] = useState("");


  // Enquiry Filtering and Pagination
  // const filteredEnquiryData = enquiryData.filter(
  //   (item) =>
  //     (item.student_name &&
  //       item.student_name
  //         .toLowerCase()
  //         .includes(searchTermEnquiry.toLowerCase())) ||
  //     (item.Prefered_course &&
  //       item.Prefered_course.toLowerCase().includes(
  //         searchTermEnquiry.toLowerCase()
  //       )) ||
  //     (item.Source_type &&
  //       item.Source_type.toLowerCase().includes(
  //         searchTermEnquiry.toLowerCase()
  //       )) ||
  //     (item.followups_status &&
  //       item.followups_status
  //         .toLowerCase()
  //         .includes(searchTermEnquiry.toLowerCase()))
  // );
  const filteredEnquiryData = enquiryData.filter((item) => {
    const matchesDate = selectedDate
      ? new Date(item.Enquiry_date).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
      : true;

    const matchesSearchTerm =
      (item.student_name && item.student_name.toLowerCase().includes(searchTermEnquiry.toLowerCase())) ||
      (item.Prefered_course && item.Prefered_course.toLowerCase().includes(searchTermEnquiry.toLowerCase())) ||
      (item.Source_type && item.Source_type.toLowerCase().includes(searchTermEnquiry.toLowerCase())) ||
      (item.followups_status && item.followups_status.toLowerCase().includes(searchTermEnquiry.toLowerCase()));

    return matchesDate && matchesSearchTerm;
  });

  const totalPagesEnquiry = Math.ceil(
    filteredEnquiryData.length / entriesPerPageEnquiry
  );
  const displayedEnquiryData = filteredEnquiryData.slice(
    (currentPageEnquiry - 1) * entriesPerPageEnquiry,
    currentPageEnquiry * entriesPerPageEnquiry
  );

  // Student Filtering and Pagination
  // const filteredStudentData = studentData.filter(
  //   (item) =>
  //     item.name &&
  //     item.name.toLowerCase().includes(searchTermStudent.toLowerCase())
  // );

  // const totalPagesStudent = Math.ceil(
  //   filteredStudentData.length / entriesPerPageStudent
  // );
  // const displayedStudentData = filteredStudentData.slice(
  //   (currentPageStudent - 1) * entriesPerPageStudent,
  //   currentPageStudent * entriesPerPageStudent
  // );

  // Placeholder functions for export actions
  const handleCopy = (tableId) => {
    const tableElement = document.getElementById(tableId);
    if (tableElement) {
      const rows = Array.from(tableElement.rows)
        .map((row) =>
          Array.from(row.cells)
            .map((cell) => cell.innerText)
            .join("\t")
        )
        .join("\n");

      navigator.clipboard
        .writeText(rows)
        .then(() => {
          alert("Table data copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error(`Table with ID '${tableId}' not found`);
    }
  };

  const handleExport = (tableId, fileName) => {
    const tableElement = document.getElementById(tableId);
    if (tableElement) {
      const worksheet = XLSX.utils.table_to_sheet(tableElement);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, fileName);
    } else {
      console.error(
        `Table with ID '${tableId}' not found. Check if the table is rendered in the DOM.`
      );
    }
  };

  const handleGeneratePDF = (tableId, fileName) => {
    const tableElement = document.getElementById(tableId);
    if (!tableElement) {
      console.error(`Table with ID '${tableId}' not found`);
      return;
    }
    html2canvas(tableElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.save(fileName);
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
      });
  };

  const handlePrint = (tableId, reportTitle) => {
    const printWindow = window.open("", "_blank");
    const tableElement = document.getElementById(tableId);
    if (tableElement) {
      printWindow.document.write(`
        <html>
          <head>
            <title>${reportTitle}</title>
            <style>
              table { width: 100%; border-collapse: collapse; }
              th, td { border: 1px solid #000; padding: 8px; text-align: left; }
              th { background-color: #f2f2f2; }
            </style>
          </head>
          <body>
            <h1>${reportTitle}</h1>
            <div>${tableElement.outerHTML}</div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    } else {
      console.error(`Table with ID '${tableId}' not found`);
    }
  };
  // Attendance Report Start
  const [attendanceData, setAttendanceData] = useState([]);
  const [displayedAttendanceData, setDisplayedAttendanceData] = useState([]);
  const [entriesPerPageAttendance, setEntriesPerPageAttendance] = useState(10);
  const [currentPageAttendance, setCurrentPageAttendance] = useState(1);
  const [searchTermAttendance, setSearchTermAttendance] = useState("");

  // Fetch attendance data from the backend
    // ("http://192.168.1.38:8000/api/attendancereport")
   
  useEffect(() => {
    axios
      .get (apiUrl + "/attendancereport")
      .then((response) => {
        setAttendanceData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching attendance data:", error);
      });
  }, []);


  const filteredAttendanceData = attendanceData.filter((item) => {
    const matchesDate = selectedDate
      ? new Date(item.date).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
      : true;

    const matchesSearchTerm =
      item.name.toLowerCase().includes(searchTermAttendance.toLowerCase()) ||
      item.empid.toLowerCase().includes(searchTermAttendance.toLowerCase());

    return matchesDate && matchesSearchTerm;
  });


  // Filter data based on the search term
  useEffect(() => {
    const filteredData = attendanceData.filter((item) =>
      item.name.toLowerCase().includes(searchTermAttendance.toLowerCase())
    );
    const startIdx = (currentPageAttendance - 1) * entriesPerPageAttendance;
    const endIdx = startIdx + entriesPerPageAttendance;
    setDisplayedAttendanceData(filteredData.slice(startIdx, endIdx));
  }, [
    attendanceData,
    searchTermAttendance,
    currentPageAttendance,
    entriesPerPageAttendance,
  ]);

  const totalPagesAttendance = Math.ceil(
    attendanceData.filter((item) =>
      item.name.toLowerCase().includes(searchTermAttendance.toLowerCase())
    ).length / entriesPerPageAttendance
  );

  const handleEntriesPerPageChange =
    (setEntriesPerPage, setCurrentPage) => (e) => {
      setEntriesPerPage(Number(e.target.value));
      setCurrentPage(1);
    };

  // Attendance Report End

  // Expense Report Start

  // State for Expense
  const [searchTermExpense, setSearchTermExpense] = useState("");
  const [entriesPerPageExpense, setEntriesPerPageExpense] = useState(10);
  const [currentPageExpense, setCurrentPageExpense] = useState(1);
  const [expenseData, setExpenseData] = useState([]);

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const expenseResponse = await axios.get(`${apiUrl}/expense_listreport`);
        setExpenseData(expenseResponse.data);
      } catch (error) {
        console.error("Error fetching expense data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredExpenseData = expenseData.filter((item) => {
    // Check if the item has notes and if they match the search term
    const matchesNotes =
      item.notes &&
      item.notes.toLowerCase().includes(searchTermExpense.toLowerCase());

    // Check if the selected date matches the item's date (if selectedDate exists)
    const matchesDate = selectedDate
      ? new Date(item.date).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
      : true;

    // Return the item if both conditions match
    return matchesNotes && matchesDate;
  });


  const totalPagesExpense = Math.ceil(
    filteredExpenseData.length / entriesPerPageExpense
  );

  const displayedExpenseData = filteredExpenseData.slice(
    (currentPageExpense - 1) * entriesPerPageExpense,
    currentPageExpense * entriesPerPageExpense
  );

  // Expense Report End

  // Income Report Start

  const [incomeData, setIncomeData] = useState([]);
  const [searchTermIncome, setSearchTermIncome] = useState("");
  const [entriesPerPageIncome, setEntriesPerPageIncome] = useState(10);
  const [currentPageIncome, setCurrentPageIncome] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          // enquiryResponse,
          // expenseResponse,
          incomeResponse,
          // studentResponse,
        ] = await Promise.all([
          // axios.get(`${apiUrl}/leadsreport`),
          // axios.get(`${apiUrl}/expense_listreport`),
          axios.get(`${apiUrl}/income_listreport`),
          // axios.get(`${apiUrl}/studingstudentreport`),
        ]);

        // setEnquiryData(enquiryResponse.data);
        // setExpenseData(expenseResponse.data);
        setIncomeData(incomeResponse.data);
        // setStudentData(studentResponse.data);
      } catch (error) {
        console.error("Error fetching report data:", error);
      }
    };

    fetchData();
  }, []);

  // Income Filtering and Pagination
  // const filteredIncomeData = incomeData.filter((item) => {
  //   const branchName = item.branch_id[0]?.branch_name || "";
  //   const typeName = item.type[0]?.type || "";
  //   return (
  //     branchName.toLowerCase().includes(searchTermIncome.toLowerCase()) ||
  //     typeName.toLowerCase().includes(searchTermIncome.toLowerCase()) ||
  //     item.description.toLowerCase().includes(searchTermIncome.toLowerCase())
  //   );
  // });
  const filteredIncomeData = incomeData.filter((item) => {
    // Get the branch name and type name
    const branchName = item.branch_id[0]?.branch_name || "";
    const typeName = item.type[0]?.type || "";

    // Match for the search term in branch name, type name, and description
    const matchesSearchTerm =
      branchName.toLowerCase().includes(searchTermIncome.toLowerCase()) ||
      typeName.toLowerCase().includes(searchTermIncome.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTermIncome.toLowerCase());

    // Match for the date filter (if selectedDate exists)
    const matchesDate = selectedDate
      ? new Date(item.date).toLocaleDateString() === new Date(selectedDate).toLocaleDateString()
      : true; // If no date is selected, it defaults to true

    // Return the item if it matches both search term and date filter
    return matchesSearchTerm && matchesDate;
  });


  const totalPagesIncome = Math.ceil(
    filteredIncomeData.length / entriesPerPageIncome
  );
  const displayedIncomeData = filteredIncomeData.slice(
    (currentPageIncome - 1) * entriesPerPageIncome,
    currentPageIncome * entriesPerPageIncome
  );

  // Income Report End

  // Student Report Start

  return (
    <>
      <div
        className="card p-2"
        style={{
          marginTop: "-80px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 pt-2 text-left">
            <h6 className="px-2 pt-2">
              <b>Daily Report</b>
            </h6>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 pt-2  d-flex"style={{display:"flex",justifyContent:"end"}}>

            <div>
              <p className="pt-2">
                <b>Date</b>
              </p>
            </div>
            <input style={{width:"200px",height:"40px"}}
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="form-control mx-2"
            />

          </div>
        </div>

        <hr></hr>
        <div className="row p-3">
          <div className="col-lg-3 col-md-3 col-sm-12 text-center ">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Opening Balance</h6>
              <h3 className="">Rs. 06</h3>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Today Income</h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
              {/* <h3 className="">Rs. 06</h3> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Today Expense</h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row p-3">
          <div className="col-lg-3 col-md-3 col-sm-12 text-center ">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">Closing Balance</h6>
              <h3 className="">Rs. 06</h3>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">To Convert</h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
              {/* <h3 className="">Rs. 06</h3> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12">
            <div className="card text-center border-3">
              <h6 className="bg-secondary text-white py-1">
                Student and Staff
              </h6>
              <div className="row text-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">Cash</p>
                  <h5 className="">Rs. </h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <p className="">UPI</p>
                  <h5 className="">Rs. </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        {/* <div className="row text-center p-2">
          <div className="col-lg-4">
            <p className="">
              <b>Date</b>
            </p>
            <input type="date" className="form-control"></input>
          </div>
          <div className="col-lg-2">
            <p className="">
              <b>Click</b>
            </p>
            <button className="btn border">
              <i class="fa-solid fa-filter text-danger"></i>
            </button>
          </div>
        </div> */}
      </div>
      <div className="container mt-5 bg-white">
        {/* Enquiry Report */}


        <div className="">
          <h6 className="text-center">
            <b>Enquiry Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select
                  value={entriesPerPageEnquiry}
                  onChange={handleEntriesPerPageChange(
                    setEntriesPerPageEnquiry,
                    setCurrentPageEnquiry
                  )}
                  className="form-control mx-2"
                  style={{ width: "80px" }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={filteredEnquiryData.length}>All</option>
                </select>
              </div>

              <div className="col-md-6 text-center">
                <button
                  className="btn btn-secondary2 mx-2"
                  onClick={() => handleCopy("enquiryTable")}
                >
                  Copy
                </button>
                <button
                  className="btn btn-success2 mx-2"
                // onClick={handleCSVExport1}
                >
                  CSV
                </button>
                <button
                  className="btn btn-excel2 mx-2"
                  onClick={() =>
                    handleExport("enquiryTable", "Enquiry_Data.xlsx")
                  }
                >
                  Export
                </button>
                <button
                  className="btn btn-info2 mx-2"
                  onClick={() =>
                    handleGeneratePDF("enquiryTable", "Enquiry_Report.pdf")
                  }
                >
                  PDF
                </button>
                <button
                  className="btn btn-warning2 mx-2"
                  onClick={() => handlePrint("enquiryTable", "Enquiry Report")}
                >
                  Print
                </button>
              </div>

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTermEnquiry}
                  onChange={(e) => setSearchTermEnquiry(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="enquiryTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Name</th>
                      <th>Mobile No</th>
                      <th>Course</th>
                      <th>Source Type</th>
                      <th>Enquiry Date</th>
                   
                
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedEnquiryData.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          {index +
                            1 +
                            (currentPageEnquiry - 1) * entriesPerPageEnquiry}
                        </td>
                        <td>{item.student_name}</td>
                        <td>{item.mobile_no}</td>
                        <td>{item.Prefered_course}</td>
                        <td>{item.Source_type}</td>
                        <td>
                          {new Date(item.Enquiry_date).toLocaleDateString()}
                        </td>
                
         
                        <td>{item.followups_status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {totalPagesEnquiry > 1 && (
              <div className="row">
                <div className="col-lg-10 "></div>
                <div className="col-lg-2 mt-3 pb-5">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${currentPageEnquiry === 1 ? "disabled" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() =>
                            setCurrentPageEnquiry(currentPageEnquiry - 1)
                          }
                        >
                          Previous
                        </button>
                      </li>
                      {[...Array(totalPagesEnquiry)].map((_, i) => (
                        <li
                          key={i}
                          className={`page-item ${currentPageEnquiry === i + 1 ? "active" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => setCurrentPageEnquiry(i + 1)}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${currentPageEnquiry === totalPagesEnquiry
                          ? "disabled"
                          : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() =>
                            setCurrentPageEnquiry(currentPageEnquiry + 1)
                          }
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Repeat similar structure for Expense Report */}
        <div className="mt-5">
          <h6 className="text-center">
            <b>Attendance Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select
                  value={entriesPerPageAttendance}
                  onChange={handleEntriesPerPageChange(
                    setEntriesPerPageAttendance,
                    setCurrentPageAttendance
                  )}
                  className="form-control mx-2"
                  style={{ width: "80px" }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={attendanceData.length}>All</option>
                </select>
              </div>

              <div className="col-md-6 text-center">
                <button
                  className="btn btn-secondary2 mx-2"
                  onClick={() => handleCopy("attendanceTable")}
                >
                  Copy
                </button>
                <button
                  className="btn btn-success2 mx-2"
                // onClick={handleCSVExport1}
                >
                  CSV
                </button>
                <button
                  className="btn btn-excel2 mx-2"
                  onClick={() =>
                    handleExport("attendanceTable", "Attendance_Data.xlsx")
                  }
                >
                  Export
                </button>
                <button
                  className="btn btn-info2 mx-2"
                  onClick={() =>
                    handleGeneratePDF(
                      "attendanceTable",
                      "Attendance_Report.pdf"
                    )
                  }
                >
                  PDF
                </button>
                <button
                  className="btn btn-warning2 mx-2"
                  onClick={() =>
                    handlePrint("attendanceTable", "Attendance Report")
                  }
                >
                  Print
                </button>
              </div>

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTermAttendance}
                  onChange={(e) => setSearchTermAttendance(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="attendanceTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Branch</th>
                      <th>Employee ID</th>
                      <th>Name</th>
                      <th>Date</th>
                      <th>Login</th>
                      <th>Logout</th>
                      <th>Work Hours</th>
                    </tr>
                  </thead>

                  <tbody>
                    {/* {displayedAttendanceData.map((item, index) => ( */}
                    {filteredAttendanceData.map((item, index) => (

                      <tr key={item._id}>
                        <td>
                          {index +
                            1 +
                            (currentPageAttendance - 1) *
                            entriesPerPageAttendance}
                        </td>
                        <td>{item.branch_id.branch_name}</td>
                        <td>{item.empid || "N/A"}</td>
                        <td>{item.name}</td>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>{item.login}</td>
                        <td>{item.logout}</td>
                        <td>{item.workhour}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {totalPagesAttendance > 1 && (
              <div className="mt-3 pb-5">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    <li
                      className={`page-item ${currentPageAttendance === 1 ? "disabled" : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          setCurrentPageAttendance(currentPageAttendance - 1)
                        }
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPagesAttendance)].map((_, i) => (
                      <li
                        key={i}
                        className={`page-item ${currentPageAttendance === i + 1 ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPageAttendance(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${currentPageAttendance === totalPagesAttendance
                        ? "disabled"
                        : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          setCurrentPageAttendance(currentPageAttendance + 1)
                        }
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>

        {/* Repeat similar structure for Expense Report */}
        <div className="mt-5">
          <h6 className="text-center">
            <b>Expense Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select
                  value={entriesPerPageExpense}
                  onChange={(e) => {
                    setEntriesPerPageExpense(Number(e.target.value));
                    setCurrentPageExpense(1); // Reset to page 1
                  }}
                  className="form-control mx-2"
                  style={{ width: "80px" }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={filteredExpenseData.length}>All</option>
                </select>
              </div>

              {/* <div className="col-md-6 text-center"> */}
              <div className="col-md-6 text-center">
                <button
                  className="btn btn-secondary2 mx-2"
                  onClick={() => handleCopy("expenseTable")}
                >
                  Copy
                </button>
                <button
                  className="btn btn-success2 mx-2"
                // onClick={handleCSVExport1}
                >
                  CSV
                </button>
                <button
                  className="btn btn-excel2 mx-2"
                  onClick={() =>
                    handleExport("expenseTable", "Expense_Data.xlsx")
                  }
                >
                  Export
                </button>
                <button
                  className="btn btn-info2 mx-2"
                  onClick={() =>
                    handleGeneratePDF("expenseTable", "Expense_Report.pdf")
                  }
                >
                  PDF
                </button>
                <button
                  className="btn btn-warning2 mx-2"
                  onClick={() => handlePrint("expenseTable", "Expense Report")}
                >
                  Print
                </button>
              </div>
              {/* </div> */}

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTermExpense}
                  onChange={(e) => setSearchTermExpense(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="incomeTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Branch</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Amount</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Paytype</th>
                    </tr>
                  </thead>

                  <tbody>
                    {displayedExpenseData.map((item, index) => (
                      <tr key={item._id}>
                        <td>
                          {index +
                            1 +
                            (currentPageExpense - 1) * entriesPerPageExpense}
                        </td>
                        <td>{item.branch_id[0]?.branch_name || "N/A"}</td>
                        <td>{item.type[0]?.type || "N/A"}</td>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>{item.notes}</td>
                        <td>{item.amount}</td>
                        <td>{item.from}</td>
                        <td>{item.to}</td>
                        <td>{item.paytype}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9"></div>
              <div className="col-lg-3">
                {totalPagesExpense > 1 && (
                  <div className="mt-3 pb-5">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${currentPageExpense === 1 ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() =>
                              setCurrentPageExpense(currentPageExpense - 1)
                            }
                          >
                            Previous
                          </button>
                        </li>
                        {[...Array(totalPagesExpense)].map((_, i) => (
                          <li
                            key={i}
                            className={`page-item ${currentPageExpense === i + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPageExpense(i + 1)}
                            >
                              {i + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${currentPageExpense === totalPagesExpense
                            ? "disabled"
                            : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() =>
                              setCurrentPageExpense(currentPageExpense + 1)
                            }
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Repeat similar structure for Income Report */}
        <div className="mt-5">
          <h6 className="text-center">
            <b>Income Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select
                  value={entriesPerPageIncome}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    setEntriesPerPageIncome(value);
                    setCurrentPageIncome(1); // Reset to the first page
                  }}
                  className="form-control mx-2"
                  style={{ width: "80px" }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={filteredIncomeData.length}>All</option>
                </select>
              </div>

              <div className="col-md-6 text-center">
                <button
                  className="btn btn-secondary2 mx-2"
                  onClick={() => handleCopy("incomeTable")}
                >
                  Copy
                </button>
                <button
                  className="btn btn-success2 mx-2"
                // onClick={handleCSVExport1}
                >
                  CSV
                </button>
                <button
                  className="btn btn-excel2 mx-2"
                  onClick={() =>
                    handleExport("incomeTable", "Income_Data.xlsx")
                  }
                >
                  Export
                </button>
                <button
                  className="btn btn-info2 mx-2"
                  onClick={() =>
                    handleGeneratePDF("incomeTable", "Income_Report.pdf")
                  }
                >
                  PDF
                </button>
                <button
                  className="btn btn-warning2 mx-2"
                  onClick={() => handlePrint("incomeTable", "Income Report")}
                >
                  Print
                </button>
              </div>

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTermIncome}
                  onChange={(e) => setSearchTermIncome(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="incomeTable">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Branch Id</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Description</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Paytype</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {displayedIncomeData.length > 0 ? (
                      displayedIncomeData.map((item, index) => (
                        <tr key={item._id}>
                          <td>
                            {index +
                              1 +
                              (currentPageIncome - 1) * entriesPerPageIncome}
                          </td>
                          <td>{item.branch_id[0]?.branch_id}</td>
                          <td>{item.type[0]?.type}</td>
                          <td>{new Date(item.date).toLocaleDateString()}</td>
                          <td>{item.description}</td>
                          <td>{new Date(item.from).toLocaleDateString()}</td>
                          <td>{new Date(item.to).toLocaleDateString()}</td>
                          <td>{item.paytype}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9"></div>
              <div className="col-lg-3">
                {totalPagesIncome > 1 && (
                  <div className="mt-3 pb-5">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${currentPageIncome === 1 ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() =>
                              setCurrentPageIncome(currentPageIncome - 1)
                            }
                          >
                            Previous
                          </button>
                        </li>
                        {[...Array(totalPagesIncome)].map((_, i) => (
                          <li
                            key={i}
                            className={`page-item ${currentPageIncome === i + 1 ? "active" : ""
                              }`}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPageIncome(i + 1)}
                            >
                              {i + 1}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${currentPageIncome === totalPagesIncome
                            ? "disabled"
                            : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() =>
                              setCurrentPageIncome(currentPageIncome + 1)
                            }
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Repeat similar structure for Student Report */}
        {/* <div className="mt-5">
          <h6 className="text-center">
            <b>Student Report</b>
          </h6>
          <div className="card-body p-3" style={{ backgroundColor: "#E8EFF9" }}>
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show:</p>
                <select
                  value={entriesPerPageStudent}
                  onChange={handleEntriesPerPageChange}
                  className="form-control mx-2"
                  style={{ width: "80px" }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={studentData.length}>All</option>
                </select>
              </div>

              <div className="col-md-6 text-center">
                <button className="btn btn-secondary mx-2">Copy</button>
                <button className="btn btn-success mx-2">Export</button>
                <button className="btn btn-info mx-2">PDF</button>
                <button className="btn btn-warning mx-2">Print</button>
              </div>

              <div className="col-md-3 d-flex align-items-center">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTermStudent}
                  onChange={(e) => setSearchTermStudent(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row p-2">
              <div style={{ overflowX: "auto" }}>
                <table className="table table-border" id="studentTable">
                  <thead>
                    <tr>
                      <th>Order No</th>
                      <th>Student Name</th>
                      <th>Course</th>
                      <th>Mobile</th>
                      <th>Fees</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedStudentData.map((item, index) => (
                      <tr key={item._id}>
                        <td>
                          {index +
                            1 +
                            (currentPageStudent - 1) * entriesPerPageStudent}
                        </td>

                        <td>{item.leads_id[0]?.student_name || "N/A"}</td>
                        <td>{item.leads_id[0]?.Prefered_course || "N/A"}</td>
                        <td>{item.leads_id[0]?.mobile_no || "N/A"}</td>
                        <td>{item.fees}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {totalPagesStudent > 1 && (
              <div className="mt-3 pb-5">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    <li
                      className={`page-item ${
                        currentPageStudent === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          setCurrentPageStudent(currentPageStudent - 1)
                        }
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPagesStudent)].map((_, i) => (
                      <li
                        key={i}
                        className={`page-item ${
                          currentPageStudent === i + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPageStudent(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPageStudent === totalPagesStudent
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          setCurrentPageStudent(currentPageStudent + 1)
                        }
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </>
  );
}

export default DailyReport;
