import axios from "axios";
import React, { useState, useEffect } from "react";
import apiUrl from "../../environment/Api";



function Followups() {
    return(
        <>

        <h2>
        <div className="card">
        <div className="mt-2">
          <table className="table table-striped responsive">
            <thead>
              <tr>
                <th>S.No</th>
           <th>Register No</th> {/* Hide column for 'demo' */}
                <th> Name</th>

                <th>Mobile No</th>
                <th>Course</th>
                <th>Total Fees</th>
                <th>Due</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
          
                <tr >
                  <td> </td>
                  <td></td> {/* Conditionally render branch */}
                  <td></td>

                  <td></td>
                  <td></td>
                  <td></td>
           
                    <td className="text-center hideres ">
                      <p className=" text-white green-label"></p>
                    </td>
               
                    <td className="text-center hideres">
                      <p className="text-white red-label"></p>
                    </td>
        
                  <td className="text-center">
                    {/* <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                      className="btn delete"
                      onClick={() => handleOpenModal(item._id, item.username)}
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button> */}

                  
                      <button
                        className="btn updelete mx-2"
                    
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
            
                      <button
                        className="btn delete"
                        
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                 
                  </td>
                </tr>
         
            </tbody>
          </table>
        </div>
      </div>
        </h2>
        </>
    )
}

export default Followups;




