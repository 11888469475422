// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import apiUrl from "../../environment/Api";
// import { Modal, Button } from 'react-bootstrap';
// import { toast } from "react-toastify";
// import { Link } from "react-router-dom";

// function Roles() {

//     const [showModals, setShowModals] = useState(false);
//     const navigate = useNavigate();
//     const [roleData, setRoleData] = useState({
//         role: '',
//         modules: [],
//         privileges: []
//     });

//     //get roles data
//     const [data, setData] = useState([])
//     const getData = async () => {
//         try {
//             const response = await axios.get(apiUrl + `/role/student`);
//             if (response) {
//                 if (response.status === 200) {
//                     console.log("adminrole get", response.data)
//                     setData(response.data)
//                 }
//             }
//         }
//         catch (err) {
//             console.log(err)
//         }
//     }

//     useEffect(() => {
//         window.scrollTo(0, 0);
//       }, []);

//     const [values, setall] = useState([])

//     const getall = async () => {
//         try {
//             // const response = await axios.get(apiUrl + '/allrole');
//             const response = await axios.get(apiUrl + '/role_2');
//             if (response) {
//                 if (response.status === 200) {
//                     console.log("admin role get", response.data[0].id)
//                     setall(response.data)
//                 }
//             }
//         }
//         catch (err) {
//             console.log(err)
//         }
//     }

//     useEffect(() => {
//         getData();
//         getall();
//         getpermission();
//     }, [])

//     const [permissionDatas, setpermissionDatas] = useState([])

//     const getpermission = async () => {
//         const admin_id = localStorage.getItem('admin_id')
//         const page = "role"
//         console.log("the admin_id", admin_id + "" + page)
//         try {
//             console.log("the admin_id123", admin_id + "" + page)
//             console.log("the condition check:", admin_id + "the page:" + page)
//             const response = await axios.get(apiUrl+ `/permission/${admin_id}/${page}`);
//             console.log("Response for the previllages123:", response.data.data[0]);
//             setpermissionDatas(response.data.data[0]);
//             if (response.data.data[0].all) {
//                 console.log("the all is true ")
//             }
//             if (!response.data.data[0].add) {
//                 console.log("the all is false ")
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error.message);
//         }

//     }

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setRoleData({
//             ...roleData,
//             [name]: value
//         });
//     };

//     const handleCheckboxChange = (e) => {
//         const { name, checked } = e.target;
//         setRoleData({
//             ...roleData,
//             [name]: checked ? [...roleData[name], name] : roleData[name].filter(item => item !== name)
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // You can perform form submission logic here
//         console.log('Submitted data:', roleData);
//     };

//     // delete popup
//     const [isDeletePopUP, setisDeletePopup] = useState(false);
//     const [deleteId, setdeleteId] = useState("");
//     const [deleteName, setdeleteName] = useState("");

//     const handleRoleDeletePopup = async (id, name) => {

//     //     console.log("the delete ", permissionDatas.delete)
//     //     if (permissionDatas.all || permissionDatas.delete) {
//     //         console.log("the all ", permissionDatas.all)
//     //         setdeleteId(id)
//     //         setdeleteName(name)
//     //         setShowModals(true);
//     //         console.log("the", isDeletePopUP)
//     //     } else {
//     //         toast.warning(" you dont have permission")
//     //     }
//     // }

//     console.log("the delete ", permissionDatas.delete)

//         console.log("the all ", permissionDatas.all)
//         setdeleteId(id)
//         setdeleteName(name)
//         setShowModals(true);
//         console.log("the", isDeletePopUP)

//     }

//     const handleCloseModal = () => {
//         setShowModals(false);
//     }

//     const handleDelete = async (e) => {
//         e.preventDefault();
//         try {
//             await axios.delete(apiUrl + `/role/${deleteId}`);

//             setShowModals(false);
//             getall();
//             setdeleteId("");
//             setdeleteName("")

//         }
//         catch (err) {

//         }
//     }

//     // const handleupdatepop = (id) => {
//     //     console.log("IDDD", id)
//     //     if (permissionDatas.all || permissionDatas.edit) {
//     //         navigate(`/Editrole/${id}`)
//     //     } else {
//     //         toast.warning("you dont have permission")
//     //     }
//     // }

//     const handleupdatepop = (_id) => {

//         navigate(`/Editrole/${_id}`)
//         console.log("IDDD", _id)
//         // toast.warning("you dont have permission")

//     }

//     // the pagination ---------------------------------------------------------------------
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [searchQuery, setSearchQuery] = useState("");
//     // Pagination
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = values.slice(indexOfFirstItem, indexOfLastItem);

//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     // Search
//     const filtered = currentItems.filter((item) =>
//         Object.values(item).some(
//             (value) =>
//                 typeof value === "string" &&
//                 value.toLowerCase().includes(searchQuery.toLowerCase()) ||
//                 (typeof value === "number" && value.toString().includes(searchQuery))
//         )
//     );

//     const handleSearchChange = (event) => {
//         setSearchQuery(event.target.value);
//         setCurrentPage(1);
//     };

//     const totalPages = Math.ceil(values.length / itemsPerPage);

//     const goToNextPage = () => {
//         setCurrentPage((prevPage) =>
//             prevPage === totalPages ? prevPage : prevPage + 1
//         );
//     };

//     const goToPrevPage = () => {
//         setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
//     };

//     return (
//         <div className='top'>
//             {/*------------------------------ delete model----------------------------- */}
//             {/* <Modal className="modal" isOpen={isDeletePopUP} onClose={handlePopupClose}>
//                 <h2>Are you sure you want to Delete?</h2>
//                 <p className="d-flex justify-content-center">Role Name :  <strong className="ml-3">{deleteName}</strong></p>
//                 <div className='d-flex justify-content-evenly'>
//                     <button className='btn btn-secondary' onClick={handlePopupClose}>Back</button>
//                     <button className='btn btn-danger' onClick={handleDeleteClick}>Delete</button>
//                 </div>
//             </Modal> */}

//             <Modal className="modal" show={showModals} onHide={handleCloseModal}>
//                 <Modal.Header>
//                     <p>Are you sure you want to delete?</p>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <label className="form-label d-flex">
//                         <h5 className='mx-1'> role Name :</h5> <h5>{deleteName} </h5>
//                     </label>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
//                         Back
//                     </Button>
//                     <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
//                 </Modal.Footer>
//             </Modal>

//             {/*------------------------------ delete model----------------------------- */}

//             <div className="" style={{ marginTop: '-80px' }}>
//                 <div className="row mb-3">
//                     <div className="col-lg-11">
//                         <Link to="/dashboard" className="text-secondary dashBoard_text">
//                             <b>Dashboard / </b>
//                         </Link>
//                         <Link to="" className="text-danger dashBoard_text">
//                             <b>Roles</b>
//                         </Link>
//                         {/* <Link to="" className="text-danger dashBoard_text">
//                             <b>Profile</b>
//                         </Link> */}
//                     </div>
//                 </div>
//             </div>

//             <div className='d-flex'>
//                 <h5 className="mt-2">Roles</h5>
//                 <div className='mx-3'><button className='btn btn-success add' onClick={() => navigate('/addrole')} >Add </button></div>
//             </div>

//             {/* ------------ search and per page ----------------------------*/}

//             <div className="mb-3 mt-3">
//                 <div className="row">
//                     <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//                         <label className='form-label text-dark mt-2'>Show : </label>
//                         <div>
//                             <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
//                                 {/* <option value='5'>5</option> */}
//                                 <option value='10'>10</option>
//                                 <option value='20'>20</option>
//                                 <option value='50'>50</option>
//                             </select>
//                         </div>
//                     </div>
//                     <div className="col-lg-7 col-md-4 col-sm-1"></div>
//                     <div className="col-lg-3 col-md-4 col-sm-7">

//                         <div className="w-full flex-1">
//                             <form>
//                                 <div className="relative">

//                                     <input
//                                         className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                                         placeholder="Search ..."
//                                         type="search"
//                                         value={searchQuery}
//                                         onChange={handleSearchChange}
//                                     />

//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {/* --------------------------------end------------------------------------------- */}

//             <div className="card">
//                 <div className="mt-2">
//                     <div className="table-responsive">
//                         <table className="table table-striped">
//                             <thead>
//                                 <tr>
//                                     <th>S.No</th>
//                                     <th>Role Name</th>
//                                     <th>Module</th>
//                                     <th className='text-center'>Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {
//                                     filtered.map((item, index) => (
//                                         <tr key={index}>
//                                             <th>{index + 1}</th>
//                                             <td>{item.rolename}</td>
//                                             <td>
//                                                 {Array.isArray(item.modules) && item.modules.map((module, index) => (
//                                                     <span key={index}>{module.name}{index !== item.modules.length - 1 && ', '}</span>
//                                                 ))}
//                                             </td>
//                                             <td>
//                                                 <div className="d-flex" >
//                                                     <button style={{ marginTop: '-10px' }} className="btn updelete mx-2 box" onClick={() => handleupdatepop(item._id)}>
//                                                         <i className="fa-solid fa-pen-to-square"></i>
//                                                     </button>
//                                                     <button style={{ marginTop: '-10px' }} className="btn delete box" onClick={() => handleRoleDeletePopup(item.id, item.rolename)}>
//                                                         <i className="fa-solid fa-trash-can"></i>
//                                                     </button>
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     ))
//                                 }
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             </div>

//             {/* _------------------------------pagination button -------------------------------- */}
//             <div className="mt-3 pb-5">
//                 <div className="row">
//                     <div className="col-lg-10 col-sm-12">
//                         <nav className="d-flex float-right">
//                             <ul className="pagination justify-content-center">
//                                 <li
//                                     className={` page-item ${currentPage === 1 ? "disabled" : ""
//                                         }`}
//                                 >
//                                     <button className="page-link" onClick={goToPrevPage}>
//                                         Previous
//                                     </button>
//                                 </li>
//                                 {Array.from({ length: totalPages }, (_, i) => (
//                                     <li
//                                         key={i}
//                                         className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
//                                             }`}
//                                     >
//                                         <button
//                                             className="page-link "
//                                             onClick={() => paginate(i + 1)}
//                                         >
//                                             {i + 1}
//                                         </button>
//                                     </li>
//                                 ))}
//                                 <li
//                                     className={`page-item ${currentPage === totalPages ? "disabled" : ""
//                                         }`}
//                                 >
//                                     <button className="page-link " onClick={goToNextPage}>
//                                         Next
//                                     </button>
//                                 </li>
//                             </ul>
//                         </nav>{" "}
//                     </div>
//                     <div className="col-lg-2 col-sm-12 mt-1">
//                         <div className="float-end">
//                             <span className="text-dark ">
//                                 Page {currentPage} of {totalPages}
//                             </span>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* ---------------------------------------end ---------------------------------- */}
//         </div>

//     )
// }

// export default Roles;

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

function Roles() {
  const [showModals, setShowModals] = useState(false);
  const [roleData, setRoleData] = useState({
    role: "",
    modules: [],
    privileges: [],
  });
  const [data, setData] = useState([]);
  const [values, setAll] = useState([]);
  const [previllages, setPrevillages] = useState([]);
  const [deleteName, setDeleteName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    getAll();
    getToken();
  }, []);

  const getData = async () => {
    try {
      const response = await axios.get(apiUrl + `/role`);
      if (response && response.status === 200) setData(response.data);
    } catch (err) {
      console.error(err);
    }
  };
//  const getData = async () => {
//     try {
//       const response = await axios.get(apiUrl + `/role_2`);
//       if (response && response.status === 200) setData(response.data);
//     } catch (err) {
//       console.error(err);
//     }
//   };

  // const getAll = async () => {
  //   try {
  //     const responsed = await axios.get(apiUrl + "/role_2");
  //     console.log("the reponse get", responsed)
  //     if (responsed && responsed.status === 200) setAll(responsed.data.reverse());
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };


  const getAll = async () => {
    try {
      const responsed = await axios.get(apiUrl + "/role_2");
      console.log("The response get:", responsed);
  
      if (responsed && responsed.status === 200) {
        // Map over each role and filter modules with true actions
        const filteredData = responsed.data.map(role => {
          const filteredModules = role.modules.filter(module => {
            // Check if any action is true in the module
            return Object.values(module.actions).includes(true);
          });
  
          // Return the role with the filtered modules
          return { ...role, modules: filteredModules };
        }).reverse(); // Reverse the array after filtering
  
        setAll(filteredData);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  

  const getToken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(apiUrl + "/get_detail", { token });
      const id = response.data.detail.rolename;
      if (id) {
        const moduleResponse = await axios.get(apiUrl + `/role_2/${id}`);
        const filteredAccess = moduleResponse.data.modules.filter(
          (module) => module.name === "Branch"
        );
        setPrevillages(filteredAccess[0]?.actions || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleDelete = async () => {
  
    try {
      console.log("the delete id ",deleteId)
      await axios.delete(apiUrl + `/role_2/${deleteId}`);
    
      setShowModals(false);
      getAll();
      setDeleteId("");
      setDeleteName("");
      toast.error("Role deleted successfully");
    } catch (err) {
      console.error("Error deleting role:", err);
    }
  };

  const handleRoleDeletePopup = (id, rolename) => {
    setDeleteId(id);
    setDeleteName(rolename);
    setShowModals(true);
  };

  const handleUpdatePopup = (_id) => navigate(`/Editrole/${_id}`);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = values.slice(indexOfFirstItem, indexOfLastItem);

  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const totalPages = Math.ceil(values.length / itemsPerPage);

  return (
    <div className="top">
      <Modal
        className="modal"
        show={showModals}
        onHide={() => setShowModals(false)}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Role Name:</h5> <h5>{deleteName}</h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModals(false)}
          >
            Back
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="d-flex align-items-center mb-3">
        <h5 className="mt-2">Roles</h5>
        {previllages.add && (
          <button
            className="btn btn-success add mx-3"
            onClick={() => navigate("/addrole")}
          >
            Add
          </button>
        )}
      </div>

      {/* Search and pagination */}
      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show:</label>
            <select
              className="form-control w-100 mx-2"
              onChange={(e) => setItemsPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-7 offset-lg-7">
            <input
              className="form-control"
              placeholder="Search..."
              type="search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Role Name</th>
                <th>Module</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <td>{item.rolename}</td>
                  <td>
                    {item.modules?.map((module, idx) => (
                      <span key={idx}>
                        {module.name}
                        {idx !== item.modules.length - 1 && ", "}
                      </span>
                    ))}
                  </td>
                  <td className="d-flex justify-content-center">
                    {item.rolename !== 'superadmin' && previllages.edit && (
                      <button
                        className="btn btn-primary mx-1"
                        onClick={() => handleUpdatePopup(item._id)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    )}
                    {item.rolename !== 'superadmin' && previllages.delete && (
                      <button
                        className="btn btn-danger mx-1"
                        onClick={() =>
                          handleRoleDeletePopup(item._id, item.rolename)
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-9"></div>
        <div className="col-lg-3">
          <nav>
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                >
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((_, idx) => (
                <li
                  key={idx}
                  className={`page-item ${currentPage === idx + 1 ? "" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => setCurrentPage(idx + 1)}
                  >
                    {idx + 1}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Roles;
