import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function StaffInfo() {
  const [staff, setStaff] = useState([]); // Initialize as empty array
  const [imageFile, setImageFile] = useState(null);

  const [addData, setAddData] = useState({
    fees: "",
    discount: "",
    payment_type: "",
    duration: "",
  });

  const { id } = useParams();

  useEffect(() => {
    fetchDatas();
  }, [id]);

  const fetchDatas = async () => {
    try {
      const responseStaff = await axios.get(apiUrl + `/staff/${id}`);
      setStaff(responseStaff.data[0]);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const formatDatefitching = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };

  function handleIdProof(e) {
    const file = e.target.files[0];
    if (file) {
      setAddData({ ...addData, id_proof: file });

      const reader = new FileReader();
      reader.onload = () => {
        setImageFile(reader.result); // Display the uploaded image as base64
      };
      reader.readAsDataURL(file);
    }
  }

  const navigate = useNavigate();

  const handlecancel = () => {
    navigate("/staff");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", addData);
    // Add your logic to handle form submission
  };

  return (
    <>
      <div className="marginup">
        <div className="row">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/staff" className="text-secondary dashBoard_text">
              <b>Staff Details / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Profile</b>
            </Link>
          </div>
        </div>
        <h5>
          <b>Staff Profile</b>
        </h5>
        <div className="row mt-2">
          <div className="col-lg-3 col-sm-3 col-md-3">
            <div className="card carddetails p-2">
              <div className="imgdetails">
                <img
                  src={`${apiUrl}/uploads/${staff.photo_upload}`}
                  alt=""
                  className="img-fluid"
                  style={{ height: "200px", width: "100%", objectFit: "cover" }}
                />
              </div>
              <div className="text-center mt-2 text-white">
                <h6 className="profile-details">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-user "></i>
                    </div>
                    <div className="col-lg-10 text-center">
                      <b> {staff?.name || ""}</b>
                    </div>
                  </div>
                </h6>
                <h6 className="profile-details">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-phone"></i>
                    </div>
                    <div className="col-lg-10 text-center">
                      <b> {staff?.mobile || ""}</b>
                    </div>
                  </div>
                </h6>
                <h6 className="profile-details">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center">
                      <b> {staff?.empid || ""}</b>
                    </div>
                  </div>
                </h6>
                <h6 className="profile-details">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-book"></i>
                    </div>
                    <div className="col-lg-10 text-center">
                      <b> {staff?.designation?.[0].department || ""}</b>
                    </div>
                  </div>
                </h6>
                <h6 className="profile-details">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-book"></i>
                    </div>
                    <div className="col-lg-10 text-center">
                      <b> {staff?.designation?.[0].designation || ""}</b>
                    </div>
                  </div>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="card cardborder p-1">
              <div className="row p-1">
                <div className="col-lg-6 col-md-6 col-sm-6 text-danger">
                  <b>Profile Info</b>
                </div>
              </div>
            </div>
            <div className="card cardborder">
              <div className="lightblue p-2">
                <div className="row p-1">
                  <div className="col-lg-5">
                    <strong>Branch </strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.branch_id?.[0]?.branch_name || ""}
                  </div>
                </div>
                <div className="row p-1">
                  <div className="col-lg-5">
                    <strong>Father Name </strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.fathername || ""}
                  </div>
                </div>
                <div className="row mt-1 p-1">
                  <div className="col-lg-5">
                    <strong>Qualification</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.qualification || ""}
                  </div>
                </div>
                <div className="row mt-1 p-1">
                  <div className="col-lg-5">
                    <strong>Mobile</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.mobile || ""}
                  </div>
                </div>
                <div className="row mt-1 p-1">
                  <div className="col-lg-5">
                    <strong>Address</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.address || ""}
                  </div>
                </div>
                <div className="row mt-1 p-1">
                  <div className="col-lg-5">
                    <strong>Aadhar No.</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.aadhar_number || ""}
                  </div>
                </div>
                <div className="row p-1">
                  <div className="col-lg-5">
                    <strong>Date of Birth</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.dateofbirth
                      ? new Date(staff.dateofbirth).toLocaleDateString("en-CA") // Format as YYYY-MM-DD
                      : ""}
                  </div>
                </div>

                <div className="row p-1">
                  <div className="col-lg-5">
                    <strong>Email</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.email || ""}
                  </div>
                </div>
                <div className="row mt-1 p-1">
                  <div className="col-lg-5">
                    <strong>Salary</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.salary || ""}
                  </div>
                </div>
                <div className="row mt-1 p-1">
                  <div className="col-lg-5">
                    <strong>Notes</strong>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.notes || ""}
                  </div>
                </div>
                <div className="row mt-2 details-datas">
                  <div className="col-lg-5 mt-1">
                    <b>Status</b>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.status || ""}
                  </div>
                </div>
                <div className="row mt-2 details-datas">
                  <div className="col-lg-5 mt-1">
                    <b> Timing</b>
                  </div>
                  <div className="col-lg-6 text-secondary">
                    {staff?.timings || ""}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card carddetails p-1">
              <div className="row p-1">
                <div className="col-lg-12 text-danger">
                  <b>Documents</b>
                </div>
              </div>
            </div>
            <div className="card carddetails details-data_overall p-1">
              <div>
                <img
                  src={`${apiUrl}/uploads/${staff.documents}`}
                  alt=""
                  className="img-fluid"
                  style={{ height: "220px", width: "100%", objectFit: "cover" }}
                />
              </div>
            </div>

            <div className="card cardborder p-1 Completed-profile">
              <div className="row details-datas">
                <div className="col-lg-5 mt-1">
                  <b>User ID</b>
                </div>
                <div className="col-lg-7 text-secondary">
                  {staff?.deviceUserId || ""}
                </div>
              </div>

              <div className="row details-datas">
                <div className="col-lg-5 mt-1">
                  <b>Martial Status</b>
                </div>
                <div className="col-lg-7 text-secondary">
                  {staff?.marital_status || ""}
                </div>
              </div>

              <div className="row details-datas">
                <div className="col-lg-5 mt-1">
                  <b>Gender</b>
                </div>
                <div className="col-lg-7 text-secondary">
                  {staff?.gender || ""}
                </div>
              </div>
              <div className="row details-datas">
                <div className="col-lg-5 mt-1">
                  <b>Join Date</b>
                </div>
                <div className="col-lg-7 text-secondary">
                  {staff?.join_date
                    ? new Date(staff.join_date).toLocaleDateString("en-CA") // Format as YYYY-MM-DD
                    : ""}
                </div>
              </div>
              <div className="row mt-2 details-datas mb-5">
                <div className="col-lg-5 mt-1">
                  <b>Interview Date</b>
                </div>
                <div className="col-lg-7 text-secondary">
                  {staff?.interview_date
                    ? new Date(staff.interview_date).toLocaleDateString("en-CA") // Format as YYYY-MM-DD
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffInfo;
