// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import apiUrl from "../../environment/Api";
// import "../../assets/css/Addstudent.css";
// import { useNavigate, useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import { Modal, Button } from "react-bootstrap";
// const steps = [
//   "Step 1: Personal Details",
//   "Step 2: Address Details",
//   "Step 3: Confirmation",
// ];

// function RegistrationUpdate() {
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [responseData, setResponseData] = useState([]);
//   const [responseOldData, setResponseOldData] = useState([]);
//   const [responsepayment, setResponsepayment] = useState([]);
//   const [responsepurpose, setResponsepurpose] = useState([]);
//   const [responsesource, setResponsesource] = useState([]);

//   const [addData, setAddData] = useState({});

//   const [branch, setbranch] = useState([]);

//   const navigate = useNavigate();
//   const handlecancel = () => {
//     navigate("/Studyingstudents");
//   };

//   const { id } = useParams();
//   const [Coursename, setCourse] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   useEffect(() => {
//     fetchData();


//   }, []);

//   const [imagePreview, setImagePreview] = useState(null);



//   const [imagePreviewuploade, setImagePreviewuplaod] = useState(null);

//   console.log("id is there" + id);
//   const [courseData, getCourseData] = useState([]);
//   const fetchData = async () => {
//     try {
//       const response = await axios.get(apiUrl + `/student/${id}`);
//       const responsecourse = await axios.get(apiUrl + `/course`);
//       const responsepayments = await axios.get(apiUrl + "/paytype");
//       const responsepurpose = await axios.get(apiUrl + "/enquiry_purpose");
//       const responsesources = await axios.get(apiUrl + "/source_type")
//       console.log("Response from studingstudent idss:", response.data);
//       if (response.data.length > 0) {
//         const idProofUrl = `${apiUrl}/uploads/${response.data[0].id_proof}`;
//         setImagePreview(idProofUrl);
//       }
//       if (response.data.length > 0) {
//         const photouploadUrl = `${apiUrl}/uploads/${response.data[0].photo_upload}`;
//         setImagePreviewuplaod(photouploadUrl);
//       }


//       console.log("Response from server:555", response.data[0]?.category);

//       setResponsepayment(responsepayments.data);
//       getCourseData(responsecourse.data);
//       setResponsepurpose(responsepurpose.data);
//       setResponsesource(responsesources.data);
//       setResponseOldData(response);
//       setAddData(response.data);
//       setCourse(response.data[0]?.Prefered_course_lead)
//       const data = responsecourse.data
//       console.log("Response from server:1212", response.data);
//       console.log("Response from server:", response.data[0]?.category);
//       console.log("Response from server:", response.data[0]?.category);
//       console.log("Response from server:12", Coursename);

//       if (response && response.data) {
//         const data = response.data;
//         const filteredData = data.filter((item) => item.status !== "Converted");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };
//   useEffect(() => {
//     fetchDatas();
//     fetchDatased();

//     const filteredCourses = courseData.filter(
//       (course) => course.course_name === Coursename
//     );

//     // Update `filteredData` state
//     setFilteredData(filteredCourses);
//   }, [filteredData]);

//   const formatDate = (date) => {
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

//   console.log("data is there responseOldData", responseOldData);

//   // // Use useEffect to set state when responseData is available
//   // useEffect(() => {

//   //       console.log("Data is there111", id, studentData);

//   //       setAddData((prevState) => ({
//   //         ...prevState,
//   //         branch_id: responseOldData.branch_id,
//   //         course_id: responseOldData.course_id,
//   //         student_name: responseOldData.student_name,
//   //         Source_type: responseOldData.Source_type,
//   //         assign_staff: responseOldData.assign_staff,
//   //         mobile_no: responseOldData.mobile_no,
//   //         email: responseOldData.email,
//   //         Enquiry_date: responseOldData.Enquiry_date,
//   //         Prefered_course: responseOldData.Prefered_course,
//   //         leads_id: responseOldData._id,
//   //         fees: responseOldData.fees,
//   //         discount: responseOldData.discount,
//   //         duration: responseOldData.duration,
//   //       }));

//   //       console.log("Data is there", responseOldData);

//   //   }, [responseOldData]);

//   console.log("llolllll", addData);

//   const handlesubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append("admission_date", addData[0]?.admission_date);
//     formData.append("qualification", addData[0]?.qualification);
//     formData.append("duraction", filteredData[0]?.duration);
//     formData.append("batch_timings", addData[0]?.batch_timings);
//     formData.append("fees", filteredData[0]?.fees);
//     formData.append("discount", filteredData[0]?.discount);
//     formData.append("student_type", addData[0]?.student_type);
//     formData.append("payment_type", addData[0]?.payment_type);
//     formData.append("roll_no", addData[0]?.roll_no);
//     // formData.append("leads_id", addData[0]?.leads_id[0]?._id);
//     formData.append("father_name", addData[0]?.father_name);
//     formData.append("date_of_birth", addData[0]?.date_of_birth);
//     formData.append("trainer", addData[0]?.trainer);
//     formData.append("course_id", addData[0]?.course_id);
//     formData.append("register_no", addData[0]?.register_no);
//     formData.append("placement", addData[0]?.placement);




//     formData.append("branch_lead", addData[0]?.branch_lead);
//     formData.append("student_name_lead", addData[0]?.student_name_lead);
//     formData.append("mobile_no_lead", addData[0]?.mobile_no_lead);
//     formData.append("email_lead", addData[0]?.email_lead);
//     formData.append("Prefered_course_lead", Coursename);
//     formData.append("purpose_enquiry_lead", addData[0]?.purpose_enquiry_lead);
//     formData.append("Enquiry_date_lead", addData[0]?.Enquiry_date_lead);
//     formData.append("Source_type_lead", addData[0]?.Source_type_lead);



//     formData.append("contact_type_lead", addData[0]?.contact_type_lead);
//     formData.append("assign_staff_lead", addData[0]?.assign_staff_lead);
//     formData.append("referal_candidate_name_lead", addData[0]?.referal_candidate_name_lead);
//     formData.append("referal_candidate_lead", addData[0]?.referal_candidate_lead);
//     formData.append("followups_time_lead", addData[0]?.followups_time_lead);
//     formData.append("followups_date_lead", addData[0]?.followups_date_lead);
//     // formData.append("status_date_lead", updatedDatas.status_date);
//     formData.append("status_time_lead", addData[0]?.status_time_lead);
//     formData.append("followups_status_lead", addData[0]?.followups_status_lead);
//     formData.append("followups_notes_lead", addData[0]?.followups_notes_lead);
//     formData.append("interest_lead", addData[0]?.interest_lead);

//     if (addData[0]?.photo_upload)
//       formData.append("photo_upload", addData[0]?.photo_upload);
//     if (addData[0]?.id_proof) formData.append("id_proof", addData[0]?.id_proof);

//     const leadsdata = {

//       // branch_id: addData[0]?.leads_id[0]?.branch_id,
//       // student_name: addData[0]?.leads_id[0]?.student_name,
//       // Source_type: addData[0]?.leads_id[0]?.Source_type,
//       // assign_staff: addData[0]?.leads_id[0]?.assign_staff,
//       // mobile_no: addData[0]?.leads_id[0]?.mobile_no,
//       // email: addData[0]?.leads_id[0]?.email,
//       // Enquiry_date: addData[0]?.leads_id[0]?.Enquiry_date,
//       // Prefered_course: addData[0]?.leads_id[0]?.Prefered_course,
//       // followups_status: "converted",

//       branch_id: addData[0]?.branch_id,
//       student_name: addData[0]?.student_name,
//       Source_type: addData[0]?.Source_type,
//       assign_staff: addData[0]?.assign_staff,
//       mobile_no: addData[0]?.mobile_no,
//       email: addData[0]?.email,
//       Enquiry_date: addData[0]?.Enquiry_date,
//       Prefered_course: addData[0]?.Prefered_course,
//       followups_status: "converted",
//     };

//     console.log("formdata", leadsdata);

//     try {
//       console.log("the registration updatessssssssssssssssss ", formData)
//       const response = await axios.put(`${apiUrl}/student/${id}`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       // const responseleads = await axios.put(
//       //   `${apiUrl}/leads/${addData[0]?.leads_id[0]?._id}`,
//       //   leadsdata
//       // );
//       // console.log("Updated:", response, responseleads);

//       toast.success("added successfully!");

//       setAddData({
//         admission_date: "",
//         qualification: "",
//         duraction: "",
//         batch_timings: "",
//         fees: "",
//         discount: "",
//         student_type: "",
//         father_name: "",
//         payment_type: "",
//         date_of_birth: "",
//         roll_no: "",
//         photo_upload: null, // File input
//         id_proof: null, // File input
//         // leads_id: "",
//         trainer: "",
//         course_id: "",
//       });
//       fetchData();
//       navigate("/Studyingstudents");
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const fetchDatas = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/leads");

//       if (response && response.data) {
//         const data = response.data;

//         setResponseDatas(data);
//         console.log("Response from servers63s3s:", data);
//       } else {
//         console.error("No data received from server.");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const handleStatusChange = (e) => {
//     const selectedStatus = e.target.value;
//     console.log("the select status", selectedStatus);
//     setAddData({ ...addData, status: selectedStatus });

//     if (selectedStatus === "Callback") {
//       setShowCallbackDate(true);
//     } else if (selectedStatus === "Converted") {
//       setShowCallbackDate(true);
//     } else {
//       setShowCallbackDate(false);
//       setAddData((prevData) => ({
//         ...prevData,
//         callback_date: "",
//         converted_date: "",
//       }));
//     }
//   };

//   const [studentId, setSutudentId] = useState([]);
//   const [stafftId, setStaffId] = useState([]);
//   const fetchDatased = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/Branch");
//       const responsestudent = await axios.get(apiUrl + "/student");
//       const responsestaff = await axios.get(apiUrl + "/staff");
//       console.log("Response from server:", response.data);
//       setbranch(response.data);
//       setSutudentId(responsestudent.data);
//       setStaffId(responsestaff.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const [showCallbackDate, setShowCallbackDate] = useState(false);

//   const [referaltype, setreferaltype] = useState("");

//   const handlereferal = (e) => {
//     console.log(e.target.value);

//     setreferaltype(e.target.value);
//   };

//   const [activeStep, setActiveStep] = useState(0);

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     navigate("/Studyingstudents")
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   // Helper function to format ISO date to YYYY-MM-DD
//   const formatDatefitching = (dateString) => {
//     if (!dateString) return "";
//     return dateString.split("T")[0]; // This will return only the date part (yyyy-mm-dd)
//   };

//   // Function to handle course fetching
//   function handelecoursefetch(e) {
//     const selectedCourseId = e.target.value;

//     console.log(selectedCourseId, "Selected Course ID");

//     // Use find() to get the matching course object
//     const selectedCourse = courseData.find(
//       (item) => item.course_id == selectedCourseId
//     );

//     console.log("Selected Course:", selectedCourse.course_name);

//     if (selectedCourse) {
//       // Update addData state with selected course details
//       setAddData((prevData) => ({
//         ...prevData,
//         0: {
//           // Ensure you're updating the correct index in the array
//           ...prevData[0],
//           Prefered_course: selectedCourse.course_name, // Set the course name
//           fees: selectedCourse.fees, // Set the fees
//           discount: selectedCourse.discount, // Set the discount
//           duraction: selectedCourse.duration,
//           course_id: selectedCourse.course_id,
//         },
//       }));
//     } else {
//       console.error("Course not found");
//     }
//   }
//   const [selectedOption, setSelectedOption] = useState("");

//   const handleChangereg = (e) => {
//     setAddData({ ...addData, register_no: e.target.value });
//   };
//   //web Cam
//   const videoRef = useRef(null);
//   const fileInputRef = useRef(null);
//   const canvasRef = useRef(null);
//   // console.log("add data", addData);

//   // const photo_upload = addData[0]?.photo_upload;
//   // const id_proof = addData[0]?.id_proof;
//   // const [image, setImage] = useState(null);
//   // const [imageFile, setImageFile] = useState(null);

//   // useEffect(() => {
//   //   setImage(`http://192.168.1.38:8000/api/uploads/${photo_upload}`);
//   //   setImageFile(`http://192.168.1.38:8000/api/uploads/${id_proof}`);
//   // }, []);
//   const [image, setImage] = useState(null);
//   const [imageFile, setImageFile] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     if (addData.length > 0) {
//       const photo = addData[0]?.photo_upload;
//       const proof = addData[0]?.id_proof;
//       // setImage(photo ? `http://192.168.1.38:8000/api/uploads/${photo}` : null);
//       setImage(photo ? `${apiUrl}/api/uploads/${photo}` : null);

//       setImageFile(
//         //  proof ? `http://192.168.1.38:8000/api/uploads/${proof}` : null
//         proof ? `${apiUrl}/api/uploads/${proof}` : null
//       );
//     }
//   }, [addData]);

//   const [isWebcamActive, setIsWebcamActive] = useState(false);

//   // Open file input dialog
//   const openFileUpload = () => {
//     setIsWebcamActive(false); // Ensure webcam is turned off when file upload is selected
//     setImagePreviewuplaod(false);

//     fileInputRef.current.click();
//   };

//   // Handle file input change
//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];

//     setAddData((prevData) => ({
//       ...prevData,
//       0: {
//         // Ensure you're updating the correct index in the array
//         ...prevData[0],
//         photo_upload: file, // Correctly update qualification
//       },
//     }));
//     // setAddData({ ...addData, photo_upload: file });
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImagePreviewuplaod(reader.result); // Display the uploaded image
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   // Start webcam stream
//   const startWebcam = async () => {
//     setIsWebcamActive(true);
//     setImagePreviewuplaod(false);

//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//       }
//     } catch (err) {
//       console.error("Error accessing webcam: ", err);
//     }
//   };

//   const captureImage = () => {
//     setImagePreviewuplaod(false);

//     const video = videoRef.current;
//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");

//     if (video && canvas) {
//       // Set canvas size to match video dimensions
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);

//       // Get the data URL of the captured image
//       const imageUrl = canvas.toDataURL("image/png");
//       setImagePreviewuplaod(imageUrl); // Optional: if you want to display the image in the UI

//       // Convert the data URL to a Blob
//       const byteString = atob(imageUrl.split(",")[1]);
//       const mimeString = imageUrl.split(",")[0].split(":")[1].split(";")[0];
//       const arrayBuffer = new ArrayBuffer(byteString.length);
//       const uintArray = new Uint8Array(arrayBuffer);

//       for (let i = 0; i < byteString.length; i++) {
//         uintArray[i] = byteString.charCodeAt(i);
//       }

//       const blob = new Blob([uintArray], { type: mimeString });

//       // Create a File object from the Blob
//       const file = new File([blob], "captured_image.png", {
//         type: "image/png",
//       });

//       // Set the file in your state
//       // setAddData({ ...addData, photo_upload: file });
//       setAddData((prevData) => ({
//         ...prevData,
//         0: {
//           // Ensure you're updating the correct index in the array
//           ...prevData[0],
//           photo_upload: file, // Correctly update qualification
//         },
//       }));

//       // Stop the webcam after capturing the image
//       stopWebcam();
//     }
//   };

//   // Stop the webcam stream
//   const stopWebcam = () => {
//     if (videoRef.current && videoRef.current.srcObject) {
//       const stream = videoRef.current.srcObject;
//       const tracks = stream.getTracks();
//       tracks.forEach((track) => track.stop()); // Stop each track
//       videoRef.current.srcObject = null;
//       setIsWebcamActive(false);
//     }
//   };
//   function handleIdProof(e) {
//     const file = e.target.files[0];

//     if (file) {
//       // setAddData({ ...addData, id_proof: file });
//       setAddData((prevData) => ({
//         ...prevData,
//         0: {
//           // Ensure you're updating the correct index in the array
//           ...prevData[0],
//           id_proof: file, // Correctly update qualification
//         },
//       }));

//       const reader = new FileReader();
//       reader.onload = () => {
//         setImagePreview(reader.result); // Display the uploaded image as base64
//       };
//       reader.readAsDataURL(file);
//     }
//   }



//   const handleCourseFetch = (e) => {
//     const selectedCourse = e.target.value;

//     // Update `addData` state
//     setCourse(selectedCourse)
//     console.log("Filtered Courses:", courseData);
//     // Filter the courses based on the selected category
//     const filteredCourses = courseData.filter(
//       (course) => course.course_name === selectedCourse
//     );

//     // Update `filteredData` state
//     setFilteredData(filteredCourses);

//     // Log the filtered courses directly
//     console.log("Filtered Courses:filteredCourses", filteredCourses);

//     // setAddData((prevData) => ({
//     //   ...prevData,

//     //   duraction:filteredData.duration,
//     //   fees:filteredData.fees,
//     //   discount:filteredData.discount,
//     // }))
//   };


//   return (
//     <div className="style" style={{ marginTop: "-80px", marginBottom: "30px" }}>
//       <div className="insermodal">
//         <div>
//           <h5>Registration</h5>
//           {/* <form onSubmit={handlesubmit}> */}
//           {/* Other input fields */}
//           <div className="row mt-5 ">
//             {activeStep === 0 && (
//               <div className="col-lg-12" style={{ marginTop: "-25px" }}>
//                 <div className="card  p-4 pb-0">
//                   <h4 className="mt-1 mb-3"> step 1</h4>
//                   <div className="form-group">
//                     <div className="row">
//                       <div className="col-lg-6">
//                         <div className="floating-label mt-1">
//                           <label className="form-label">
//                             <b></b>
//                           </label>
//                           <select
//                             className="form-select"
//                             placeholder=""
//                             value={addData[0]?.branch_lead}
//                             onChange={(e) =>
//                               setAddData((prevData) => {
//                                 const newBranchId = e.target.value; // Get the new branch ID from the select
//                                 return {
//                                   ...prevData,
//                                   0: {
//                                     // Ensure you correctly update the first element of addData
//                                     ...prevData[0],

//                                     ...prevData[0]?.branch_lead, // Spread the previous leads_id object
//                                     branch_lead: newBranchId, // Update the branch_id

//                                   },
//                                 };
//                               })
//                             }
//                             required
//                           >
//                             <option value="">Select Branch</option>
//                             {branch.map((item, index) => (
//                               <option key={index} value={item._id}>
//                                 {item.branch_name}
//                               </option>
//                             ))}
//                           </select>

//                           <label
//                             className={addData.branch_lead ? "filled" : ""}
//                           ></label>
//                         </div>
//                       </div>

//                       {/* <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData[0]?.father_name}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 father_name: e.target.value,
//                               })
//                             }
//                           />
//                           <label
//                             className={addData.father_name ? "filled" : ""}
//                           >
//                             Father Name
//                           </label>
//                         </div>
//                       </div> */}

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData[0]?.father_name || ""} // Controlled input for father_name
//                             onChange={(e) =>
//                               setAddData((prevData) => {
//                                 const updatedData = [...prevData];
//                                 updatedData[0] = {
//                                   ...updatedData[0],
//                                   father_name: e.target.value,
//                                 };
//                                 return updatedData;
//                               })
//                             }
//                           />
//                           <label
//                             className={addData[0]?.father_name ? "filled" : ""}
//                           >
//                             Father Name
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData[0]?.student_name_lead || ""} // Controlled input
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   ...prevData[0],


//                                   ...prevData[0]?.student_name_lead,
//                                   student_name_lead: e.target.value, // Correctly update student_name

//                                 },
//                               }))
//                             } // Handle onChange event
//                             required
//                           />
//                           {/* Conditionally apply the "filled" class to the label if student_name exists */}
//                           <label
//                             className={
//                               addData[0]?.student_name_lead
//                                 ? "filled"
//                                 : ""
//                             }
//                           >
//                             Student Name
//                           </label>
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData[0]?.qualification || ""}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   ...prevData[0],
//                                   qualification: e.target.value,
//                                 },
//                               }))
//                             }
//                           />
//                           <label
//                             className={
//                               addData[0]?.qualification ? "filled" : ""
//                             }
//                           >
//                             Qualification
//                           </label>
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="number"
//                             maxLength={10}
//                             value={addData[0]?.mobile_no_lead}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   ...prevData[0],


//                                   ...prevData[0]?.mobile_no_lead,
//                                   mobile_no_lead: e.target.value, // Correctly update student_name


//                                 },
//                               }))
//                             } // Store the file object
//                             required
//                           />
//                           <label className={addData.mobile_no_lead ? "filled" : ""}>
//                             Mobile No
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="date"
//                             value={formatDatefitching(
//                               addData[0]?.date_of_birth || ""
//                             )}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   date_of_birth: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             } // Store the file object
//                             required
//                           />
//                           <label
//                             className={
//                               addData[0]?.date_of_birth ? "filled" : ""
//                             }
//                           >
//                             Date_of_birth
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="email"
//                             value={addData[0]?.email_lead}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   ...prevData[0],


//                                   ...prevData[0]?.email_lead,
//                                   email_lead: e.target.value, // Correctly update student_name


//                                 },
//                               }))
//                             } // Store the file object
//                             required
//                           />
//                           <label className={addData.email_lead ? "filled" : ""}>
//                             Email
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         {/* <div className="floating-label">
//                           <select
//                             className="form-control"
//                             value={addData[0]?.student_type || ""}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   student_type: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             }
//                             required
//                           >
//                             <option value=""> Select Student Type</option>
//                             <option value="Studying">Studying</option>
//                             <option value="Working">Working</option>
//                             <option value="Jobseeking">Jobseeking</option>
//                           </select>
//                           <label
//                             className={addData.student_type ? "filled" : ""}
//                           ></label>
//                         </div> */}


//                         <div className="floating-label">
//                           <select
//                             className="form-control"
//                             value={addData[0]?.purpose_enquiry_lead || ""}


//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   purpose_enquiry_lead: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             }
//                             required
//                           >
//                             <option value="">Select Student Type</option>
//                             {responsepurpose.map((item, index) => (
//                               <option
//                                 key={index}
//                                 value={item.enquiry_purpose}
//                               >
//                                 {item.enquiry_purpose}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div>
//                           <p>Upload Image</p>

//                           {/* Buttons for selecting file or webcam */}
//                           <button
//                             onClick={openFileUpload}
//                             className="btn border btn-secondary mx-2  "
//                             aria-label="Upload from file"
//                           >
//                             <i className="fa fa-file" /> Upload
//                           </button>
//                           <button
//                             onClick={startWebcam}
//                             className="btn border btn-secondary  mx-2  "
//                             aria-label="Open webcam"
//                           >
//                             <i className="fa fa-camera mx-2" />
//                             Live
//                           </button>

//                           {/* Hidden file input for image upload */}
//                           <input
//                             type="file"
//                             ref={fileInputRef}
//                             onChange={handleFileUpload}
//                             accept="image/*"
//                             style={{ display: "none" }}
//                           />

//                           {/* Video element to show webcam stream */}
//                           {isWebcamActive && (
//                             <div>
//                               <video
//                                 ref={videoRef}
//                                 autoPlay
//                                 width="200"
//                                 height="200"
//                               />
//                               <button
//                                 className="mx-2 fs-2 bg-transparent border-0"
//                                 onClick={(e) => captureImage(e)}
//                                 aria-label="Capture image from webcam"
//                               >
//                                 <i className="fa-solid fa-computer-mouse"></i>
//                               </button>
//                             </div>
//                           )}

//                           {/* Hidden canvas to capture webcam image */}
//                           <canvas ref={canvasRef} style={{ display: "none" }} />

//                           {/* Display captured or uploaded image */}
//                           {imagePreviewuploade && (
//                             <div>
//                               <p>Your Image</p>
//                               <img
//                                 src={imagePreviewuploade}
//                                 alt="Uploaded or Captured"
//                                 width="200"
//                                 height="200"
//                                 className="object-fit-cover"
//                               />
//                             </div>
//                           )}
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="file"
//                             onChange={handleIdProof} // Store the file object
//                             required
//                           />
//                           <label className={addData.id_proof ? "filled" : ""}>
//                             ID Proof
//                           </label>
//                           {imagePreview ? (
//                             <div>
//                               <p>ID Proof:</p>
//                               <img
//                                 src={imagePreview}
//                                 alt="ID Proof"
//                                 width="200"
//                                 height="200"
//                                 className="object-fit-cover"
//                               />
//                             </div>
//                           ) : (
//                             <p>No ID Proof available</p>
//                           )}

//                           {/* {imageFile && (
//                             <div>
//                               <p>Your Image</p>
//                               <img
//                                 src={imageFile} // Use imageFile, not image
//                                 alt="Uploaded or Captured"
//                                 width="200"
//                                 height="200"
//                                 className="object-fit-cover"
//                               />
//                             </div>
//                           )} */}
//                         </div>
//                       </div>

//                     </div>
//                   </div>
//                 </div>
//               </div>
//             )}

//             {/* ////////////////////////////////////////////////// */}
//             {activeStep === 1 && (
//               <div className="col-lg-12" style={{ marginTop: "-25px" }}>
//                 <div className="card p-2 pb-0">
//                   <div className="form-group">
//                     <div className="row">
//                       <h4 className="mt-2 mb-4"> step 2</h4>

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             disabled
//                             type="text"
//                             placeholder=""
//                             value={addData[0]?.roll_no}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   roll_no: e.target.value, // Correctly update qualification
//                                 },
//                               }))

//                             }
//                           />
//                           <label
//                             className={addData[0]?.roll_no ? "filled" : ""}
//                           >
//                             Roll Number
//                           </label>
//                         </div>


//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             disabled
//                             placeholder=""
//                             value={filteredData[0]?.fees}
//                             onChange={(e) =>
//                               setAddData({ ...addData, fees: e.target.value })
//                             }
//                           />
//                           <label className={addData.fees ? "filled" : ""}>
//                             fees
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">

//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             disabled
//                             placeholder=""
//                             value={addData[0]?.category}
//                             onChange={(e) =>
//                               setAddData({ ...addData, category: e.target.value })
//                             }
//                           />
//                           <label className={addData.category ? "filled" : ""}>
//                             Category
//                           </label>
//                         </div>



//                         <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={Coursename}
//                             onChange={(e) => handleCourseFetch(e)}
//                             required
//                           >
//                             <option disabled value="">
//                               Select Course
//                             </option>{" "}
//                             {/* Ensure value is empty string for default */}
//                             {courseData.map((item, index) => (
//                               <option key={index} value={item.course_name}>
//                                 {item.course_id}-{item.course_name}
//                               </option>
//                             ))}
//                           </select>
//                           <label
//                             className={
//                               addData[0]?.course_id ? "filled" : ""
//                             }
//                           >
//                             Preferred Course
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             disabled
//                             placeholder=""
//                             // value={addData[0].discount}
//                             value={filteredData[0]?.discount}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 discount: e.target.value,
//                               })
//                             }
//                           />
//                           <label
//                             className={
//                               addData[0]?.leads_id[0]?.discount ? "filled" : ""
//                             }
//                           >
//                             Discount
//                           </label>
//                         </div>

//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="date"
//                             value={formatDatefitching(
//                               addData[0]?.Enquiry_date_lead
//                             )} // Format the date correctly
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   ...prevData[0],

//                                   ...prevData[0]?.Enquiry_date_lead,
//                                   Enquiry_date_lead: e.target.value, // Correctly update student_name

//                                 },
//                               }))
//                             } // Update the date as entered by the user
//                             required
//                           />
//                           <label
//                             className={
//                               addData[0]?.Enquiry_date_lead
//                                 ? "filled"
//                                 : ""
//                             }
//                           >
//                             Enquiry date
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         {/* <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={filteredData[0]?.duration}
//                             // onChange={(e) => handelecoursefetcch(e)}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 duration: e.target.value,
//                               })
//                             }
//                             disabled
//                             required
//                           >
//                             <option disabled value=""></option>{" "}
                          
//                             {courseData.map((item, index) => (
//                               <option value={item.duration}>
//                                 {item.duration}
//                               </option>
//                             ))}
//                           </select>
//                           <label
//                             className={addData[0]?.duraction ? "filled" : ""}
//                           >
//                             Duration{" "}
//                           </label>
//                         </div> */}

//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             disabled
//                             placeholder=""
//                             value={filteredData[0]?.duration}
//                             onChange={(e) =>
//                               setAddData({ ...addData, duration: e.target.value })
//                             }
//                           />
//                           <label className={addData.duration ? "filled" : ""}>
//                             fees
//                           </label>
//                         </div>

//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="date"
//                             placeholder=""
//                             value={addData[0]?.admission_date}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   admission_date: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             }
//                             required
//                           />
//                           <label
//                             className={
//                               addData[0]?.admission_date ? "filled" : ""
//                             }
//                           >
//                             Admission Date
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
                     

//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             value={addData[0]?.batch_timings || ""} // Controlled input for father_name
//                             onChange={(e) =>
//                               setAddData((prevData) => {
//                                 const updatedData = [...prevData];
//                                 updatedData[0] = {
//                                   ...updatedData[0],
//                                   batch_timings: e.target.value,
//                                 };
//                                 return updatedData;
//                               })
//                             }
//                           />
//                           <label
//                             className={addData[0]?.batch_timings ? "filled" : ""}
//                           >
//                             Batch timing
//                           </label>
//                         </div>


//                       </div>

//                       <div className="col-lg-6">
//                         {/* {console.log(
//                           addData.Source_type,
//                           "addData.Source_type ---"
//                         )}
//                         <select
//                           className="form-control"
//                           value={addData[0]?.leads_id[0]?.Source_type || ""} // Dynamically link to state
//                           onChange={(e) =>
//                             setAddData((prevData) => ({
//                               ...prevData,
//                               0: {
//                                 ...prevData[0],
//                                 leads_id: [
//                                   {
//                                     ...prevData[0]?.leads_id[0],
//                                     Source_type: e.target.value, // Correctly update student_name
//                                   },
//                                 ],
//                               },
//                             }))
//                           } // Update state on change
//                         >
//                           <option value="" disabled>
//                             Select Source Type
//                           </option>{" "}
                        
//                           <option value="Google Ad">Google Ad</option>
//                           <option value="Linkedln">LinkedIn</option>
//                           <option value="Facebook">Facebook</option>
//                           <option value="Naukri">Naukri</option>
//                           <option value="Referral">Referral</option>
//                           <option value="Online">Online</option>{" "}
                 
//                         </select> */}



//                         {console.log(
//                           addData.Source_type_lead,
//                           "addData.Source_type ---"
//                         )}

//                         <select
//                           className="form-select"
//                           placeholder=""
//                           value={addData[0]?.Source_type_lead || ""}
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               Source_type_lead: e.target.value,
//                             })
//                           }
//                           required
//                         >
//                           <option value="">Select Source Type </option>
//                           {responsesource.map((item, index) => (
//                             <option key={index} value={item.source_type}>
//                               {item.source_type}
//                             </option>
//                           ))}
//                         </select>




//                       </div>
//                       <div className="col-lg-6">
//                         {/* <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={addData[0]?.payment_type}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   payment_type: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             }
//                             required
//                           >
//                             <option value="">Payment Type</option>
//                             <option value="G-pay">G-pay</option>
//                             <option value="Phone pay">Phone Pay</option>
//                             <option value="Cash">Cash</option>
//                             <option value="Depite card">Debit Card</option>
//                           </select>
//                           <label
//                             className={addData[0]?.payment_type ? "filled" : ""}
//                           ></label>
//                         </div> */}



//                         <div className="floating-label">
//                           <select
//                             className="form-select"
//                             value={addData[0]?.payment_type}
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   payment_type: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             }
//                             required
//                           >
//                             <option value="">Payment Type</option>

//                             {responsepayment.map((item, index) => (
//                               <option key={index} value={item.Payment_type}>
//                                 {item.Payment_type}
//                               </option>
//                             ))}

//                           </select>
//                           <label
//                             className={addData[0]?.payment_type ? "filled" : ""}
//                           ></label>
//                         </div>
//                       </div>
//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <select
//                             className="form-control"
//                             value={addData[0]?.trainer || ""} // Ensure it starts with an empty string if not set
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   trainer: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             }
//                           >
//                             <option value="" disabled>
//                               Select Trainer
//                             </option>{" "}
//                             {/* Ensure value is empty for disabled option */}
//                             {stafftId.map((data, index) => (
//                               <option key={index} value={data.empid}>
//                                 {data.name} - {data.empid}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <div className="floating-label">
//                           <input
//                             className="form-control"
//                             type="text"
//                             placeholder=""
//                             disabled
//                             value={addData[0]?.register_no || ""} // Ensure it starts with an empty string if not set
//                             onChange={handleChangereg}
//                             required
//                           />
//                           <label
//                             className={addData.register_no ? "filled" : ""}
//                           >
//                             Registration No
//                           </label>
//                         </div>
//                       </div>

//                       <div className="col-lg-6">
//                         <label>Placement</label>
//                         <br />
//                         <label>
//                           <input
//                             type="radio"
//                             name="option"
//                             value="yes" // Set the correct value for "yes"
//                             checked={addData[0]?.placement === "yes"} // Check if the placement is "yes"
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   placement: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             } // Update placement state
//                           />
//                           &nbsp; Yes &nbsp;
//                         </label>
//                         <label>
//                           <input
//                             type="radio"
//                             name="option"
//                             value="no" // Set the correct value for "no"
//                             checked={addData[0]?.placement === "no"} // Check if the placement is "no"
//                             onChange={(e) =>
//                               setAddData((prevData) => ({
//                                 ...prevData,
//                                 0: {
//                                   // Ensure you're updating the correct index in the array
//                                   ...prevData[0],
//                                   placement: e.target.value, // Correctly update qualification
//                                 },
//                               }))
//                             } // Update placement state
//                           />
//                           &nbsp; No &nbsp;
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       <div style={{ marginTop: "20px" }}>
//         {activeStep === steps.length - 1 ? (
//           <div>
//             <div className="container">
//               <div className="row" style={{ marginTop: "-60px" }}>
//                 <div className="col-lg-3">
//                   <div className="card carddetails p-2">
//                     <div>
//                       <img
//                         // src="http://192.168.1.38:8000/api/uploads/1728891657163-Screenshot%20(38).png"
//                         src={imagePreviewuploade}
//                         alt="Uploaded or Captured"
//                         width="100%"
//                         height="200"
//                         className="object-fit-cover mb-2"
//                       />
//                     </div>
//                     <div className="text-center text-white">
//                       <h6 className="profile-details bg-secondary">
//                         <div className="row text-center">
//                           <div className="col-lg-1">
//                             <i class="fa-solid fa-user "></i>
//                           </div>
//                           <div className="col-lg-10 text-center">
//                             <b>{addData[0]?.student_name_lead || ""}</b>
//                           </div>
//                         </div>
//                       </h6>
//                       <h6 className="profile-details bg-secondary">
//                         <div className="row text-center">
//                           <div className="col-lg-1">
//                             <i class="fa-solid fa-phone"></i>
//                           </div>
//                           <div className="col-lg-10 text-center">
//                             <b>{addData[0]?.mobile_no_lead || ""}</b>
//                           </div>
//                         </div>
//                       </h6>
//                       <h6 className="profile-details bg-secondary">
//                         <div className="row text-center">
//                           <div className="col-lg-1">
//                             <i class="fa-solid fa-id-card"></i>
//                           </div>
//                           <div className="col-lg-10 text-center">
//                             <b> {addData[0].register_no || ""}</b>
//                           </div>
//                         </div>
//                       </h6>
//                       <h6 className="profile-details bg-secondary">
//                         <div className="row text-center">
//                           <div className="col-lg-1">
//                             <i class="fa-solid fa-book"></i>
//                           </div>
//                           <div className="col-lg-10 text-center">
//                             <b>
//                               {addData[0]?.red || ""}
//                             </b>
//                           </div>
//                         </div>
//                       </h6>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-5">
//                   <div className="card cardborder">
//                     <div className="lightblued p-2">
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Branch </strong>
//                         </div>
//                         <div className="col-lg-7 ">
//                           <div className="">
//                             <label className="form-label bg-transparent border-0 text-secondary">
//                               <b></b>
//                             </label>
//                             <select
//                               className="form-label bg-transparent border-0 text-secondary"
//                               disabled
//                               placeholder=""
//                               value={addData[0]?.branch_lead}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   branch_lead: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               {branch.map((item, index) => (
//                                 <option key={index} value={item._id}>
//                                   {item.branch_name}
//                                 </option>
//                               ))}
//                             </select>
//                             <label
//                               className={addData.branch_lead ? "filled" : ""}
//                             ></label>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Roll No </strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.roll_no}
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Father Name </strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.father_name || ""}
//                         </div>
//                       </div>
//                       <div className="row mt-1 p-1">
//                         <div className="col-lg-5">
//                           <strong>Qualification</strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.qualification || ""}
//                         </div>
//                       </div>

//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Date of Birth</strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {formatDatefitching(addData[0]?.date_of_birth || "")}
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Email</strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.email_lead || ""}
//                         </div>
//                       </div>
//                       <div className="row mt-1 p-1">
//                         <div className="col-lg-5">
//                           <strong>Purpose Enquiry</strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.student_type}
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Source Type</strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.Source_type_lead || ""}
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Batch Timing </strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.batch_timings}
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Placement </strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.placement || ""}
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Trainer</strong>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {/* {addData.trainer} */}
//                           <div className="" style={{ marginLeft: "-12px" }}>
//                             <select
//                               className="form-control bg-transparent border-0 text-secondary"
//                               disabled
//                               value={addData[0]?.trainer || ""} // Ensure it starts with an empty string if not set
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   trainer: e.target.value,
//                                 })
//                               }
//                             >
//                               {/* Ensure value is empty for disabled option */}
//                               {stafftId.map((data, index) => (
//                                 <option key={index} value={data.empid}>
//                                   {data.name} - {data.empid}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Admission Date</strong>
//                         </div>
//                         <div className="col-lg-7 text-secondary">
//                           {formatDatefitching(addData[0]?.admission_date)}
//                         </div>
//                       </div>
//                       <div className="row p-1">
//                         <div className="col-lg-5">
//                           <strong>Enquiry Date </strong>
//                         </div>
//                         <div className="col-lg-7 text-secondary">
//                           {formatDatefitching(
//                             addData[0]?.Enquiry_date_lead
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4">
//                   <div className="card carddetails p-1">
//                     <div className="row p-1">
//                       <div className="col-lg-12 text-danger">
//                         <b>ID Proof</b>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="card carddetails details-data_overall p-1">
//                     <div>
//                       <img
//                         src={imagePreview} // Use imageFile, not image
//                         alt="Uploaded or Captured"
//                         width="100%"
//                         height="200vh"
//                         className="object-fit-full"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
//                     <div className="card cardborder ">
//                       {/* <b className="text-danger p-1">Topics Covered</b> */}
//                     </div>
//                     <div className="card cardborder p-1 Completed-profile ">
//                       <div className="row details-datas ">
//                         <div className="col-lg-6 mt-1">
//                           <b> Fees</b>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {/* {addData[0]?.fees} */}
//                           {filteredData[0]?.fees}
//                         </div>
//                       </div>
//                       <div className="row mt-2 details-datas">
//                         <div className="col-lg-6 mt-1">
//                           <b>Discount</b>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {/* {addData[0]?.discount} */}
//                           {filteredData[0]?.discount}
//                         </div>
//                       </div>
//                       <div className="row mt-2 details-datas">
//                         <div className="col-lg-6 mt-1">
//                           <b>Payment Type</b>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {addData[0]?.payment_type || ""}
//                         </div>
//                       </div>
//                       <div className="row mt-2 details-datas">
//                         <div className="col-lg-6 mt-1">
//                           <b>Duration</b>
//                         </div>
//                         <div className="col-lg-6 text-secondary">
//                           {/* {addData[0]?.duraction} */}
//                           {filteredData[0]?.duration}
//                         </div>
//                       </div>
//                     </div>

//                     <div className="row p-3 ">
//                       <div className="text-center ">
//                         <input type="checkbox" id="agree" required />
//                         <label htmlFor="agree " className="mx-2">
//                           I Agree to all conditions
//                         </label>
//                       </div>

//                       <div className="col-lg-4 text-center d-flex justify-content-end">
//                         <button
//                           className="Cancelbut "
//                           variant="secondary"
//                           onClick={handlecancel}
//                         >
//                           Cancel
//                         </button>
//                       </div>
//                       <div className="col-lg-4 d-flex ">
//                         <Button
//                           variant="contained"
//                           style={{
//                             backgroundColor: "blue",
//                             color: "white",
//                           }} // Inline style for background color
//                           disabled={activeStep === 0}
//                           onClick={handleBack}
//                         >
//                           Back
//                         </Button>
//                       </div>
//                       <div className="col-lg-4 text-center d-flex justify-content-start">
//                         <button
//                           className="Submitbut"
//                           type="submit"
//                           variant="primary"
//                           onClick={handlesubmit}
//                         >
//                           Submit
//                         </button>
//                       </div>

//                       {/* <div className="text-center mt-3"></div> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <>
//             <Button
//               variant="contained"
//               style={{
//                 backgroundColor: "gray",
//                 marginRight: "10px",
//                 color: "white",
//               }} // Inline style for background color
//               // disabled={activeStep === 0}
//               onClick={handleBack}
//             >
//               Back
//             </Button>

//             <Button variant="contained" color="primary" onClick={handleNext}>
//               Next
//             </Button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// }

// export default RegistrationUpdate;




import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import apiUrl from "../../environment/Api";
import "../../assets/css/Addstudent.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
const steps = [
  "Step 1: Personal Details",
  "Step 2: Address Details",
  "Step 3: Confirmation",
];

function RegistrationUpdate() {
  const [responseDatas, setResponseDatas] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [responseOldData, setResponseOldData] = useState([]);
  const [responsepayment, setResponsepayment] = useState([]);
  const [responsepurpose, setResponsepurpose] = useState([]);
  const [responsesource, setResponsesource] = useState([]);

  const [addData, setAddData] = useState({});

  const [branch, setbranch] = useState([]);

  const navigate = useNavigate();
  const handlecancel = () => {
    navigate("/Studyingstudents");
  };

  const { id } = useParams();
  const [Coursename, setCourse] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    fetchData();


  }, []);

  const [imagePreview, setImagePreview] = useState(null);



  const [imagePreviewuploade, setImagePreviewuplaod] = useState(null);

  console.log("id is there" + id);
  const [courseData, getCourseData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + `/student/${id}`);
      const responsecourse = await axios.get(apiUrl + `/course`);
      const responsepayments = await axios.get(apiUrl + "/paytype");
      const responsepurpose = await axios.get(apiUrl + "/enquiry_purpose");
      const responsesources = await axios.get(apiUrl + "/source_type")
      console.log("Response from studingstudent idss:", response.data);
      if (response.data.length > 0) {
        const idProofUrl = `${apiUrl}/uploads/${response.data[0].id_proof}`;
        setImagePreview(idProofUrl);
      }
      if (response.data.length > 0) {
        const photouploadUrl = `${apiUrl}/uploads/${response.data[0].photo_upload}`;
        setImagePreviewuplaod(photouploadUrl);
      }


      console.log("Response from server:555", response.data[0]?.category);

      setResponsepayment(responsepayments.data);
      getCourseData(responsecourse.data);
      setResponsepurpose(responsepurpose.data);
      setResponsesource(responsesources.data);
      setResponseOldData(response);
      setAddData(response.data);
      setCourse(response.data[0]?.Prefered_course_lead)
      const data = responsecourse.data
      console.log("Response from server:1212", response.data);
      console.log("Response from server:", response.data[0]?.category);
      console.log("Response from server:", response.data[0]?.category);
      console.log("Response from server:12", Coursename);

      if (response && response.data) {
        const data = response.data;
        const filteredData = data.filter((item) => item.status !== "Converted");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchDatas();
    fetchDatased();

    const filteredCourses = courseData.filter(
      (course) => course.course_name === Coursename
    );

    // Update `filteredData` state
    setFilteredData(filteredCourses);
  }, [filteredData]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  console.log("data is there responseOldData", responseOldData);

  // // Use useEffect to set state when responseData is available
  // useEffect(() => {

  //       console.log("Data is there111", id, studentData);

  //       setAddData((prevState) => ({
  //         ...prevState,
  //         branch_id: responseOldData.branch_id,
  //         course_id: responseOldData.course_id,
  //         student_name: responseOldData.student_name,
  //         Source_type: responseOldData.Source_type,
  //         assign_staff: responseOldData.assign_staff,
  //         mobile_no: responseOldData.mobile_no,
  //         email: responseOldData.email,
  //         Enquiry_date: responseOldData.Enquiry_date,
  //         Prefered_course: responseOldData.Prefered_course,
  //         leads_id: responseOldData._id,
  //         fees: responseOldData.fees,
  //         discount: responseOldData.discount,
  //         duration: responseOldData.duration,
  //       }));

  //       console.log("Data is there", responseOldData);

  //   }, [responseOldData]);

  console.log("llolllll", addData);

  const handlesubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("admission_date", addData[0]?.admission_date);
    formData.append("qualification", addData[0]?.qualification);
    formData.append("duraction", filteredData[0]?.duration);
    formData.append("batch_timings", addData[0]?.batch_timings);
    formData.append("fees", filteredData[0]?.fees);
    formData.append("discount", filteredData[0]?.discount);
    formData.append("student_type", addData[0]?.student_type);
    formData.append("payment_type", addData[0]?.payment_type);
    formData.append("roll_no", addData[0]?.roll_no);
    // formData.append("leads_id", addData[0]?.leads_id[0]?._id);
    formData.append("father_name", addData[0]?.father_name);
    formData.append("date_of_birth", addData[0]?.date_of_birth);
    formData.append("trainer", addData[0]?.trainer);
    formData.append("course_id", addData[0]?.course_id);
    formData.append("register_no", addData[0]?.register_no);
    formData.append("placement", addData[0]?.placement);




    formData.append("branch_lead", addData[0]?.branch_lead);
    formData.append("student_name_lead", addData[0]?.student_name_lead);
    formData.append("mobile_no_lead", addData[0]?.mobile_no_lead);
    formData.append("email_lead", addData[0]?.email_lead);
    formData.append("Prefered_course_lead", Coursename);
    formData.append("purpose_enquiry_lead", addData[0]?.purpose_enquiry_lead);
    formData.append("Enquiry_date_lead", addData[0]?.Enquiry_date_lead);
    formData.append("Source_type_lead", addData[0]?.Source_type_lead);



    formData.append("contact_type_lead", addData[0]?.contact_type_lead);
    formData.append("assign_staff_lead", addData[0]?.assign_staff_lead);
    formData.append("referal_candidate_name_lead", addData[0]?.referal_candidate_name_lead);
    formData.append("referal_candidate_lead", addData[0]?.referal_candidate_lead);
    formData.append("followups_time_lead", addData[0]?.followups_time_lead);
    formData.append("followups_date_lead", addData[0]?.followups_date_lead);
    // formData.append("status_date_lead", updatedDatas.status_date);
    formData.append("status_time_lead", addData[0]?.status_time_lead);
    formData.append("followups_status_lead", addData[0]?.followups_status_lead);
    formData.append("followups_notes_lead", addData[0]?.followups_notes_lead);
    formData.append("interest_lead", addData[0]?.interest_lead);

    if (addData[0]?.photo_upload)
      formData.append("photo_upload", addData[0]?.photo_upload);
    if (addData[0]?.id_proof) formData.append("id_proof", addData[0]?.id_proof);

    const leadsdata = {

      // branch_id: addData[0]?.leads_id[0]?.branch_id,
      // student_name: addData[0]?.leads_id[0]?.student_name,
      // Source_type: addData[0]?.leads_id[0]?.Source_type,
      // assign_staff: addData[0]?.leads_id[0]?.assign_staff,
      // mobile_no: addData[0]?.leads_id[0]?.mobile_no,
      // email: addData[0]?.leads_id[0]?.email,
      // Enquiry_date: addData[0]?.leads_id[0]?.Enquiry_date,
      // Prefered_course: addData[0]?.leads_id[0]?.Prefered_course,
      // followups_status: "converted",

      branch_id: addData[0]?.branch_id,
      student_name: addData[0]?.student_name,
      Source_type: addData[0]?.Source_type,
      assign_staff: addData[0]?.assign_staff,
      mobile_no: addData[0]?.mobile_no,
      email: addData[0]?.email,
      Enquiry_date: addData[0]?.Enquiry_date,
      Prefered_course: addData[0]?.Prefered_course,
      followups_status: "converted",
    };

    console.log("formdata", leadsdata);

    try {
      console.log("the registration updatessssssssssssssssss ", formData)
      const response = await axios.put(`${apiUrl}/student/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // const responseleads = await axios.put(
      //   `${apiUrl}/leads/${addData[0]?.leads_id[0]?._id}`,
      //   leadsdata
      // );
      // console.log("Updated:", response, responseleads);

      toast.success("Updated Successfully!");

      setAddData({
        admission_date: "",
        qualification: "",
        duraction: "",
        batch_timings: "",
        fees: "",
        discount: "",
        student_type: "",
        father_name: "",
        payment_type: "",
        date_of_birth: "",
        roll_no: "",
        photo_upload: null, // File input
        id_proof: null, // File input
        // leads_id: "",
        trainer: "",
        course_id: "",
      });
      fetchData();
      navigate("/Studyingstudents");
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/leads");

      if (response && response.data) {
        const data = response.data;

        setResponseDatas(data);
        console.log("Response from servers63s3s:", data);
      } else {
        console.error("No data received from server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    console.log("the select status", selectedStatus);
    setAddData({ ...addData, status: selectedStatus });

    if (selectedStatus === "Callback") {
      setShowCallbackDate(true);
    } else if (selectedStatus === "Converted") {
      setShowCallbackDate(true);
    } else {
      setShowCallbackDate(false);
      setAddData((prevData) => ({
        ...prevData,
        callback_date: "",
        converted_date: "",
      }));
    }
  };

  const [studentId, setSutudentId] = useState([]);
  const [stafftId, setStaffId] = useState([]);
  const fetchDatased = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      const responsestudent = await axios.get(apiUrl + "/student");
      const responsestaff = await axios.get(apiUrl + "/staff");
      console.log("Response from server:", response.data);
      setbranch(response.data);
      setSutudentId(responsestudent.data);
      setStaffId(responsestaff.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [showCallbackDate, setShowCallbackDate] = useState(false);

  const [referaltype, setreferaltype] = useState("");

  const handlereferal = (e) => {
    console.log(e.target.value);

    setreferaltype(e.target.value);
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // navigate("/Studyingstudents")
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (dateString) => {
    if (!dateString) return "";
    return dateString.split("T")[0]; // This will return only the date part (yyyy-mm-dd)
  };

  // Function to handle course fetching
  function handelecoursefetch(e) {
    const selectedCourseId = e.target.value;

    console.log(selectedCourseId, "Selected Course ID");

    // Use find() to get the matching course object
    const selectedCourse = courseData.find(
      (item) => item.course_id == selectedCourseId
    );

    console.log("Selected Course:", selectedCourse.course_name);

    if (selectedCourse) {
      // Update addData state with selected course details
      setAddData((prevData) => ({
        ...prevData,
        0: {
          // Ensure you're updating the correct index in the array
          ...prevData[0],
          Prefered_course: selectedCourse.course_name, // Set the course name
          fees: selectedCourse.fees, // Set the fees
          discount: selectedCourse.discount, // Set the discount
          duraction: selectedCourse.duration,
          course_id: selectedCourse.course_id,
        },
      }));
    } else {
      console.error("Course not found");
    }
  }
  const [selectedOption, setSelectedOption] = useState("");

  const handleChangereg = (e) => {
    setAddData({ ...addData, register_no: e.target.value });
  };
  //web Cam
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);
  // console.log("add data", addData);

  // const photo_upload = addData[0]?.photo_upload;
  // const id_proof = addData[0]?.id_proof;
  // const [image, setImage] = useState(null);
  // const [imageFile, setImageFile] = useState(null);

  // useEffect(() => {
  //   setImage(`http://192.168.1.38:8000/api/uploads/${photo_upload}`);
  //   setImageFile(`http://192.168.1.38:8000/api/uploads/${id_proof}`);
  // }, []);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (addData.length > 0) {
      const photo = addData[0]?.photo_upload;
      const proof = addData[0]?.id_proof;
      // setImage(photo ? `http://192.168.1.38:8000/api/uploads/${photo}` : null);
      setImage(photo ? `${apiUrl}/api/uploads/${photo}` : null);

      setImageFile(
        //  proof ? `http://192.168.1.38:8000/api/uploads/${proof}` : null
        proof ? `${apiUrl}/api/uploads/${proof}` : null
      );
    }
  }, [addData]);

  const [isWebcamActive, setIsWebcamActive] = useState(false);

  // Open file input dialog
  const openFileUpload = () => {
    setIsWebcamActive(false); // Ensure webcam is turned off when file upload is selected
    setImagePreviewuplaod(false);

    fileInputRef.current.click();
  };

  // Handle file input change
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    setAddData((prevData) => ({
      ...prevData,
      0: {
        // Ensure you're updating the correct index in the array
        ...prevData[0],
        photo_upload: file, // Correctly update qualification
      },
    }));
    // setAddData({ ...addData, photo_upload: file });
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreviewuplaod(reader.result); // Display the uploaded image
      };
      reader.readAsDataURL(file);
    }
  };

  // Start webcam stream
  const startWebcam = async () => {
    setIsWebcamActive(true);
    setImagePreviewuplaod(false);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error accessing webcam: ", err);
    }
  };

  const captureImage = () => {
    setImagePreviewuplaod(false);

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (video && canvas) {
      // Set canvas size to match video dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the data URL of the captured image
      const imageUrl = canvas.toDataURL("image/png");
      setImagePreviewuplaod(imageUrl); // Optional: if you want to display the image in the UI

      // Convert the data URL to a Blob
      const byteString = atob(imageUrl.split(",")[1]);
      const mimeString = imageUrl.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([uintArray], { type: mimeString });

      // Create a File object from the Blob
      const file = new File([blob], "captured_image.png", {
        type: "image/png",
      });

      // Set the file in your state
      // setAddData({ ...addData, photo_upload: file });
      setAddData((prevData) => ({
        ...prevData,
        0: {
          // Ensure you're updating the correct index in the array
          ...prevData[0],
          photo_upload: file, // Correctly update qualification
        },
      }));

      // Stop the webcam after capturing the image
      stopWebcam();
    }
  };

  // Stop the webcam stream
  const stopWebcam = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop()); // Stop each track
      videoRef.current.srcObject = null;
      setIsWebcamActive(false);
    }
  };
  function handleIdProof(e) {
    const file = e.target.files[0];

    if (file) {
      // setAddData({ ...addData, id_proof: file });
      setAddData((prevData) => ({
        ...prevData,
        0: {
          // Ensure you're updating the correct index in the array
          ...prevData[0],
          id_proof: file, // Correctly update qualification
        },
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result); // Display the uploaded image as base64
      };
      reader.readAsDataURL(file);
    }
  }



  const handleCourseFetch = (e) => {
    const selectedCourse = e.target.value;

    // Update `addData` state
    setCourse(selectedCourse)
    console.log("Filtered Courses:", courseData);
    // Filter the courses based on the selected category
    const filteredCourses = courseData.filter(
      (course) => course.course_name === selectedCourse
    );

    // Update `filteredData` state
    setFilteredData(filteredCourses);

    // Log the filtered courses directly
    console.log("Filtered Courses:filteredCourses", filteredCourses);

    // setAddData((prevData) => ({
    //   ...prevData,

    //   duraction:filteredData.duration,
    //   fees:filteredData.fees,
    //   discount:filteredData.discount,
    // }))
  };


  const [isCheck, setIsCheck] = useState(false);

  const handleClickCheckBox = () => {
  setIsCheck(true)
  }
 

  return (
    <div className="style" style={{ marginTop: "-80px", marginBottom: "30px" }}>
      <div className="insermodal">
        <div>
          <h5>Registration</h5>
          {/* <form onSubmit={handlesubmit}> */}
          {/* Other input fields */}
          <div className="row mt-5 ">
            {activeStep === 0 && (
              <div className="col-lg-12" style={{ marginTop: "-25px" }}>
                <div className="card  p-4 pb-0">
                  <h4 className="mt-1 mb-3"> step 1</h4>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="floating-label mt-1">
                          <label className="form-label">
                            <b></b>
                          </label>
                          <select
                            className="form-select"
                            placeholder=""
                            value={addData[0]?.branch_lead}
                            onChange={(e) =>
                              setAddData((prevData) => {
                                const newBranchId = e.target.value; // Get the new branch ID from the select
                                return {
                                  ...prevData,
                                  0: {
                                    // Ensure you correctly update the first element of addData
                                    ...prevData[0],

                                    ...prevData[0]?.branch_lead, // Spread the previous leads_id object
                                    branch_lead: newBranchId, // Update the branch_id

                                  },
                                };
                              })
                            }
                            required
                          >
                            <option value="">Select Branch</option>
                            {branch.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.branch_name}
                              </option>
                            ))}
                          </select>

                          <label
                            className={addData.branch_lead ? "filled" : ""}
                          ></label>
                        </div>
                      </div>

                      {/* <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData[0]?.father_name}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                father_name: e.target.value,
                              })
                            }
                          />
                          <label
                            className={addData.father_name ? "filled" : ""}
                          >
                            Father Name
                          </label>
                        </div>
                      </div> */}

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData[0]?.father_name || ""} // Controlled input for father_name
                            onChange={(e) =>
                              setAddData((prevData) => {
                                const updatedData = [...prevData];
                                updatedData[0] = {
                                  ...updatedData[0],
                                  father_name: e.target.value,
                                };
                                return updatedData;
                              })
                            }
                          />
                          <label
                            className={addData[0]?.father_name ? "filled" : ""}
                          >
                            Father Name
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData[0]?.student_name_lead || ""} // Controlled input
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  ...prevData[0],


                                  ...prevData[0]?.student_name_lead,
                                  student_name_lead: e.target.value, // Correctly update student_name

                                },
                              }))
                            } // Handle onChange event
                            required
                          />
                          {/* Conditionally apply the "filled" class to the label if student_name exists */}
                          <label
                            className={
                              addData[0]?.student_name_lead
                                ? "filled"
                                : ""
                            }
                          >
                            Student Name
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData[0]?.qualification || ""}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  ...prevData[0],
                                  qualification: e.target.value,
                                },
                              }))
                            }
                          />
                          <label
                            className={
                              addData[0]?.qualification ? "filled" : ""
                            }
                          >
                            Qualification
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="number"
                            maxLength={10}
                            value={addData[0]?.mobile_no_lead}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  ...prevData[0],


                                  ...prevData[0]?.mobile_no_lead,
                                  mobile_no_lead: e.target.value, // Correctly update student_name


                                },
                              }))
                            } // Store the file object
                            required
                          />
                          <label className={addData.mobile_no_lead ? "filled" : ""}>
                            Mobile No
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            value={formatDatefitching(
                              addData[0]?.date_of_birth || ""
                            )}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  date_of_birth: e.target.value, // Correctly update qualification
                                },
                              }))
                            } // Store the file object
                            required
                          />
                          <label
                            className={
                              addData[0]?.date_of_birth ? "filled" : ""
                            }
                          >
                            Date_of_birth
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="email"
                            value={addData[0]?.email_lead}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  ...prevData[0],


                                  ...prevData[0]?.email_lead,
                                  email_lead: e.target.value, // Correctly update student_name


                                },
                              }))
                            } // Store the file object
                            required
                          />
                          <label className={addData.email_lead ? "filled" : ""}>
                            Email
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        {/* <div className="floating-label">
                          <select
                            className="form-control"
                            value={addData[0]?.student_type || ""}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  student_type: e.target.value, // Correctly update qualification
                                },
                              }))
                            }
                            required
                          >
                            <option value=""> Select Student Type</option>
                            <option value="Studying">Studying</option>
                            <option value="Working">Working</option>
                            <option value="Jobseeking">Jobseeking</option>
                          </select>
                          <label
                            className={addData.student_type ? "filled" : ""}
                          ></label>
                        </div> */}


                        <div className="floating-label">
                          <select
                            className="form-control"
                            value={addData[0]?.purpose_enquiry_lead || ""}


                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  purpose_enquiry_lead: e.target.value, // Correctly update qualification
                                },
                              }))
                            }
                            required
                          >
                            <option value="">Select Student Type</option>
                            {responsepurpose.map((item, index) => (
                              <option
                                key={index}
                                value={item.enquiry_purpose}
                              >
                                {item.enquiry_purpose}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div>
                          <p>Upload Image</p>

                          {/* Buttons for selecting file or webcam */}
                          <button
                            onClick={openFileUpload}
                            className="btn border btn-secondary mx-2  "
                            aria-label="Upload from file"
                          >
                            <i className="fa fa-file" /> Upload
                          </button>
                          <button
                            onClick={startWebcam}
                            className="btn border btn-secondary  mx-2  "
                            aria-label="Open webcam"
                          >
                            <i className="fa fa-camera mx-2" />
                            Live
                          </button>

                          {/* Hidden file input for image upload */}
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            accept="image/*"
                            style={{ display: "none" }}
                          />

                          {/* Video element to show webcam stream */}
                          {isWebcamActive && (
                            <div>
                              <video
                                ref={videoRef}
                                autoPlay
                                width="200"
                                height="200"
                              />
                              <button
                                className="mx-2 fs-2 bg-transparent border-0"
                                onClick={(e) => captureImage(e)}
                                aria-label="Capture image from webcam"
                              >
                                <i className="fa-solid fa-computer-mouse"></i>
                              </button>
                            </div>
                          )}

                          {/* Hidden canvas to capture webcam image */}
                          <canvas ref={canvasRef} style={{ display: "none" }} />

                          {/* Display captured or uploaded image */}
                          {imagePreviewuploade && (
                            <div>
                              <p>Your Image</p>
                              <img
                                src={imagePreviewuploade}
                                alt="Uploaded or Captured"
                                width="200"
                                height="200"
                                className="object-fit-cover"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="file"
                            onChange={handleIdProof} // Store the file object
                            required
                          />
                          <label className={addData.id_proof ? "filled" : ""}>
                            ID Proof
                          </label>
                          {imagePreview ? (
                            <div>
                              <p>ID Proof:</p>
                              <img
                                src={imagePreview}
                                alt="ID Proof"
                                width="200"
                                height="200"
                                className="object-fit-cover"
                              />
                            </div>
                          ) : (
                            <p>No ID Proof available</p>
                          )}

                          {/* {imageFile && (
                            <div>
                              <p>Your Image</p>
                              <img
                                src={imageFile} // Use imageFile, not image
                                alt="Uploaded or Captured"
                                width="200"
                                height="200"
                                className="object-fit-cover"
                              />
                            </div>
                          )} */}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* ////////////////////////////////////////////////// */}
            {activeStep === 1 && (
              <div className="col-lg-12" style={{ marginTop: "-25px" }}>
                <div className="card p-2 pb-0">
                  <div className="form-group">
                    <div className="row">
                      <h4 className="mt-2 mb-4"> step 2</h4>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            disabled
                            type="text"
                            placeholder=""
                            value={addData[0]?.roll_no}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  roll_no: e.target.value, // Correctly update qualification
                                },
                              }))

                            }
                          />
                          <label
                            className={addData[0]?.roll_no ? "filled" : ""}
                          >
                            Roll Number
                          </label>
                        </div>


                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            placeholder=""
                            value={filteredData[0]?.fees}
                            onChange={(e) =>
                              setAddData({ ...addData, fees: e.target.value })
                            }
                          />
                          <label className={addData.fees ? "filled" : ""}>
                            fees
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            placeholder=""
                            value={addData[0]?.category}
                            onChange={(e) =>
                              setAddData({ ...addData, category: e.target.value })
                            }
                          />
                          <label className={addData.category ? "filled" : ""}>
                            Category
                          </label>
                        </div>



                        <div className="floating-label">
                          <select
                            className="form-select"
                            value={Coursename}
                            onChange={(e) => handleCourseFetch(e)}
                            required
                          >
                            <option disabled value="">
                              Select Course
                            </option>{" "}
                            {/* Ensure value is empty string for default */}
                            {courseData.map((item, index) => (
                              <option key={index} value={item.course_name}>
                                {item.course_id}-{item.course_name}
                              </option>
                            ))}
                          </select>
                          <label
                            className={
                              addData[0]?.course_id ? "filled" : ""
                            }
                          >
                            Preferred Course
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            placeholder=""
                            // value={addData[0].discount}
                            value={filteredData[0]?.discount}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                discount: e.target.value,
                              })
                            }
                          />
                          <label
                            className={
                              addData[0]?.leads_id[0]?.discount ? "filled" : ""
                            }
                          >
                            Discount
                          </label>
                        </div>

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            value={formatDatefitching(
                              addData[0]?.Enquiry_date_lead
                            )} // Format the date correctly
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  ...prevData[0],

                                  ...prevData[0]?.Enquiry_date_lead,
                                  Enquiry_date_lead: e.target.value, // Correctly update student_name

                                },
                              }))
                            } // Update the date as entered by the user
                            required
                          />
                          <label
                            className={
                              addData[0]?.Enquiry_date_lead
                                ? "filled"
                                : ""
                            }
                          >
                            Enquiry date
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        {/* <div className="floating-label">
                          <select
                            className="form-select"
                            value={filteredData[0]?.duration}
                            // onChange={(e) => handelecoursefetcch(e)}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                duration: e.target.value,
                              })
                            }
                            disabled
                            required
                          >
                            <option disabled value=""></option>{" "}
                          
                            {courseData.map((item, index) => (
                              <option value={item.duration}>
                                {item.duration}
                              </option>
                            ))}
                          </select>
                          <label
                            className={addData[0]?.duraction ? "filled" : ""}
                          >
                            Duration{" "}
                          </label>
                        </div> */}

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            disabled
                            placeholder=""
                            value={filteredData[0]?.duration}
                            onChange={(e) =>
                              setAddData({ ...addData, duration: e.target.value })
                            }
                          />
                          <label className={addData.duration ? "filled" : ""}>
                            fees
                          </label>
                        </div>

                        {/* <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            placeholder=""
                            value={addData[0]?.admission_date}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  admission_date: e.target.value, // Correctly update qualification
                                },
                              }))
                            }
                            required
                          />
                          <label
                            className={
                              addData[0]?.admission_date ? "filled" : ""
                            }
                          >
                            Admission Date
                          </label>
                        </div> */}



                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            value={formatDatefitching(
                              addData[0]?.admission_date || ""
                            )}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  admission_date: e.target.value, // Correctly update qualification
                                },
                              }))
                            } // Store the file object
                            required
                          />
                          <label
                            className={
                              addData[0]?.admission_date ? "filled" : ""
                            }
                          >
                             Admission Date
                          </label>
                        </div>








                      </div>

                      <div className="col-lg-6">
                     

                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            value={addData[0]?.batch_timings || ""} // Controlled input for father_name
                            onChange={(e) =>
                              setAddData((prevData) => {
                                const updatedData = [...prevData];
                                updatedData[0] = {
                                  ...updatedData[0],
                                  batch_timings: e.target.value,
                                };
                                return updatedData;
                              })
                            }
                          />
                          <label
                            className={addData[0]?.batch_timings ? "filled" : ""}
                          >
                            Batch timing
                          </label>
                        </div>


                      </div>

                      <div className="col-lg-6">
                        {/* {console.log(
                          addData.Source_type,
                          "addData.Source_type ---"
                        )}
                        <select
                          className="form-control"
                          value={addData[0]?.leads_id[0]?.Source_type || ""} // Dynamically link to state
                          onChange={(e) =>
                            setAddData((prevData) => ({
                              ...prevData,
                              0: {
                                ...prevData[0],
                                leads_id: [
                                  {
                                    ...prevData[0]?.leads_id[0],
                                    Source_type: e.target.value, // Correctly update student_name
                                  },
                                ],
                              },
                            }))
                          } // Update state on change
                        >
                          <option value="" disabled>
                            Select Source Type
                          </option>{" "}
                        
                          <option value="Google Ad">Google Ad</option>
                          <option value="Linkedln">LinkedIn</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Naukri">Naukri</option>
                          <option value="Referral">Referral</option>
                          <option value="Online">Online</option>{" "}
                 
                        </select> */}



                        {console.log(
                          addData.Source_type_lead,
                          "addData.Source_type ---"
                        )}

                        <select
                          className="form-select"
                          placeholder=""
                          value={addData[0]?.Source_type_lead || ""}
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              Source_type_lead: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Source Type </option>
                          {responsesource.map((item, index) => (
                            <option key={index} value={item.source_type}>
                              {item.source_type}
                            </option>
                          ))}
                        </select>




                      </div>
                      <div className="col-lg-6">
                        {/* <div className="floating-label">
                          <select
                            className="form-select"
                            value={addData[0]?.payment_type}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  payment_type: e.target.value, // Correctly update qualification
                                },
                              }))
                            }
                            required
                          >
                            <option value="">Payment Type</option>
                            <option value="G-pay">G-pay</option>
                            <option value="Phone pay">Phone Pay</option>
                            <option value="Cash">Cash</option>
                            <option value="Depite card">Debit Card</option>
                          </select>
                          <label
                            className={addData[0]?.payment_type ? "filled" : ""}
                          ></label>
                        </div> */}



                        <div className="floating-label">
                          <select
                            className="form-select"
                            value={addData[0]?.payment_type}
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  payment_type: e.target.value, // Correctly update qualification
                                },
                              }))
                            }
                            required
                          >
                            <option value="">Payment Type</option>

                            {responsepayment.map((item, index) => (
                              <option key={index} value={item.Payment_type}>
                                {item.Payment_type}
                              </option>
                            ))}

                          </select>
                          <label
                            className={addData[0]?.payment_type ? "filled" : ""}
                          ></label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <select
                            className="form-control"
                            value={addData[0]?.trainer || ""} // Ensure it starts with an empty string if not set
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  trainer: e.target.value, // Correctly update qualification
                                },
                              }))
                            }
                          >
                            <option value="" disabled>
                              Select Trainer
                            </option>{" "}
                            {/* Ensure value is empty for disabled option */}
                            {stafftId.map((data, index) => (
                              <option key={index} value={data.empid}>
                                {data.name} - {data.empid}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            disabled
                            value={addData[0]?.register_no || ""} // Ensure it starts with an empty string if not set
                            onChange={handleChangereg}
                            required
                          />
                          <label
                            className={addData.register_no ? "filled" : ""}
                          >
                            Registration No
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <label>Placement</label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            name="option"
                            value="yes" // Set the correct value for "yes"
                            checked={addData[0]?.placement === "yes"} // Check if the placement is "yes"
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  placement: e.target.value, // Correctly update qualification
                                },
                              }))
                            } // Update placement state
                          />
                          &nbsp; Yes &nbsp;
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="option"
                            value="no" // Set the correct value for "no"
                            checked={addData[0]?.placement === "no"} // Check if the placement is "no"
                            onChange={(e) =>
                              setAddData((prevData) => ({
                                ...prevData,
                                0: {
                                  // Ensure you're updating the correct index in the array
                                  ...prevData[0],
                                  placement: e.target.value, // Correctly update qualification
                                },
                              }))
                            } // Update placement state
                          />
                          &nbsp; No &nbsp;
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        {activeStep === steps.length - 1 ? (
          <div>
            <div className="container">
              <div className="row" style={{ marginTop: "-60px" }}>
                <div className="col-lg-3">
                  <div className="card carddetails p-2">
                    <div>
                      <img
                        // src="http://192.168.1.38:8000/api/uploads/1728891657163-Screenshot%20(38).png"
                        src={imagePreviewuploade}
                        alt="Uploaded or Captured"
                        width="100%"
                        height="200"
                        className="object-fit-cover mb-2"
                      />
                    </div>
                    <div className="text-center text-white">
                      <h6 className="profile-details bg-secondary">
                        <div className="row text-center">
                          <div className="col-lg-1">
                            <i class="fa-solid fa-user "></i>
                          </div>
                          <div className="col-lg-10 text-center">
                            <b>{addData[0]?.student_name_lead || ""}</b>
                          </div>
                        </div>
                      </h6>
                      <h6 className="profile-details bg-secondary">
                        <div className="row text-center">
                          <div className="col-lg-1">
                            <i class="fa-solid fa-phone"></i>
                          </div>
                          <div className="col-lg-10 text-center">
                            <b>{addData[0]?.mobile_no_lead || ""}</b>
                          </div>
                        </div>
                      </h6>
                      <h6 className="profile-details bg-secondary">
                        <div className="row text-center">
                          <div className="col-lg-1">
                            <i class="fa-solid fa-id-card"></i>
                          </div>
                          <div className="col-lg-10 text-center">
                            <b> {addData[0].register_no || ""}</b>
                          </div>
                        </div>
                      </h6>
                      <h6 className="profile-details bg-secondary">
                        <div className="row text-center">
                          <div className="col-lg-1">
                            <i class="fa-solid fa-book"></i>
                          </div>
                          <div className="col-lg-10 text-center">
                            <b>
                              {addData[0]?.Prefered_course_lead || ""}
                            </b>
                          </div>
                        </div>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="card cardborder">
                    <div className="lightblued p-2">
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Branch </strong>
                        </div>
                        <div className="col-lg-7 ">
                          <div className="">
                            <label className="form-label bg-transparent border-0 text-secondary">
                              <b></b>
                            </label>
                            <select
                              className="form-label bg-transparent border-0 text-secondary"
                              disabled
                              placeholder=""
                              value={addData[0]?.branch_lead}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  branch_lead: e.target.value,
                                })
                              }
                              required
                            >
                              {branch.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.branch_name}
                                </option>
                              ))}
                            </select>
                            <label
                              className={addData.branch_lead ? "filled" : ""}
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Roll No </strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.roll_no}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Father Name </strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.father_name || ""}
                        </div>
                      </div>
                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Qualification</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.qualification || ""}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Date of Birth</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {formatDatefitching(addData[0]?.date_of_birth || "")}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Email</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.email_lead || ""}
                        </div>
                      </div>
                      <div className="row mt-1 p-1">
                        <div className="col-lg-5">
                          <strong>Purpose Enquiry</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.student_type}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Source Type</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.Source_type_lead || ""}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Batch Timing </strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.batch_timings}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Placement </strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.placement || ""}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Trainer</strong>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {addData.trainer} */}
                          <div className="" style={{ marginLeft: "-12px" }}>
                            <select
                              className="form-control bg-transparent border-0 text-secondary"
                              disabled
                              value={addData[0]?.trainer || ""} // Ensure it starts with an empty string if not set
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  trainer: e.target.value,
                                })
                              }
                            >
                              {/* Ensure value is empty for disabled option */}
                              {stafftId.map((data, index) => (
                                <option key={index} value={data.empid}>
                                  {data.name} - {data.empid}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Admission Date</strong>
                        </div>
                        <div className="col-lg-7 text-secondary">
                          {formatDatefitching(addData[0]?.admission_date)}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-lg-5">
                          <strong>Enquiry Date </strong>
                        </div>
                        <div className="col-lg-7 text-secondary">
                          {formatDatefitching(
                            addData[0]?.Enquiry_date_lead
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card carddetails p-1">
                    <div className="row p-1">
                      <div className="col-lg-12 text-danger">
                        <b>ID Proof</b>
                      </div>
                    </div>
                  </div>
                  <div className="card carddetails details-data_overall p-1">
                    <div>
                      <img
                        src={imagePreview} // Use imageFile, not image
                        alt="Uploaded or Captured"
                        width="100%"
                        height="200vh"
                        className="object-fit-full"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
                    <div className="card cardborder ">
                      {/* <b className="text-danger p-1">Topics Covered</b> */}
                    </div>
                    <div className="card cardborder p-1 Completed-profile ">
                      <div className="row details-datas ">
                        <div className="col-lg-6 mt-1">
                          <b> Fees</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {addData[0]?.fees} */}
                          {filteredData[0]?.fees}
                        </div>
                      </div>
                      <div className="row mt-2 details-datas">
                        <div className="col-lg-6 mt-1">
                          <b>Discount</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {addData[0]?.discount} */}
                          {filteredData[0]?.discount}
                        </div>
                      </div>
                      <div className="row mt-2 details-datas">
                        <div className="col-lg-6 mt-1">
                          <b>Payment Type</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {addData[0]?.payment_type || ""}
                        </div>
                      </div>
                      <div className="row mt-2 details-datas">
                        <div className="col-lg-6 mt-1">
                          <b>Duration</b>
                        </div>
                        <div className="col-lg-6 text-secondary">
                          {/* {addData[0]?.duraction} */}
                          {filteredData[0]?.duration}
                        </div>
                      </div>
                    </div>

                    <div className="row p-3 ">
                      <div className="text-center ">
                        <input type="checkbox" id="agree" click={isCheck} onClick={handleClickCheckBox} required />
                        <label htmlFor="agree " className="mx-2">
                          I Agree to all conditions
                        </label>
                      </div>

                      <div className="col-lg-4 text-center d-flex justify-content-end">
                        <button
                          className="Cancelbut "
                          variant="secondary"
                          onClick={handlecancel}
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-lg-4 d-flex ">
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                          }} // Inline style for background color
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </Button>
                      </div>
                      <div className="col-lg-4 text-center d-flex justify-content-start">
                        <button
                          className="btn btn-success"
                          type="submit"
                          variant="primary"
                          disabled={!isCheck}
                          onClick={handlesubmit}
                        >
                          Update
                        </button>
                      </div>

                      {/* <div className="text-center mt-3"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Button
              variant="contained"
              style={{
                backgroundColor: "gray",
                marginRight: "10px",
                color: "white",
              }} // Inline style for background color
              // disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>

            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default RegistrationUpdate;

