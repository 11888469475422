// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { ToWords } from "to-words"; // Ensure you've installed the `to-words` package.
// import apiUrl from "../../environment/Api"; // Adjust this based on your actual API URL.

// function PrintInvoice() {
 

// export default PrintInvoice;






import React, { useEffect, useState } from 'react';
import '../../assets/css/Print.css'
import axios from 'axios';
import apiUrl from '../../environment/Api';
import { useParams } from 'react-router-dom';
import { ToWords } from 'to-words';

function Print() {
   const { id } = useParams(); // Get invoice ID from the URL
   const [getdetailsdata, setdetailsdata] = useState(null);  // Invoice details (single)
   const [getlistdata, setlistdata] = useState([]);          // Invoice list (items)
 
   // Fetch invoice details and item list data
   useEffect(() => {
     getinvoicedetails();
     getinvoicelist();
   }, []);
 
   useEffect(() => {
     // Auto-print when both details and list data are loaded
     if (getdetailsdata && getlistdata.length > 0) {
       window.print();
     }
   }, [getdetailsdata, getlistdata]);
 
   // Fetch invoice details by ID
   const getinvoicedetails = async () => {
     try {
       const response = await axios.get(`${apiUrl}/invoice_details/${id}`);
       console.log("invoicedetails", response.data);
       setdetailsdata(response.data);
     } catch (error) {
       console.log("Error fetching invoice details:", error);
     }
   };
 
   // Fetch invoice list (items) by ID
   const getinvoicelist = async () => {
     try {
       const response = await axios.get(`${apiUrl}/invoice_list/${id}`);
       console.log("invoicelist", response.data);
       setlistdata(response.data);
     } catch (error) {
       console.log("Error fetching invoice list:", error);
     }
   };
 
   // If data is not yet fetched, show a loading message
   if (!getdetailsdata) {
     return <div>Loading...</div>;
   }
 
   // Convert paid amount to words (in Indian Rupees)
   const toWords = new ToWords({
     localeCode: "en-IN",
     converterOptions: {
       currency: true,
       ignoreDecimal: false,
       ignoreZeroCurrency: false,
       doNotAddOnly: false,
       currencyOptions: {
         name: "Rupees",
         plural: "Rupees",
         symbol: "₹",
         fractionalUnit: {
           name: "Paisa",
           plural: "Paisa",
           symbol: "",
         },
       },
     },
   });
 
   const words = toWords.convert(getdetailsdata[0].paid);
 
   return (
     <>
       <div id="border">
         <div className="background">
           <div className="overall">
             <div className="d-flex justify-content-between">
               <div>
                 <div className="techsoft">
                   <p className="para">
                     <b className="alan">AALAN TECHSOFT</b>
                     <br />
                     24/302 JH-TOWER
                     <br />
                     LIC COLONY Road
                     <br />
                     Salem, Tamilnadu-636004
                     <br />
                     Phone: (+91)9080108634
                     <br />
                     Email: aalantechsoft@gmail.com
                   </p>
                 </div>
               </div>
               <div style={{ width: "280px" }}>
                 <p className="alan" style={{ color: "white" }}>
                   INVOICE
                 </p>
                 <div className="card photo">
                   <img src="" alt="Company Logo" />
                 </div>
                 <div className="data-2" style={{ color: "white" }}>
                   <label className="voice">Invoice No: {getdetailsdata[0].invoice_no}</label>
                   <p className="line"></p>
                   <label className="voice">Date: {getdetailsdata[0].date}</label>
                   <p className="line"></p>
                   <label className="voice">Payment Type: {getdetailsdata[0].paytype}</label>
                   <p className="line"></p>
                 </div>
               </div>
             </div>
           </div>
 
           <div className="row">
             <div className="d-flex justify-content-between">
               <div>
                 <div className="detailss">
                   <h5 className="bill">
                     <b>Bill To</b>
                   </h5>
                   <label>Name: {getdetailsdata[0].name}</label>
                   <p className="line"></p>
                   <label>Address:</label>
                   <p className="line"></p>
                   <label>Phone: {getdetailsdata[0].mobile}</label>
                   <p className="line"></p>
                   <label>Email: {getdetailsdata[0].email}</label>
                   <p className="line"></p>
                 </div>
               </div>
             </div>
 
             {/* Invoice Items List */}
             <div className="col-lg-12">
               <div className="card start">
                 <div className="table-responsive">
                   <table className="table table-bordered">
                     <thead className="thead-dark">
                       <tr>
                         <th>S.NO</th>
                         <th>Description</th>
                         <th>Qty</th>
                         <th>Price</th>
                         <th>Total</th>
                       </tr>
                     </thead>
                     <tbody>
                       {getlistdata.map((item, index) => (
                         <tr key={index}>
                           <td>{index + 1}</td>
                           <td>{item.description}</td>
                           <td>{item.qty}</td>
                           <td>{item.price}</td>
                           <td>{item.total}</td>
                         </tr>
                       ))}
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>
 
             {/* Invoice Summary and Footer */}
             <div className="d-flex justify-content-between">
               <div>
                 <div className="amount">
                   <label>Amount in Words:</label>
                   <p className="line">{words}</p>
                   <label>Terms & Conditions:</label>
                   <p className="line"></p>
                 </div>
               </div>
               <div>
                 <div style={{ width: "280px" }} className="total">
                   <div className="d-flex">
                     <p style={{ width: "125px" }}>Subtotal</p>
                     <p>: {getdetailsdata[0].subtotal}</p>
                   </div>
 
                   <div className="d-flex">
                     <p style={{ width: "125px" }}>Discount</p>
                     <p>: {getdetailsdata[0].discount}</p>
                   </div>
 
                   <div className="d-flex">
                     <p style={{ width: "125px" }}>GST</p>
                     <p>: {getdetailsdata[0].gst}</p>
                   </div>
 
                   <div className="d-flex">
                     <p style={{ width: "125px" }}>
                       <b>Net Total</b>
                     </p>
                     <p>
                       <b>: {getdetailsdata[0].nettotal}</b>
                     </p>
                   </div>
 
                   <div className="d-flex">
                     <p style={{ width: "125px" }}>Paid</p>
                     <p>: {getdetailsdata[0].paid}</p>
                   </div>
 
                   <div className="d-flex">
                     <p style={{ width: "125px" }}>Balance</p>
                     <p>: {getdetailsdata[0].balance}</p>
                   </div>
                 </div>
               </div>
             </div>
 
             <div>
               <p className="text-center">
                 IF YOU HAVE ANY QUESTIONS CONCERNING THIS INVOICE CONTACT 9080108634 aalantechsoft@gmail.com
               </p>
               <p className="text-center">------THANK YOU FOR YOUR BUSINESS------</p>
             </div>
           </div>
         </div>
       </div>
     </>
   );
 }

export default Print;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import { Button } from "react-bootstrap";
// import apiUrl from "../../environment/Api";

// function Print() {
//    const { invoiceNo } = useParams(); // Get invoiceNo from the URL
//    const [invoiceData, setInvoiceData] = useState(null);
 
//    // Fetch the invoice details based on the invoice number
//    useEffect(() => {
//      const fetchInvoiceDetails = async () => {
//        try {
//          const response = await axios.get(`${apiUrl}/invoice_details`, {
//            params: { invoice_no: invoiceNo },
//          });
//          setInvoiceData(response.data);
//        } catch (error) {
//          console.error("Error fetching invoice details:", error.message);
//        }
//      };
//      fetchInvoiceDetails();
//    }, [invoiceNo]);
 
//    // Print the page
//    const handlePrint = () => {
//      window.print(); // Trigger the browser's print dialog
//    };
 
//    if (!invoiceData) {
//      return <div>Loading...</div>; // Show loading until the data is fetched
//    }
 
//    return (
//      <div className="print-page">
//        <div className="print-container">
//          <h2 className="text-center">Invoice</h2>
//          <div className="invoice-header">
//            <p><strong>Invoice No:</strong> {invoiceData.invoice_no}</p>
//            <p><strong>Date:</strong> {new Date(invoiceData.date).toLocaleDateString()}</p>
//            <p><strong>Branch:</strong> {invoiceData.branch_name}</p>
//            <p><strong>Name:</strong> {invoiceData.name}</p>
//            <p><strong>Email:</strong> {invoiceData.email}</p>
//            <p><strong>Phone:</strong> {invoiceData.mobile}</p>
//          </div>
 
//          <div className="invoice-details">
//            <h4>Details</h4>
//            <table className="table">
//              <thead>
//                <tr>
//                  <th>Description</th>
//                  <th>Quantity</th>
//                  <th>Price</th>
//                  <th>Total</th>
//                </tr>
//              </thead>
//              <tbody>
//                {/* Ensure invoiceData.items is defined before using map */}
//                {Array.isArray(invoiceData.items) && invoiceData.items.length > 0 ? (
//                  invoiceData.items.map((item, index) => (
//                    <tr key={index}>
//                      <td>{item.description}</td>
//                      <td>{item.qty}</td>
//                      <td>{item.price}</td>
//                      <td>{item.total}</td>
//                    </tr>
//                  ))
//                ) : (
//                  <tr>
//                    <td colSpan="4">No items available</td>
//                  </tr>
//                )}
//              </tbody>
//            </table>
//          </div>
 
//          <div className="invoice-footer">
//            <p><strong>Subtotal:</strong> {invoiceData.subtotal}</p>
//            <p><strong>GST:</strong> {invoiceData.gst}</p>
//            <p><strong>Discount:</strong> {invoiceData.discount}</p>
//            <p><strong>Net Total:</strong> {invoiceData.nettotal}</p>
//            <p><strong>Paid:</strong> {invoiceData.paid}</p>
//            <p><strong>Balance:</strong> {invoiceData.balance}</p>
//          </div>
 
//          <div className="text-center">
//            <Button onClick={handlePrint} className="btn btn-primary">
//              Print Invoice
//            </Button>
//          </div>
//        </div>
//      </div>
//    );
//  }

// export default Print;
