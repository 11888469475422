import React, { useState, useEffect } from "react";
import { FaTh, FaBars } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Navbar from "../Navbar";
import "../../assets/css/Sidebar.css";
import "../../components/footer/Footer";
import Footer from "../../components/footer/Footer";

import axios from "axios";
import apiUrl from "../../environment/Api";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({}); // State to manage dropdown visibility
  const location = useLocation();
  const navigate = useNavigate();

  const [role_id, setroleid] = useState();
  const [branch_id, setbranchid] = useState();
  const [pages, setpages] = useState([]);

  useEffect(() => {
    setIsOpen(false);
    gettoken();
  }, [location]);

  const toggle = () => setIsOpen(!isOpen);

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    // console.log("the toekn in the localStorage is : ",token);
    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      // setResponseData(response.data);

      console.log("The role id is  : ", response.data.detail.rolename);

      setroleid(response.data.detail.rolename);
      setbranchid(response.data.detail.branch_id);
      // this get ============================================
      if (response.data.detail.rolename) {
        const response_role = await axios.get(
          apiUrl + `/role_2/${response.data.detail.rolename}`
        );
        console.log(
          "token detail from serverbr for role name:response_role",
          response_role
        );
        console.log(
          "token detail from serverbr for role name:",
          response_role.data.rolename
        );
        localStorage.setItem("rolename", response_role.data.rolename);
      }

      localStorage.setItem("assignId", response.data.detail.assignId);

      console.log("the siderbar response", response);
      // this get ===========================
      if (response.data.detail.branch_id) {
        const responsebranch = await axios.get(
          apiUrl + `/branch/${response.data.detail.branch_id}`
        );
        console.log("token detail from serverbr:", responsebranch.data);

        localStorage.setItem("branch_id", response.data.detail.branch_id);
        localStorage.setItem("role_id", response.data.detail.rolename);
        localStorage.setItem("username", response.data.detail.username);
        localStorage.setItem("branch_code", responsebranch.data[0]?.branch_id);
        localStorage.setItem(
          "branch_name",
          responsebranch.data[0]?.branch_name
        );
      } else {
        console.log("the role_id is : ", role_id);
        console.log("the branch is : ", branch_id);

        localStorage.setItem("branch_id", response.data.detail.branch_id);
        localStorage.setItem("role_id", response.data.detail.rolename);
        localStorage.setItem("username", response.data.detail.username);
        localStorage.setItem("branch_code", "All");
        localStorage.setItem("branch_name", "Branches");
      }

      const id = response.data.detail.rolename;

      // getpages();
      // this get ============================
      if (id) {
        try {
          const response = await axios.get(
            apiUrl + `/get_one_role_2_pages/${id}`
          );

          console.log("the reponse page list is : ", response.data);

          const received_pages = response.data;

          // if(received_pages){
          setpages(response.data);
          // }

          console.log("the assigned pages are : ", pages);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // const getpages = async() => {
  //     try{
  //         const response  = await axios.get(apiUrl+`/get_one_role_2_pages/${role_id}`);

  //         console.log("the reponse page list is : ",response.data);
  //         setpages(response.data);

  //         console.log("the assigned pages are : ",pages);
  //     }catch(error){
  //         console.error("Error fetching data:", error.message);
  //     }
  // }

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: (
        <FaTh
          className={
            location.pathname === "/dashboard" ? "text-white" : "text-danger"
          }
        />
      ),
    },
    {
      path: "/role",
      name: "Roles",
      icon: (
        <i
          className={`${location.pathname === "/role" ? "text-white" : "text-danger"
            } fa-solid fa-layer-group`}
        ></i>
      ),
    },
    {
      path: "/user",
      name: "Users",
      icon: (
        <i
          className={`${location.pathname === "/user" ? "text-white" : "text-danger"
            } fa-solid fa-user-tie`}
        ></i>
      ),
    },
    {
      path: "/branch",
      name: "Branch",
      icon: (
        <i
          className={`${location.pathname === "/branch" ? "text-white" : " text-danger"
            } fa-solid fa-sitemap`}
        ></i>
      ),
    },
    {
      path: "/student",
      name: "Leads",
      icon: <i class="fa-solid fa-right-from-bracket"></i>,
      // Add subItems for dropdown
      subItems: [
        {
          path: "/student",
          name: "New Enquiry",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/Enquiry",
          name: "Enquiry",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/Followups",
          name: "Followups",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/funnel",
          name: "Funnel",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/Walkin",
          name: "Walkin-Details",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/Sourcetype",
          name: "Sourcetype",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/Enquirypurpose",
          name: "Enquirypurpose",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },

      ],
    },
    {
      path: "/Studyingstudents",
      name: "Entrollment",
      icon: <i class="fa-solid fa-users-line"></i>,
      // Add subItems for dropdown
      subItems: [
        {
          path: "/Studyingstudents",
          name: "Studying Students",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/Studentdairy",
          name: "Students Dairy",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/completed",
          name: "Completed",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
      ],
    },

    {
      path: "/course",
      name: "Course",

      icon: <i className="fa-regular fa-pen-to-square"></i>,
      // Add subItems for dropdown
      subItems: [
        {
          path: "/course",
          name: "Course",
          icon: (
            <i id="icons" class="fa-sharp fa-solid fa-window-minimize "></i>
          ),
        },
        {
          path: "/category",
          name: "Category",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },

        // { path: "/schedule", name: "Schedule", icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i> },
      ],
    },
    {
      path: "/Staff",
      name: "Staff",
      icon: <i className="fa-solid fa-user-tie"></i>,
      subItems: [
        {
          path: "/staff",
          name: "Staff",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/attendance",
          name: "Attendance",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/designation",
          name: "Designation",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/leave",
          name: "Leave",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/salary",
          name: "Salary Details",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/holiday",
          name: "Holiday",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
      ],
    },
    // {
    //   path: "/Job",
    //   name: "Job",
    //   icon: <i class="fa-solid fa-handshake-simple"></i>,
    //   subItems: [
    //     {
    //       path: "/Job",
    //       name: "Job",
    //       icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
    //     },

    //   ],
    // },
    {
      path: "/Jobportal",
      name: "Job Portal",
      icon: (
        <i
          className={`${location.pathname === "/Jobportal" ? "text-white" : "text-danger"
            } fa-solid fa-handshake-simple`}
        ></i>
      ),
    },
    // {
    //   path: "/Promotions",
    //   name: "Promotions",
    //   icon: <i className="fa-solid fa-bullhorn"></i>,
    //   subItems: [
    //     {
    //       path: "/newpromotions",
    //       name: "New Promotions",
    //       icon: (
    //         <i id="icons" className="fa-sharp fa-solid fa-window-minimize"></i>
    //       ),
    //     },
    //   ],
    // },
    {
      path: "/newpromotions",
      name: "New Promotions",
      icon: (
        <i
          className={`${location.pathname === "/newpromotions" ? "text-white" : "text-danger"
            } fa-solid fa-bullhorn`}
        ></i>
      ),
    },


    // {
    //   path: "/Asset",
    //   name: "Asset",
    //   icon: <i class="fa-regular fa-compass"></i>,
    //   // Add subItems for dropdown
    //   subItems: [
    //     // { path: "/assettype", name: "Asset Type", icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i> },
    //     {
    //       path: "/assetlist",
    //       name: "Asset List",
    //       icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
    //     },
    //   ],
    // },
    {
      path: "/assetlist",
      name: "Asset List",
      icon: (
        <i
          className={`${location.pathname === "/assetlist" ? "text-white" : "text-danger"
            } fa-regular fa-compass`}
        ></i>
      ),
    },
    {
      path: "/expenselist",
      name: "Expense List",
      icon: <i class="fa-solid fa-bars"></i>,
      // Add subItems for dropdown
      subItems: [
        // { path: "/expensetype", name: "Expense Type", icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i> },
        {
          path: "/expenselist",
          name: "Expense List",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/vendarDetails",
          name: "VendorDetails",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/paytype",
          name: "PayType",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
      ],
    },
    // {
    //   path: "/expenselist",
    //   name: "Expense List",
    //   icon: (
    //     <i
    //       className={`${location.pathname === "/expenselist" ? "text-white" : "text-danger"
    //         } fa-solid fa-bars`}
    //     ></i>
    //   ),
    // },

    // {
    //   path: "/expenselist",
    //   name: "Expense List",
    //   icon: (
    //     <i
    //       className={`${
    //         location.pathname === "/expenselist" ? "text-white" : "text-danger"
    //       } fa-solid fa-bars`}
    //     ></i>
    //   ),
    //   subItems: [

    //     {
    //       path: "/vendarDetails",
    //       name: "VendarDetails",
    //       icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
    //     },

    //   ],
    // },

    // {
    //   path: "/Income",
    //   name: "Income",
    //   icon: <i class="fa-solid fa-comments-dollar"></i>,
    //   // Add subItems for dropdown
    //   subItems: [
    //     // { path: "/incometype", name: "Income Type", icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i> },
    //     {
    //       path: "/incomelist",
    //       name: "Income List",
    //       icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
    //     },
    //   ],
    // },
    {
      path: "/incomelist",
      name: "Income List",
      icon: (
        <i
          className={`${location.pathname === "/incomelist" ? "text-white" : "text-danger"
            } fa-comments-dollar`}
        ></i>
      ),
    },

    {
      path: "/Invoice",
      name: "Invoice",
      icon: (
        <i
          className={`${location.pathname === "/Invoice" ? "text-white" : "text-danger"
            } fa-solid fa-inbox`}
        ></i>
      ),
      // Add subItems for dropdown
    },
    {
      path: "/Report",
      name: "Report",
      icon: <i class="fa-regular fa-file"></i>,
      // Add subItems for dropdown
      subItems: [
        {
          path: "/dailyreport",
          name: "Daily Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/enquiryreport",
          name: "Enquiry Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/attendancereport",
          name: "Attendance Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/expensereport",
          name: "Expense Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/studentreport",
          name: "Student Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/staffreport",
          name: "Staff Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/incomereport",
          name: "Income Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
        {
          path: "/invoiceReport",
          name: "Invoice Report",
          icon: <i id="icons" class="fa-sharp fa-solid fa-window-minimize"></i>,
        },
      ],
    },
  ];

  const handleDropdownToggle = (index) => {
    setDropdownStates((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== String(index)) {
          newState[key] = false;
        }
      });

      newState[index] = !newState[index];

      return newState;
    });
  };

  const handleUserItemClick = () => {
    // Close all dropdowns when clicking on the "Role Name" item
    setDropdownStates({});
  };

  if (
    location.pathname === "/register" ||
    location.pathname === "*" ||
    location.pathname.startsWith("/invoice/invoiceprint/") ||
    location.pathname.startsWith("/invoice/bill/")
  ) {
    return (
      <main
        style={{
          padding: isOpen ? "20px" : "0px",
          marginTop: isOpen ? "0px" : "0px",
        }}
      >
        {children}
      </main>
    );
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const handleLogout = () => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("id");
  //     localStorage.removeItem("branch_code");
  //     localStorage.removeItem("branch_id");
  //     localStorage.removeItem("branch_name");
  //     localStorage.removeItem("role_id");
  //     localStorage.removeItem("type");
  //     localStorage.removeItem("username");
  //     // navigate('/admin');
  //     navigate("/");
  //     window.location.reload();
  //   } else {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("id");
  //     localStorage.removeItem("branch_code");
  //     localStorage.removeItem("branch_id");
  //     localStorage.removeItem("branch_name");
  //     localStorage.removeItem("role_id");
  //     localStorage.removeItem("type");
  //     localStorage.removeItem("username");
  //     // navigate('/admin');
  //     navigate("/");
  //     window.location.reload();
  //   }
  // };

  const handleLogoClick = () => {
    navigate('/dashboard');  // Navigate to the dashboard route
  };



  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      // Assuming username is stored in localStorage
      const username = localStorage.getItem("username");
      try {
        // Make the API request to the backend to log out the user
        const response = await axios.post(apiUrl + '/logoutadmin', { username });
        // Check if the response includes a message and show it
        if (response.data && response.data.message) {
          // alert(response.data.message);  // Show the backend response message
        } else {
          // If no message in the response
          alert("Logout failed. Please try again.");
        }
        console.log("the response from sidebar 1", response)

        // Clear localStorage items after successful logout
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("branch_code");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("branch_name");
        localStorage.removeItem("role_id");
        localStorage.removeItem("type");
        localStorage.removeItem("username");

        // Navigate to the homepage or login page
        navigate("/");
        window.location.reload();  // Reload the page to reset any remaining state

      } catch (error) {
        // Error handling in case of API failure
        if (error.response) {
          alert(error.response.data.message || "Error logging out.");
        } else {
          alert("There was an error logging out. Please try again.");
        }

        // Still clear localStorage if logout fails
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("branch_code");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("branch_name");
        localStorage.removeItem("role_id");
        localStorage.removeItem("type");
        localStorage.removeItem("username");

        // Redirect to the homepage or login page
        navigate("/");
        window.location.reload();
      }
    } else {
      // If no token found, just clear localStorage and navigate
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("branch_code");
      localStorage.removeItem("branch_id");
      localStorage.removeItem("branch_name");
      localStorage.removeItem("role_id");
      localStorage.removeItem("type");
      localStorage.removeItem("username");

      // Navigate to the homepage or login page
      navigate("/");
      window.location.reload();
    }
  };







  return (
    <>
      <Navbar toggle={isOpen} />
      <Modal className="modal" show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to logout?</p>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container-main">
        <div
          className="sidebar"
          style={{
            position: "fixed",
            top: "70px",
            bottom: 0,
            left: 0,
            width: isOpen ? "44px" : "250px",
            zIndex: 1000,
          }}
        >
          {/* <div className="top_section">
                        <p style={{ display: isOpen ? "none" : "block" }} className="logo d-flex mx-1"><i className="fa-regular fa-circle-user sidebarusericon mx-1 mt-1"></i> <div>
                        Admin</div></p>
                        <div style={{ marginLeft: isOpen ? "7px" : "100px" }} className="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div> */}
          <div className="top_section">
            <p
              style={{ display: isOpen ? "none" : "block" }}
              className={`logo mx-1 ${isOpen ? "" : "d-flex"}`}
            >
              <i className="fa-regular fa-circle-user sidebarusericon mx-1 mt-1"></i>
              <div className="admincont">
                {" "}
                <img
                  src={require("../../assets/img/scimitar logo slogan.png")}
                  alt="Company Logo"
                  style={{
                    width: "170px",
                    height: "40px",
                    marginLeft: "-25px",
                    marginTop: "5px",
                    objectFit: 'cover'
                  }}
                  onClick={handleLogoClick}
                ></img>
              </div>
            </p>
            <div
              style={{ marginLeft: isOpen ? "7px" : "70px" }} 
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <div key={index}>
              {item.subItems ? (
                <>
                  {item.subItems.some(subItem => pages.includes(subItem.name)) && (
                    <div
                      className="link"
                      onClick={() => handleDropdownToggle(index)}
                    >
                      <div className="icon">{item.icon}</div>
                      <div
                        style={{ display: isOpen ? "none" : "flex" }}
                        className="link_text"
                      >
                        {item.name}
                      </div>
                      <div className="dropdown_icon ">
                        {dropdownStates[index] ? (
                          <i class="fa-solid fa-chevron-up icon-admin"></i>
                        ) : (
                          <i class="fa-solid fa-chevron-down icon-admin"></i>
                        )}
                      </div>
                    </div>)}
                  {dropdownStates[index] && (
                    <div
                      className="subMenu"
                      style={{ marginLeft: isOpen ? "7px" : "30px" }}
                    >
                      {item.subItems.map((subItem, subIndex) =>
                        pages.includes(subItem.name) ? (
                          <NavLink
                            key={subIndex}
                            to={subItem.path}
                            className="link submenu-admin"
                            activeClassName="actived"
                            style={{
                              display:
                                location.pathname === "/register"
                                  ? "none"
                                  : "flex",
                            }}
                          >
                            <div className="icon">{subItem.icon}</div>
                            <div
                              style={{ display: isOpen ? "none" : "block" }}
                              className="link_text"
                            >
                              {subItem.name}
                            </div>
                          </NavLink>
                        ) : null
                      )}
                    </div>
                  )}
                </>
              ) : pages.includes(item.name) ? (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                  style={{
                    display:
                      location.pathname === "/register" ? "none" : "flex",
                  }}
                  onClick={
                    item.path === "/user"
                      ? handleUserItemClick
                      : item.path === "/role"
                        ? handleUserItemClick
                        : null || item.path === "/invoice"
                          ? handleUserItemClick
                          : null
                  }
                >
                  <div className="icon">{item.icon}</div>
                  <div
                    style={{ display: isOpen ? "none" : "block" }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                </NavLink>
              ) : null}
            </div>
          ))}

          <div className="link" onClick={() => setShowModal(true)}>
            <div className="icon">
              <i className="fa-solid fa-right-from-bracket"></i>
            </div>
            <div
              style={{ display: isOpen ? "none" : "block" }}
              className="link_text"
            >
              Logout
            </div>

            {showModal && (
              <div className="logout-modal">
                <p>Are you sure you want to log out?</p>
                <button onClick={handleLogout}>Yes, log me out</button>
                <button onClick={() => setShowModal(false)}>Cancel</button>
              </div>
            )}
          </div>

        </div>
        <main className={`main ${isOpen ? "isClose" : "isOpen"}`}>
          {children}
          <div className="footer">{/* <Footer/> */}</div>
        </main>
      </div>
    </>
  );
};

export default Sidebar;

