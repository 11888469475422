import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Job() {
  const [activeStep, setActiveStep] = useState(0);
  const [experienceLevel, setExperienceLevel] = useState("");
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep === 1 && experienceLevel === "Fresher") {
      setActiveStep(3);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    navigate("/Jobportal");
  };

  const handleExperienceChange = (e) => {
    setExperienceLevel(e.target.value);
  };

  const handleSubmit = () => {
    navigate("/jobMemberinfo");
  };

  return (
    <>
      <div className="container" style={{ marginTop: "-50px" }}>
        <h3 className="">Job Application</h3>
        {activeStep === 0 && (
          <div className="card p-2">
            <h5 className="text-center">Personal Details</h5>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Full Name</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    required
                  />
                  <label className="">Date of Birth</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Contact Number</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Email Address</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Position</label>
                </div>
                <div className="floating-label">
                  <select className="form-control" required>
                    <option value="source1"> Select Source</option>
                    <option value="source1">Referral</option>
                    <option value="source2">Job Portal</option>
                    <option value="source3">Campus Recruitment</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Current Address</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Permanent Address</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Blood Group</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Guardian Name</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Guardian Phone Number</label>
                </div>

                <div className="text-center p-2">
                  <button
                    className="btn btn-secondary mx-2"
                    onClick={handleBack}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-danger" onClick={handleNext}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeStep === 1 && (
          <div className="card p-3">
            <h5 className="text-center">Education Details</h5>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Highest Qualification</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">University / Institution Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Additional Certifications</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Field of Study</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Year of Graduation</label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    onChange={handleExperienceChange}
                    required
                  >
                    <option value="">Select Experience Year</option>
                    <option value="Fresher">Fresher</option>
                    <option value="Experience">Experience</option>
                  </select>
                </div>
              </div>
              <div className="text-center p-2">
                <button className="btn btn-secondary mx-2" onClick={handleBack}>
                  Back
                </button>
                <button className="btn btn-danger" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {activeStep === 2 && experienceLevel === "Experience" && (
          <div className="card p-3">
            <h5 className="text-center">Professional Details</h5>
            <div className="row">
              {/* Professional Details Fields */}
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Organization</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    required
                  />
                  <label className="">Date of Joining</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    required
                  />
                  <label className="">Date of Relieving</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Designation</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Take Home</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Expected Salary</label>
                </div>
              </div>

              <div className="text-center p-2">
                <button className="btn btn-secondary mx-2" onClick={handleBack}>
                  Back
                </button>
                <button className="btn btn-danger" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {activeStep === 3 && (
          <div className="card p-3">
            <h5 className="text-center">Skills and Competition</h5>
            <div className="row">
              <div className="col-lg-6">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Technical Skills</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Languages</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Relevant Soft Skills</label>
                </div>
              </div>

              <div className="text-center p-2">
                <button className="btn btn-secondary mx-2" onClick={handleBack}>
                  Back
                </button>
                <button className="btn btn-danger" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )}

        {activeStep === 4 && (
          <div className="card p-3">
            <h5 className="text-center">References</h5>
            <div className="row">
              <div className="col-lg-6">
                <h3 className="">Reference 1</h3>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Name</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Position</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Contact Number</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Email Address</label>
                </div>
              </div>

              <div className="col-lg-6">
                <h3 className="">Reference 2</h3>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Name</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Position</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Contact Number</label>
                </div>
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    required
                  />
                  <label className="">Email Address</label>
                </div>
              </div>
            </div>

            <div className="floating-label">
              <textarea
                className="form-control"
                type="text"
                placeholder=""
                required
              />
              <label className="">Additional Information</label>
            </div>

            <div className="text-center p-2">
              <button className="btn btn-secondary mx-2" onClick={handleBack}>
                Back
              </button>
              <button className="btn btn-danger" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Job;
