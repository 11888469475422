function JobMemeberInfo() {
  return (
    <>
      <div className="container" style={{ marginTop: "-80px" }}>
        <div className="row">
          <div className="col-lg-4">
            <div className="card p-2">
              <div className="text-center text-white">
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-user "></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-phone"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
                <h6 className="profile-details bg-secondary">
                  <div className="row text-center">
                    <div className="col-lg-1">
                      <i class="fa-solid fa-id-card"></i>
                    </div>
                    <div className="col-lg-10 text-center"></div>
                  </div>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="card p-3">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Position </strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Source </strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>
                  <div className="row mt-1 p-1">
                    <div className="col-lg-5">
                      <strong>Highest Qualification</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>
                        University/
                        <br />
                        Institution
                      </strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Field of Study</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>
                  <div className="row mt-1 p-1">
                    <div className="col-lg-5">
                      <strong>Year of Graduation</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Additional Certifications </strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Organization</strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Date of Joining </strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Date of Reliving </strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Designation </strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>
                  <div className="row mt-1 p-1">
                    <div className="col-lg-5">
                      <strong>Take Home</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Expected Salary</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Total Years of Experience</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>
                  <div className="row mt-1 p-1">
                    <div className="col-lg-5">
                      <strong>Current/Most Recent Job Title</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Current/Most Recent Employer</strong>
                    </div>
                    <div className="col-lg-6 text-secondary"></div>
                  </div>

                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Duration in Current Position</strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Achievements / Projects</strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Technical Skills</strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Languages</strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Relevant Soft Skills</strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                  <div className="row p-1">
                    <div className="col-lg-5">
                      <strong>Duration in Last Position</strong>
                    </div>
                    <div className="col-lg-7 text-secondary"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobMemeberInfo;
