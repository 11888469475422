import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";

function CoursePreview() {
  const [responseData, setResponseData] = useState([]);
  const [responseDataa, setResponseDataa] = useState(null);

  const { id, course_id } = useParams();

  const navigate = useNavigate();

  const handleback = () => {
    navigate(`/course`);
  };

  const handlePageEditAdd = () => {
    navigate(`/courseinfo/${id}/${course_id}`);
  };

  useEffect(() => {
    fetchData();
    console.log("Course ID:", course_id);
  }, []);

  const fetchData = async () => {
    try {
      const responsed = await axios.get(apiUrl + `/courseinfo/${course_id}`);
      console.log("response from the server coursepreview", responsed.data);
      setResponseDataa(responsed.data[0]);
      setResponseData(responsed.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  return (
    <>
      <div className="top">
        <div className="row">
          <div className="col-lg-4 d-flex">
            <h5 className="mt-2">Course Previews</h5>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-2">
            <div className="p-1 bg-danger text-white text-center">
              {responseDataa ? responseDataa.course_name : "Loading..."}
            </div>
          </div>

          <div className="col-lg-3"></div>
          <div className="col-lg-2">
            <div
              className="card p-2 bg-success text-center text-white"
              onClick={handlePageEditAdd}
            >
              Add / Edit
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="card p-3">
          <h6 className="text-white bg-danger text-center p-3">Syllabus</h6>
          <hr />
          <table className="table table-striped">
            <thead>
              <tr className="text-dark">
                <th>S.No</th>
                <th>Modules</th>
                <th>Topics</th>
                <th>Sub Topic</th>
                <th>Durations</th>
              </tr>
            </thead>
            <tbody>
              {responseData.length > 0 ? (
                responseData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.modules}</td>
                    <td>{item.topics}</td>
                    <td>{item.subtopic}</td>
                    <td>{item.duration}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No Data in the syllabus...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="container mt-3 mb-5">
        <div className="row">
          <div className="col-lg-1">
            <button className="btn btn-secondary" onClick={handleback}>
              Back
            </button>
          </div>
          <div className="col-lg-8"></div>
        </div>
      </div>
    </>
  );
}

export default CoursePreview;
