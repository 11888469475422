import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import "../../assets/css/AttendanceReport.css";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function AttendanceReport() {
  const { id } = useParams();
  const [filtered, setFiltered] = useState([]);
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("staff");
  const [searchTermStaff, setSearchTermStaff] = useState("");
  const [searchTermStudent, setSearchTermStudent] = useState("");

  // Handle tab switching
  const handleTabClick = (value) => {
    setActiveTab(value);
  };

  // Handle search input changes for staff
  const handleSearchChangeStaff = (e) => {
    setSearchTermStaff(e.target.value);
  };

  // Handle search input changes for student
  const handleSearchChangeStudent = (e) => {
    setSearchTermStudent(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const leadsResponse = await axios.get(apiUrl + "/attendance_overall");
        setFiltered(leadsResponse.data);

        const uniqueStatuses = Array.from(
          new Set(leadsResponse.data.map((item) => item.followups_status))
        ).map((status) => ({ followups_status: status }));

        setStatuses(uniqueStatuses);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const leadsResponse = await axios.get(
          apiUrl + "/student_dairy_overall"
        );
        setStudentAttendance(leadsResponse.data);

        const uniqueStatuses = Array.from(
          new Set(leadsResponse.data.map((item) => item.followups_status))
        ).map((status) => ({ followups_status: status }));

        setStatuses(uniqueStatuses);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

  const filteredData = filtered.filter((item) => {
    const isCourseMatch = selectedCourse
      ? item.Prefered_course === selectedCourse
      : true;
    const isStatusMatch = selectedStatus
      ? item.followups_status === selectedStatus
      : true;

    const itemEnquiryDate = new Date(item.Enquiry_date);
    const isFromDateMatch = fromDate
      ? itemEnquiryDate >= new Date(fromDate)
      : true;
    const isToDateMatch = toDate ? itemEnquiryDate <= new Date(toDate) : true;

    const isSearchMatch = searchTerm
      ? (item.empid &&
          item.empid.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.empid &&
          item.empid.toUpperCase().includes(searchTerm.toUpperCase())) ||
        (item.mobile_no && String(item.mobile_no).includes(searchTerm)) ||
        (item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.name &&
          item.name.toUpperCase().includes(searchTerm.toUpperCase())) ||
        (item.branch_id.branch_name &&
          item.branch_id.branch_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (item.branch_id.branch_name &&
          item.branch_id.branch_name
            .toUpperCase()
            .includes(searchTerm.toUpperCase()))
      : true;

    return (
      isCourseMatch &&
      isStatusMatch &&
      isFromDateMatch &&
      isToDateMatch &&
      isSearchMatch
    );
  });

  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const currentEntry = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when entries per page changes
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setSelectedCourse("");
    setSelectedStatus("");
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const railwayToNormalTime = (railwayTime) => {
    if (!railwayTime) return "";
    const [hour, minute] = railwayTime.split(":");
    const parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);
    if (parsedHour < 12) {
      return `${parsedHour}:${minute} AM`;
    } else if (parsedHour === 12) {
      return `${parsedHour}:${minute} PM`;
    } else {
      return `${parsedHour - 12}:${minute} PM`;
    }
  };

  const handleCopy = () => {
    const tableElement = document.getElementById("tableId");
    if (tableElement) {
      const tableData = tableElement.innerText;
      navigator.clipboard
        .writeText(tableData)
        .then(() => {
          alert("Attendance data copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Table with ID 'tableId' not found");
    }
  };

  const handleCSVExport = () => {
    // Define CSV headers
    const csvHeaders = [
      "S.No",
      "Branch Name",
      "Employee ID",
      "Name",
      "Date",
      "Login Time",
      "Logout Time",
      "Work Hours",
    ];

    // Prepare CSV data
    const csvData = filteredData.map((item, index) => [
      index + 1, // Serial number
      item.branch_id ? item.branch_id.branch_name : "N/A", // Branch name, with fallback
      item.empid || "N/A", // Employee ID, with fallback
      item.name || "N/A", // Name, with fallback
      new Date(item.date).toLocaleDateString(), // Format date
      railwayToNormalTime(item.login || ""), // Convert login time
      railwayToNormalTime(item.logout || ""), // Convert logout time
      railwayToNormalTime(item.workhour || ""), // Convert work hours
    ]);

    // Generate CSV content
    const csvContent = [
      csvHeaders.join(","), // Headers
      ...csvData.map((row) => row.join(",")), // Data rows
    ].join("\n");

    // Create a Blob for CSV content and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "attendance_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = () => {
    const tableElement = document.getElementById("tableId");
    if (tableElement) {
      const worksheet = XLSX.utils.table_to_sheet(tableElement);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "attendance_table_data.xlsx");
    } else {
      console.error(
        "Table with ID 'tableId' not found. Check if the table is rendered in the DOM."
      );
    }
  };

  const handleGeneratePDF = () => {
    const tableElement = document.getElementById("tableId");
    if (!tableElement) {
      console.error("Table with ID 'tableId' not found");
      return;
    }
    html2canvas(tableElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.save("attendance_report.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
      });
  };

  const handlePrint = () => {
    const tableElement = document.getElementById("tableId");
    if (tableElement) {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Attendance Table</title>
            <style>
              body { font-family: Arial, sans-serif; }
              table { width: 100%; border-collapse: collapse; margin-top: 20px; }
              th, td { border: 1px solid #000; padding: 8px; text-align: left; }
              th { background-color: #f2f2f2; }
            </style>
          </head>
          <body>
            <h1 style="text-align: center;">Attendance Report</h1>
            <div>${tableElement.outerHTML}</div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    } else {
      console.error("Table with ID 'tableId' not found");
    }
  };

  return (
    <>
      <div className="container-fluid mb-5">
        <div
          className="card p-2"
          style={{
            marginTop: "-80px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-8 col-sm-6 pt-2">
              <h6 className="text-center">
                <b>Attendance Report</b>
              </h6>
            </div>
          </div>
          <hr />
          <div className="row p-2">
            <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
              <input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                className="form-control"
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-12">
              <input
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                className="form-control"
              />
            </div>

            <div className="col-lg-2 col-md-2 col-sm-6">
              <select
                className="form-select"
                value={activeTab}
                onChange={(e) => handleTabClick(e.target.value)}
              >
                <option value="staff">Staff</option>
                <option value="student">Student</option>
              </select>
            </div>

            {/* Search input field */}
            <div className="col-lg-2 col-md-2 col-sm-6">
              <input
                type="text"
                className="form-control"
                placeholder={
                  activeTab === "staff" ? "Staff ID" : "Register No."
                }
                value={
                  activeTab === "staff" ? searchTermStaff : searchTermStudent
                }
                onChange={
                  activeTab === "staff"
                    ? handleSearchChangeStaff
                    : handleSearchChangeStudent
                }
                style={{
                  backgroundColor:
                    activeTab === "staff" ? "#f0f0f0" : "#e0e0e0", // Dull background for each tab
                }}
              />
            </div>

            <div className="col-lg-2 col-md-3 col-sm-12 d-flex align-items-end mx-3">
              <button
                className="btn border btn-danger"
                onClick={handleClearFilters}
              >
                <i className="fa-solid fa-xmark"></i> Clear
              </button>
            </div>
          </div>
          <hr></hr>

          <div className="container ">
            <div className="row">
              <div className="col-md-3 d-flex align-items-center">
                <p className="mb-0 mr-2">Show: </p>
                <select
                  value={entriesPerPage}
                  onChange={handleEntriesPerPageChange}
                  className="form-control mx-2"
                  style={{ width: "50px" }}
                >
                  {/* <option value={5}>5</option> */}
                  <option value={10}>10</option>
                  <option value={15}>20</option>
                  <option value={filteredData.length}>All</option>
                </select>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-secondary2 mx-2"
                  onClick={handleCopy}
                >
                  Copy
                </button>
                <button
                  className="btn btn-success2 mx-2"
                  onClick={handleCSVExport}
                >
                  CSV
                </button>
                <button className="btn btn-excel2 mx-2" onClick={handleExport}>
                  Excel
                </button>
                <button
                  className="btn btn-info2 mx-2"
                  onClick={handleGeneratePDF}
                >
                  PDF
                </button>
                <button className="btn btn-warning2 mx-2" onClick={handlePrint}>
                  Print
                </button>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <input
                  className="form-control border"
                  type="text"
                  placeholder="Search.."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>

            {activeTab === "staff" && (
              <table
                id="tableId"
                className="table mt-2 table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Branch</th>
                    <th>Staff Id</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Log In</th>
                    <th>Log Out</th>
                    <th>workhour</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.length > 0 ? (
                    currentEntries.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1 + (currentPage - 1) * entriesPerPage}
                        </td>
                        <td>
                          {item.branch_id ? item.branch_id.branch_name : "N/A"}
                        </td>
                        <td>{item.empid}</td>
                        <td>{item.name}</td>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>{railwayToNormalTime(item.login || "")}</td>
                        <td>{railwayToNormalTime(item.logout || "")}</td>
                        <td>{railwayToNormalTime(item.workhour || "")}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {activeTab === "student" && (
              <table className="table mt-2 table-bordered table-striped">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Branch</th>
                    <th>Student</th>
                    <th>Course</th>
                    <th>Date</th>
                    <th>Log In</th>
                    <th>Log Out</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntry.length > 0 ? (
                    currentEntry.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {index + 1 + (currentPage - 1) * entriesPerPage}
                        </td>
                        <td>
                          {item.branch_id ? item.branch_id.branch_name : "N/A"}
                        </td>

                        <td>{item.student}</td>
                        <td>{item.course}</td>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>{railwayToNormalTime(item.login || "")}</td>
                        <td>{railwayToNormalTime(item.logout || "")}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {/* Pagination Controls */}
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="dataTables_info"
                  role="status"
                  aria-live="polite"
                >
                  Showing {indexOfFirstEntry + 1} to{" "}
                  {Math.min(indexOfLastEntry, filtered.length)} of{" "}
                  {filtered.length} entries
                </div>
              </div>
              <div className="col-lg-5"></div>
              <div className="col-lg-3">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </a>
                    </li>
                    {[...Array(totalPages)].map((_, i) => (
                      <li
                        key={i}
                        className={`page-item ${
                          currentPage === i + 1 ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() => handlePageChange(i + 1)}
                        >
                          {i + 1}
                        </a>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttendanceReport;
