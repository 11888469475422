import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import '../../assets/css/Studentinvoice.css'
import { ToWords } from 'to-words';
import { useParams } from "react-router-dom";




function Studentinvoice() {





    const [responseData, setResponseData] = useState({});
    const [formattedDate, setFormattedDate] = useState("");
    const [formatdate,setformatdate] = useState("")
    const [words, setWords] = useState("");

 

    const { id } = useParams();


    useEffect(() => {
        fetchData();
        const timeoutId = setTimeout(() => {
            window.print();
        }, 100); // Delay of 2000 milliseconds (2 seconds)
    
        // Cleanup the timeout if the component unmounts or if the id changes
        return () => clearTimeout(timeoutId);
    }, [id]);

  

    const fetchData = async () => {
        try {

            console.log("invoice_no",id)
            const response = await axios.get(apiUrl + `/invoice_join/${id}`);

            console.log("Invoice data:", response.data);
            setResponseData(response.data);

            // Format the date
            const formattedDate = new Date(response.data.date).toLocaleDateString();
            setFormattedDate(formattedDate);


            const formatdate = new Date(response.data.next_due_date).toLocaleDateString();
            setformatdate(formatdate);
            

            // Convert nettotal to words if it's valid
            if (response.data.nettotal) {
                const amountInWords = toWords.convert(response.data.nettotal);
                setWords(amountInWords);
            }
          
        } catch (error) {
            console.error("Error fetching data:", error.message);
        }
    };





    const toWords = new ToWords({
        localeCode: "en-IN",
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: {
                name: "Rupees",
                plural: "Rupees",
                symbol: "₹",
                fractionalUnit: {
                    name: "Paisa",
                    plural: "Paisa",
                    symbol: "",
                },
            },
        },
    });




    return (
        <>
            {/* mainbill */}
            <div className='main_border'>
                <div className='academy_invoice'>
                    <p className='skimitar_heading'>SKIMITAR ACADEMY INVOICE
                    </p>

                </div>
                <hr className='line'></hr>
                {/* main branch */}
                <div className='main_branch'>
                    <div className='sub_branch'>
                        <div className='branch_code'>
                            <div className='branch_length'>
                                <p className='fonts'>Branch Code
                                </p>
                            </div>
                            : {responseData.branch_id}
                        </div>
                        <div className='branch_code'>
                            <div className='branch_length'>
                                <p className='fonts'>Address
                                </p>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div><p className='fonts'> :</p></div>
                                <p className='fonts' style={{ lineHeight: "15px", width: "150px", paddingLeft: "5px", marginTop: "-4px" }}>
                                    Gopinathapuram,salem -636013
                                </p>
                            </div>
                        </div>
                        <div className='branch_code'>
                            <div className='branch_length'>
                                <p className='fonts'>Phone No
                                </p>
                            </div>
                            : {responseData.mobile}
                        </div>
                        <div className='branch_code'>
                            <div className='branch_length'>
                                <p className='fonts' >Email ID
                                </p>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div><p className='fonts'> :</p></div>
                                <p className='fonts' style={{ lineHeight: "10px", paddingLeft: "5px", marginTop: "-3px" }}>
                                    {responseData.email}
                                </p>
                            </div>
                            {/* :  {responseData.email} */}
                        </div>
                    </div>
                    <div className="logo_main">
                        <img className='logoimage'
                            src={require("../../assets/img/scimitar logo slogan.png")}
                            alt="Company Logo" ></img>
                    </div>
                </div>
                <hr className='line_fri'></hr>
                {/* student details main*/}

                <div className='student_main'>
                    <div className='student_details'>
                        <p className=''>
                            <b className='fonts_head'> Student Details:</b>

                        </p>
                    </div>
                    <p className='invoice_details'>
                        <b className='fonts_head'>Invoice Details</b>
                    </p>
                </div>
                {/* student details and invoice details*/}

                <div className='student_invoice'>
                    {/* //student */}
                    <div className='student_invoice_cont'>
                        <div className='student_data_main'>
                            <div className='student'>
                                <p className='fonts'> Student Name
                                </p>
                            </div>
                            <div className='student_data'>
                                <p className='fonts'> {responseData.name}</p>
                            </div>

                        </div>
                        <div className='student_data_main'>
                            <div className='student'>
                                <p className='fonts'> Enroll No
                                </p>
                            </div>
                            <div className='student_data'>
                                <p className='fonts'> {responseData.student_info?.register_no}</p>
                            </div>

                        </div>
                        <div className='student_data_main_address'>
                            <div className='student'>
                                <p className='fonts'>Address
                                </p>
                            </div>
                            <div className='student_data' style={{ width: "140px" }}>
                                <p className='fonts' style={{ lineHeight: "16px", marginTop: "-3px" }}>       Gopinathapuram,salem -636012 </p>
                            </div>

                        </div>
                        <div className='student_data_main'>
                            <div className='student'>
                                <p className='fonts'>Phone No
                                </p>
                            </div>
                            <div className='student_data'>
                                <p className='fonts'> {responseData.mobile}</p>
                            </div>

                        </div>
                        <div className='student_data_main'>
                            <div className='student'>
                                <p className='fonts'>Email ID
                                </p>
                            </div>
                            <div className='student_data'>
                                <p className='fonts'> {responseData.email}</p>
                            </div>

                        </div>
                        <div className='student_data_main'>
                            <div className='student'>
                                <p className='fonts'>Total hours
                                </p>
                            </div>
                            <div className='student_data'>
                                <p className='fonts'> {responseData.student_info?.duraction} </p>
                            </div>

                        </div>
                        <div className='student_data_main'>
                            <div className='student' style={{ width: "100px" }}>
                                <p className='fonts'>Course Purpose
                                </p>
                            </div>
                            <div className='student_data'>
                                <p className='fonts'> </p>
                            </div>

                        </div>
                    </div>
                    {/* ////invoice */}

                    <div className='invoice_data_head'>
                        <div className='invoice_data_main'>
                            <div className='invoice'>
                                <p className='fonts'> Student Id
                                </p>
                            </div>
                            <div className='invoice_data'>
                                <p className='fonts'> {responseData.student_info?.roll_no}</p>
                            </div>
                        </div>
                        <div className='invoice_data_main'>
                            <div className='invoice'>
                                <p className='fonts'> Invoice No
                                </p>
                            </div>
                            <div className='invoice_data'>
                                <p className='fonts'> {responseData.invoice_no}</p>
                            </div>
                        </div>
                        <div>
                            <div className='invoice_data_main'>
                                <div className='invoice'>
                                    <p className='fonts'>Date
                                    </p>
                                </div>
                                <div className='invoice_data'>
                                    <p className='fonts'>  {formattedDate}</p>
                                </div>
                            </div>
                            <div className='invoice_data_main'>
                                <div className='invoice'>
                                    <p className='fonts'> Total Fees
                                    </p>
                                </div>
                                <div className='invoice_data'>
                                    <p className='fonts'> {responseData.nettotal}</p>
                                </div>
                            </div>
                        </div>

                        <div className='invoice_data_main'>
                            <div className='invoice'>
                                <p className='fonts'> Paid Till Date
                                </p>
                            </div>
                            <div className='invoice_data'>
                                <p className='fonts'>{formattedDate} </p>
                            </div>
                        </div>
                        <div className='invoice_data_main'>
                            <div className='invoice'>
                                <p className='fonts'><b>Pending Fees</b>
                                </p>
                            </div>
                            <div className='invoice_data'>
                                <p className='fonts'> <b>{responseData.balance}</b></p>
                            </div>
                        </div>
                        <div className='invoice_data_main'>
                            <div className='invoice'>
                                <p className='fonts'> Payment Due Date
                                </p>
                            </div>
                            <div className='invoice_data'>
                                <p className='fonts'>{formattedDate} </p>
                            </div>
                        </div>
                        <div className='invoice_data_main'>
                            <div className='invoice'>
                                <p className='fonts'> Next Due Date
                                </p>
                            </div>
                            <div className='invoice_data'>
                                <p className='fonts'>{formatdate}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='line_fr'></hr>
                <div className='payment_main_head'>
                    <p className='fonts_head'><b>Payment made by:	</b></p>
                    <div className='payment_main'>
                        {/* /// */}
                        <div className='payment_head'>
                            <div className='Person_name'>
                                <div >
                                    <p className='fonts'>Person Name
                                    </p>
                                </div>
                                <div className='Person_data'>
                                    <p className='fonts'> {responseData.person_name}</p>
                                </div>

                            </div>
                            <div className='Person_name'>
                                <div >
                                    <p className='fonts'>Contact
                                    </p>
                                </div>
                                <div className='Person_data'>
                                    <p className='fonts'>  {responseData.contact}</p>
                                </div>

                            </div>
                        </div>
                        {/* /// */}
                        <div className='relationship_main'>

                            <div className='contact_name'>
                                <div className='Relationship'>
                                    <p className='fonts'>Relationship
                                    </p>
                                </div>
                                <div className='contact_data'>
                                    <p className='fonts'> {responseData.paid_by}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className='sno_payment'>
                    {/* <div className='sno'>
                        <p><b>SI.NO</b></p>
                    </div>
                    <div className='description'>
                        <p><b>Description</b></p>
                    </div>
                    <div className='payment'>
                        <p><b>Payment Made</b></p>
                    </div> */}
                    <table className='main_table'>
                        <thead className='main_heading_data'>
                            <tr>
                                <th className='table_sno main_head' >SI.NO</th>
                                <th className='table_Description main_head'>Description</th>
                                <th className='main_head'>Payment Made</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td className='main_data_table'>1</td>
                                <td className='main_data_table'>{responseData.dependent}</td>
                                <td className='main_data_table'>{responseData.paytype}</td>
                            </tr>
                            {/* <tr>
                                <td className='main_data_table'>2</td>
                                <td className='main_data_table'>Library Fee</td>
                                <td className='main_data_table'>$50</td>
                            </tr>
                            <tr>
                                <td className='main_data_table'>2</td>
                                <td className='main_data_table'>Library Fee</td>
                                <td className='main_data_table'>$50</td>
                            </tr> */}
                        </tbody>
                    </table>

                </div>
                <div>
                    <div className='sno_payment'>
                        <div className='sno' style={{width:"290px"}}>
                            <p className='fonts'><b>Amount In Words </b>{words}</p>
                        </div>
                        <div>
                            <div style={{ display: "flex" }}>
                                <div className='description'>
                                    <p className='fonts'>Sub Total</p>

                                </div>
                                <div className='payment'>
                                    <p className='fonts'>{responseData.subtotal}</p>

                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className='description'>

                                    <p className='fonts'>Discount </p>

                                </div>
                                <div className='payment'>

                                    <p className='fonts'>{responseData.discount}</p>

                                </div>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className='description'>

                                    <p className='fonts'><b>Final Amount </b></p>
                                </div>
                                <div className='payment'>

                                    <p className='fonts'> {responseData.paid}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='line_fr'></hr>
                <div className='thanks_main'>
                    <div className='main_dec' style={{ width: "390px" }}>
                        <div className='declaration'>

                            <p className='fonts_head' style={{ marginTop: "-10px" }}><b>Declaration</b>:
                            </p>
                            <p className='fonts' style={{ marginTop: "-15px" }}>Payment can be made via bank transfer,debit card, other agreed methods.
                            </p>
                            <p className='fonts' style={{ marginTop: "-15px" }}>We hereby confirm that this invoice accurately reflects the actual price of the goods listed and that all details provided are true and correct.
                            </p>

                        </div>
                        <div className='contact_main' style={{ marginTop: "-10px" }}>
                            <div className='sig_main'>
                                <div className='Contact'>
                                    <div className='contact_heading'>
                                        <p className='fonts'>Contact us
                                        </p>
                                    </div>
                                    <p className='contact_data_last fonts'>: help@skimitar.com
                                    </p>

                                </div>
                                <div className='Contact'>
                                    <div className='contact_heading'>
                                        <p className='fonts'>More Details
                                        </p>
                                    </div>
                                    <p className='contact_data_last fonts'>: www.skimitaracademy.com
                                    </p>

                                </div>
                                <div className='Contact'>
                                    <div className='contact_heading'>
                                        <p className='fonts'>Office
                                        </p>
                                    </div>
                                    <p className='contact_data_last fonts'>: 8428110909
                                    </p>

                                </div>
                            </div>
                            {/* <div className='seal_sig'>
                                    <div></div>
                            <p className='sig_cont fonts'>Seal & Signature
                                        </p>
                                     </div> */}

                        </div>
                    </div>
                    <div className='seal_sig'>
                        <div></div>
                        <p className='sig_cont fonts'>Seal & Signature
                        </p>
                    </div>
                </div>



                <p className='Thanks fonts'>Thanks for having training with us!!! best wishes !!!
                </p>

            </div>

        </>
    )
}
export default Studentinvoice;
