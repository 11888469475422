import React, { useRef, useState ,useEffect} from "react";
import { TableBody, TableHead } from "@mui/material";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill's CSS

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "align",
];

function FullFeaturedEditor() {
  const [editorContent, setEditorContent] = useState("");

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>New Promotions</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-9 d-flex">
          <h5 className="mt-2">New Promotions</h5>
        </div>
      </div>

      <div>
        <h2>Full-Featured Text Editor</h2>
        <ReactQuill
          theme="snow"
          value={editorContent}
          onChange={handleEditorChange}
          modules={modules}
          formats={formats}
          placeholder="Write something amazing..."
        />
        <h3>Editor Output:</h3>
        <div
          dangerouslySetInnerHTML={{ __html: editorContent }}
          style={{ width: "100px", height: "10px" }}
        />
      </div>

      <div className="container mt-5">
        <div className="row mt-3">
          <div className="col-lg-4">
            <div className="card p-3">
              <div className="row mb-2">
                <div className="col-lg-12">
                  <h5 className="text-center">To Mail</h5>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4">
                  <label>Title</label>
                </div>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter title"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4">
                  <label>Message</label>
                </div>
                <div className="col-lg-8">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Enter message"
                  ></textarea>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4">
                  <label>Image</label>
                </div>
                <div className="col-lg-8">
                  <input type="file" className="form-control" />
                </div>
              </div>
              <div className="row mt-3 mb-2 text-center">
                <div className="col-lg-6">
                  <button className="btn btn-primary">Preview</button>
                </div>
                <div className="col-lg-6">
                  <button className="btn btn-primary">Send</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-8 text-end">
                <h5>Excel</h5>
              </div>
              <div className="col-lg-4 mb-3">
                <input type="file" className="form-control" />
              </div>
            </div>
            <table className="table table-striped border">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {/* Example: data.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                )) */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default FullFeaturedEditor;
