import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";

function Jobportal() {

  const [responseData, setResponseData] = useState([]);
  const [previllages, setprevillages] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [role_id, setroleid] = useState('');
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);




  const [addData, setAddData] = useState({




    companyname: '',
    designation: '',
    location: '',
    no_of_vacancies: '',
    applied_candidate: '',
    Job_description:'',
    deadline: '',
    start_date: '',
    status: '',


  });



  const [updateData, setUpdateData] = useState({
    companyname: '',
    designation: '',
    location: '',
    no_of_vacancies: '',
    applied_candidate: '',
    Job_description:'',
    deadline: '',
    start_date: '',
    status: '',

  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);




  useEffect(() => {
    fetchData();
    gettoken();

  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/jobportal");
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };



  const gettoken = async () => {
    const token = localStorage.getItem('token');
    // console.log("the toekn in the localStorage is : ",token);
    try {
      const response = await axios.post(apiUrl + "/get_detail", { "token": token });
      console.log("token detail from server:", response.data);
      // setResponseData(response.data);

      console.log("The role id is : ", response.data.detail.rolename);
      setroleid(response.data.detail.rolename);
      console.log("the role_id is : ", role_id);

      localStorage.setItem('role_id', response.data.detail.rolename);

      const id = response.data.detail.rolename

      // getpages();

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);

          console.log("the reponse list in brach is : ", response.data.modules);

          const modules = response.data.modules;

          const filtered_access = modules.filter(item => item.name == "Branch")

          console.log("the modules is : ", filtered_access[0].actions);

          setprevillages(filtered_access[0].actions);

          console.log("the assigned previllages is : ", previllages.add);

        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }






  const handleNewButtonClick = () => {
    if (previllages.add || previllages.all) {
      setShowModal(true);
    } else {
      toast.error("You don't have the permission");
    }

  };



  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {

        companyname: addData.companyname,
        designation: addData.designation,
        location: addData.location,
        no_of_vacancies: addData.no_of_vacancies,
        applied_candidate: addData.applied_candidate,
        Job_description:addData.Job_description,
        deadline: addData.deadline,
        start_date: addData.start_date,
        status: addData.status,


      }
      const response = await axios.post(apiUrl + "/jobportal", formData);
      console.log("Updated:", response);
      setShowModal(false);
      toast.success("added successfully!");
      setAddData({
        name: ''
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };







  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    if (previllages.edit || previllages.all) {
      setUpdateId(_id)
      console.log(" the data to updata", data)
      setUpdateShowModal(true);
      setUpdateData({

        companyname: data.companyname,
        designation: data.designation,
        location: data.location,
        no_of_vacancies: data.no_of_vacancies,
        applied_candidate: data.applied_candidate,
        Job_description:data.Job_description,
        deadline: data.deadline,
        start_date: data.start_date,
        status: data.status,


      })
    } else {
      toast.error("You don't have the permission");
    }
  }



  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {


        companyname: updateData.companyname,
        designation: updateData.designation,
        location: updateData.location,
        no_of_vacancies: updateData.no_of_vacancies,
        applied_candidate: updateData.applied_candidate,
        Job_description:updateData.Job_description,
        deadline: updateData.deadline,
        start_date: updateData.start_date,
        status: updateData.status,







      }
      console.log("the update submit data", formData)
      const response = await axios.put(apiUrl + `/jobportal/${updateid}`, formData)
      console.log("response for update", response)
      setUpdateShowModal(false)
      toast.success("Update successfully!");
      fetchData();
    }
    catch (error) {
      console.error("Error updating data:", error.message);
    }

  }

  const navigate = useNavigate();

  const handleIncomeType = () => {
    navigate("/Job");
  };



  const handleDateChange = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      deadline: e.target.value,
      start_date: e.target.value,
    }));
  };


  const formatDatefitching = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };




  const handleDelete = async (id) => {
    try {

      const response = await axios.delete(apiUrl + `/jobportal/${getdeleteid}`)
      console.log("delete data ", response)
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    }
    catch (error) {
      console.error("Error updating data:", error.message);
    }

  };


  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  }

  const [getdeleteid, setdeleteid] = useState('')
  const [getdeletename, setdeletename] = useState('')

  const handleOpenModal = (_id, category_name) => {

    if (previllages.all || previllages.delete) {
      console.log(" the id", _id)
      console.log(" the name", category_name)
      setdeleteid(_id);
      setdeletename(category_name);
      setDeleteShowModals(true);
    } else {
      toast.error("You don't have the permission");
    }
  }




  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);




  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };




  const updateinfo = (id) => {
    navigate(`/JobDetails/${id}`);
    // navigate(`jobportalinfo/${id}`);
  };

  return (

    <div className='top'>
      <div className="" style={{ marginTop: '-80px' }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-secondary dashBoard_text">
              <b>Job / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>JobPortal</b>
            </Link>
            {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
          </div>
        </div>
      </div>
      {/* <div className='d-flex'>
        <h5 className='mt-2'>JobPortal</h5>
        <div className='mx-3'><button className='btn  add' onClick={handleNewButtonClick}>Add</button></div>
      </div> */}


      <div className="row">
        <div className="col-lg-10">
          <div className="d-flex">
            <h5 className="mt-2">Job Portal </h5>
            <div className="mx-3">

              {previllages.add && (
                <button className="btn  add" onClick={() => setShowModal(true)}>
                  Add
                </button>
              )}


            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-secondary" onClick={handleIncomeType}>
            Job Application
          </button>
        </div>
      </div>
      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className='form-label text-dark mt-2'>Show : </label>
            <div>
              <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
                {/* <option value='5'>5</option> */}
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">

            <div className="w-full flex-1">
              <form>
                <div className="relative">

                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className="mt-1">
          <table className="table table-striped "  >
            <thead >
              <tr>
                <th>S.No</th>
                <th>Job Title</th>
                <th>Location</th>
                <th>No Vacancies</th>
                <th>DeadLine</th>
                <th>Applied Candidate</th>
                <th>Status</th>



                <th className='text-center'>Action</th>
              </tr>
            </thead>

            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>{item.designation}</td>
                  <td>{item.location}</td>
                  <td>{item.no_of_vacancies}</td>

                  <td>{new Date(item.deadline).toLocaleDateString()}</td>
                  <td>{item.applied_candidate}</td>
                  <td className="text-center hideres">
                    <p
                      className={`text-white ${item.status === "Active" ? "green-label" : "red-label"
                        }`}
                    >
                      {item.status}
                    </p>
                  </td>



                  {/* <td  ><p className={item.status === 'Active' ? 'active-row' : 'inactive-row'}>{item.status}</p></td> */}


                  <td className='text-center'>

                    <button className='btn updelete mx-2' onClick={() => handleUpdate(item._id, item)}><i class="fa-solid fa-pen-to-square"></i></button>
                    <button className='btn delete'
                      onClick={() => handleOpenModal(item._id, item.category_name)}
                    > <i class="fa-solid fa-trash-can"></i></button>
                    <button
                      className="btn"
                      onClick={() => updateinfo(item._id)}
                    >
                      <i class="fa-solid fa-circle-info"></i>
                    </button>


                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>


      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add JobPortal </Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <div className='row'>
            <div className='col-lg-6'>

              <div className="form-group">



                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=''
                    value={addData.companyname}
                    onChange={(e) => setAddData({ ...addData, companyname: e.target.value })}
                    required
                  />
                  <label className={addData.companyname ? "filled" : ""}>
                    Company Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=''
                    value={addData.designation}
                    onChange={(e) => setAddData({ ...addData, designation: e.target.value })}
                    required
                  />
                  <label className={addData.designation ? "filled" : ""}>
                    Designation
                  </label>
                </div>


                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=''
                    value={addData.location}
                    onChange={(e) => setAddData({ ...addData, location: e.target.value })}
                    required
                  />
                  <label className={addData.location ? "filled" : ""}>
                    Location
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=''
                    value={addData.no_of_vacancies}
                    onChange={(e) => setAddData({ ...addData, no_of_vacancies: e.target.value })}
                    required
                  />
                  <label className={addData.no_of_vacancies ? "filled" : ""}>
                    No of vacancies
                  </label>
                </div>


                {/* <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=''
                    value={addData.applied_candidate}
                    onChange={(e) => setAddData({ ...addData, applied_candidate: e.target.value })}
                    required
                  />
                  <label className={addData.applied_candidate ? "filled" : ""}>
                    Applied Candidate
                  </label>
                </div> */}


              </div>
            </div>
            <div className='col-lg-6'>


              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=''
                  value={addData.deadline}
                  onChange={(e) => setAddData({ ...addData, deadline: e.target.value })}
                  required
                />
                <label className={addData.deadline ? "filled" : ""}>
                  Deadline
                </label>
              </div>




              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=''
                  value={addData.start_date}
                  onChange={(e) => setAddData({ ...addData, start_date: e.target.value })}
                  required
                />
                <label className={addData.start_date ? "filled" : ""}>
                  Start Date
                </label>
              </div>


              <div className="floating-label">
              <textarea
                    className="form-control"
                    type="text"
                    placeholder=''
                    value={addData.Job_description}
                    onChange={(e) => setAddData({ ...addData, Job_description: e.target.value })}
                    required
                  />
                  <label className={addData.Job_description ? "filled" : ""}>
                  Job_description
                  </label>
                </div>




              <div className="floating-label">
                <select
                  className="form-control"
                  value={addData.status || ""} // Ensure it defaults to an empty string if undefined
                  onChange={(e) =>
                    setAddData({ ...addData, status: e.target.value })
                  }
                >
                  <option value="">Select status</option>
                  <option value="Active">Active</option>
                  <option value="InActive">InActive</option>
                </select>

                <label className={addData.status ? "filled" : ""}>
                  Select status
                </label>
              </div>



            </div>




          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button className='cancelbut' variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button className='submitbut' variant="primary" onClick={handlesubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>


      <Modal show={isUpdateshowModal} onHide={() => setUpdateShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Jobportal</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* Other input fields */}

          <div className="form-group">










          </div>



          <div className='row'>
            <div className='col-lg-6'>

              <div className="form-group">





                <div className="floating-label">
                  <input className="form-control" type="text" placeholder='' value={updateData.companyname} onChange={(e) => setUpdateData({ ...updateData, companyname: e.target.value })} required />
                  <label className={updateData.companyname ? "filled" : ""}>
                    Company Name
                  </label>
                </div>

                <div className="floating-label">
                  <input className="form-control" type="text" placeholder='' value={updateData.designation} onChange={(e) => setUpdateData({ ...updateData, designation: e.target.value })} required />
                  <label className={updateData.designation ? "filled" : ""}>
                    Designation
                  </label>
                </div>


                <div className="floating-label">
                  <input className="form-control" type="text" placeholder='' value={updateData.location} onChange={(e) => setUpdateData({ ...updateData, location: e.target.value })} required />
                  <label className={updateData.location ? "filled" : ""}>
                    Designation
                  </label>
                </div>




                <div className="floating-label">
                  <input className="form-control" type="text" placeholder='' value={updateData.no_of_vacancies} onChange={(e) => setUpdateData({ ...updateData, no_of_vacancies: e.target.value })} required />
                  <label className={updateData.no_of_vacancies ? "filled" : ""}>
                    No of Vacancies
                  </label>
                </div>


{/* 
                <div className="floating-label">
                  <input className="form-control" type="text" placeholder='' value={updateData.applied_candidate} onChange={(e) => setUpdateData({ ...updateData, applied_candidate: e.target.value })} required />
                  <label className={updateData.applied_candidate ? "filled" : ""}>
                    Applied Candidate
                  </label>
                </div> */}



              </div>
            </div>
            <div className='col-lg-6'>

              <div className="floating-label">
                <input
                  type="date"
                  className="form-control"
                  placeholder=""
                  value={formatDatefitching(updateData.deadline)}
                  onChange={handleDateChange}
                />
                <label className={updateData.deadline ? "filled" : ""}>
                  {/* {updateData.date} */}
                  Deadline
                </label>
              </div>



              <div className="floating-label">
                <input
                  type="date"
                  className="form-control"
                  placeholder=""
                  value={formatDatefitching(updateData.start_date)}
                  onChange={handleDateChange}
                />
                <label className={updateData.start_date ? "filled" : ""}>
                  {/* {updateData.date} */}
                  Start Date
                </label>
              </div>




              <div className="floating-label">
                  <textarea className="form-control" type="text" placeholder='' value={updateData.Job_description} onChange={(e) => setUpdateData({ ...updateData, Job_description: e.target.value })} required />
                  <label className={updateData.Job_description ? "filled" : ""}>
                  Job Description
                  </label>
                </div>





              <div className="floating-label">
                <select
                  className="form-control"
                  value={updateData.status}
                  placeholder=""
                  onChange={(e) =>
                    setUpdateData({ ...updateData, status: e.target.value })
                  }
                >
                  <option value="">Select status</option>
                  <option value="Active">Active</option>
                  <option value="InActive">InActive</option>
                </select>
                <label className={updateData.status ? "filled" : ""}>
                  Select status
                </label>
              </div>


            </div>




          </div>





          {/* -------------------delete -------------------- */}
        </Modal.Body>

        <Modal.Footer>
          <Button className='cancelbut' variant="secondary" onClick={() => setUpdateShowModal(false)}>Cancel</Button>
          <Button className='submitbut' variant="primary" onClick={handleupdatesubmit}>Update</Button>
        </Modal.Footer>
      </Modal>


      <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>


          <label className="form-label d-flex">
            <h5 className='mx-1'>branch Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
        </Modal.Footer>
      </Modal>
      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">

          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>

        </div>
      </div>



    </div>
  )
}



export default Jobportal
