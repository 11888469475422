// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Modal, Button } from 'react-bootstrap';
// import apiUrl from '../../environment/Api';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// import { Link } from "react-router-dom";



// function Studentdairy() {



//   const [responseData, setResponseData] = useState([]);
//   const [student_dairy_overall, setStudent_dairy_overall] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [isUpdateShowModal, setUpdateShowModal] = useState(false);
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [addDatass, setAddDatass] = useState({ empid: '', name: '' });
//   const [deleteShowModals, setDeleteShowModals] = useState(false);
//   const [branch, setbranch] = useState([]);
//   const [courseinfo, setcourseinfo] = useState([]);
//   const [role_id, setroleid] = useState('');
//   const [previllages, setprevillages] = useState({})



//   // current date//////////////////////////////
//   const formatDate = (date) => {
//     if (!(date instanceof Date)) {
//       date = new Date(date); // Convert input to Date if it's not already
//     }
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${year}-${month}-${day}`;
//   };

//   const [addData, setAddData] = useState({

//     branch_id: '',
//     student: '',
//     roll_no: '',
//     course: '',
//     date: formatDate(new Date()),
//     login: '',
//     logout: '',
//     topic_covered: '',
//     notes: '',
//     studyingstudent: "",
//     course_id: ""




//   });
//   const [updateData, setUpdateData] = useState({


//     branch_id: '',
//     student: '',
//     roll_no: '',
//     course: '',
//     date: "",
//     login: '',
//     logout: '',
//     topic_covered: '',
//     notes: ''


//   });


//   const handleDateChange = (e) => {
//     setAddData(prevData => ({
//       ...prevData,
//       date: e.target.value // Input type=date handles the format
//     }));
//   };

//   const [rolename, setrolename] = useState([]);

//   useEffect(() => {
//     fetchData();
//     const rolename = localStorage.getItem("rolename");
//     setrolename(rolename)
//   }, []);


//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const fetchData = async () => {
//     try {

//       const response = await axios.get(apiUrl + "/student_dairy", {
//         params: { data: localStorage.getItem("branch_id") },
//       });

//       const responseovelldata = await axios.get(apiUrl + "/student_dairy_overall", {
//         params: { data: localStorage.getItem("branch_id") },
//       });


//       console.log("Response from server:", response.data);
//       console.log("Response from server responseovelldata:", responseovelldata.data);
//       setResponseData(response.data.reverse());
//       setStudent_dairy_overall(responseovelldata.data)
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   useEffect(() => {
//     fetchDatas();
//     fetchDataed();
//     gettoken();

//   }, []);



//   const fetchDatas = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/staff");
//       console.log("Response from:", response.data);
//       setResponseDatas(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const gettoken = async () => {
//     const token = localStorage.getItem('token');
//     // console.log("the toekn in the localStorage is : ",token);
//     try {
//       const response = await axios.post(apiUrl + "/get_detail", { "token": token });
//       console.log("token detail from server:", response.data);
//       // setResponseData(response.data);

//       console.log("The role id is : ", response.data.detail.rolename);
//       setroleid(response.data.detail.rolename);
//       console.log("the role_id is : ", role_id);

//       localStorage.setItem('role_id', response.data.detail.rolename);

//       const id = response.data.detail.rolename

//       // getpages();

//       if (id) {
//         try {
//           const response = await axios.get(apiUrl + `/role_2/${id}`);

//           console.log("the reponse list in brach is : ", response.data.modules);

//           const modules = response.data.modules;

//           const filtered_access = modules.filter(item => item.name == "Branch")

//           console.log("the modules is : ", filtered_access[0].actions);

//           setprevillages(filtered_access[0].actions);

//           console.log("the assigned previllages is : ", previllages.add);

//         } catch (error) {
//           console.error("Error fetching data:", error.message);
//         }
//       }

//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   }



//   const [getCoursesAll, setCourseAll] = useState([])
//   const [getRollAll, setRollAll] = useState([])
//   const fetchDataed = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/Branch");
//       const responsecourse = await axios.get(apiUrl + "/course");
//       const responseRole = await axios.get(apiUrl + "/student");
//       const courseinfo = await axios.get(apiUrl + "/courseinfo");
//       console.log("Response from server:", response.data);
//       console.log("Response from server:courseinfo", courseinfo);
//       setCourseAll(responsecourse.data)
//       setRollAll(responseRole.data)
//       setbranch(response.data);
//       setcourseinfo(courseinfo.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const handleAddSubmit = async (e) => {
//     e.preventDefault();
//     if (!validateFields()) {
//       return;
//     }
//     try {
//       const formData = {
//         branch_id: addData.branch_id,
//         student: addData.student,
//         roll_no: addData.roll_no,
//         course: addData.course,
//         date: addData.date,
//         login: addData.login,
//         logout: addData.logout,
//         topic_covered: addData.topic_covered,
        
//         notes: addData.notes,
//         course_id: addData.course_id,
//         studyingstudent: addData.studyingstudent
//       };

//       console.log(formData, "Submitting form data");

//       const response = await axios.post(apiUrl + "/student_dairy", formData);
//       console.log("Added:", response);

//       setShowModal(false);
//       fetchData(); // Assuming this refreshes the data to show the newly added student entry
//       toast.success("Added successfully!");

//       // Reset form
//       setAddData({
//         branch_id: '',
//         student: '',
//         roll_no: '',
//         course: '',
//         date: formatDate(new Date()), // Ensuring date resets to current date
//         login: '',
//         logout: '',
//         topic_covered: '',
//         notes: ''
//       });

//     } catch (error) {
//       console.error("Error adding data:", error.message);
//       toast.error("Failed to add student diary entry."); // Show error notification
//     }
//   };



//   // console.log(getCoursesAll, "getCoursesAll---")
//   // const handleNewButtonClick = () => {
//   //   if (previllages?.add || previllages?.all) {
//   //     setShowModal(true);
//   //   } else {
//   //     toast.error("You don't have the permission to add");
//   //   }
//   // }




//   const railwayToNormalTime = (railwayTime) => {
//     const [hour, minute] = railwayTime.split(':');
//     const parsedHour = parseInt(hour);
//     const parsedMinute = parseInt(minute);
//     if (parsedHour < 12) {
//       return `${parsedHour}:${minute} AM`;
//     } else if (parsedHour === 12) {
//       return `${parsedHour}:${minute} PM`;
//     } else {
//       return `${parsedHour - 12}:${minute} PM`;
//     }
//   };








//   const calculateWorkHours = (login, logout, lunch_out, lunch_in) => {
//     const loginTime = new Date(`1970-01-01T${login}:00`);
//     const logoutTime = new Date(`1970-01-01T${logout}:00`);
//     const lunchoutTime = new Date(`1970-01-01T${lunch_out}:00`);
//     const lunchinTime = new Date(`1970-01-01T${lunch_in}:00`);

//     const totalWorkTime = (logoutTime - loginTime) - (lunchinTime - lunchoutTime);
//     const hours = Math.floor(totalWorkTime / (1000 * 60 * 60));
//     const minutes = Math.floor((totalWorkTime % (1000 * 60 * 60)) / (1000 * 60));
//     console.log("the return value", loginTime, logoutTime, lunchoutTime, lunchinTime);
//     return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
//   };



//   const [updateId, setUpdateId] = useState("");

//   const handleUpdate = (_id, data) => {
//     if (previllages.edit || previllages.all) {
//       setUpdateId(_id);
//       console.log("The data to update", data);
//       setUpdateShowModal(true);
//       setUpdateData({


//         branch_id: data.branch_id,
//         student: data.student,
//         course: data.course,
//         roll_no: data.roll_no,
//         date: data.date,
//         login: data.login,
//         logout: data.logout,
//         topic_covered: data.topic_covered,
//         notes: data.notes

//       });
//     } else {
//       toast.error("You don't have the permission to update");
//     }
//   }






//   const handleUpdateSubmit = async (e) => {
//     e.preventDefault();
//     // const workhour = calculateWorkHours(updateData.login, updateData.logout, updateData.lunchout, updateData.lunchin);
//     try {
//       const formData = {

//         branch_id: updateData.branch_id,
//         student: updateData.student,
//         course: updateData.course,
//         roll_no: updateData.roll_no,
//         date: updateData.date,
//         login: updateData.login,
//         logout: updateData.logout,
//         topic_covered: updateData.topic_covered,
//         notes: updateData.notes


//       };
//       console.log("The update submit data111", formData);
//       const response = await axios.put(apiUrl + `/student_dairy/${updateId}`, formData);
//       console.log("Response for update", response);
//       setUpdateShowModal(false);
//       toast.success("Update successfully!");
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(apiUrl + `/student_dairy/${getdeleteid}`);
//       console.log("Deleted data", response);
//       toast.error("Deleted successfully!");
//       setDeleteShowModals(false);
//       fetchData();
//     } catch (error) {
//       console.error("Error deleting data:", error.message);
//     }
//   };




//   const handleCloseModal = () => {
//     setDeleteShowModals(false);
//   }

//   const [getdeleteid, setdeleteid] = useState('')
//   const [getdeletename, setdeletename] = useState('')
//   const handleOpenModal = (_id, student) => {

//     if (previllages.all || previllages.delete) {
//       console.log(" the id", _id)
//       console.log(" the name", student)
//       setdeleteid(_id);
//       setdeletename(student);
//       setDeleteShowModals(true);
//     } else {
//       toast.error("You don't have the permission to delete");
//     }
//   }






//   // Pagination and search logic
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [searchQuery, setSearchQuery] = useState("");
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const filtered = currentItems.filter((item) =>
//     Object.values(item).some(
//       (value) =>
//         typeof value === "string" &&
//         value.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         (typeof value === "number" && value.toString().includes(searchQuery))
//     )
//   );

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//     setCurrentPage(1);
//   };

//   const totalPages = Math.ceil(responseData.length / itemsPerPage);

//   const goToNextPage = () => {
//     setCurrentPage((prevPage) =>
//       prevPage === totalPages ? prevPage : prevPage + 1
//     );
//   };

//   const goToPrevPage = () => {
//     setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
//   };



//   console.log("getRollAll---===", getRollAll)


//   const handleCourseChange = (e) => {

//     const selectedRollNo = e.target.value;
//     console.log("selectedRollNo+_+_", selectedRollNo)
//     console.log("role data this", getRollAll)


//     console.log("this is topics", topics)

//     setAddData({ ...addData, course: e.target.value });
//   };


//   const [topics, setTopics] = useState({})
//   const handelRole = (e) => {
//     const selectedRollNo = e.target.value;
//     console.log("Roleee+_+_", selectedRollNo)
//     console.log("getRollAll+_+_", getRollAll)




//     // Find the selected student details using the selected roll number
//     const selectedStudent = getRollAll.find(item => item.roll_no == selectedRollNo);
//     console.log("selectedStudent+_+_", selectedStudent)
//     console.log("selectedStudent+_+_", selectedStudent.Prefered_course_lead)

//     const topicsfilter = selectedStudent.Prefered_course_lead

//     const topic = courseinfo.find(item => item.course_name == topicsfilter);

//     console.log("selectedStudent+_+_topic", topic)

//     console.log("selectedStudent+_+_topic1212", topic.topics)

//     setTopics(topic.topics)

//     console.log("selectedStudent+_+_topic1212", topics)


//     // setTopics(topic.topics)
//     // Check if leads_id exists and has at least one entry
//     const studentName = selectedStudent?.student_name_lead || null;
//     const studentcourse = selectedStudent?.Prefered_course_lead || null;
//     const studentbranchid = selectedStudent?.branch_lead[0]?._id || null;
//     const studyingstudent = selectedStudent?._id || null;
//     const course_id = selectedStudent?.course_id || null;
//     // Default to empty string if no student name exists
//     console.log(studentName, "student_name_+_+_")
//     console.log(selectedStudent, "selectedStudent+_+_")

//     setAddData({
//       ...addData,
//       roll_no: selectedRollNo, // Set the selected roll number
//       student: studentName, // Safely set the student name
//       course: studentcourse,
//       studyingstudent: studyingstudent,
//       course_id: course_id,
//       branch_id: studentbranchid
//     });


//   };




//   // const [updateData, setUpdateData] = useState({
//   //   branch_id: { branch_name: '' },
//   //   student: '',
//   //   roll_no: '',
//   //   topic_covered: '',
//   //   course: '',
//   //   date: '',
//   //   login: '',
//   //   logout: ''
//   // });



//   const handleDateChangee = (e) => {
//     setUpdateData({ ...updateData, date: e.target.value });
//   };
//   // Helper function to format ISO date to YYYY-MM-DD
//   const formatDatefitching = (isoDate) => {
//     if (!isoDate) return ''; // Return an empty string if no date is provided
//     const date = new Date(isoDate);
//     return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
//   };

//   function AllDataHandale() {
//     setResponseData(student_dairy_overall)
//     setStudent_dairy_overall(responseData)
//   }
//   const navigate = useNavigate()

//   const updateinfo = (id) => {
//     navigate(`/StudentInfo/${id}`);
//   };



//   const [errors, setErrors] = useState({});

//   const validateFields = () => {
//     let tempErrors = {};
//     let isValid = true;

//     if (!addData.branch_id) {
//       tempErrors.branch_id = "Branch is required.";
//       isValid = false;
//     }






//     if (!addData.roll_no) {
//       tempErrors.roll_no = "StudentName is required.";
//       isValid = false;
//     }




//     if (!addData.login) {
//       tempErrors.login = "Login is required.";
//       isValid = false;
//     }


//     if (!addData.topic_covered) {
//       tempErrors.topic_covered = "Topic Covered is required.";
//       isValid = false;
//     }




//     if (!addData.notes) {
//       tempErrors.notes = "Notes is required.";
//       isValid = false;
//     }
//     setErrors(tempErrors);
//     return isValid;
//   }




//   // branch_id: addData.branch_id,
//   // student: addData.student,
//   // roll_no: addData.roll_no,
//   // course: addData.course,
//   // date: addData.date,
//   // login: addData.login,
//   // logout: addData.logout,
//   // topic_covered: addData.topic_covered,
//   // notes: addData.notes,
//   // course_id: addData.course_id,
//   // studyingstudent: addData.studyingstudent




//   return (
//     <>
//       <div className='top'>

//         <div className="" style={{ marginTop: '-80px' }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>Student Dairy</b>
//               </Link>
//               {/* <Link to="" className="text-danger dashBoard_text">
//                             <b>Profile</b>
//                         </Link> */}
//             </div>
//           </div>
//         </div>

//         {/* <div className='d-flex'>
//           <h5 className='mt-2'> Student Dairy </h5>
//           <div className='mx-3'><button className='btn  add' onClick={handleNewButtonClick}>Add </button></div>
//           <div className='mx-3'><button className='btn  btn-danger' onClick={AllDataHandale}> <i class="fa-solid fa-arrows-rotate"></i></button></div>
//         </div> */}
//         <div className='row'>
//           <div className='col-lg-9'>
//             <div className='d-flex'>
//               <h5 className='mt-2'> Student Dairy </h5>
//               {/* <div className='mx-3'><button className='btn  add' onClick={handleNewButtonClick}>Add </button></div> */}
//               {previllages.add && (
//                 <button
//                   className="btn btn-success add mx-3"
//                   onClick={() => setShowModal(true)}
//                 >
//                   Add
//                 </button>
//               )}


//             </div>
//           </div>
//           <div className='col-lg-1'>
//             <div className=''><button className='btn  btn-danger' onClick={AllDataHandale}>Overall</button></div>
//           </div>


//           <div className='col-lg-2'>
//             <div>
//               <input
//                 type="file"
//                 id="file-upload"
//                 accept=".xls,.xlsx"
//                 style={{ display: "none" }}
//               // onChange={handleFileUpload}
//               />
//               <label htmlFor="file-upload" className="btn btn-secondary">
//                 File Upload
//               </label>
//             </div>
//           </div>

//         </div>

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className='form-label text-dark mt-2'>Show : </label>
//               <div>
//                 <select className="form-control w-100 mx-2" onChange={(e) => { setItemsPerPage(e.target.value) }}>
//                   {/* <option value='5'>5</option> */}
//                   <option value='10'>10</option>
//                   <option value='20'>20</option>
//                   <option value='50'>50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">

//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white appearance-none pl-8"
//                       placeholder="Search ..."
//                       type="search"
//                       value={searchQuery}
//                       onChange={handleSearchChange}
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>


//         <div className='card'>
//           <div className="mt-1">
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   {rolename === "superadmin" && <th>Branch</th>}
//                   <th>Student </th>
//                   <th>Course </th>
//                   {/* <th>Roll No</th> */}
//                   <th>Date</th>
//                   <th>Log In</th>
//                   <th>Log Out</th>
//                   <th>Topic Covered </th>
//                   <th className='text-center' id="tablebutton">Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filtered.map((item, index) => (
//                   <tr key={index}>
//                     <td>{(index + 1) + (currentPage - 1) * itemsPerPage}</td>
//                     {/* <td>{item.branch_id?.branch_name}</td> */}
//                     {rolename === "superadmin" && <td>{item.branch_id?.branch_name}</td>}
//                     <td>{item.student}</td>
//                     <td>{item.course}</td>
//                     {/* <td>{item.roll_no}</td> */}
//                     <td>{formatDatefitching(item.date)}</td>
//                     <td>{railwayToNormalTime(item.login || '')}</td>
//                     {/* <td>{railwayToNormalTime(item.lunch_out || '')}</td>
//                     <td>{railwayToNormalTime(item.lunch_in || '')}</td> */}
//                     <td>{railwayToNormalTime(item.logout || '')}</td>
//                     <td>{item.topic_covered}</td>

//                     <td className='text-center'>
//                       {/* <button className="btn updelete mx-1" onClick={() => handleUpdate(item._id, item)}>
//                         <i className="fa-solid fa-pen-to-square"></i>
//                       </button>

//                       <button className='btn delete ' onClick={() => handleOpenModal(item._id, item.student)}>
//                         <i className="fa-solid fa-trash-can"></i>
//                       </button> */}
//                       {previllages.edit && (
//                         <button
//                           className="btn updelete mx-1"
//                           onClick={() => handleUpdate(item._id, item)}
//                         >
//                           <i className="fa-solid fa-pen-to-square"></i>
//                         </button>
//                       )}
//                       {previllages.delete && (
//                         <button
//                           lassName='btn delete '
//                           onClick={() =>
//                             handleOpenModal(item._id, item.student)
//                           }
//                         >
//                           <i className="fa-solid fa-trash-can"></i>
//                         </button>
//                       )}

//                       <button
//                         className="btn "
//                         onClick={() => updateinfo(item.studyingstudent)}
//                       >
//                         <i class="fa-solid fa-circle-info"></i>
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>

//             </table>
//           </div>
//         </div>



//         <Modal show={showModal} onHide={() => setShowModal(false)} className="custom-modal">
//           <Modal.Header closeButton>
//             <Modal.Title>Add Student Dairy </Modal.Title>
//           </Modal.Header>
//           <Modal.Body>

//             <form onSubmit={handleAddSubmit}>
//               <div className='row'>
//                 <div className='col-lg-6'>


//                   <div className="form-group">
//                     <div className="floating-label">
//                       <select className="form-select" value={addData.roll_no}


//                         // onChange={(e) => setAddData({ ...addData, roll_no: e.target.value })}
//                         onChange={(e) => handelRole(e)}
//                       >
//                         <option> Select Student</option>
//                         {getRollAll.map((item, index) => (
//                           <option key={index} value={item.roll_no}>
//                             {item.roll_no} - {item.student_name_lead}
//                           </option>
//                         ))}
//                       </select>

//                       {errors.roll_no && (
//                         <div className="error-text">{errors.roll_no}</div>
//                       )}
//                     </div>


//                     <div className="floating-label">
//                       <select
//                         className="form-select"
//                         value={addData.branch_id}

//                         onChange={(e) => setAddData({ ...addData, branch_id: e.target.value })}
//                       >
//                         <option>Select Branch</option>
//                         {branch.map((item, index) => (
//                           <option key={index} value={item._id}>
//                             {item.branch_id} - {item.branch_name}
//                           </option>
//                         ))}
//                       </select>
//                       <label className={addData.branch_id ? "filled" : ""}>
//                         Select Branch
//                       </label>
//                       {errors.branch_id && (
//                         <div className="error-text">{errors.branch_id}</div>
//                       )}
//                     </div>








//                     {/* <div className="floating-label">
//                       <input type="text" className="form-control" placeholder='' value={addData.student} onChange={(e) => setAddData({ ...addData, student: e.target.value })} />
//                       <label className={addData.student ? "filled" : ""}>

//                         Student
//                       </label>
//                     </div> */}





//                     {/* <div className="floating-label">
//                       <input type="text" className="form-control" placeholder='' value={addData.roll_no} onChange={(e) => setAddData({ ...addData, roll_no: e.target.value })} />
//                       <label className={addData.roll_no ? "filled" : ""}>
//                         Roll No
//                       </label>
//                     </div> */}


//                     {/* ======================================== */}


//                     <div className="floating-label">
//                       <select className="form-select" value={addData.course} onChange={handleCourseChange}>
//                         <option> Select Course</option>
//                         {getCoursesAll.map((item, index) => (
//                           <option key={index} value={item.course_name}>
//                             {item.course_name}
//                           </option>
//                         ))}
//                       </select>
//                     </div>

//                     {/* <div className="floating-label">
//                       <select className="form-select" value={addData.course}

//                         onChange={(e) => setAddData({ ...addData, course: e.target.value })}>
//                         <option> Select Course</option>
//                         {getCoursesAll.map((item, index) => (
//                           <option key={index} value={item.course_name}>
//                             {item.course_name}
//                           </option>
//                         ))}
//                       </select>
                     
//                     </div> */}


//                     {/*=====================  */}

//                   </div>

//                 </div>
//                 <div className='col-lg-6'>
//                   <div className="form-group">




//                     <div className="floating-label">
//                       <input className="form-control" type="date" placeholder='' value={addData.date} onChange={handleDateChange} required />
//                       <label className={addData.date ? "filled" : ""}>
//                         Date
//                       </label>
//                       {errors.date && (
//                         <div className="error-text">{errors.date}</div>
//                       )}
//                     </div>


//                     <div className="floating-label">
//                       <input type="time" className="form-control" placeholder='' value={addData.login} onChange={(e) => setAddData({ ...addData, login: e.target.value })} />
//                       <label className={addData.login ? "filled" : ""}>
//                         Log In
//                       </label>
//                       {errors.login && (
//                         <div className="error-text">{errors.login}</div>
//                       )}
//                     </div>



//                     <div className="floating-label">
//                       <input type="time" className="form-control" placeholder='' value={addData.logout} onChange={(e) => setAddData({ ...addData, logout: e.target.value })} disabled />
//                       <label className={addData.logout ? "filled" : ""}>
//                         Logout
//                       </label>
//                     </div>



//                   </div>
//                 </div>
//                 <div className='col-lg-6'>
//                   <div className="floating-label">

//                     {/* <p>{{topics}}</p> */}
//                     <input type="text" className="form-control" placeholder='' value={topics} onChange={(e) => setAddData({ ...addData, topic_covered: e.target.value })} />
//                     <label className={addData.topic_covered ? "filled" : ""}>
//                       Topic Covered
//                     </label>
//                     {/* <select
//                       className="form-control"
//                       value={addData.topic_covered}
//                       onChange={(e) => setAddData({ ...addData, topic_covered: e.target.value })}
//                       id="topicCovered"
//                     >
//                       <option value="">Select Topic</option>
//                       {courseinfo.map((item) => (
//                         <option key={item._id} value={item.topics}>
//                           {item.topics}
//                         </option>
//                       ))}
//                     </select> */}

//                     {/* Label with Conditional Class */}
//                     <label
//                       htmlFor="topicCovered"
//                       className={addData.topic_covered ? "filled" : ""}
//                     >
//                     </label>
                   
//                   </div>

//                 </div>
//                 <div className='col-lg-6'>
//                   <div className="floating-label">
//                     <input type="text" className="form-control" placeholder='' value={addData.notes} onChange={(e) => setAddData({ ...addData, notes: e.target.value })} />
//                     <label className={addData.notes ? "filled" : ""}>
//                       Notes
//                     </label>
//                     {errors.notes && (
//                       <div className="error-text">{errors.notes}</div>
//                     )}
//                   </div>
//                 </div>
//               </div>


// {/* 
//               <p>{topics}</p> */}

//               <div className='modalbutton'>
//                 <Button variant="secondary" className='mt-3 cancelbut' onClick={() => setShowModal(false)}>Cancel</Button>
//                 <button type="submit" className="btn btn-primary mt-3 mx-2 submitbut">Submit</button>
//               </div>


//             </form>


//           </Modal.Body>
//         </Modal>




//         <Modal className='custom-modal' show={isUpdateShowModal} onHide={() => setUpdateShowModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Update Student Diary</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <form onSubmit={handleUpdateSubmit}>
//               <div className='row'>
//                 <div className='col-lg-6'>

//                   <div className="floating-label">
//                     <input
//                       type="text"
//                       disabled
//                       className="form-control"
//                       placeholder=''
//                       value={updateData.student}
//                       onChange={(e) => setUpdateData({ ...updateData, student: e.target.value })}
//                       required
//                     />
//                     <label className={updateData.student ? "filled" : ""}>
//                       Student
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       disabled
//                       type="text"
//                       placeholder=''
//                       value={updateData.branch_id.branch_name}
//                       onChange={(e) => setUpdateData({ ...updateData, branch_id: { branch_name: e.target.value } })}
//                       required
//                     />
//                     <label className={updateData.branch_id.branch_name ? "filled" : ""}>
//                       Branch
//                     </label>
//                   </div>



//                   <div className="floating-label">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder=''
//                       disabled
//                       value={updateData.roll_no}
//                       onChange={(e) => setUpdateData({ ...updateData, roll_no: e.target.value })}
//                       required
//                     />
//                     <label className={updateData.roll_no ? "filled" : ""}>
//                       Roll No
//                     </label>
//                   </div>
//                   <div className="floating-label">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder=''

//                       value={updateData.course}
//                       onChange={(e) => setUpdateData({ ...updateData, course: e.target.value })}
//                       required
//                     />
//                     <label className={updateData.course ? "filled" : ""}>
//                       Course
//                     </label>
//                   </div>

//                 </div>

//                 <div className='col-lg-6'>

//                   {/* <div className="floating-label">
//                     <input
//                       type="text"
//                       className="form-control"

//                       placeholder=''
//                       value={updateData.topic_covered}
//                       onChange={(e) => setUpdateData({ ...updateData, topic_covered: e.target.value })}
//                       required
//                     />
//                     <label className={updateData.topic_covered ? "filled" : ""}>
//                       Topic Covered
//                     </label>
//                   </div> */}
//                   <div className="floating-label">
//                     {/* Dropdown for Topic Covered */}
//                     <select
//                       className="form-control"
//                       value={updateData.topic_covered}
//                       onChange={(e) => setUpdateData({ ...updateData, topic_covered: e.target.value })}
//                       required
//                     >
//                       <option value="">Select Topic</option>
//                       {courseinfo.map((item) => (
//                         <option key={item._id} value={item.topics}>
//                           {item.topics}
//                         </option>
//                       ))}
//                     </select>

//                     {/* OR Text Input for Custom Topic */}

//                     {/* Label with Conditional Class */}
//                     <label className={updateData.topic_covered ? "filled" : ""}>
//                       Topic Covered
//                     </label>
//                   </div>
//                   <div className="floating-label">
//                     <input
//                       type="date"
//                       className="form-control"
//                       placeholder=''
//                       value={formatDatefitching(updateData.date)}
//                       onChange={handleDateChangee}
//                       required
//                     />
//                     <label className={formatDatefitching(updateData.date) ? "filled" : ""}>
//                       Date
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       type="time"
//                       className="form-control"
//                       placeholder=''
//                       value={updateData.login}
//                       onChange={(e) => setUpdateData({ ...updateData, login: e.target.value })}
//                       required
//                     />
//                     <label className={updateData.login ? "filled" : ""}>
//                       Log In
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       type="time"
//                       className="form-control"
//                       placeholder=''
//                       value={updateData.logout}
//                       onChange={(e) => setUpdateData({ ...updateData, logout: e.target.value })}
//                       required
//                     />
//                     <label className={updateData.logout ? "filled" : ""}>
//                       Log Out
//                     </label>
//                   </div>
//                   <div className="floating-label">
//                     <input
//                       type="text"
//                       className="form-control"

//                       placeholder=''
//                       value={updateData.notes}
//                       onChange={(e) => setUpdateData({ ...updateData, notes: e.target.value })}
//                       required
//                     />
//                     <label className={updateData.notes ? "filled" : ""}>
//                       notes
//                     </label>
//                   </div>
//                 </div>
//               </div>

//               <div className='modalbutton'>
//                 <Button variant="secondary" className='mt-3 cancelbut mx-2' onClick={() => setUpdateShowModal(false)}>Cancel</Button>
//                 <Button type="submit" className="btn btn-primary mt-3 submitbut">Update</Button>
//               </div>
//             </form>
//           </Modal.Body>
//         </Modal>

//         <Modal className="modal" show={deleteShowModals} onHide={handleCloseModal}>
//           <Modal.Header>
//             <p>Are you sure you want to delete?</p>
//           </Modal.Header>
//           <Modal.Body>

//             <label className="form-label d-flex">
//               <h5 className='mx-1'>Student :</h5> <h5>{getdeletename} </h5>
//             </label>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button className='cancelbut' variant="secondary" onClick={handleCloseModal}>
//               Close
//             </Button>
//             <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
//           </Modal.Footer>
//         </Modal>



//         <div className="mt-3 pb-5">
//           <div className="row">
//             <div className="col-lg-10 col-sm-12">
//               <nav className="d-flex float-right">
//                 <ul className="pagination justify-content-center">
//                   <li
//                     className={` page-item ${currentPage === 1 ? "disabled" : ""
//                       }`}
//                   >
//                     <button className="page-link" onClick={goToPrevPage}>
//                       Previous
//                     </button>
//                   </li>
//                   {Array.from({ length: totalPages }, (_, i) => (
//                     <li
//                       key={i}
//                       className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
//                         }`}
//                     >
//                       <button
//                         className="page-link "
//                         onClick={() => paginate(i + 1)}
//                       >
//                         {i + 1}
//                       </button>
//                     </li>
//                   ))}
//                   <li
//                     className={`page-item ${currentPage === totalPages ? "disabled" : ""
//                       }`}
//                   >
//                     <button className="page-link " onClick={goToNextPage}>
//                       Next
//                     </button>
//                   </li>
//                 </ul>
//               </nav>{" "}
//             </div>
//             <div className="col-lg-2 col-sm-12 mt-1">
//               <div className="float-end">
//                 <span className="text-dark ">
//                   Page {currentPage} of {totalPages}
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>


//       </div>


//     </>



//   )
// }

// export default Studentdairy
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../../environment/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Studentdairy() {
  const [responseData, setResponseData] = useState([]);
  const [student_dairy_overall, setStudent_dairy_overall] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateShowModal, setUpdateShowModal] = useState(false);
  const [responseDatas, setResponseDatas] = useState([]);
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [branch, setbranch] = useState([]);
  const [courseinfo, setcourseinfo] = useState([]);
  const [filtercourse, setcourses] = useState([]);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convert input to Date if it's not already
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [addData, setAddData] = useState({
    branch_id: "",
    student: "",
    roll_no: "",
    course: "",
    date: formatDate(new Date()),
    login: "",
    logout: "",
    topic_covered: "",
    subtopic: "",
    notes: "",
    studyingstudent: "",
    course_id: "",
  });
  // console.log("student:", addData);
  const [updateData, setUpdateData] = useState({
    branch_id: "",
    student: "",
    roll_no: "",
    course: "",
    date: "",
    login: "",
    logout: "",
    topic_covered: "",
    subtopic: "",
    notes: "",
  });

  const handleDateChange = (e) => {
    setAddData((prevData) => ({
      ...prevData,
      date: e.target.value, // Input type=date handles the format
    }));
  };

  const [rolename, setrolename] = useState([]);

  useEffect(() => {
    fetchData();
    const rolename = localStorage.getItem("rolename");
    setrolename(rolename);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/student_dairy", {
        params: { data: localStorage.getItem("branch_id") },
      });

      const responseovelldata = await axios.get(
        apiUrl + "/student_dairy_overall",
        {
          params: { data: localStorage.getItem("branch_id") },
        }
      );

      console.log("Response from server:", response.data);
      console.log(
        "Response from server responseovelldata:",
        responseovelldata.data
      );
      setResponseData(response.data.reverse());
      setStudent_dairy_overall(responseovelldata.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchDataed();
    gettoken();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/staff");
      console.log("Response from:", response.data);
      setResponseDatas(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      console.log("The role id is : ", response.data.detail.rolename);
      setroleid(response.data.detail.rolename);
      console.log("the role_id is : ", role_id);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      // getpages();

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);
          console.log("the reponse list in brach is : ", response.data.modules);
          const modules = response.data.modules;
          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );
          console.log("the modules is : ", filtered_access[0].actions);
          setprevillages(filtered_access[0].actions);
          console.log("the assigned previllages is : ", previllages.add);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [getCoursesAll, setCourseAll] = useState([]);
  const [getRollAll, setRollAll] = useState([]);
  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      const responsecourse = await axios.get(apiUrl + "/course");
      const responseRole = await axios.get(apiUrl + "/student");
      const courseinfo = await axios.get(apiUrl + "/courseinfo");
      console.log("Response from server:", response.data);
      console.log("Response from server:courseinfo", courseinfo);
      setCourseAll(responsecourse.data);
      setRollAll(responseRole.data);
      setbranch(response.data);
      setcourseinfo(courseinfo.data);
      console.log("course dropdown", courseinfo.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleAddSubmit = async (e) => {
    console.log("SUbmit");
    e.preventDefault();
    if (!validateFields()) {
      console.log("validation fail");
      return;
    }
    try {
      const formData = {
        branch_id: addData.branch_id,
        student: addData.student,
        roll_no: addData.roll_no,
        course: addData.course,
        date: addData.date,
        login: addData.login,
        logout: addData.logout,
        topic_covered: topics,
        subtopic: addData.subtopic,
        notes: addData.notes,
        course_id: addData.course_id,
        studyingstudent: addData.studyingstudent,
      };
      console.log("addData:", formData);

      console.log(formData, "Submitting form data");

      const response = await axios.post(apiUrl + "/student_dairy", formData);
      console.log("Added:", response);

      setShowModal(false);
      fetchData(); // Assuming this refreshes the data to show the newly added student entry
      toast.success("Added successfully!");
      setAddData({
        branch_id: "",
        student: "",
        roll_no: "",
        course: "",
        date: formatDate(new Date()), // Ensuring date resets to current date
        login: "",
        logout: "",
        topic_covered: "",
        subtopic: "",
        notes: "",
      });
    } catch (error) {
      console.error("Error adding data:", error.message);
      toast.error("Failed to add student diary entry."); // Show error notification
    }
  };

  const railwayToNormalTime = (railwayTime) => {
    const [hour, minute] = railwayTime.split(":");
    const parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);
    if (parsedHour < 12) {
      return `${parsedHour}:${minute} AM`;
    } else if (parsedHour === 12) {
      return `${parsedHour}:${minute} PM`;
    } else {
      return `${parsedHour - 12}:${minute} PM`;
    }
  };

  const calculateWorkHours = (login, logout, lunch_out, lunch_in) => {
    const loginTime = new Date(`1970-01-01T${login}:00`);
    const logoutTime = new Date(`1970-01-01T${logout}:00`);
    const lunchoutTime = new Date(`1970-01-01T${lunch_out}:00`);
    const lunchinTime = new Date(`1970-01-01T${lunch_in}:00`);

    const totalWorkTime = logoutTime - loginTime - (lunchinTime - lunchoutTime);
    const hours = Math.floor(totalWorkTime / (1000 * 60 * 60));
    const minutes = Math.floor(
      (totalWorkTime % (1000 * 60 * 60)) / (1000 * 60)
    );
    console.log(
      "the return value",
      loginTime,
      logoutTime,
      lunchoutTime,
      lunchinTime
    );
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const [updateId, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log("The data to update is", data);

      console.log("The data to update is121", data.branch_id.branch_name);
      console.log("The data to update is", data.branch_id[0]?._id);
      setUpdateShowModal(true);
      setUpdateData({
        branch_id: data.branch_id,
        student: data.student,
        course: data.course,
        roll_no: data.roll_no,
        date: data.date,
        login: data.login,
        logout: data.logout,
        topic_covered: data.topic_covered,
        subtopic: data.subtopic,
        notes: data.notes,
      });
      console.log("The data to update is updateData", updateData);
    } else {
      toast.error("You don't have the permission to update");
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        branch_id: updateData.branch_id[0]?._id,
        student: updateData.student,
        course: updateData.course,
        roll_no: updateData.roll_no,
        date: updateData.date,
        login: updateData.login,
        logout: updateData.logout,
        topic_covered: updateData.topic_covered,
        subtopic: updateData.subtopic,
        notes: updateData.notes,
      };
      console.log("The update submit data111", formData);
      const response = await axios.put(
        apiUrl + `/student_dairy/${updateId}`,
        formData
      );
      console.log("Response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        apiUrl + `/student_dairy/${getdeleteid}`
      );
      console.log("Deleted data", response);
      toast.error("Deleted successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, student) => {
    if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", student);
      setdeleteid(_id);
      setdeletename(student);
      setDeleteShowModals(true);
    } else {
      toast.error("You don't have the permission to delete");
    }
  };

  // Pagination and search logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  // console.log("getRollAll---===", getRollAll);
  const [ddTopics, setDdtopics] = useState([]);

  const handleCourseChange = (e) => {
    const selectedRollNo = e.target.value;
    const filteredCourses = courseinfo.filter(
      (item) => item.course_name == selectedRollNo
    );
    setDdtopics(filteredCourses);

    // Update topics and subtopics based on the course selection
    const courseDetails = courseinfo.find(
      (item) => item.course_name === selectedRollNo
    );
    setTopics(courseDetails.topics); // Set topics based on the selected course
    setSubTopics(courseDetails.subTopics); // Set subtopics based on the selected course

    setAddData({ ...addData, course: e.target.value });
  };

  const [topics, setTopics] = useState([]);
  const [subtopics, setsubtopic] = useState([]);
  const [subTopics, setSubTopics] = useState([]);

  const handelRole = (e) => {
    const selectedRollNo = e.target.value;
    console.log("handelRole---", handelRole);

    const selectedStudent = getRollAll.find(
      (item) => item.roll_no == selectedRollNo
    );

    const topicsfilter = selectedStudent.Prefered_course_lead;
    const topic = courseinfo.find((item) => item.course_name == topicsfilter);
    setTopics(topic.topics);

    setsubtopic(topic.subtopic);

    const subtopicsfilter = selectedStudent.Prefered_course_lead;
    const subtopic = courseinfo.find(
      (item) => item.course_name == subtopicsfilter
    );
    setSubTopics(subtopic.subTopics);

    const studentName = selectedStudent?.student_name_lead || null;
    const studentcourse = selectedStudent?.Prefered_course_lead || null;
    const studentbranchid = selectedStudent?.branch_lead[0]?._id || null;
    const studyingstudent = selectedStudent?._id || null;
    const course_id = selectedStudent?.course_id || null;

    const courseinfoss = courseinfo.find(
      (item) => item.course_name == studentcourse
    );
    console.log("handelRole courseinfoss---", courseinfoss);
    const filteredCourses = courseinfo.filter(
      (item) => item.course_name == courseinfoss.course_name
    );
    console.log(
      "handelRole filteredCourses---",
      filteredCourses,
      courseinfoss.course_name
    );

    setDdtopics(filteredCourses);
    setcourses(courseinfoss);
    setAddData({
      ...addData,
      roll_no: selectedRollNo, // Set the selected roll number
      student: studentName, // Safely set the student name
      course: studentcourse,
      studyingstudent: studyingstudent,
      course_id: course_id,
      branch_id: studentbranchid,
    });
  };

  const handleDateChangee = (e) => {
    setUpdateData({ ...updateData, date: e.target.value });
  };

  const handlesubtopic = (e) => {
    setsubtopic(e.target.value);
  };
  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  function AllDataHandale() {
    setResponseData(student_dairy_overall);
    setStudent_dairy_overall(responseData);
  }
  const navigate = useNavigate();

  const updateinfo = (id) => {
    navigate(`/StudentInfo/${id}`);
  };

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.branch_id) {
      tempErrors.branch_id = "Branch is required.";
      console.log("branch_id is error");
      isValid = false;
    }

    if (!addData.roll_no) {
      tempErrors.roll_no = "StudentName is required.";
      console.log("roll_no is error");
      isValid = false;
    }

    if (!addData.login) {
      tempErrors.login = "Login is required.";
      console.log("login is error");
      isValid = false;
    }

    if (!addData.notes) {
      tempErrors.notes = "Notes is required.";
      console.log("notes is error");
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard / </b>
              </Link>
              <Link to="" className="text-danger dashBoard_text">
                <b>Student Dairy</b>
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9">
            <div className="d-flex">
              <h5 className="mt-2"> Student Dairy </h5>
              {/* <div className='mx-3'><button className='btn  add' onClick={handleNewButtonClick}>Add </button></div> */}
              {previllages.add && (
                <button
                  className="btn btn-success add mx-3"
                  onClick={() => setShowModal(true)}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="col-lg-1">
            <div className="">
              <button className="btn  btn-danger" onClick={AllDataHandale}>
                Overall
              </button>
            </div>
          </div>

          <div className="col-lg-2">
            <div>
              <input
                type="file"
                id="file-upload"
                accept=".xls,.xlsx"
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload" className="btn btn-secondary">
                File Upload
              </label>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white appearance-none pl-8"
                      placeholder="Search ..."
                      type="search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  {rolename === "superadmin" && <th>Branch</th>}
                  <th>Student </th>
                  <th>Course </th>

                  <th>Date</th>
                  <th>Log In</th>
                  <th>Log Out</th>
                  <th>Topic Covered </th>
                  <th className="text-center" id="tablebutton">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>

                    {rolename === "superadmin" && (
                      <td>{item.branch_id?.branch_name}</td>
                    )}
                    <td>{item.student}</td>
                    <td>{item.course}</td>

                    <td>{formatDatefitching(item.date)}</td>
                    <td>{railwayToNormalTime(item.login || "")}</td>

                    <td>{railwayToNormalTime(item.logout || "")}</td>
                    <td>{item.topic_covered}</td>

                    <td className="text-center">
                      {/* <button className="btn updelete mx-1" onClick={() => handleUpdate(item._id, item)}>
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>

                      <button className='btn delete ' onClick={() => handleOpenModal(item._id, item.student)}>
                        <i className="fa-solid fa-trash-can"></i>
                      </button> */}
                      {previllages.edit && (
                        <button
                          className="btn updelete mx-1"
                          onClick={() => handleUpdate(item._id, item)}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      )}
                      {previllages.delete && (
                        <button
                          lassName="btn delete "
                          onClick={() =>
                            handleOpenModal(item._id, item.student)
                          }
                        >
                          <i className="fa-solid fa-trash-can"></i>
                        </button>
                      )}

                      <button
                        className="btn "
                        onClick={() => updateinfo(item.studyingstudent)}
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          className="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Student Dairy </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <select
                        className="form-select"
                        value={addData.roll_no}
                        onChange={(e) => handelRole(e)}
                      >
                        {getRollAll.map((item, index) => (
                          <option key={index} value={item.roll_no}>
                            {item.roll_no} - {item.student_name_lead}
                          </option>
                        ))}
                      </select>
                      <label className={addData.roll_no ? "filled" : ""}>
                        Select Student
                      </label>
                      {errors.roll_no && (
                        <div className="error-text">{errors.roll_no}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <select
                        className="form-select"
                        value={addData.branch_id}
                        onChange={(e) =>
                          setAddData({ ...addData, branch_id: e.target.value })
                        }
                      >
                        <option>Select Branch</option>
                        {branch.map((item, index) => (
                          <option key={index} value={item._id}>
                            {item.branch_id} - {item.branch_name}
                          </option>
                        ))}
                      </select>
                      <label className={addData.branch_id ? "filled" : ""}>
                        Select Branch
                      </label>
                      {errors.branch_id && (
                        <div className="error-text">{errors.branch_id}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <select
                        className="form-select"
                        value={addData.course}
                        onChange={handleCourseChange}
                      >
                        <option> Select Course</option>
                        {getCoursesAll.map((item, index) => (
                          <option key={index} value={item.course_name}>
                            {item.course_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="floating-label">
                      <input
                        className="form-control"
                        type="date"
                        placeholder=""
                        value={addData.date}
                        onChange={handleDateChange}
                        required
                      />
                      <label className={addData.date ? "filled" : ""}>
                        Date
                      </label>
                      {errors.date && (
                        <div className="error-text">{errors.date}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        type="time"
                        className="form-control"
                        placeholder=""
                        value={addData.login}
                        onChange={(e) =>
                          setAddData({ ...addData, login: e.target.value })
                        }
                      />
                      <label className={addData.login ? "filled" : ""}>
                        Log In
                      </label>
                      {errors.login && (
                        <div className="error-text">{errors.login}</div>
                      )}
                    </div>

                    <div className="floating-label">
                      <input
                        type="time"
                        className="form-control"
                        placeholder=""
                        value={addData.logout}
                        onChange={(e) =>
                          setAddData({ ...addData, logout: e.target.value })
                        }
                        disabled
                      />
                      <label className={addData.logout ? "filled" : ""}>
                        Logout
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={topics}
                      onChange={(e) =>
                        setAddData({
                          ...addData,
                          topic_covered: e.target.value,
                        })
                      }
                    >
                      <option> Select Topic</option>
                      {ddTopics.map((item, index) => (
                        <option key={index} value={topics}>
                          {item.topics}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="floating-label">
                    <select
                      className="form-select"
                      value={subtopics} // Set to the selected subtopic
                      // onChange={(e) =>
                      //   setAddData({
                      //     ...addData,
                      //     subtopic: e.target.value,
                      //   })
                      // }
                      onChange={handlesubtopic}
                    >
                      <option> Select SubTopic</option>
                      {ddTopics.map((item, index) => (
                        <option key={index} value={item.subTopics}>
                          {" "}
                          {item.subtopic}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={addData.notes}
                      onChange={(e) =>
                        setAddData({ ...addData, notes: e.target.value })
                      }
                    />
                    <label className={addData.notes ? "filled" : ""}>
                      Notes
                    </label>
                    {errors.notes && (
                      <div className="error-text">{errors.notes}</div>
                    )}
                  </div>
                </div>
              </div>

              {/* 
              <p>{topics}</p> */}

              <div className="modalbutton">
                <Button
                  variant="secondary"
                  className="mt-3 cancelbut"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </Button>
                <button
                  type="submit"
                  className="btn btn-primary mt-3 mx-2 submitbut"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          className="custom-modal"
          show={isUpdateShowModal}
          onHide={() => setUpdateShowModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Student Diary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleUpdateSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="floating-label">
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      placeholder=""
                      value={updateData.student}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          student: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.student ? "filled" : ""}>
                      Student
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      className="form-control"
                      disabled
                      type="text"
                      placeholder=""
                      value={updateData.branch_id.branch_name}
                      required
                    />
                    <label className={updateData.branch_id ? "filled" : ""}>
                      Branch
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled
                      value={updateData.roll_no}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          roll_no: e.target.value,
                        })
                      }
                      required
                    />
                    <label className={updateData.roll_no ? "filled" : ""}>
                      Roll No
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={updateData.course}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, course: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.course ? "filled" : ""}>
                      Course
                    </label>
                  </div>

                  <div className="floating-label">
                    {/* <select
                      className="form-select"
                      value={updateData.topic_covered}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          topic_covered: e.target.value,
                        })
                      }
                    >
                      <option>Select Topic</option>
                      {ddTopics.map((item, index) => (
                        <option key={index} value={item.topics}>
                          {item.topics}
                        </option>
                      ))}
                    </select> */}

                    <select
                      className="form-select"
                      value={updateData.topic_covered}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          topic_covered: e.target.value,
                        })
                      }
                    >
                      <option>Select Topic</option>
                      {ddTopics.length > 0 ? (
                        ddTopics.map((item, index) => (
                          <option key={index} value={item.topics}>
                            {item.topics}
                          </option>
                        ))
                      ) : (
                        <option disabled>No topics available</option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="floating-label">
                    {/* <select
                      className="form-select"
                      value={subTopics} // Set to the selected subtopic
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          subtopic: e.target.value,
                        })
                      }
                    >
                      <option> Select SubTopic</option>
                      {ddTopics.map((item, index) => (
                        <option key={index} value={item.subTopics}>
                          {" "}
                          
                          {item.subtopic}
                        </option>
                      ))}
                    </select> */}

                    <select
                      className="form-select"
                      value={updateData.subtopic}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          subtopic: e.target.value,
                        })
                      }
                    >
                      <option>Select SubTopic</option>
                      {ddTopics
                        .filter(
                          (item) => item.topics === updateData.topic_covered
                        ) // Filter subtopics based on the selected topic
                        .map((item, index) => (
                          <option key={index} value={item.subtopic}>
                            {item.subtopic}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="floating-label">
                    <input
                      type="date"
                      className="form-control"
                      placeholder=""
                      value={formatDatefitching(updateData.date)}
                      onChange={handleDateChangee}
                      required
                    />
                    <label
                      className={
                        formatDatefitching(updateData.date) ? "filled" : ""
                      }
                    >
                      Date
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="time"
                      className="form-control"
                      placeholder=""
                      value={updateData.login}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, login: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.login ? "filled" : ""}>
                      Log In
                    </label>
                  </div>

                  <div className="floating-label">
                    <input
                      type="time"
                      className="form-control"
                      placeholder=""
                      value={updateData.logout}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, logout: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.logout ? "filled" : ""}>
                      Log Out
                    </label>
                  </div>
                  <div className="floating-label">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={updateData.notes}
                      onChange={(e) =>
                        setUpdateData({ ...updateData, notes: e.target.value })
                      }
                      required
                    />
                    <label className={updateData.notes ? "filled" : ""}>
                      notes
                    </label>
                  </div>
                </div>
              </div>

              <div className="modalbutton">
                <Button
                  variant="secondary"
                  className="mt-3 cancelbut mx-2"
                  onClick={() => setUpdateShowModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="btn btn-primary mt-3 submitbut"
                >
                  Update
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          className="modal"
          show={deleteShowModals}
          onHide={handleCloseModal}
        >
          <Modal.Header>
            <p>Are you sure you want to delete?</p>
          </Modal.Header>
          <Modal.Body>
            <label className="form-label d-flex">
              <h5 className="mx-1">Student :</h5> <h5>{getdeletename} </h5>
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="cancelbut"
              variant="secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="submitbut"
              variant="primary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="mt-3 pb-5">
          <div className="row">
            <div className="col-lg-10 col-sm-12">
              <nav className="d-flex float-right">
                <ul className="pagination justify-content-center">
                  <li
                    className={` page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={goToPrevPage}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li
                      key={i}
                      className={`mx-2 page-item ${
                        currentPage === i + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link "
                        onClick={() => paginate(i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link " onClick={goToNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>{" "}
            </div>
            <div className="col-lg-2 col-sm-12 mt-1">
              <div className="float-end">
                <span className="text-dark ">
                  Page {currentPage} of {totalPages}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Studentdairy;
