import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


function Walkin() {
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState([]);
  const [showCallbackDate, setShowCallbackDate] = useState(false);
  const [Updateshow, setUpdateShow] = useState(false);

  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  useEffect(() => {
    fetchData();
    gettoken();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function fetchData() {
    const resposponseData = await axios.get(apiUrl + "/walkinleads", {
      params: { data: localStorage.getItem("branch_id") },
    });
    setResponseData(resposponseData.data.reverse());
  }

  
  const gettoken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      setroleid(response.data.detail.rolename);
      localStorage.setItem("role_id", response.data.detail.rolename);
      const id = response.data.detail.rolename;
      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);
          const modules = response.data.modules;
          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );
          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };



  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };


  const [startTime, setStartTime] = useState(null); // To store the start time
  const [endTime, setEndTime] = useState(null); 
  const [UpdatedData, setUpdatedData] = useState([]);
  const [duraton, setDuraton] = useState(null); 
  
  const handleUpdated = async (_id, data) => {
    if (previllages.edit || previllages.all) {
      console.log("this", _id);
      const filterUpdateData = await axios.get(apiUrl + `/leads/${_id}`);
      console.log(filterUpdateData.data?.[0], "filterUpdateData___999");
      setUpdatedData(filterUpdateData.data?.[0]);
      const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

      // Set the formatted time to state
      setStartTime(currentTime);
      setUpdateShow(true);
    } else {
      toast.error("You don't have the permission to update");
    }
  };

  const handleupdatesubmit = async (id) => {


    const endTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

    setEndTime(endTime);
  
    // Convert 12-hour time (e.g., "11:32 AM") to 24-hour format (e.g., "11:32")
    const convertTo24Hour = (time) => {
      const [timeString, period] = time.split(" ");
      let [hours, minutes] = timeString.split(":").map(num => parseInt(num));
      
      if (period === "PM" && hours !== 12) {
        hours += 12; // Convert PM times to 24-hour format, except for 12 PM which stays the same
      }
      if (period === "AM" && hours === 12) {
        hours = 0; // 12 AM should be 00 in 24-hour format
      }
      
      // Format to "HH:mm"
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };
  
    // Convert startTime and endTime to 24-hour format
    const startTime24 = convertTo24Hour(startTime);
    const endTime24 = convertTo24Hour(endTime);
  
    console.log("this is my start time", startTime24);
    console.log("this is my endTime time", endTime24);
  
    // Create date objects using 24-hour time format
    const startDate = new Date(`1970-01-01T${startTime24}:00`);
    const endDate = new Date(`1970-01-01T${endTime24}:00`);
  
    // Check if dates are valid
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error("Invalid date format");
      return;
    }
  
    // Handle time difference calculation when endTime is earlier than startTime (i.e., crossing midnight)
    let timeDifference = endDate - startDate;
    if (timeDifference < 0) {
      // If the difference is negative, it means endTime is on the next day
      endDate.setDate(endDate.getDate() + 1);
      timeDifference = endDate - startDate;
    }
  
    // Convert the time difference from milliseconds to minutes
    const timeDifferenceInMinutes = Math.floor(timeDifference / 1000 / 60);
  
    console.log(`Time Difference: ${timeDifferenceInMinutes} minutes`);

    setDuraton(timeDifferenceInMinutes)

    try {
      let updateDatas;

      // Check if the followups_status is "converted"
      if (UpdatedData.followups_status === "converted") {
        console.log("hiiii");

        // Update the state and prepare updateDatas in one step
        const updatedData = {
          ...UpdatedData,
          followups_status: "Qualified",
          followups_date: new Date(),
          duration_time: duraton // Correctly get the current date
        };

        // Create updateDatas with the updated state
        updateDatas = {
          branch_id: updatedData.branch_id,
          student_name: updatedData.student_name,
          mobile_no: updatedData.mobile_no,
          email: updatedData.email,
          Source_type: updatedData.Source_type,
          referal_candidate: updatedData.referal_candidate || "",
          referal_candidate_name: updatedData.referal_candidate_name || "",
          Enquiry_date: updatedData.Enquiry_date,
          Prefered_course: updatedData.Prefered_course,
          followups_date: updatedData.followups_date,
          status_time: updatedData.status_time,
          followups_status: updatedData.followups_status,
          followups_notes: updatedData.followups_notes,
          duration_time:duraton
        };

        // Now you can use updateDatas as needed
        
        setUpdatedData(updateDatas); // Update the state

        if (UpdatedData.followups_status === "converted") {
          // navigate(`/Registration/${id}`);
          navigate(`/Registration/${id}`, { state: { updatedData: UpdatedData } });
        }     }
       else {
       ///////
try {
        console.log("The console update data as is : ", updateDatas, id);
        const response = await axios.put(apiUrl + `/leads/${id}`, UpdatedData);
        console.log("the response : ", response);

        const student_Id = response.data._id;
        console.log("the history update post", student_Id);
        const formDataaa = {
          student_Id: student_Id,
          Source_type: "",
          Enquiry_date: "",
          contact_type: UpdatedData.contact_type,
          assign_staff: UpdatedData.assign_staff,
          notes: UpdatedData.followups_notes,
          status: UpdatedData.followups_status,
          duration_time:duraton
        };

        if (response.status === 200) {
          const response12 = await axios.post(
            apiUrl + "/leadhistroy",
            formDataaa
          );
          console.log("the history follow-up", response12);

          fetchData();
          toast.success("Converted successfully!");
          setUpdateShow(false);
          // if (UpdatedData.followups_status === "converted") {
          //   // navigate(`/Registration/${id}`);
          //   navigate(`/Registration/${id}`, { state: { updatedData: UpdatedData } });
          // }
        } else {
          toast.error("Failed to convert!");
        }
      


}
catch{

}





      //  ///////
      }
 
      console.log("checking data is fine",UpdatedData);
      console.log(id, "idddss");

      // try {
      //   console.log("The console update data as is : ", updateDatas, id);
      //   const response = await axios.put(apiUrl + `/leads/${id}`, UpdatedData);
      //   console.log("the response : ", response);

      //   const student_Id = response.data._id;
      //   console.log("the history update post", student_Id);
      //   const formDataaa = {
      //     student_Id: student_Id,
      //     Source_type: "",
      //     Enquiry_date: "",
      //     contact_type: UpdatedData.contact_type,
      //     assign_staff: UpdatedData.assign_staff,
      //     notes: UpdatedData.followups_notes,
      //     status: UpdatedData.followups_status,
      //     duration_time:duraton
      //   };

      //   if (response.status === 200) {
      //     const response12 = await axios.post(
      //       apiUrl + "/leadhistroy",
      //       formDataaa
      //     );
      //     console.log("the history follow-up", response12);

      //     fetchData();
      //     toast.success("Converted successfully!");
      //     setUpdateShow(false);
      //     if (UpdatedData.followups_status === "converted") {
      //       // navigate(`/Registration/${id}`);
      //       navigate(`/Registration/${id}`, { state: { updatedData: UpdatedData } });
      //     }
      //   } else {
      //     toast.error("Failed to convert!");
      //   }
      // } 


   


      // catch (error) {
      //   toast.error("Error in conversion!");
      //   console.log("Error fetching data:", error.message);
      // }
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };

  function handalefollowupsStatus(e) {
    setUpdatedData({ ...UpdatedData, followups_status: e.target.value });
  }

  function interstedHandel(e) {
    console.log(e.target.value);

    setUpdatedData((prevData) => ({
      ...prevData,
      interest: e.target.value,
    }));
  }

  const getStatusLabelClass = (status) => {
    switch (status) {
      case "Unqualified":
        return "bg-danger1 text-white1";
      case "Reachable":
        return "bg-secondary1 text-white1";
      case "converted":
        return "bg-warning1 text-black1"; // Assuming black text for "converted"
      case "Rescheduled":
        return "bg-primary1 text-white1";
      case "Qualified":
        return "bg-success1 text-white1";
      case "NotReachable":
        return "bg-dark1 text-white1";
      case "Scheduled":
        return "bg-info1 text-white1";
      case "New":
        return "bg-dark1 text-white1";
      case "Walk-in-reschedule":
        return "bg-dark1 text-white1";
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="top">
      <div className="" style={{marginTop: '-80px'}}>
                    <div className="row mb-3">
                        <div className="col-lg-11">
                            <Link to="/dashboard" className="text-secondary dashBoard_text">
                                <b>Dashboard / </b>
                            </Link>
                            <Link to="" className="text-danger dashBoard_text">
                                <b>Walkin</b>
                            </Link>
                            {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
                        </div>
                    </div>
                </div>
        <div className="row">
          <div className="col-lg-9 d-flex">
            <h5 className="mt-2">Walkin </h5>
          </div>
        </div>

        {/* /////////////////////////////////////////////////////////////////// */}

        <div className="mb-3 mt-3">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
              <label className="form-label text-dark mt-2">Show : </label>
              <div>
                <select
                  className="form-control w-100 mx-2"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                  }}
                >
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
            <div className="col-lg-7 col-md-4 col-sm-1"></div>
            <div className="col-lg-3 col-md-4 col-sm-7">
              <div className="w-full flex-1">
                <form>
                  <div className="relative">
                    <input
                      className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                      placeholder="Search ..."
                      type="search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* //////////////////////////////////////////////////////////////////////////////////// */}

        <div className="card">
          <div className="mt-1">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Mobile No</th>
                  <th>Course</th>
                  <th>Source Type</th>
                  <th>Notes </th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.student_name}</td>
                    <td>{item.mobile_no}</td>
                    <td>{item.Prefered_course}</td>
                    <td>{item.Source_type}</td>
                    <td>{item.followups_notes}</td>
               
                 

                    <td>
                      <p
                        className={`text-center ${getStatusLabelClass(
                          item.followups_status
                        )}`}
                      >
                        {item.followups_status}
                      </p>
                    </td>

                    {/* <td>
                                            <p
                                                className={`text-center text-white ${{
                                                    Unqualified: "bg-danger",
                                                    Reachable: "bg-secondary",
                                                    converted: "bg-warning",
                                                    Rescheduled: "bg-primary",
                                                    Qualified: "bg-success",
                                                    NotReachable: "bg-danger",
                                                    Scheduled: "bg-info",
                                                    "Walk-in-reschedule": "bg-dark",
                                                }[item.followups_status] || ""
                                                    }`}
                                            >
                                                {item.followups_status}
                                            </p>
                                        </td> */}
                    <td className="text-center">
                      {/* <button
                        className="btn updelete "
                        onClick={() => handleUpdated(item._id, item)}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </button> */}
                       {previllages.edit && (
                      <button
                        className="btn btn-primary mx-1"
                        onClick={() => handleUpdated(item._id, item)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    )}

                      {/* <button className='btn btn-info' onClick={handaleRegistration}>
                                                <i class="fa-solid fa-circle-info"></i></button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal show={Updateshow} onHide={() => setUpdateShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> status update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Other input fields */}
          <div className="row">
            <div className="col-lg-12 ">
              <div className=" p-2 pb-0">
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          disabled
                          value={UpdatedData.student_name}
                          onChange={(e) =>
                            setUpdatedData({
                              ...UpdatedData,
                              student_name: e.target.value,
                            })
                          }
                          required
                        />
                        <label
                          className={UpdatedData.student_name ? "filled" : ""}
                        >
                          Name
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="tel"
                          placeholder=""
                          value={UpdatedData.mobile_no}
                          disabled
                          onChange={(e) =>
                            setUpdatedData({
                              ...UpdatedData,
                              mobile_no: e.target.value,
                            })
                          }
                          required
                        />
                        <label
                          className={UpdatedData.mobile_no ? "filled" : ""}
                        >
                          Mobile No
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="tel"
                          placeholder=""
                          value={UpdatedData.Prefered_course}
                          disabled
                          onChange={(e) =>
                            setUpdatedData({
                              ...UpdatedData,
                              Prefered_course: e.target.value,
                            })
                          }
                          required
                        />
                        <label
                          className={
                            UpdatedData.Prefered_course ? "filled" : ""
                          }
                        >
                          Prefered Course
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="floating-label">
                        <input
                          className="form-control"
                          type="tel"
                          placeholder=""
                          value={UpdatedData.Source_type}
                          disabled
                          onChange={(e) =>
                            setUpdatedData({
                              ...UpdatedData,
                              Source_type: e.target.value,
                            })
                          }
                          required
                        />
                        <label
                          className={UpdatedData.Source_type ? "filled" : ""}
                        >
                          Source_type
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="floating-label">
                        <select
                          className="form-control"
                          value={UpdatedData.followups_status || ""}
                          onChange={(e) => handalefollowupsStatus(e)}
                          required
                        >
                          <option value="">Select Followups Status</option>{" "}
                          {/* Default option */}
                          <option value="Rescheduled">Rescheduled</option>
                          <option value="Qualified">Qualified</option>
                          <option value="converted">Converted</option>
                          <option value="NotReachable">Not Reachable</option>
                        </select>
                        <label
                          className={
                            UpdatedData.followups_status ? "filled" : ""
                          }
                        >
                          Followups Status
                        </label>
                      </div>
                    </div>

                    <div
                      className={`${
                        UpdatedData.followups_status == "Rescheduled"
                          ? "row"
                          : "d-none"
                      }`}
                    >
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            placeholder=""
                            value={
                              UpdatedData.status_date
                                ? formatDatefitching(UpdatedData.status_date)
                                : ""
                            }
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                status_date: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={UpdatedData.status_date ? "filled" : ""}
                          >
                            Reschudule Date
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="time"
                            placeholder=""
                            value={UpdatedData.status_time}
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                status_time: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={UpdatedData.status_time ? "filled" : ""}
                          >
                            Reschudule Time
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        UpdatedData.followups_status == "Qualified" 
                          ? "row"
                          : "d-none"
                      }`}
                    >
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="date"
                            placeholder=""
                            value={
                              UpdatedData.followups_date
                                ? formatDatefitching(UpdatedData.followups_date)
                                : ""
                            }
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                followups_date: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={
                              UpdatedData.followups_date ? "filled" : ""
                            }
                          >
                            Visit Date
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="floating-label">
                          <input
                            className="form-control"
                            type="time"
                            placeholder=""
                            value={UpdatedData.status_time}
                            onChange={(e) =>
                              setUpdatedData({
                                ...UpdatedData,
                                status_time: e.target.value,
                              })
                            }
                            required
                          />
                          <label
                            className={UpdatedData.status_time ? "filled" : ""}
                          >
                            Visit Time
                          </label>
                        </div>
                      </div>
                    </div>

                    <div

                      className={

                        UpdatedData.followups_status === "Qualified" ||
                        UpdatedData.followups_status === "Rescheduled"
                          ? "row"
                          : "d-none"
                      }
                    >
                      <div className="floating-label">
                        <textarea
                          className="form-control"
                          value={UpdatedData.followups_notes || ""}
                          onChange={(e) =>
                            setUpdatedData({
                              ...UpdatedData,
                              followups_notes: e.target.value,
                            })
                          }
                          required
                        ></textarea>
                        <label
                          className={
                            UpdatedData.followups_notes ? "filled" : ""
                          }
                        >
                          Followups Notes
                        </label>
                      </div>
                    </div>

                    <div>
                      <input
                        type="radio"
                        id="html"
                        classNameame="mx-2"
                        value="Interest"
                        onChange={(e) => interstedHandel(e)}
                      />
                      <label for="html">Interest</label>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>

            
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={() => handleupdatesubmit(UpdatedData._id)}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Walkin;
