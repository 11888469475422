import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../../environment/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Invoice() {
  const [responseData, setResponseData] = useState([]);
  const [category, setCategory] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const [responseDatas, setResponseDatas] = useState([]);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetchDatas();
    gettoken();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/invoice_details");
      console.log("Response from server:", response.data.reverse());
      setResponseData(response.data);
      setFilterData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const gettoken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);

      setroleid(response.data.detail.rolename);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);

          const modules = response.data.modules;

          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );

          setprevillages(filtered_access[0].actions);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
    if (e.target.value === "showall") {
      setFilterData(responseData);
    } else {
      const filteredData = responseData.filter(
        (data) => e.target.value === data.type
      );
      setFilterData(filteredData);
    }
  };

  // const handleNewButtonClick = () => {
  //   if (previllages.add || previllages.all) {
  //     navigate("/Addinvoice");
  //   } else {
  //     toast.error("You don't have the permission to add");
  //   }
  // };

  // const handleNewButton = (id) => {
  //   if (previllages.edit || previllages.all) {
  //     navigate(`/print/${id}`);
  //   } else {
  //     toast.error("You don't have the permission to update");
  //   }
  // };
  const handleNewButton = (invoice_no) => {
   
    navigate(`/Studentinvoice/${invoice_no}`);
  };

  const handleButtonClick = (id) => {
    if (previllages.edit || previllages.all) {
      navigate(`/Updateinvoice/${id}`);
    } else {
      toast.error("You don't have the permission to update");
    }
  };

  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (studyingstudent, _id, type) => {
    if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the name", type);
      setdeleteid(studyingstudent);
      setdeletename(type);
      setDeleteShowModals(true);
    } else {
      toast.error("You don't have the permission to delete");
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log("Response from delete:", getdeleteid);
      // const response = await axios.delete(apiUrl + `/invoice_details/${getdeleteid}`);
      const responsepaymenthistroy = await axios.delete(
        apiUrl + `/delete_overall_invoice/${getdeleteid}`
      );
      console.log("Response from delete:", responsepaymenthistroy);
      setDeleteShowModals(false);
      fetchDatas();
    } catch (error) {
      console.error("Error deleting invoice:", error.message);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = filterData.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Invoice</b>
            </Link>
            {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
          </div>
        </div>
      </div>
      <div className="d-flex">
        <h5 className="mt-2">Invoice</h5>
        <div className="mx-3">


          {previllages.add && (
            <button
              className="btn  add "
              onClick={() => navigate("/Addinvoice")}
            >
              Add
            </button>
          )}


        </div>
      </div>

      {/* Search and per page */}
      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-1"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-1">
            <select
              value={category}
              onChange={handleCategory}
              className="form-control"
            >
              <option value="showall">Select All</option>
              <option value="course">Course</option>
              {/* <option value="projectname">Projectname</option> */}
      
            </select>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white appearance-none pl-8"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Invoice No</th>
                <th>Branch</th>
                <th>Date</th>
                <th>Course</th>
                {/* <th>Description</th> */}
                <th>Name</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Balance</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {console.log("the current items ", currentItems)}
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.invoice_no}</td>
                  <td>{item.branch_name}</td>
                  <td>{new Date(item.date).toLocaleDateString()}</td>
                  <td>{item.dependent}</td>
                  {/* <td>{item.dependent}</td> */}
                  <td>{item.name}</td>
                  <td>{item.nettotal}</td>
                  <td>{item.paid}</td>
                  <td>{item.balance}</td>
                  <td className="text-center ">
                    {/* <button className='btn btn-info change'><i className="fa-solid fa-circle-info"></i></button> */}



                    {previllages.edit && (
                      <button
                        className="btn updelete"
                        onClick={() =>
                          handleButtonClick(item.studyingstudent, item._id)
                        }
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                    )}

                    {previllages.download && (
                      <button
                        className="btn  Print "
                        onClick={() => handleNewButton(item.invoice_no)}
                      >
                        <i className="fa-solid fa-print"></i>
                      </button>
                    )}

                    {previllages.delete && (
                      <button
                        className="btn delete change"
                        onClick={() =>
                          handleOpenModal(
                            item.studyingstudent,
                            item._id,
                            item.type
                          )
                        }
                      >
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    )}





                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Employee Id:</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button className="page-link" onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
