//   import React, { useRef, useState, useEffect } from "react";
// import "../../assets/css/Dashboard.css";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import apiUrl from "../../environment/Api";
// import Attendance from "./Attendance";
// // Register the necessary components with ChartJS

// import { Chart, registerables } from "chart.js";
// import { Line, Bar, Pie } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
// } from "chart.js";
// import { click } from "@testing-library/user-event/dist/click";

// // Register required components
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement
// );
// function Dashboard() {
//   const [responseData, setResponseData] = useState([]);
//   const [year, setYear] = useState("2024");
//   const [projectChartData, setProjectChartData] = useState(null);
//   const [courseChartData, setCourseChartData] = useState(null);
//   const [ontimeCounts, setOntimeChart] = useState(null);
//   const [newontimeCounts, setnewOntimeChart] = useState(null);
//   const [lateCounts, setLateChart] = useState(null);
//   const [newlateCounts, setnewLateChart] = useState(null);
//   const [convertedCount, setConvertedCount] = useState(0);
//   const [convertedCounts, setConvertedCounts] = useState(0);
//   const [selectedChart, setSelectedChart] = useState("project");
//   const [selectedChartAttendance, setSelectedChartAttendance] =
//     useState("late");
//   const [courseCount, setCourseCount] = useState(0);
//   const [filterCourse, setFilterCourse] = useState([]);
//   const [StudentCountData, setStudentCountData] = useState(0);
//   const [callbackData, setCallbackData] = useState([]);
//   const [callbackDatas, setCallbackDatas] = useState([]);
//   const [complete, setComplete] = useState(0);
//   const [difference, setDifference] = useState([]);
//   const [filteredDataattendance, setFilteredDataattendance] = useState([]);
//   const [branch, setbranch] = useState([]);

//   // projecr  chart///////////////////////////////////////////////////

//   const navigate = useNavigate();

//   const clickincome = () => {
//     navigate("/incomelist");
//   };

//   const clickexpense = () => {
//     navigate("/Expenselist");
//   };

//   const clickedcourses = () => {
//     navigate("/course");
//   };

//   const clickEntroll = () => {
//     navigate("/student");
//   };

//   useEffect(() => {
//     fetchData();
//   }, [year, selectedChart]);

//   const fetchData = async () => {
//     try {
//       console.log("Fetching data for year:", year);
//       const response = await axios.get(apiUrl + "/enquiry", {
//         params: {
//           year: year,
//         },
//       });
//       console.log("Response from server enquiry:", response.data);

//       // Filter data based on the selected year
//       const filteredData = response.data.filter((item) => {
//         const itemYear = new Date(item.startdate).getFullYear();
//         return item.status === "Converted" && itemYear.toString() === year;
//       });

//       const filteredDatas = response.data.filter((item) => {
//         const itemYear = new Date(item.date).getFullYear();
//         return (
//           (item.status === "Callback" || item.status === "Interested") &&
//           itemYear.toString() === year
//         );
//       });
//       const filteredCallbackDatas = response.data.filter((item) => {
//         return item.status === "Callback";
//       });

//       console.log("Filtered data for year:", filteredDatas);

//       setConvertedCount(filteredData.length);
//       setConvertedCounts(filteredDatas.length);
//       setCallbackDatas(filteredCallbackDatas);
//       // Count occurrences for each month
//       const projectCounts = Array.from({ length: 12 }, () => 0);

//       filteredData.forEach((item) => {
//         const monthIndex = new Date(item.startdate).getMonth();
//         projectCounts[monthIndex]++;
//       });

//       console.log("Project Counts for each month:", projectCounts);

//       setProjectChartData({
//         labels: [
//           "Jan",
//           "Feb",
//           "Mar",
//           "Apr",
//           "May",
//           "June",
//           "July",
//           "Aug",
//           "Sep",
//           "Oct",
//           "Nov",
//           "Dec",
//         ],
//         datasets: [
//           {
//             label: "Project Enquiry Count",
//             data: projectCounts,
//             backgroundColor: "rgb(13,110,253)",
//             borderColor: "rgba(0, 0, 0, 0)",
//             borderWidth: 0,
//           },
//         ],
//       });
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   // course chart/////////////////////////////////////////////////////////////////////////
//   useEffect(() => {
//     fetchDatas();
//   }, [year, selectedChart]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const fetchDatas = async () => {
//     try {
//       console.log("Fetching data for year:", year);
//       const response = await axios.get(apiUrl + "/student", {
//         params: {
//           year: year,
//         },
//       });
//       console.log("Response from server:data", response.data);

//       // Filter data based on the selected year
//       const filteredData = response.data.filter((item) => {
//         const itemYear = new Date(item.date).getFullYear();
//         return itemYear.toString() === year;
//       });

//       const filteredStudentData = response.data.filter((item) => {
//         return item.status === "Converted";
//       });
//       const filteredCallbackData = response.data.filter((item) => {
//         return item.status === "Callback";
//       });
//       const filteredCompleteData = response.data.filter((item) => {
//         return item.convertedstatus === "Completed";
//       });
//       console.log("Filtered data for year:", filteredData);
//       console.log("Filtered data for callback:", filteredCallbackData);
//       console.log("Filtered data for convert:", filteredStudentData);
//       console.log("Filtered data for completed:", filteredCompleteData);
//       console.log("count", categoryLength);
//       // Set total count of enrollments
//       setCourseCount(filteredData.length);
//       setFilterCourse(filteredData);
//       setStudentCountData(filteredStudentData.length);
//       setCallbackData(filteredCallbackData);
//       setComplete(filteredCompleteData.length);
//       // Count occurrences for each month
//       const courseCounts = Array.from({ length: 12 }, () => 0);

//       filteredData.forEach((item) => {
//         const monthIndex = new Date(item.date).getMonth();
//         courseCounts[monthIndex]++;
//       });

//       console.log("Course Counts for each month:", courseCounts);

//       setCourseChartData({
//         labels: [
//           "Jan",
//           "Feb",
//           "Mar",
//           "Apr",
//           "May",
//           "June",
//           "July",
//           "Aug",
//           "Sep",
//           "Oct",
//           "Nov",
//           "Dec",
//         ],
//         datasets: [
//           {
//             label: "Course Enquiry Count",
//             data: courseCounts,
//             backgroundColor: "rgb(98, 75, 255)",
//             borderColor: "rgba(0, 0, 0, 0)",
//             borderWidth: 0,
//           },
//         ],
//       });
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   // course count////////////////////////////////////////

//   const uniqueCategory = [
//     ...new Set(
//       filterCourse.map((item) => item.selectcourse.toLowerCase().trim())
//     ),
//   ];
//   const categoryLength = uniqueCategory.map((selectcourse) => ({
//     selectcourse,
//     length: filterCourse.filter(
//       (item) => item.selectcourse.toLowerCase() === selectcourse.toLowerCase()
//     ).length,
//   }));
//   const maxCount = Math.max(...categoryLength.map((item) => item.length));
//   const coursesWithMaxCount = categoryLength.filter(
//     (item) => item.length === maxCount
//   );

//   const handleChangeYear = (e) => {
//     setYear(e.target.value);
//   };

//   // Attendance////////////////////

//   useEffect(() => {
//     fetchDatastaff();
//   }, [year, selectedChartAttendance]);

//   const fetchDatastaff = async () => {
//     let difference = 0; // Declare difference outside try block

//     try {
//       console.log("Fetching attendance data for year:", year);
//       const response = await axios.get(apiUrl + "/attendance", {
//         params: {
//           year: year,
//         },
//       });
//       const staffResponse = await axios.get(apiUrl + "/staff", {
//         params: {
//           year: year,
//         },
//       });

//       console.log("Response from server:staff", staffResponse.data);

//       // Calculate the difference between attendance and staff data
//       const attendanceData = response.data;
//       const staffData = staffResponse.data;
//       console.log("Response from server:attendance", response.data);
//       console.log("Response from server:staffResponse", staffResponse.data);

//       const today = new Date();
//       const todayString = today.toISOString().split("T")[0];

//       const filteredData = response.data.filter((item) => {
//         const itemDate = new Date(item.date);
//         const itemYear = itemDate.getFullYear();

//         // Check if the item's year matches the current year and date matches today
//         return (
//           item.date !== "" &&
//           itemYear.toString() === year &&
//           item.date === todayString
//         );
//       });

//       setFilteredDataattendance(filteredData);
//       const difference = staffResponse.data.length - filteredData.length; // Assign difference value here
//       console.log("Filtered Attendance Data:", filteredData);
//       setDifference(difference);
//     } catch (error) {
//       console.error("Error fetching attendance data:", error.message);
//     }
//   };

//   const [attendanceData, setAttendanceData] = useState([]);

//   useEffect(() => {
//     fetchDatastaffss();
//   }, [year]);

//   const fetchDatastaffss = async () => {
//     try {
//       console.log("Fetching attendance data for year:", year);
//       const response = await axios.get(apiUrl + "/attendance", {
//         params: {
//           year: year,
//         },
//       });
//       const staffResponse = await axios.get(apiUrl + "/staff", {
//         params: {
//           year: year,
//         },
//       });

//       console.log("Response from server:staff", staffResponse.data);
//       console.log("Response from server:attendance", response.data);

//       const attendanceData = response.data;
//       setAttendanceData(attendanceData);

//       const today = new Date();
//       const todayString = today.toISOString().split("T")[0];

//       const filteredData = attendanceData.filter((item) => {
//         const itemDate = new Date(item.date);
//         const itemYear = itemDate.getFullYear();

//         return (
//           item.date !== "" &&
//           itemYear.toString() === year &&
//           item.date === todayString
//         );
//       });

//       setFilteredDataattendance(filteredData);
//       const difference = staffResponse.data.length - filteredData.length;
//       console.log("Filtered Attendance Data:", filteredData);
//       setDifference(difference);

//       processAttendanceData(attendanceData);
//     } catch (error) {
//       console.error("Error fetching attendance data:", error.message);
//     }
//   };

//   const processAttendanceData = (data) => {
//     const currentYear = new Date().getFullYear();
//     const ontimeCounts = Array.from({ length: 12 }, () => 0);
//     const lateCounts = Array.from({ length: 12 }, () => 0);
//     console.log("the latecount ", lateCounts);

//     // setnewLateChart([0,0,0,0,0,0,1,3,0,0,0,0]);
//     setnewLateChart(lateCounts);
//     setnewOntimeChart(newontimeCounts);
//     // console.log("the new latecount ",newlateCounts);

//     data.forEach((item) => {
//       const itemDate = new Date(item.date);
//       const monthIndex = itemDate.getMonth();

//       // Check if the item's year matches the selected year
//       if (itemDate.getFullYear() === parseInt(year)) {
//         const loginTime = new Date(`1970-01-01T${item.login}:00`);
//         const cutoffTime = new Date("1970-01-01T09:30:00");

//         if (loginTime <= cutoffTime) {
//           ontimeCounts[monthIndex]++;
//         } else {
//           lateCounts[monthIndex]++;
//         }
//       }
//     });

//     setOntimeChart({
//       labels: [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "June",
//         "July",
//         "Aug",
//         "Sep",
//         "Oct",
//         "Nov",
//         "Dec",
//       ],
//       datasets: [
//         {
//           label: "Ontime Login Count",
//           data: ontimeCounts,
//           backgroundColor: "rgb(98, 75, 255)",
//           borderColor: "rgba(0, 0, 0, 0)",
//           borderWidth: 0,
//         },
//       ],
//     });

//     setLateChart({
//       labels: [
//         "Jan",
//         "Feb",
//         "Mar",
//         "Apr",
//         "May",
//         "June",
//         "July",
//         "Aug",
//         "Sep",
//         "Oct",
//         "Nov",
//         "Dec",
//       ],
//       datasets: [
//         {
//           label: "Late Login Count",
//           data: lateCounts,
//           backgroundColor: "rgba(255, 0, 0, 0.8)",
//           borderColor: "rgba(0, 0, 0, 0)",
//           borderWidth: 0,
//         },
//       ],
//     });
//   };

//   // yesrscalculate/////////////////////////////////////////////////////////////

//   Chart.register(...registerables);

//   const StackedAreaChart = () => {
//     const chartRef = useRef(null);

//     useEffect(() => {
//       const chart = chartRef.current;
//       if (chart) {
//         const ctx = chart.ctx;
//         const gradient = ctx.createLinearGradient(0, 0, 0, 400);
//         gradient.addColorStop(0, "rgba(255, 99, 132, 0.4)"); // Light red
//         gradient.addColorStop(1, "rgba(255, 99, 132, 0)"); // Transparent red

//         chart.data.datasets[0].backgroundColor = gradient;
//         chart.update();
//       }
//     }, []);

//     const data = {
//       labels: ["January", "February", "March", "April", "May", "June"],
//       datasets: [
//         {
//           label: "No of Students",
//           data: [25, 35, 45, 60, 80, 95], // Updated data with values between 20 and 100
//           borderColor: "rgba(255, 99, 132, 1)",
//           backgroundColor: "rgba(255, 99, 132, 0.2)",
//           fill: true,
//           tension: 0.4,
//         },
//       ],
//     };
//     const options = {
//       scales: {
//         x: {
//           stacked: true,
//           ticks: {
//             maxRotation: 90, // Adjust this for spacing between x-axis labels
//             minRotation: 0,
//             autoSkip: false, // Prevents skipping of labels if they overlap
//           },
//         },
//         y: {
//           stacked: true,
//         },
//       },
//       plugins: {
//         legend: {
//           display: true,
//           position: "top",
//         },
//       },
//     };

//     return <Line ref={chartRef} data={data} options={options} />;
//   };

//   // ///////////////////////////////////////////////////////////////

//   const DoubleBarChart = () => {
//     // Data for the chart
//     const data = {
//       labels: ["January", "February", "March", "April", "May", "June"], // X-axis labels
//       datasets: [
//         {
//           label: "Revenue",
//           data: [20000, 30000, 40000, 50000, 60000, 80000], // First dataset (10,000 to 100,000 range)
//           backgroundColor: "rgba(255, 99, 132, 0.5)", // Bar color for first dataset
//         },
//         {
//           label: "Expense",
//           data: [15000, 35000, 45000, 55000, 70000, 90000], // Second dataset (10,000 to 100,000 range)
//           backgroundColor: "rgba(239, 50, 76, 0.8)", // Bar color for second dataset
//         },
//       ],
//     };
//     // Chart configuration options
//     const options = {
//       responsive: true,
//       plugins: {
//         legend: {
//           position: "top", // Position of the legend (top, bottom, left, right)
//         },
//         title: {
//           display: true,
//           text: "Revenue and Expense ",
//         },
//       },
//       scales: {
//         x: {
//           grid: {
//             display: false, // Hides grid lines on X-axis
//           },
//         },
//         y: {
//           beginAtZero: true,
//           min: 0, // Minimum value for the Y-axis
//           max: 100000, // Maximum value for the Y-axis
//           ticks: {
//             stepSize: 20000, // Interval between tick marks on the Y-axis
//           },
//           grid: {
//             display: true, // Shows grid lines on Y-axis
//           },
//         },
//       },
//     };

//     return <Bar data={data} options={options} />;
//   };

//   const PieChart = () => {
//     // const data = {
//     //   labels: ["course 1", "course 2", "course 3"],
//     //   datasets: [
//     //     {
//     //       label: "# of Votes",
//     //       data: [12, 19, 10],
//     //       backgroundColor: [
//     //         // "#B8001F",
//     //         // "#D8A25E",
//     //         // "#FADFA1",
//     //         "#B8001F",
//     //         "#F25B70",
//     //         "#FFB1C1",
//     //       ],
//     //       borderColor: ["#B8001F", "#D8A25E", "#FADFA1"],
//     //       borderWidth: 3,
//     //     },
//     //   ],
//     // };

//     const data = {
//       labels: [
//         "South Korea",
//         "Canada",
//         "United Kingdom",
//         "Netherlands",
//         "Italy",
//         "France",
//         "Japan",
//         "United States",
//         "China",
//         "Germany",
//       ],
//       datasets: [
//         {
//           label: "Population",
//           data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
//           backgroundColor: "rgb(242,91,112)",
//           borderColor: "rgb(242,91,112)",
//           borderWidth: 1,
//         },
//       ],
//     };

//     const options = {
//       indexAxis: "y", // Set to 'y' for horizontal bar chart
//       responsive: true,
//       plugins: {
//         legend: {
//           position: "top",
//         },
//         title: {
//           display: true,
//           text: "Population by Country",
//         },
//       },
//     };

//     return (
//       <div className="chart-container">
//         <Bar data={data} options={options} />
//       </div>
//     );
//   };

//   useEffect(() => {
//     fetchDataed();
//   }, []);

//   const fetchDataed = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/branch_income_list");
//       console.log("Response from server:", response.data);
//       setbranch(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   return (
//     <div className="top">
//       <div className="dashboard">
//         <h5>
//           <b>Dashboard</b>
//         </h5>
//       </div>

//       <div className="row  ">
//         <div className="col-lg-12  col-md-8 col-sm-6">
//           <div className="row">
//             <div className="col-lg-3">
//               <div className="card one p-2">
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className="fa-solid fa-graduation-cap iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>44</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Leads</b>
//                   </h6>
//                 </div>
//               </div>
//             </div>

//             {/* Course */}

//             <div className="col-lg-3">
//               <div className="card one p-2">
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className="fa-solid fa-user-plus iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>75</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Pending Followups</b>{" "}
//                   </h6>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3">
//               <div className="card one p-2">
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className=" fa-brands fa-readme iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>8</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Fee due Followups</b>
//                   </h6>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3">
//               <div className="card one p-2" onClick={clickedcourses}>
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className=" fa-solid fa-toggle-on iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>12</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Course</b>
//                   </h6>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="col-lg-12  col-md-8 col-sm-6 mt-2">
//           <div className="row">
//             <div className="col-lg-3">
//               <div className="card one p-2" onClick={clickEntroll}>
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className=" fa-solid fa-chalkboard-user iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>14</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Enrollments</b>
//                   </h6>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3">
//               <div className="card one p-2 ">
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className=" fa-solid fa-hand-holding-dollar iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>24</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Student Dairy</b>
//                   </h6>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3">
//               <div className="card one p-2 " onClick={clickincome}>
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className=" fa-solid fa-scale-unbalanced iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>15</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Income</b>
//                   </h6>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-3">
//               <div className="card one p-2" onClick={clickexpense}>
//                 <div className="d-flex">
//                   <div
//                     className="card hello"
//                     style={{
//                       padding: "8px",
//                       width: "45px",
//                       marginTop: "10px",
//                       height: "45px",
//                     }}
//                   >
//                     <i
//                       className=" fa-solid fa-gears iconcards"
//                       style={{
//                         color: "white",
//                         fontSize: "22px",
//                         border: "none",
//                       }}
//                     ></i>
//                   </div>
//                   {/* <div className='spacer mt-2'>
//                     <h4>32</h4>
//                   </div> */}
//                 </div>
//                 <div className="enq mt-1">
//                   <h6 className="name">
//                     <b>Expense</b>
//                   </h6>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* card part 2 */}

//       <div className="charts pt-2"></div>

//       <div className="Row">
//         <div className="col-lg-12 pt-2">
//           <div className=" charts-container">
//             <div className="col-lg-6 px-2">
//               <div className="col-lg-12">
//                 <h5 className="pb-2">
//                   Leads Conversion{" "}
//                   <span className="sixmonth">last 6 Month</span>{" "}
//                 </h5>
//               </div>
//               <div className="card studentcard ">
//                 <div className="gradients">
//                   <StackedAreaChart /> {/* Correct component name */}
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6 salescard ">
//               <div className="d-flex">
//                 <div className="">
//                   <h5 className="pb-2">Active Student </h5>
//                 </div>
//                 <div className="mx-5 mt-1">
//                   <select className="form-control p-0">
//                     <option value="Interested">1 week</option>
//                     <option value="Callback">1 month</option>
//                   </select>
//                 </div>
//               </div>
//               <div className="card ">
//                 <div className="">
//                   <DoubleBarChart />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* ////////////// */}

//       {/* <!-- Course Followups / Callback Card --> */}
//       <div className="row mt-4">
//         <div className="col-lg-6">
//           <h5 className="">Pending Payment</h5>
//           <div className="card p-3 mb-5">
//             <div className="row">
//               <div className="col-lg-6">
//                 <div className="card p-3">
//                   <h5 className="text-center bg-danger text-white p-3">Salem</h5>
//                   <hr></hr>
//                   <div className="card">
//                     <div className="row details-data">
//                       <div className="col-lg-6 mt-1">
//                         <b>Pending Payment</b>
//                       </div>
//                       <div className="col-lg-6 text-secondary">
//                         {/* {paymentoverallDetails?.fees || ""} */}
//                       </div>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="card p-3">
//                   <h5 className="text-center bg-danger text-white p-3">Erode</h5>
//                   <hr></hr>
//                   <div className="card">
//                     <div className="row details-data">
//                       <div className="col-lg-6 mt-1">
//                         <b>Pending Payment</b>
//                       </div>
//                       <div className="col-lg-6 text-secondary">
//                         {/* {paymentoverallDetails?.fees || ""} */}
//                       </div>
//                     </div>

//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="row mt-3 ">
//               <div className="col-lg-12">
//                 <div className="card p-3">
//                   <h5 className="text-center bg-danger text-white p-3">Chennai</h5>
//                   <hr></hr>
//                   <div className="card">
//                     <div className="row details-data">
//                       <div className="col-lg-6 mt-1">
//                         <b>Pending Payment</b>
//                       </div>
//                       <div className="col-lg-6 text-secondary">
//                         {/* {paymentoverallDetails?.fees || ""} */}
//                       </div>
//                     </div>

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="col-lg-6 ">
//           <h5 className="">Course popularity</h5>
//           <div className="card">
//             <div className="App" style={{ width: "400px", header: "600px" }}>
//               <div className="mt-5">
//                 <PieChart />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Dashboard;

// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useRef, useState, useEffect } from "react";
import "../../assets/css/Dashboard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import Attendance from "./Attendance";
// Register the necessary components with ChartJS

// import { Chart, registerables } from "chart.js";
// import { Line, Bar, Pie } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement,
// } from "chart.js";
import { click } from "@testing-library/user-event/dist/click";



import { Chart } from 'react-google-charts';

// // Register required components
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement
// );
function Dashboard() {
  const [responseData, setResponseData] = useState([]);
  const [year, setYear] = useState("2024");
  const [projectChartData, setProjectChartData] = useState(null);
  const [courseChartData, setCourseChartData] = useState(null);
  const [ontimeCounts, setOntimeChart] = useState(null);
  const [newontimeCounts, setnewOntimeChart] = useState(null);
  const [lateCounts, setLateChart] = useState(null);
  const [newlateCounts, setnewLateChart] = useState(null);
  const [convertedCount, setConvertedCount] = useState(0);
  const [convertedCounts, setConvertedCounts] = useState(0);
  const [selectedChart, setSelectedChart] = useState("project");
  const [selectedChartAttendance, setSelectedChartAttendance] =
    useState("late");
  const [courseCount, setCourseCount] = useState(0);
  const [filterCourse, setFilterCourse] = useState([]);
  const [StudentCountData, setStudentCountData] = useState(0);
  const [callbackData, setCallbackData] = useState([]);
  const [callbackDatas, setCallbackDatas] = useState([]);
  const [complete, setComplete] = useState(0);
  const [difference, setDifference] = useState([]);
  const [filteredDataattendance, setFilteredDataattendance] = useState([]);
  const [branch, setbranch] = useState([]);

  // projecr  chart///////////////////////////////////////////////////

  const navigate = useNavigate();

  const clickincome = () => {
    navigate("/incomelist");
  };

  const clickexpense = () => {
    navigate("/Expenselist");
  };

  const clickedcourses = () => {
    navigate("/course");
  };

  const clickEntroll = () => {
    navigate("/student");
  };

  const clickLeads = () => {
    navigate("/Enquiry");
  };

  const clickPendingFollowups = () => {
    navigate("/Followups");
  };

  // const clickfeedueFollowups =()=>{
  //   navigate("/feedueFollowups");
  // };
  const clickfeedueFollowups = () => {
    navigate("/Studyingstudents");
  };

  const clickStudentDairy = () => {
    navigate("/Studentdairy");
  };

  useEffect(() => {
    fetchData();
  }, [year, selectedChart]);

  const fetchData = async () => {
    try {
      console.log("Fetching data for year:", year);
      const response = await axios.get(apiUrl + "/enquiry", {
        params: {
          year: year,
        },
      });
      console.log("Response from server enquiry:", response.data);

      // Filter data based on the selected year
      const filteredData = response.data.filter((item) => {
        const itemYear = new Date(item.startdate).getFullYear();
        return item.status === "Converted" && itemYear.toString() === year;
      });

      const filteredDatas = response.data.filter((item) => {
        const itemYear = new Date(item.date).getFullYear();
        return (
          (item.status === "Callback" || item.status === "Interested") &&
          itemYear.toString() === year
        );
      });
      const filteredCallbackDatas = response.data.filter((item) => {
        return item.status === "Callback";
      });

      console.log("Filtered data for year:", filteredDatas);

      setConvertedCount(filteredData.length);
      setConvertedCounts(filteredDatas.length);
      setCallbackDatas(filteredCallbackDatas);
      // Count occurrences for each month
      const projectCounts = Array.from({ length: 12 }, () => 0);

      filteredData.forEach((item) => {
        const monthIndex = new Date(item.startdate).getMonth();
        projectCounts[monthIndex]++;
      });

      console.log("Project Counts for each month:", projectCounts);

      setProjectChartData({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Project Enquiry Count",
            data: projectCounts,
            backgroundColor: "rgb(13,110,253)",
            borderColor: "rgba(0, 0, 0, 0)",
            borderWidth: 0,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // course chart/////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    fetchDatas();
  }, [year, selectedChart]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchDatas = async () => {
    try {
      console.log("Fetching data for year:", year);
      const response = await axios.get(apiUrl + "/student", {
        params: {
          year: year,
        },
      });
      console.log("Response from server:data", response.data);

      // Filter data based on the selected year
      const filteredData = response.data.filter((item) => {
        const itemYear = new Date(item.date).getFullYear();
        return itemYear.toString() === year;
      });

      const filteredStudentData = response.data.filter((item) => {
        return item.status === "Converted";
      });
      const filteredCallbackData = response.data.filter((item) => {
        return item.status === "Callback";
      });
      const filteredCompleteData = response.data.filter((item) => {
        return item.convertedstatus === "Completed";
      });
      console.log("Filtered data for year:", filteredData);
      console.log("Filtered data for callback:", filteredCallbackData);
      console.log("Filtered data for convert:", filteredStudentData);
      console.log("Filtered data for completed:", filteredCompleteData);
      console.log("count", categoryLength);
      // Set total count of enrollments
      setCourseCount(filteredData.length);
      setFilterCourse(filteredData);
      setStudentCountData(filteredStudentData.length);
      setCallbackData(filteredCallbackData);
      setComplete(filteredCompleteData.length);
      // Count occurrences for each month
      const courseCounts = Array.from({ length: 12 }, () => 0);

      filteredData.forEach((item) => {
        const monthIndex = new Date(item.date).getMonth();
        courseCounts[monthIndex]++;
      });

      console.log("Course Counts for each month:", courseCounts);

      setCourseChartData({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Course Enquiry Count",
            data: courseCounts,
            backgroundColor: "rgb(98, 75, 255)",
            borderColor: "rgba(0, 0, 0, 0)",
            borderWidth: 0,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // course count////////////////////////////////////////

  const uniqueCategory = [
    ...new Set(
      filterCourse.map((item) => item.selectcourse.toLowerCase().trim())
    ),
  ];
  const categoryLength = uniqueCategory.map((selectcourse) => ({
    selectcourse,
    length: filterCourse.filter(
      (item) => item.selectcourse.toLowerCase() === selectcourse.toLowerCase()
    ).length,
  }));
  const maxCount = Math.max(...categoryLength.map((item) => item.length));
  const coursesWithMaxCount = categoryLength.filter(
    (item) => item.length === maxCount
  );

  const handleChangeYear = (e) => {
    setYear(e.target.value);
  };

  // Attendance////////////////////

  useEffect(() => {
    fetchDatastaff();
  }, [year, selectedChartAttendance]);

  const fetchDatastaff = async () => {
    let difference = 0; // Declare difference outside try block

    try {
      console.log("Fetching attendance data for year:", year);
      const response = await axios.get(apiUrl + "/attendance", {
        params: {
          year: year,
        },
      });
      const staffResponse = await axios.get(apiUrl + "/staff", {
        params: {
          year: year,
        },
      });

      console.log("Response from server:staff", staffResponse.data);

      // Calculate the difference between attendance and staff data
      const attendanceData = response.data;
      const staffData = staffResponse.data;
      console.log("Response from server:attendance", response.data);
      console.log("Response from server:staffResponse", staffResponse.data);

      const today = new Date();
      const todayString = today.toISOString().split("T")[0];

      const filteredData = response.data.filter((item) => {
        const itemDate = new Date(item.date);
        const itemYear = itemDate.getFullYear();

        // Check if the item's year matches the current year and date matches today
        return (
          item.date !== "" &&
          itemYear.toString() === year &&
          item.date === todayString
        );
      });

      setFilteredDataattendance(filteredData);
      const difference = staffResponse.data.length - filteredData.length; // Assign difference value here
      console.log("Filtered Attendance Data:", filteredData);
      setDifference(difference);
    } catch (error) {
      console.error("Error fetching attendance data:", error.message);
    }
  };

  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    fetchDatastaffss();
  }, [year]);

  const fetchDatastaffss = async () => {
    try {
      console.log("Fetching attendance data for year:", year);
      const response = await axios.get(apiUrl + "/attendance", {
        params: {
          year: year,
        },
      });
      const staffResponse = await axios.get(apiUrl + "/staff", {
        params: {
          year: year,
        },
      });

      console.log("Response from server:staff", staffResponse.data);
      console.log("Response from server:attendance", response.data);

      const attendanceData = response.data;
      setAttendanceData(attendanceData);

      const today = new Date();
      const todayString = today.toISOString().split("T")[0];

      const filteredData = attendanceData.filter((item) => {
        const itemDate = new Date(item.date);
        const itemYear = itemDate.getFullYear();

        return (
          item.date !== "" &&
          itemYear.toString() === year &&
          item.date === todayString
        );
      });

      setFilteredDataattendance(filteredData);
      const difference = staffResponse.data.length - filteredData.length;
      console.log("Filtered Attendance Data:", filteredData);
      setDifference(difference);

      processAttendanceData(attendanceData);
    } catch (error) {
      console.error("Error fetching attendance data:", error.message);
    }
  };

  const processAttendanceData = (data) => {
    const currentYear = new Date().getFullYear();
    const ontimeCounts = Array.from({ length: 12 }, () => 0);
    const lateCounts = Array.from({ length: 12 }, () => 0);
    console.log("the latecount ", lateCounts);

    // setnewLateChart([0,0,0,0,0,0,1,3,0,0,0,0]);
    setnewLateChart(lateCounts);
    setnewOntimeChart(newontimeCounts);
    // console.log("the new latecount ",newlateCounts);

    data.forEach((item) => {
      const itemDate = new Date(item.date);
      const monthIndex = itemDate.getMonth();

      // Check if the item's year matches the selected year
      if (itemDate.getFullYear() === parseInt(year)) {
        const loginTime = new Date(`1970-01-01T${item.login}:00`);
        const cutoffTime = new Date("1970-01-01T09:30:00");

        if (loginTime <= cutoffTime) {
          ontimeCounts[monthIndex]++;
        } else {
          lateCounts[monthIndex]++;
        }
      }
    });

    setOntimeChart({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Ontime Login Count",
          data: ontimeCounts,
          backgroundColor: "rgb(98, 75, 255)",
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 0,
        },
      ],
    });

    setLateChart({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Late Login Count",
          data: lateCounts,
          backgroundColor: "rgba(255, 0, 0, 0.8)",
          borderColor: "rgba(0, 0, 0, 0)",
          borderWidth: 0,
        },
      ],
    });
  };

  // yesrscalculate/////////////////////////////////////////////////////////////

  // Chart.register(...registerables);

  // const StackedAreaChart = () => {
  //   const chartRef = useRef(null);

  //   useEffect(() => {
  //     const chart = chartRef.current;
  //     if (chart) {
  //       const ctx = chart.ctx;
  //       const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  //       gradient.addColorStop(0, "rgba(255, 99, 132, 0.4)"); // Light red
  //       gradient.addColorStop(1, "rgba(255, 99, 132, 0)"); // Transparent red

  //       chart.data.datasets[0].backgroundColor = gradient;
  //       chart.update();
  //     }
  //   }, []);

  //   const data = {
  //     // labels: ["January", "February", "March", "April", "May", "June"],
  //     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  //     datasets: [
  //       {
  //         label: "No of Students",
  //         data: [25, 35, 45, 60, 80, 95], // Updated data with values between 20 and 100
  //         borderColor: "rgba(255, 99, 132, 1)",
  //         backgroundColor: "rgba(255, 99, 132, 0.2)",
  //         // backgroundColor: null,
  //         fill: true,
  //         tension: 0.4,
  //       },
  //     ],
  //   };
  //   const options = {
  //     scales: {
  //       x: {
  //         stacked: true,
  //         ticks: {
  //           maxRotation: 90, // Adjust this for spacing between x-axis labels
  //           minRotation: 0,
  //           autoSkip: false, // Prevents skipping of labels if they overlap
  //         },
  //       },
  //       y: {
  //         stacked: true,
  //       },
  //     },
  //     plugins: {
  //       legend: {
  //         display: true,
  //         position: "top",
  //         labels: {
  //           boxWidth: 20,
  //           borderWidth: 0, // Set borderWidth to 0 to remove the border
  //         }
  //       },
  //     },
  //   };

  //   return <Line ref={chartRef} data={data} options={options} />;
  // };

  // ///////////////////////////////////////////////////////////////

  // const DoubleBarChart = () => {
  //   // Data for the chart
  //   const data = {
  //     // labels: ["January", "February", "March", "April", "May", "June"], // X-axis labels
  //     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  //     datasets: [
  //       {
  //         label: "Revenue",
  //         // data: [20000, 30000, 40000, 50000, 60000, 80000], // First dataset (10,000 to 100,000 range)
  //         // backgroundColor: "rgba(255, 99, 132, 0.5)", // Bar color for first dataset
  //         data: [20, 30, 40, 50, 60, 80],
  //         backgroundColor: "rgba(239, 50, 76, 0.8)", // Bar color for second dataset
  //         barThickness: 10,
  //       },
  //       {
  //         label: "Expense",
  //         // data: [15000, 35000, 45000, 55000, 70000, 90000], // Second dataset (10,000 to 100,000 range)
  //         // backgroundColor: "rgba(239, 50, 76, 0.8)", // Bar color for second dataset
  //         data: [15, 35, 45, 55, 70, 90],
  //         backgroundColor: "rgba(255, 99, 132, 0.5)", // Bar color for first dataset
  //         barThickness: 10,
  //       },
  //     ],
  //   };
  //   // Chart configuration options
  //   const options = {
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         position: "top", // Position of the legend (top, bottom, left, right)
  //         labels: {
  //           boxWidth: 15, // Box width for the dataset symbol
  //           padding: 2, // Space between legend items
  //         }
  //       },
  //       title: {
  //         display: true,
  //         text: "Revenue and Expense ",
  //       },
  //     },
  //     scales: {
  //       x: {
  //         grid: {
  //           display: false, // Hides grid lines on X-axis
  //         },
  //       },
  //       y: {
  //         beginAtZero: true,
  //         min: 0, // Minimum value for the Y-axis
  //         // max: 100000, // Maximum value for the Y-axis
  //         max: 100,
  //         ticks: {
  //           // stepSize: 20000, // Interval between tick marks on the Y-axis
  //           stepSize: 20,
  //         },
  //         grid: {
  //           display: true, // Shows grid lines on Y-axis
  //         },
  //       },
  //     },
  //   };

  //   return <Bar data={data} options={options} />;
  // };

  // const PieChart = () => {
  //   // const data = {
  //   //   labels: ["course 1", "course 2", "course 3"],
  //   //   datasets: [
  //   //     {
  //   //       label: "# of Votes",
  //   //       data: [12, 19, 10],
  //   //       backgroundColor: [
  //   //         // "#B8001F",
  //   //         // "#D8A25E",
  //   //         // "#FADFA1",
  //   //         "#B8001F",
  //   //         "#F25B70",
  //   //         "#FFB1C1",
  //   //       ],
  //   //       borderColor: ["#B8001F", "#D8A25E", "#FADFA1"],
  //   //       borderWidth: 3,
  //   //     },
  //   //   ],
  //   // };

  //   const data = {
  //     labels: [
  //       "South Korea",
  //       "Canada",
  //       "United Kingdom",
  //       "Netherlands",
  //       "Italy",
  //       "France",
  //       "Japan",
  //       "United States",
  //       "China",
  //       "Germany",
  //     ],
  //     datasets: [
  //       {
  //         label: "Population",
  //         data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
  //         backgroundColor: "rgb(242,91,112)",
  //         borderColor: "rgb(242,91,112)",
  //         borderWidth: 1,
  //       },
  //     ],
  //   };

  //   const options = {
  //     indexAxis: "y", // Set to 'y' for horizontal bar chart
  //     responsive: true,
  //     plugins: {
  //       legend: {
  //         position: "top",
  //       },
  //       title: {
  //         display: true,
  //         text: "Population by Country",
  //       },
  //     },
  //   };

  //   return (
  //     <div className="chart-container">
  //       <Bar data={data} options={options} />
  //     </div>
  //   );
  // };

  useEffect(() => {
    fetchDataed();
  }, []);

  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/branch_income_list");
      console.log("Response from server:", response.data);
      setbranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  //=============================== code for new react-google-charts =========================================

  //===================== code for line chart ================================================
  // Data for the chart
  const line_chart_data = [
    ['Month', 'Students'],
    ['Jan', 1000],
    ['Feb', 1170],
    ['Mar', 660],
    ['Apr', 1030],
    ['May', 1200],
    ['Jun', 950],
    ['Jul', 1300],
  ];

  // Options for the chart
  const line_chart_options = {
    title: 'No of Students',
    curveType: 'function',
    legend: { position: 'bottom' },
    pointSize: 10,  // Add points on the line
    pointsVisible: true,  // Ensure points are visible
    colors: ['#850000']
  };
  //===================== code for line chart ================================================

  //===================== code for horizontal Bar chart ================================================
  // Data for the chart
  const horizontal_bar_data = [
    ['Month', 'Population'],
    ['Jan', 1000],
    ['Feb', 1170],
    ['Mar', 660],
    ['Apr', 1030],
    ['May', 1200],
    ['Jun', 950],
    ['Jul', 1300],
  ];


  // Options for the chart
  const horizontal_bar_options = {
    title: 'No of Students',
    curveType: 'function',
    legend: { position: 'bottom' },
    pointSize: 10,  // Add points on the line
    pointsVisible: true,  // Ensure points are visible
    colors: ['#850000']
  };
  //===================== code for horizontal Bar chart ================================================

  //===================== code for double Bar chart ================================================
  const double_bar_data = [
    ['Month', 'Revenue', 'Expense'],
    ['Jan', 1000, 1200],
    ['Feb', 1170, 1300],
    ['Mar', 660, 900],
    ['Apr', 1030, 1150],
    ['May', 1200, 1400],
    ['Jun', 950, 1100],
    ['Jul', 1300, 1500],
  ];

  // Options for the chart
  const double_bar_options = {
    title: 'Active students per month',
    curveType: 'function',
    legend: { position: 'bottom' },
    pointSize: 10,  // Add points on the line
    pointsVisible: true,  // Ensure points are visible
    // colors: ['#FF5733', '#33C4FF'], // Custom colors for 2010 (orange) and 2020 (blue)
    colors: ['#DC3912', '#850000'], // Custom colors for 2010 (orange) and 2020 (blue)
  };
  //===================== code for double Bar chart ================================================
  //=============================== code for new react-google-charts =========================================

  return (
    <div className="top" style={{ background: "#f2f0f0", marginLeft: "0px", marginRight: "0px" }}>
      <div className="dashboard" >
        <h5>
          <b>Dashboard</b>
        </h5>
      </div>

      <div className="row  " >
        <div className="col-lg-12  col-md-8 col-sm-6">
          <div className="row">
            <div className="col-lg-3">
              <div className="card one p-2" onClick={clickLeads}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className="fa-solid fa-graduation-cap iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>44</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Leads</b>
                  </h6>
                </div>
              </div>
            </div>

            {/* Course */}

            <div className="col-lg-3">
              <div className="card one p-2" onClick={clickPendingFollowups}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className="fa-solid fa-user-plus iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>75</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Pending Followups</b>{" "}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card one p-2"
                onClick={clickfeedueFollowups}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className=" fa-brands fa-readme iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>8</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Fee due Followups</b>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card one p-2" onClick={clickedcourses}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className=" fa-solid fa-toggle-on iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>12</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Course</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12  col-md-8 col-sm-6 mt-2">
          <div className="row">
            <div className="col-lg-3">
              <div className="card one p-2" onClick={clickEntroll}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className=" fa-solid fa-chalkboard-user iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>14</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Enrollments</b>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card one p-2 " onClick={clickStudentDairy}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className=" fa-solid fa-hand-holding-dollar iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>24</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Student Dairy</b>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card one p-2 " onClick={clickincome}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className=" fa-solid fa-scale-unbalanced iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>15</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Income</b>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card one p-2" onClick={clickexpense}>
                <div className="d-flex">
                  <div
                    className="card hello"
                    style={{
                      padding: "8px",
                      width: "45px",
                      marginTop: "10px",
                      height: "45px",
                    }}
                  >
                    <i
                      className=" fa-solid fa-gears iconcards"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        border: "none",
                      }}
                    ></i>
                  </div>
                  {/* <div className='spacer mt-2'>
                    <h4>32</h4>
                  </div> */}
                </div>
                <div className="enq mt-1">
                  <h6 className="name">
                    <b>Expense</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* card part 2 */}

      <div className="charts pt-2"></div>

      <div className="Row">
        <div className="col-lg-12 pt-2">
          <div className=" charts-container">
            <div className="col-lg-6 px-2">
              <div className="col-lg-12">
                <h5 className="pb-2">
                  Leads Conversion{" "}
                  <span className="sixmonth">last 6 Month</span>{" "}
                </h5>
              </div>
              <div className="card studentcard ">
                {/* <div className="gradients">
                  <StackedAreaChart /> 
                </div> */}
                <Chart
                  chartType='LineChart' // Use the selected chart type
                  width="100%"
                  height="400px"
                  data={line_chart_data}
                  options={line_chart_options}
                />
              </div>
            </div>

            <div className="col-lg-6 salescard ">
              <div className="d-flex">
                <div className="">
                  <h5 className="pb-2">Active Student </h5>
                </div>
                <div className="mx-5 mt-1">
                  <select className="form-control p-0">
                    <option value="Interested">1 week</option>
                    <option value="Callback">1 month</option>
                  </select>
                </div>
              </div>
              <div className="card ">
                {/* <div className="">
                  <DoubleBarChart />
                </div> */}
                <Chart
                  chartType='ColumnChart' // Use the selected chart type
                  width="100%"
                  height="400px"
                  data={double_bar_data}
                  options={double_bar_options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ////////////// */}

      {/* <!-- Course Followups / Callback Card --> */}
      <div className="row mt-4">
        <div className="col-lg-6">
          <h5 className="">Pending Payment</h5>
          <div className="card p-3 mb-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="card p-3">
                  <h5 className="text-center bg-danger text-white p-3">
                    Salem
                  </h5>
                  <hr></hr>
                  <div className="card">
                    <div className="row details-data">
                      <div className="col-lg-6 mt-1">
                        <b>Pending Payment</b>
                      </div>
                      <div className="col-lg-6 text-secondary">
                        {/* {paymentoverallDetails?.fees || ""} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card p-3">
                  <h5 className="text-center bg-danger text-white p-3">
                    Erode
                  </h5>
                  <hr></hr>
                  <div className="card">
                    <div className="row details-data">
                      <div className="col-lg-6 mt-1">
                        <b>Pending Payment</b>
                      </div>
                      <div className="col-lg-6 text-secondary">
                        {/* {paymentoverallDetails?.fees || ""} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-3 ">
              <div className="col-lg-12">
                <div className="card p-3">
                  <h5 className="text-center bg-danger text-white p-3">
                    Chennai
                  </h5>
                  <hr></hr>
                  <div className="card">
                    <div className="row details-data">
                      <div className="col-lg-6 mt-1">
                        <b>Pending Payment</b>
                      </div>
                      <div className="col-lg-6 text-secondary"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="col-lg-6 mb-4">
          <h5 className="">Course popularity</h5>
          <div className="card">
            <div className="App" style={{ width: "400px", header: "600px" }}>
              {/* <div className="mt-5">
                <PieChart />
              </div> */}
              <Chart
                chartType='BarChart' // Use the selected chart type
                width="100%"
                height="400px"
                data={horizontal_bar_data}
                options={horizontal_bar_options}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
