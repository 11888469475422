import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../../assets/css/EnquiryReport.css";

function StaffReport() {
  const { id } = useParams();
  const [filtered, setFiltered] = useState([]);
  const [courses, setCourses] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const leadsResponse = await axios.get(apiUrl + "/staff");
        setFiltered(leadsResponse.data);

        const uniqueStatuses = Array.from(
          new Set(leadsResponse.data.map((item) => item.status))
        ).map((status) => ({ status: status }));

        setStatuses(uniqueStatuses);

        // Fetch courses
        const coursesResponse = await axios.get(apiUrl + "/courseinfo");
        console.log("Courses fetched:", coursesResponse.data); // Debug log
        setCourses(coursesResponse.data); // Set courses
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const formatDatefitching = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;

  const filteredData = filtered.filter((item) => {
    console.log(item.name, item.mobile); // Check if these fields match
    const isSearchMatch = searchTerm
      ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.mobile).includes(searchTerm) ||
        item.empid.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.designation[0]?.designation
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) || // Handling array for designation
        item.branch_id[0]?.branch_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) // Handling nested branch_id
      : true;

    const isCourseMatch = selectedCourse
      ? item.course_name.toLowerCase().includes(selectedCourse.toLowerCase())
      : true;

    const isStatusMatch = selectedStatus
      ? item.followups_status
          .toLowerCase()
          .includes(selectedStatus.toLowerCase())
      : true;

    return isSearchMatch && isCourseMatch && isStatusMatch;
  });

  console.log(filteredData); // Check filtered data

  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleClearFilters = () => {
    setSelectedCourse("");
    setSelectedStatus("");
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handleCopy = () => {
    const tableElement = document.getElementById("tableId");
    if (tableElement) {
      const tableData = tableElement.innerText;
      navigator.clipboard
        .writeText(tableData)
        .then(() => {
          alert("Staff Table data copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      console.error("Table with ID 'tableId' not found");
    }
  };

  const handleCSVExport = () => {
    const csvHeaders = [
      "S.No",
      "branch",
      "Type",
      "Date",
      "Notes",
      "Amount",
      "From",
      "To",
      "Paytype",
    ];
    const csvData = filteredData.map((item, index) => [
      index + 1,
      item.branch_id,
      item.type,
      formatDatefitching(item.date),
      item.notes,
      item.amount,
      item.from,
      item.to,

      item.paytype,
    ]);

    const csvContent = [
      csvHeaders.join(","), // Headers
      ...csvData.map((row) => row.join(",")), // Data rows
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "staff_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = () => {
    const tableElement = document.getElementById("tableId");
    if (tableElement) {
      const worksheet = XLSX.utils.table_to_sheet(tableElement);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "staff_table_data.xlsx");
    } else {
      console.error(
        "Table with ID 'tableId' not found. Check if the table is rendered in the DOM."
      );
    }
  };

  const handleGeneratePDF = () => {
    const tableElement = document.getElementById("tableId");
    if (!tableElement) {
      console.error("Table with ID 'tableId' not found");
      return;
    }
    html2canvas(tableElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.save("staff_report.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
      });
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    const tableElement = document.getElementById("tableId");
    if (tableElement) {
      printWindow.document.write(`
            <html>
              <head>
                <title>Print Staff Table</title>
                <style>
                  table { width: 100%; border-collapse: collapse; }
                  th, td { border: 1px solid #000; padding: 8px; text-align: left; }
                  th { background-color: #f2f2f2; }
                </style>
              </head>
              <body>
                <h1>Enquiry Report</h1>
                <div>${tableElement.outerHTML}</div>
              </body>
            </html>
          `);
      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Table with ID 'tableId' not found");
    }
  };

  return (
    <div className="container-fluid" style={{ backgroundColor: "#E8EFF9" }}>
      <div
        className="card p-2"
        style={{
          marginTop: "-80px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <h6 className="text-center">
            <b>Staff Report</b>
          </h6>
        </div>
        <hr />
        <div className="row p-2">
          <div className="col-lg-2 col-md-3 col-sm-12 mx-3">
            <input
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              className="form-control"
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-12">
            <input
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              className="form-control"
            />
          </div>
          {/* <div className="col-lg-2 col-md-3 col-sm-12">
            <select
              value={selectedCourse}
              onChange={handleCourseChange}
              className="form-control"
            >
              <option value="">Select Course</option>
              {courses.map((course) => (
                <option key={course.id} value={course.course_name}>
                  {course.course_name}
                </option>
              ))}
            </select>
          </div> */}
          <div className="col-lg-2 col-md-3 col-sm-12">
            <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className="form-control"
            >
              <option value="">Select Status</option>
              {statuses.map((status, index) => (
                <option key={index} value={status.status}>
                  {status.status}
                </option>
              ))}
            </select>
          </div>

          <div className="col-lg-1 col-md-2 col-sm-12">
            <button className="btn btn-danger" onClick={handleClearFilters}>
              Clear
            </button>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-3 d-flex align-items-center">
            <p className="mb-0 mr-2">Show </p>
            <select
              value={entriesPerPage}
              onChange={handleEntriesPerPageChange}
              className="form-control mx-2"
              style={{ width: "50px" }}
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={filteredData.length}>All</option>
            </select>
          </div>

          <div className="col-md-6">
            <button className="btn btn-secondary2 mx-2" onClick={handleCopy}>
              Copy
            </button>
            <button className="btn btn-success2 mx-2" onClick={handleCSVExport}>
              CSV
            </button>
            <button className="btn btn-excel2 mx-2" onClick={handleExport}>
              Excel
            </button>
            <button className="btn btn-info2 mx-2" onClick={handleGeneratePDF}>
              PDF
            </button>
            <button className="btn btn-warning2 mx-2" onClick={handlePrint}>
              Print
            </button>
          </div>

          <div className="col-md-3 d-flex align-items-center">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control"
            />
          </div>
        </div>
        <table id="tableId" className="table table-striped table-bordered mt-2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Branch</th>
              <th>Empid</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Designation</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((item, index) => (
              <tr key={index}>
                <td>{index + 1 + (currentPage - 1) * entriesPerPage}</td>
                <td>{item.branch_id[0]?.branch_name}</td>
                <td>{item.empid}</td>
                <td>{item.name}</td>
                <td>{item.mobile}</td>
                <td>{item.designation[0].designation}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="row">
          <div className="col-lg-4">
            <div className="dataTables_info" role="status" aria-live="polite">
              Showing {indexOfFirstEntry + 1} to{" "}
              {Math.min(indexOfLastEntry, filtered.length)} of {filtered.length}{" "}
              entries
            </div>
          </div>
          <div className="col-lg-5"></div>
          <div className="col-lg-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </a>
                </li>
                {[...Array(totalPages)].map((_, i) => (
                  <li
                    key={i}
                    className={`page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffReport;
