// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Modal, Button } from "react-bootstrap";
// import apiUrl from "../../environment/Api";
// import { toast } from "react-toastify";
// import { Navigate, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
// import ReactFileViewer from "react-file-viewer";

// function Course() {
//   const [responseData, setResponseData] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [isUpdateshowModal, setUpdateShowModal] = useState(false);
//   const [showModals, setShowModals] = useState(false);
//   const [branch, setbranch] = useState([]);
//   const [category, setcategory] = useState([]);
//   const [role_id, setroleid] = useState("");
//   const [previllages, setprevillages] = useState({});
//   const [rolename, setrolename] = useState([]);
//   const [addData, setAddData] = useState({
//     branch_id: "",
//     course_id: "",
//     course_name: "",
//     category_list: "",
//     description: "",
//     discount: "",
//     special_offer: "",
//     duration: "",
//     fees: "",
//     status: "",
//     image: "",
//   });

//   const [updateData, setUpdateData] = useState({
//     branch_id: "",
//     course_id: "",
//     course_name: "",
//     category_list: "",
//     description: "",
//     discount: "",
//     special_offer: "",
//     duration: "",
//     fees: "",
//     status: "",
//     image: "",
//   });

//   useEffect(() => {
//     fetchData();
//     fetchDataed();
//     gettoken();
//     fetch();
//     const rolename = localStorage.getItem("rolename");
//     setrolename(rolename);
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const handlenewschudule = () => {
//     navigate("/schedule");
//   };

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/course", {
//         params: { data: localStorage.getItem("branch_id") },
//       });
//       console.log("Response from server:", response.data);
//       setResponseData(response.data.reverse());
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const fetchDataed = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/Branch");
//       console.log("Response from server:", response.data);
//       setbranch(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const fetch = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/category_list");
//       console.log("Response from server:", response.data);
//       setcategory(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const gettoken = async () => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(apiUrl + "/get_detail", {
//         token: token,
//       });
//       console.log("token detail from server:", response.data);
//       console.log("The role id is : ", response.data.detail.rolename);
//       setroleid(response.data.detail.rolename);
//       console.log("the role_id is : ", role_id);
//       localStorage.setItem("role_id", response.data.detail.rolename);
//       const id = response.data.detail.rolename;

//       if (id) {
//         try {
//           const response = await axios.get(apiUrl + `/role_2/${id}`);
//           console.log("the reponse list in brach is : ", response.data.modules);
//           const modules = response.data.modules;
//           const filtered_access = modules.filter(
//             (item) => item.name == "Branch"
//           );
//           console.log("the modules is : ", filtered_access[0].actions);
//           setprevillages(filtered_access[0].actions);
//           console.log("the assigned previllages is : ", previllages.add);
//         } catch (error) {
//           console.error("Error fetching data:", error.message);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const handleNewButtonClick = () => {
//     if (previllages.add || previllages.all) {
//       setShowModal(true);
//     } else {
//       toast.error("You don't have the permission to add");
//     }
//   };

//   const handlesubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = {
//         branch_id: addData.branch_id,
//         course_name: addData.course_name,
//         course_id: addData.course_id,
//         category_list: addData.category_list,
//         discount: addData.discount,
//         special_offer: addData.special_offer,
//         description: addData.description,
//         duration: addData.duration,
//         fees: addData.fees,
//         status: addData.status,
//         image: addData.image,
//       };
//       const response = await axios.post(apiUrl + "/course", formData);
//       console.log("Updated:", response);
//       toast.success("added successfully!");
//       setShowModal(false);
//       setAddData({
//         branch_id: "",
//         course_id: "",
//         course_name: "",
//         category_list: "",
//         description: "",
//         discount: "",
//         special_offer: "",
//         duration: "",
//         fees: "",
//         status: "",
//         image: "",
//       });
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const [updateid, setUpdateId] = useState("");

//   const handleUpdate = (_id, data) => {
//     if (previllages.edit || previllages.all) {
//       setUpdateId(_id);
//       console.log(" the data to updata", data);
//       setUpdateShowModal(true);
//       setUpdateData({
//         branch_id: data.branch_id,
//         course_id: data.course_id,
//         discount: data.discount,
//         special_offer: data.special_offer,
//         course_name: data.course_name,
//         category_list: data.category_list,
//         description: data.description,
//         duration: data.duration,
//         fees: data.fees,
//         status: data.status,
//         image: data.image,
//       });
//     } else {
//       toast.error("You don't have the permission to update");
//     }
//   };

//   const handleupdatesubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const formData = {
//         branch_id: updateData.branch_id,
//         course_id: updateData.course_id,
//         discount: updateData.discount,
//         category_list: updateData.category_list,
//         special_offer: updateData.special_offer,
//         course_name: updateData.course_name,
//         description: updateData.description,
//         duration: updateData.duration,
//         fees: updateData.fees,
//         status: updateData.status,
//         image: updateData.image,
//       };
//       console.log("the update submit data", formData);
//       const response = await axios.put(
//         apiUrl + `/course/${updateid}`,
//         formData
//       );
//       console.log("response for update", response);
//       setUpdateShowModal(false);
//       toast.success("Update successfully!");
//       fetchData();
//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const handleDelete = async () => {
//     try {
//       await axios.delete(apiUrl + `/course/${getdeleteid}`);
//       toast.error("Deleted successfully!");
//       setShowModals(false);
//       fetchData(); // Refetch data after deletion
//     } catch (error) {
//       console.error("Error deleting course:", error.message);
//     }
//   };

//   const handleCloseModal = () => {
//     setShowModals(false);
//   };

//   const [getdeleteid, setdeleteid] = useState("");
//   const [getdeletename, setdeletename] = useState("");
//   const handleOpenModal = (_id, course_name) => {
//     if (previllages.all || previllages.delete) {
//       console.log(" the id", _id);
//       console.log(" the coursename", course_name);
//       setdeleteid(_id);
//       setdeletename(course_name);
//       setShowModals(true);
//     } else {
//       toast.error("You don't have the permission  to add");
//     }
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const day = date.getDate().toString().padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

//   // the pagination ---------------------------------------------------------------------
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//   const [searchQuery, setSearchQuery] = useState("");
//   // Pagination
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   // Search
//   const filtered = currentItems.filter((item) =>
//     Object.values(item).some(
//       (value) =>
//         (typeof value === "string" &&
//           value.toLowerCase().includes(searchQuery.toLowerCase())) ||
//         (typeof value === "number" && value.toString().includes(searchQuery))
//     )
//   );

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//     setCurrentPage(1);
//   };

//   const totalPages = Math.ceil(responseData.length / itemsPerPage);

//   const goToNextPage = () => {
//     setCurrentPage((prevPage) =>
//       prevPage === totalPages ? prevPage : prevPage + 1
//     );
//   };

//   const goToPrevPage = () => {
//     setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
//   };

//   const navigate = useNavigate();

//   const handleCourseInfo = (id, course_id) => {
//     navigate(`/coursepreview/${id}/${course_id}`);
//   };

//   const [imagePreview, setImagePreview] = useState("");

//   const [file, setFile] = useState(null);
//   const [fileType, setFileType] = useState("");
//   const [fileName, setFileName] = useState("");

//   const handleFileChange = (e) => {
//     const uploadedFile = e.target.files[0];
//     if (uploadedFile) {
//       setFile(URL.createObjectURL(uploadedFile)); // Create file URL
//       setFileType(uploadedFile.type); // Get MIME type
//       setFileName(uploadedFile.name); // Get file name
//     }
//   };

//   const renderPreview = () => {
//     if (!file) return null;

//     // Extract the file extension from file name
//     const fileExtension = fileName.split(".").pop().toLowerCase();

//     // Display preview for image files
//     if (fileType.startsWith("image")) {
//       return (
//         <div>
//           <h3>Image Preview:</h3>
//           <img
//             src={file}
//             alt="Preview"
//             style={{
//               width: "300px",
//               height: "auto",
//               border: "1px solid #ddd",
//               borderRadius: "5px",
//               padding: "5px",
//             }}
//           />
//         </div>
//       );
//     }

//     // Use ReactFileViewer for supported file types
//     const supportedFileTypes = [
//       "pdf",
//       "doc",
//       "docx",
//       "ppt",
//       "pptx",
//       "xls",
//       "xlsx",
//     ];

//     if (supportedFileTypes.includes(fileExtension)) {
//       return (
//         <div>
//           <h3>File Preview:</h3>
//           <ReactFileViewer
//             fileType={fileExtension}
//             filePath={file}
//             unsupportedComponent={
//               <p style={{ color: "red" }}>Unable to preview this file type.</p>
//             }
//           />
//         </div>
//       );
//     }

//     // Fallback for unsupported file types
//     return (
//       <div>
//         <h3>File Preview:</h3>
//         <p style={{ color: "orange" }}>
//           Preview not available for this file type: {fileExtension}
//         </p>
//       </div>
//     );
//   };

//   // the pagination  end ---------------------------------------------------------------------

//   return (
//     <>
//       <div className="top">
//         <div className="" style={{ marginTop: "-80px" }}>
//           <div className="row mb-3">
//             <div className="col-lg-11">
//               <Link to="/dashboard" className="text-secondary dashBoard_text">
//                 <b>Dashboard / </b>
//               </Link>
//               {/* <Link to="" className="text-secondary dashBoard_text">
//               <b>Course / </b>
//             </Link> */}
//               <Link to="" className="text-danger dashBoard_text">
//                 <b>Course</b>
//               </Link>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-10 d-flex">
//             <h5 className="mt-2">Course </h5>
//             <div className="mx-3">
//               <button className="btn  add" onClick={handleNewButtonClick}>
//                 Add{" "}
//               </button>
//             </div>
//           </div>
//           <div className="col-lg-2">
//             <button className="btn btn-secondary" onClick={handlenewschudule}>
//               Schedule
//             </button>
//           </div>
//         </div>

//         {/* ------------ search and per page ----------------------------*/}

//         <div className="mb-3 mt-3">
//           <div className="row">
//             <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//               <label className="form-label text-dark mt-2">Show : </label>
//               <div>
//                 <select
//                   className="form-control w-100 mx-2"
//                   onChange={(e) => {
//                     setItemsPerPage(e.target.value);
//                   }}
//                 >
//                   {/* <option value="5">5</option> */}
//                   <option value="10">10</option>
//                   <option value="20">20</option>
//                   <option value="50">50</option>
//                 </select>
//               </div>
//             </div>
//             <div className="col-lg-7 col-md-4 col-sm-1"></div>
//             <div className="col-lg-3 col-md-4 col-sm-7">
//               <div className="w-full flex-1">
//                 <form>
//                   <div className="relative">
//                     <input
//                       className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
//                       placeholder="Search ..."
//                       type="search"
//                       value={searchQuery}
//                       onChange={handleSearchChange}
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* --------------------------------end------------------------------------------- */}

//         {/* Table */}
//         <div className="card">
//           <div className="mt-1">
//             <table className="table table-striped">
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   {/* {rolename === "superadmin" && <th>Branch</th>} */}
//                   <th>Course Code</th>
//                   <th>Course </th>
//                   <th>Discount Upto</th>
//                   <th>Duration</th>
//                   <th>Fees</th>
//                   <th>Status</th>
//                   <th className="text-center">Action</th>
//                 </tr>
//               </thead>

//               <tbody>
//                 {filtered.map((item, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     {/* <td>{item.branch_id.branch_name}</td> */}
//                     {/* {rolename === "superadmin" && <td>{item.branch_id?.branch_name}</td>} */}
//                     <td>{item.course_id}</td>
//                     <td>{item.course_name}</td>
//                     <td>{item.discount}</td>
//                     <td>{item.duration}</td>
//                     <td>{item.fees}</td>

//                     <td className="text-center hideres">
//                       <p
//                         className={`text-white ${
//                           item.status === "Active" ? "green-label" : "red-label"
//                         }`}
//                       >
//                         {item.status}
//                       </p>
//                     </td>

//                     <td className="text-center">
//                       <button
//                         className="btn updelete mx-2"
//                         onClick={() => handleUpdate(item._id, item)}
//                       >
//                         <i class="fa-solid fa-pen-to-square"></i>
//                       </button>
//                       <button
//                         className="btn delete"
//                         onClick={() =>
//                           handleOpenModal(item._id, item.course_name)
//                         }
//                       >
//                         <i class="fa-solid fa-trash-can"></i>
//                       </button>

//                       <button
//                         className="btn "
//                         onClick={() =>
//                           handleCourseInfo(item._id, item.course_id)
//                         }
//                       >
//                         <i class="fa-solid fa-circle-info"></i>
//                       </button>

//                       <button className="btn" onChange={handleFileChange}>
//                         {imagePreview ? (
//                           // Show image preview if available
//                           <img
//                             src={imagePreview}
//                             alt="Preview"
//                             style={{
//                               width: "40px",
//                               height: "40px",
//                               objectFit: "cover",
//                               margin: "10px 0",
//                               padding: "10px",
//                               border: "1px solid #ccc",
//                               borderRadius: "5px",
//                             }}
//                             type="file"
//                             onChange={handleFileChange}
//                             accept=".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.jpg,.jpeg,.png"
//                           />
//                         ) : (
//                           // Default icon if no image is selected
//                           <i className="fa-solid fa-image"></i>
//                         )}
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Modal for adding a new user */}

//         <Modal
//           show={showModal}
//           onHide={() => setShowModal(false)}
//           className="custom-modal"
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Add Course</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <div className="row">
//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <div className="floating-label">
//                     <label className="form-label">
//                       <b></b>
//                     </label>
//                     <select
//                       className="form-select"
//                       value={addData.category_list}
//                       onChange={(e) =>
//                         setAddData({
//                           ...addData,
//                           category_list: e.target.value,
//                         })
//                       }
//                       required
//                     >
//                       <option value="">Select Course Category </option>
//                       {category.map((item, index) => (
//                         <option key={index} value={item.category_name}>
//                           {item.category_name}
//                         </option>
//                       ))}
//                     </select>
//                     <label
//                       className={addData.category_list ? "filled" : ""}
//                     ></label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={addData.course_name}
//                       onChange={(e) =>
//                         setAddData({ ...addData, course_name: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={addData.course_name ? "filled" : ""}>
//                       Course Name
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={addData.course_id}
//                       onChange={(e) =>
//                         setAddData({ ...addData, course_id: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={addData.course_id ? "filled" : ""}>
//                       Course Code
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={addData.discount}
//                       onChange={(e) =>
//                         setAddData({ ...addData, discount: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={addData.discount ? "filled" : ""}>
//                       Discount Upto
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={addData.fees}
//                       onChange={(e) =>
//                         setAddData({ ...addData, fees: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={addData.fees ? "filled" : ""}>Fees</label>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={addData.description}
//                       onChange={(e) =>
//                         setAddData({ ...addData, description: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={addData.description ? "filled" : ""}>
//                       Description
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={addData.special_offer}
//                       onChange={(e) =>
//                         setAddData({
//                           ...addData,
//                           special_offer: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                     <label className={addData.special_offer ? "filled" : ""}>
//                       Special_offer
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={addData.duration}
//                       onChange={(e) =>
//                         setAddData({ ...addData, duration: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={addData.duration ? "filled" : ""}>
//                       Duration
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <select
//                       className="form-control"
//                       value={addData.status || ""} // Ensure it defaults to an empty string if undefined
//                       onChange={(e) =>
//                         setAddData({ ...addData, status: e.target.value })
//                       }
//                     >
//                       <option value="">Select Status</option>
//                       <option value="Active">Active</option>
//                       <option value="InActive">InActive</option>
//                     </select>

//                     <label className={addData.status ? "filled" : ""}>
//                       Select Status
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="file"
//                       placeholder=""
//                       required
//                       value={addData.image}
//                       onChange={(e) =>
//                         setAddData({ ...addData, image: e.target.value })
//                       }
//                     />
//                     <label className={addData.image ? "filled" : ""}>
//                       File Upload
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Modal.Body>

//           <Modal.Footer>
//             <Button
//               className="cancelbut"
//               variant="secondary"
//               onClick={() => setShowModal(false)}
//             >
//               Cancel
//             </Button>
//             <Button
//               className="submitbut"
//               variant="primary"
//               onClick={handlesubmit}
//             >
//               Submit
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         {/* ----------------update */}

//         <Modal
//           show={isUpdateshowModal}
//           onHide={() => setUpdateShowModal(false)}
//           className="custom-modal"
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>Update Course</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <div className="row">
//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <div className="floating-label">
//                     <label className="form-label">
//                       <b></b>
//                     </label>
//                     <select
//                       className="form-select"
//                       value={updateData.category_list}
//                       onChange={(e) =>
//                         setUpdateData({
//                           ...updateData,
//                           category_name: e.target.value,
//                         })
//                       }
//                     >
//                       <option value="">Select Course Category </option>
//                       {category.map((item, index) => (
//                         <option key={index} value={item.category_name}>
//                           {item.category_name}
//                         </option>
//                       ))}
//                     </select>
//                     <label
//                       className={updateData.category_list ? "filled" : ""}
//                     ></label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={updateData.course_name}
//                       onChange={(e) =>
//                         setUpdateData({
//                           ...updateData,
//                           course_name: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                     <label className={updateData.course_name ? "filled" : ""}>
//                       Course Name
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={updateData.course_id}
//                       onChange={(e) =>
//                         setUpdateData({
//                           ...updateData,
//                           course_id: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                     <label className={updateData.course_id ? "filled" : ""}>
//                       Course Code
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={updateData.discount}
//                       onChange={(e) =>
//                         setUpdateData({
//                           ...updateData,
//                           discount: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                     <label className={updateData.discount ? "filled" : ""}>
//                       Discount Upto
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={updateData.description}
//                       onChange={(e) =>
//                         setUpdateData({
//                           ...updateData,
//                           description: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                     <label className={updateData.description ? "filled" : ""}>
//                       Description
//                     </label>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="form-group">
//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={updateData.special_offer}
//                       onChange={(e) =>
//                         setUpdateData({
//                           ...updateData,
//                           special_offer: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                     <label className={updateData.special_offer ? "filled" : ""}>
//                       Special Offer
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={updateData.duration}
//                       onChange={(e) =>
//                         setUpdateData({
//                           ...updateData,
//                           duration: e.target.value,
//                         })
//                       }
//                       required
//                     />
//                     <label className={updateData.duration ? "filled" : ""}>
//                       Duration
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={updateData.fees}
//                       onChange={(e) =>
//                         setUpdateData({ ...updateData, fees: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={updateData.fees ? "filled" : ""}>
//                       Fees
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <select
//                       className="form-control"
//                       value={updateData.status}
//                       placeholder=""
//                       onChange={(e) =>
//                         setUpdateData({ ...updateData, status: e.target.value })
//                       }
//                     >
//                       <option value="">Select Status</option>
//                       <option value="Active">Active</option>
//                       <option value="InActive">InActive</option>
//                     </select>
//                     <label className={updateData.status ? "filled" : ""}>
//                       Select Status
//                     </label>
//                   </div>

//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="file"
//                       placeholder=""
//                       value={updateData.image}
//                       onChange={(e) =>
//                         setUpdateData({ ...updateData, image: e.target.value })
//                       }
//                       required
//                     />
//                     <label className={updateData.image ? "filled" : ""}>
//                       File Upload
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Modal.Body>

//           <Modal.Footer>
//             <Button
//               className="cancelbut"
//               variant="secondary"
//               onClick={() => setUpdateShowModal(false)}
//             >
//               Cancel
//             </Button>
//             <Button
//               className="submitbut"
//               variant="primary"
//               onClick={handleupdatesubmit}
//             >
//               Update
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         {/* ----------------delete */}

//         <Modal className="modal" show={showModals} onHide={handleCloseModal}>
//           <Modal.Header>
//             <p>Are you sure you want to delete?</p>
//           </Modal.Header>
//           <Modal.Body>
//             <label className="form-label d-flex">
//               <h5 className="mx-1"> Course Name :</h5> <h5>{getdeletename} </h5>
//             </label>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button
//               className="cancelbut"
//               variant="secondary"
//               onClick={handleCloseModal}
//             >
//               Close
//             </Button>
//             <Button
//               className="submitbut"
//               variant="primary"
//               onClick={handleDelete}
//             >
//               Delete
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         {/* _------------------------------pagination button -------------------------------- */}
//         <div className="mt-3 pb-5">
//           <div className="row">
//             <div className="col-lg-10 col-sm-12">
//               <nav className="d-flex float-right">
//                 <ul className="pagination justify-content-center">
//                   <li
//                     className={` page-item ${
//                       currentPage === 1 ? "disabled" : ""
//                     }`}
//                   >
//                     <button className="page-link" onClick={goToPrevPage}>
//                       Previous
//                     </button>
//                   </li>
//                   {Array.from({ length: totalPages }, (_, i) => (
//                     <li
//                       key={i}
//                       className={`mx-2 page-item ${
//                         currentPage === i + 1 ? "active" : ""
//                       }`}
//                     >
//                       <button
//                         className="page-link "
//                         onClick={() => paginate(i + 1)}
//                       >
//                         {i + 1}
//                       </button>
//                     </li>
//                   ))}
//                   <li
//                     className={`page-item ${
//                       currentPage === totalPages ? "disabled" : ""
//                     }`}
//                   >
//                     <button className="page-link " onClick={goToNextPage}>
//                       Next
//                     </button>
//                   </li>
//                 </ul>
//               </nav>{" "}
//             </div>
//             <div className="col-lg-2 col-sm-12 mt-1">
//               <div className="float-end">
//                 <span className="text-dark ">
//                   Page {currentPage} of {totalPages}
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* ---------------------------------------end ---------------------------------- */}
//       </div>
//     </>
//   );
// }

// export default Course;

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../../environment/Api";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Course() {
  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [branch, setbranch] = useState([]);
  const [category, setcategory] = useState([]);
  const [role_id, setroleid] = useState("");
  const [previllages, setprevillages] = useState({});
  const [rolename, setrolename] = useState([]);
  const [addData, setAddData] = useState({
    branch_id: "",
    course_id: "",
    course_name: "",
    category_list: "",
    description: "",
    discount: "",
    special_offer: "",
    duration: "",
    fees: "",
    status: "",
    image: "",
  });

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    course_id: "",
    course_name: "",
    category_list: "",
    description: "",
    discount: "",
    special_offer: "",
    duration: "",
    fees: "",
    status: "",
    image: "",
  });

  useEffect(() => {
    fetchData();
    fetchDataed();
    gettoken();
    fetch();
    const rolename = localStorage.getItem("rolename");
    setrolename(rolename);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlenewschudule = () => {
    navigate("/schedule");
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/course", {
        params: { data: localStorage.getItem("branch_id") },
      });
      console.log("Response from server:", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchDataed = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server:", response.data);
      setbranch(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetch = async () => {
    try {
      const response = await axios.get(apiUrl + "/category_list");
      console.log("Response from server:", response.data);
      setcategory(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const gettoken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(apiUrl + "/get_detail", {
        token: token,
      });
      console.log("token detail from server:", response.data);
      // setResponseData(response.data);

      console.log("The role id is : ", response.data.detail.rolename);
      setroleid(response.data.detail.rolename);
      console.log("the role_id is : ", role_id);

      localStorage.setItem("role_id", response.data.detail.rolename);

      const id = response.data.detail.rolename;

      // getpages();

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);
          console.log("the reponse list in brach is : ", response.data.modules);
          const modules = response.data.modules;
          const filtered_access = modules.filter(
            (item) => item.name == "Branch"
          );
          console.log("the modules is : ", filtered_access[0].actions);
          setprevillages(filtered_access[0].actions);
          console.log("the assigned previllages is : ", previllages.add);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleNewButtonClick = () => {
    if (previllages.add || previllages.all) {
      setShowModal(true);
    } else {
      toast.error("You don't have the permission to add");
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: addData.branch_id,
        course_name: addData.course_name,
        course_id: addData.course_id,
        category_list: addData.category_list,
        discount: addData.discount,
        special_offer: addData.special_offer,
        description: addData.description,
        duration: addData.duration,
        fees: addData.fees,
        status: addData.status,
        image: addData.image,
      };
      const response = await axios.post(apiUrl + "/course", formData);
      console.log("Updated:", response);
      toast.success("added successfully!");
      setShowModal(false);
      setAddData({
        branch_id: "",
        course_id: "",
        course_name: "",
        category_list: "",
        description: "",
        discount: "",
        special_offer: "",
        duration: "",
        fees: "",
        status: "",
        image: "",
      });
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    if (previllages.edit || previllages.all) {
      setUpdateId(_id);
      console.log(" the data to updata", data);
      setUpdateShowModal(true);
      setUpdateData({
        branch_id: data.branch_id,
        course_id: data.course_id,
        discount: data.discount,
        special_offer: data.special_offer,
        course_name: data.course_name,
        category_list: data.category_list,
        description: data.description,
        duration: data.duration,
        fees: data.fees,
        status: data.status,
        image: data.image,
      });
    } else {
      toast.error("You don't have the permission to update");
    }
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        course_id: updateData.course_id,
        discount: updateData.discount,
        category_list: updateData.category_list,
        special_offer: updateData.special_offer,
        course_name: updateData.course_name,
        description: updateData.description,
        duration: updateData.duration,
        fees: updateData.fees,
        status: updateData.status,
        image: updateData.image,
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/course/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(apiUrl + `/course/${getdeleteid}`);
      toast.error("Deleted successfully!");
      setShowModals(false);
      fetchData(); // Refetch data after deletion
    } catch (error) {
      console.error("Error deleting course:", error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, course_name) => {
    if (previllages.all || previllages.delete) {
      console.log(" the id", _id);
      console.log(" the coursename", course_name);
      setdeleteid(_id);
      setdeletename(course_name);
      setShowModals(true);
    } else {
      toast.error("You don't have the permission  to add");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  const navigate = useNavigate();

  // const handleClickImage = (id, course_id) => {
  //   navigate(`/courseimagepreview/${id}/${course_id}`);
  // };
// ===========recent image comment
  // const handleClickImage = (id, course_id) => {
  //   // Navigates to the desired route with the parameters
  //   navigate(`/courseimagepreview/${id}/${course_id}`);
  // };

  const handleCourseInfo = (id, course_id) => {
    navigate(`/coursepreview/${id}/${course_id}`);
  };

  // the pagination  end ---------------------------------------------------------------------
  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Course</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 d-flex">
          <h5 className="mt-2">Course </h5>
          <div className="mx-3">
            <button className="btn  add" onClick={handleNewButtonClick}>
              Add{" "}
            </button>
          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-secondary" onClick={handlenewschudule}>
            Schedule Time
          </button>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                {/* <option value="5">5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}

      {/* Table */}
      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Course Code</th>
                <th>Course </th>
                <th>Discount Upto</th>
                <th>Duration</th>
                <th>Fees</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>

            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.course_id}</td>
                  <td>{item.course_name}</td>
                  <td>{item.discount}</td>
                  <td>{item.duration}</td>
                  <td>{item.fees}</td>

                  <td className="text-center hideres">
                    <p
                      className={`text-white ${
                        item.status === "Active" ? "green-label" : "red-label"
                      }`}
                    >
                      {item.status}
                    </p>
                  </td>

                  <td className="text-center">
                    <button
                      className="btn updelete mx-2"
                      onClick={() => handleUpdate(item._id, item)}
                    >
                      <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                      className="btn delete"
                      onClick={() =>
                        handleOpenModal(item._id, item.course_name)
                      }
                    >
                      <i class="fa-solid fa-trash-can"></i>
                    </button>

                    <button
                      className="btn "
                      onClick={() => handleCourseInfo(item._id, item.course_id)}
                    >
                      <i class="fa-solid fa-circle-info"></i>
                    </button>

                    {/* <button
                      className="btn"
                      onClick={() => handleClickImage(item._id, item.course_id)}
                    >
                      <i className="fa-solid fa-image"></i>
                    </button> */}


                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for adding a new user */}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={addData.category_list}
                    onChange={(e) =>
                      setAddData({ ...addData, category_list: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Course Category </option>
                    {category.map((item, index) => (
                      <option key={index} value={item.category_name}>
                        {item.category_name}
                      </option>
                    ))}
                  </select>
                  <label
                    className={addData.category_list ? "filled" : ""}
                  ></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.course_name}
                    onChange={(e) =>
                      setAddData({ ...addData, course_name: e.target.value })
                    }
                    required
                  />
                  <label className={addData.course_name ? "filled" : ""}>
                    Course Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.course_id}
                    onChange={(e) =>
                      setAddData({ ...addData, course_id: e.target.value })
                    }
                    required
                  />
                  <label className={addData.course_id ? "filled" : ""}>
                    Course Code
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.discount}
                    onChange={(e) =>
                      setAddData({ ...addData, discount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.discount ? "filled" : ""}>
                    Discount Upto
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.fees}
                    onChange={(e) =>
                      setAddData({ ...addData, fees: e.target.value })
                    }
                    required
                  />
                  <label className={addData.fees ? "filled" : ""}>Fees</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.description}
                    onChange={(e) =>
                      setAddData({ ...addData, description: e.target.value })
                    }
                    required
                  />
                  <label className={addData.description ? "filled" : ""}>
                    Description
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.special_offer}
                    onChange={(e) =>
                      setAddData({ ...addData, special_offer: e.target.value })
                    }
                    required
                  />
                  <label className={addData.special_offer ? "filled" : ""}>
                    Special_offer
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.duration}
                    onChange={(e) =>
                      setAddData({ ...addData, duration: e.target.value })
                    }
                    required
                  />
                  <label className={addData.duration ? "filled" : ""}>
                    Duration
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    value={addData.status || ""} // Ensure it defaults to an empty string if undefined
                    onChange={(e) =>
                      setAddData({ ...addData, status: e.target.value })
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>

                  <label className={addData.status ? "filled" : ""}>
                    Select Status
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="file"
                    placeholder=""
                    value={addData.image}
                    onChange={(e) =>
                      setAddData({ ...addData, image: e.target.value })
                    }
                    required
                  />
                  <label className={addData.image ? "filled" : ""}>
                    Image Upload
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------update */}

      <Modal
        show={isUpdateshowModal}
        onHide={() => setUpdateShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={updateData.category_list}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        category_name: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Course Category </option>
                    {category.map((item, index) => (
                      <option key={index} value={item.category_name}>
                        {item.category_name}
                      </option>
                    ))}
                  </select>
                  <label
                    className={updateData.category_list ? "filled" : ""}
                  ></label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.course_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        course_name: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.course_name ? "filled" : ""}>
                    Course Name
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.course_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        course_id: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.course_id ? "filled" : ""}>
                    Course Code
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.discount}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, discount: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.discount ? "filled" : ""}>
                    Discount Upto
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.description}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.description ? "filled" : ""}>
                    Description
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.special_offer}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        special_offer: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.special_offer ? "filled" : ""}>
                    Special Offer
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.duration}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, duration: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.duration ? "filled" : ""}>
                    Duration
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.fees}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, fees: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.fees ? "filled" : ""}>
                    Fees
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.status}
                    placeholder=""
                    onChange={(e) =>
                      setUpdateData({ ...updateData, status: e.target.value })
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                  <label className={updateData.status ? "filled" : ""}>
                    Select Status
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal className="modal" show={showModals} onHide={handleCloseModal}>
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1"> Course Name :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}

export default Course;
