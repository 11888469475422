import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import '../assets/css/Navbar.css'
import apiUrl from "../environment/Api";
import axios from "axios";
// import navlogo from '../../assets/img/Admin/vas-logo1-nav2.png';

function Navbar({ toggle }) {
    const navigate = useNavigate()
    const navigatehandle = () => {
        navigate('/');
    }
    const navigatehandleinvoice = () => {
        navigate('/invoice');
    }
    const haldlenavigatedashboard = () => {
        navigate('/dashboard');
    }
    const [rolename, setrolename] = useState([]);
    useEffect(() => {
        const rolename = localStorage.getItem("rolename");
        setrolename(rolename)
      }, []);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);

  // Toggle the logout button visibility when clicking the user icon
  const toggleLogoutVisibility = () => {
    setIsLogoutVisible(prevState => !prevState);
    setIsLogoutVisible(!isLogoutVisible);
  };

  // Handle logout functionality
  const handleLogout = () => {
    // localStorage.removeItem("username");
    // window.location.href = "/login";
    setIsModalVisible(true);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Rename the internal `toggle` function to something else (e.g., `toggleNavbar`)
  const toggleNavbar = () => {
    // Toggle logic for your navbar (for mobile view)
  };

  

//   const confirmLogout = () => {
//      const token = localStorage.getItem("token");
//     if (token) {
//       localStorage.removeItem("token");
//       localStorage.removeItem("id");
//       localStorage.removeItem("branch_code");
//       localStorage.removeItem("branch_id");
//       localStorage.removeItem("branch_name");
//       localStorage.removeItem("role_id");
//       localStorage.removeItem("type");
//       localStorage.removeItem("username");
//       // navigate('/admin');
//       navigate("/");
//       window.location.reload();
//     } else {
//       localStorage.removeItem("token");
//       localStorage.removeItem("id");
//       localStorage.removeItem("branch_code");
//       localStorage.removeItem("branch_id");
//       localStorage.removeItem("branch_name");
//       localStorage.removeItem("role_id");
//       localStorage.removeItem("type");
//       localStorage.removeItem("username");
//       // navigate('/admin');
//       navigate("/");
//       window.location.reload();
//     }
//   };




const confirmLogout = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      // Assuming username is stored in localStorage
      const username = localStorage.getItem("username");
      try {
        // Make the API request to the backend to log out the user
        const response = await axios.post(apiUrl + '/logoutadmin', { username });
        // Check if the response includes a message and show it
        if (response.data && response.data.message) {
          // alert(response.data.message);  // Show the backend response message
        } else {
          // If no message in the response
          alert("Logout failed. Please try again.");
        }
        console.log("the response from sidebar 111111111111111", response)

        // Clear localStorage items after successful logout
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("branch_code");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("branch_name");
        localStorage.removeItem("role_id");
        localStorage.removeItem("type");
        localStorage.removeItem("username");

        // Navigate to the homepage or login page
        navigate("/");
        window.location.reload();  // Reload the page to reset any remaining state

      } catch (error) {
        // Error handling in case of API failure
        if (error.response) {
          alert(error.response.data.message || "Error logging out.");
        } else {
          alert("There was an error logging out. Please try again.");
        }

        // Still clear localStorage if logout fails
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("branch_code");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("branch_name");
        localStorage.removeItem("role_id");
        localStorage.removeItem("type");
        localStorage.removeItem("username");

        // Redirect to the homepage or login page
        navigate("/");
        window.location.reload();
      }
    } else {
      // If no token found, just clear localStorage and navigate
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("branch_code");
      localStorage.removeItem("branch_id");
      localStorage.removeItem("branch_name");
      localStorage.removeItem("role_id");
      localStorage.removeItem("type");
      localStorage.removeItem("username");

      // Navigate to the homepage or login page
      navigate("/");
      window.location.reload();
    }
  };






  const cancelLogout = () => {
    setIsModalVisible(false);
  };

    return (
        <div className='navborder  '>
            <nav className={`${toggle ? 'toggle-active' : 'toggle-notactive'} navbar default-layout-navbar col-lg-12 p-0 fixed-top d-flex flex-row`}>
                <div className="text-center d-flex align-items-center justify-content-center">
                    <div className=" brand-logo ">
                        {/* <img onClick={haldlenavigatedashboard} src={navlogo} alt="logo" /> */}
                        <div className='logonav d-flex justify-content-between'>
                            {/* <img src={require("../../src/assets/img/scimitar logo png.jpg")} alt="Company Logo" style={{ width: "150px", height:"40px", marginLeft : "50px",marginTop:"5px" }}></img> */}
                            <p className='navbarroutertag' onClick={navigatehandle} style={{ color: 'black', marginRight: "70px" }} ></p>
                        </div>
                    </div>
                </div>
                < div className="navbar-menu-wrapper  d-flex align-items-stretch">
                    <div className='mt-3' style={{ marginLeft: '-250px' }}>
                        <i class="fa-solid fa-sitemap  usericon mx-2"></i>
                        <b className='admin-username-nav' style={{ color: 'white' }}>
                            {localStorage.getItem("branch_code")}-{localStorage.getItem("branch_name")}
                        </b>
                    </div>

                    {/* <ul className="navbar-nav navbar-nav-right responsive-none" style={{ height: '50px' }}> */}
                    {/* <li className="nav-item"> */}

                    {/* <i className="fa-regular fa-circle-user usericon mx-2 "></i>

                        <b className='admin-username-nav' style={{ color: 'white' }}>
                        {localStorage.getItem("username")}

                        </b> */}
                    {/* </li> */}
                    {/* </ul> */}
                    <ul className="navbar-nav navbar-nav-right responsive-none" style={{ height: '50px' }}>
                <li className="nav-item" onClick={toggleLogoutVisibility} style={{ cursor: 'pointer' }}>
                    <i className="fa-regular fa-circle-user usericon mx-2"></i>
                    <b className='admin-username-nav' style={{ color: 'white' }}>
                        {localStorage.getItem("username")}
                    </b>

                    {isLogoutVisible && (
                        <button
                            className="btn btn-light"
                            onClick={handleLogout}
                            style={{ marginLeft: '10px' }}
                        >
                            Logout
                        </button>
                    )}
                </li>
            </ul>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggle}></button>

            {/* Modal for Logout Confirmation */}
            {isModalVisible && (
                <div className="modal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                           {/* <div className="modal-header">
                                <h5 className="modal-title">Confirm Logout</h5>
                                <button type="button" className="close" onClick={cancelLogout}>
                                    <span>&times;</span>
                                </button>
                            </div>  */}
                            <div className="modal-body">
                                <p className='text-dark'>Are you sure you want to log out?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={cancelLogout}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={confirmLogout}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
                </div>
            </nav>

            <div className="spacer"></div>
        </div>
    );
}

export default Navbar;
