import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import apiUrl from "../../environment/Api"; // Base API URL

const CourseImagePreview = () => {
  const { id, course_id } = useParams(); // Get ID and course_id from URL
  const [course, setCourse] = useState(null); // State to hold course details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/course/${course_id}`);
        const foundCourse = response.data.find((course) => course._id === id);

        if (!foundCourse) {
          setError("Course not found");
        } else {
          setCourse(foundCourse);
        }
      } catch (err) {
        console.error("Error fetching course:", err);
        setError("Failed to load course details");
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  return (
    <div>
      <h1>Image Preview</h1>
      <p>Course ID: {course_id}</p>
      {/* <p>Item ID: {id}</p> */}
      <div className="container" style={{ width: "100%", height: "300px" }}>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : course && course.image ? (
          <img
            src={`${apiUrl}/uploads/${course.image}`}
            alt={`Preview of ${course.course_name}`}
            style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
          />
        ) : (
          <p>No image available</p>
        )}
      </div>

      <div className="">{}</div>
    </div>
  );
};

export default CourseImagePreview;
