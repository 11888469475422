// // import React, { useEffect, useState } from "react";
// // import "../../assets/css/Studentinfo.css";
// // import axios from "axios";
// // import apiUrl from "../../environment/Api";
// // import { useParams } from "react-router-dom";
// // import { Link } from "react-router-dom";

// // function Leadhistroy() {
// //   const { id } = useParams();
// //   const [lead, setlead] = useState([]);
// //   const [studentss, setstudent] = useState([]);

// //   useEffect(() => {
// //     fetchDataed();
// //     const username = localStorage.getItem("username");
// //     console.log("Response from server   histropy 45435435:111", id);
// //   }, [id]);

// //   console.log("the beggin");
// //   const fetchDataed = async () => {
// //     console.log("Response from server   histropy 45435435:", id);
// //     try {
// //       const response = await axios.get(`${apiUrl}/leadhistroy/${id}`);
// //       const responsed = await axios.get(`${apiUrl}/leads/${id}`);
// //       console.log(
// //         "Response from server   histropy 45435435 leadsss:",
// //         responsed.data
// //       );
// //       console.log(
// //         "Response from server  45435435 leadhistroys :",
// //         response.data
// //       );
// //       setlead(response.data);
// //       console.log("Response from server  45435435:setlead", lead);
// //       setstudent(responsed.data);
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };

// //   console.log("the end");
// //   const handleDelete = async (id) => {
// //     try {
// //       const responses = await axios.delete(`${apiUrl}/leadhistroy/${id}`);
// //       fetchDataed();

// //       console.log("Response from deleteeee:", id);
// //       console.log("Response from deleteeee:", responses);
// //     } catch (error) {
// //       console.error("Error deleting invoice:", error.message);
// //     }
// //   };

// //   function formatDatefitching(isoDate) {
// //     if (!isoDate) return ""; // Return an empty string if no date is provided
// //     const date = new Date(isoDate);
// //     return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
// //   }

// //   const assign_staff = localStorage.getItem("assignId");

// //   const [idlocal, setlocalid] = useState([]);

// //   useEffect(() => {
// //     fetchData();
// //     const id_local = localStorage.getItem("username");
// //     setlocalid(id_local);
// //   }, []);

// //   const fetchData = async () => {
// //     try {
// //       const response = await axios.get(apiUrl + "/leads", {
// //         params: { data: localStorage.getItem("branch_id") },
// //       });
// //       console.log("Response from serverrrr:", response.data.reverse());
// //       if (response && response.data) {
// //       }
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };


// //   useEffect(() => {
// //     gettoken()
// //   })


// //   const gettoken = async () => {
// //     const token = localStorage.getItem("token");
// //     // console.log("the toekn in the localStorage is : ",token);
// //     try {
// //       const response = await axios.post(apiUrl + "/get_detail", {
// //         token: token,
// //       });
// //       console.log("token detail from server:", response.data);
// //       // setResponseData(response.data);

// //       console.log("The role id is : ", response.data.detail.rolename);
// //       setroleid(response.data.detail.rolename);
// //       console.log("the role_id is : ", role_id);

// //       localStorage.setItem("role_id", response.data.detail.rolename);

// //       const id = response.data.detail.rolename;

// //       // getpages();

// //       if (id) {
// //         try {
// //           const response = await axios.get(apiUrl + `/role_2/${id}`);

// //           console.log("the reponse list in brach is : ", response.data.modules);

// //           const modules = response.data.modules;

// //           const filtered_access = modules.filter(
// //             (item) => item.name == "Branch"
// //           );

// //           console.log("the modules is : ", filtered_access[0].actions);

// //           setprevillages(filtered_access[0].actions);

// //           console.log("the assigned previllages is : ", previllages.add);
// //         } catch (error) {
// //           console.error("Error fetching data:", error.message);
// //         }
// //       }
// //     } catch (error) {
// //       console.error("Error fetching data:", error.message);
// //     }
// //   };

// //    const [previllages, setprevillages] = useState({});
// //     const [role_id, setroleid] = useState("");

// // const [getdeleteid, setdeleteid] = useState("");
// //   const [getdeletename, setdeletename] = useState("");
// //    const handleOpenModal = (_id, username) => {
// //     console.log(" the id", _id);
// //     console.log(" the name", username);
// //     setdeleteid(_id);
// //     setdeletename(username);
  
// //   };
  

   
  

// //   return (
// //     <div className="marginup ">
// //       <div className="row">
// //         <div className="col-lg-11">
// //           <Link to="/dashboard" className="text-secondary dashBoard_text">
// //             <b>Dashboard / </b>
// //           </Link>
// //           <Link to="/student" className="text-secondary dashBoard_text">
// //             <b>Leads / </b>
// //           </Link>
// //           <Link to="" className="text-danger dashBoard_text">
// //             <b>Histroy</b>
// //           </Link>
// //         </div>
// //       </div>

// //       <div className="row mt-2">
// //         <div className="col-lg-3">
// //           <h5>
// //             <b>Student Profile</b>
// //           </h5>
// //         </div>
// //         <div className="col-lg-4"></div>
// //         <div className="col-lg-5 ">
// //           <div className="row"></div>
// //         </div>
// //       </div>

// //       <div className="row mt-2">
// //         <div className="col-lg-3 col-sm-3 col-md-3">
// //           <div className="card carddetails p-2">
// //             <div className="imgdetails">
// //               <img
// //                 className="profileimg"
// //                 src={require("../../assets/img/icon.jpg")}
// //                 alt="Profile"
// //               />
// //             </div>
// //             <div className="text-start mt-2 text-white">
// //               <h6 className="profile-detailsed ">
// //                 <i class="fa-solid fa-user px-3"></i>
// //                 <b>{studentss[0]?.student_name}</b>
// //               </h6>
// //               <h6 className="profile-detailsed ">
// //                 <i class="fa-solid fa-phone px-3"></i>
// //                 {studentss[0]?.mobile_no}
// //               </h6>
// //               <h6 className="profile-detailsed ">
// //                 {" "}
// //                 <i class="fa-solid fa-id-card px-3"></i>
// //                 {studentss[0]?.Prefered_course}
// //               </h6>
// //               <h6 className="profile-detailsed ">
// //                 {" "}
// //                 <i class="fa-solid fa-book px-3"></i>
// //                 {studentss[0]?.Source_type}
// //               </h6>
// //               <h6 className="profile-detailsed ">
// //                 {" "}
// //                 <i class="fa-solid fa-calendar-days px-3"></i>
// //                 {formatDatefitching(studentss[0]?.Enquiry_date)}
// //               </h6>
// //               <h6 className="profile-detailsed ">
// //                 {" "}
// //                 <i class="fa-solid fa-recycle px-3"></i> No.of.Attempt :{" "}
// //                 {lead.length}
// //               </h6>
// //             </div>
// //           </div>
// //         </div>

// //         <div className="col-lg-9 col-sm-9 col-md-9">
// //           <div className="row cardborder ">
// //             <div className="card cardborder p-1">
// //               <div className="row p-2">
// //                 <div className="col-lg-6">
// //                   <b>Histroy</b>
// //                 </div>
// //               </div>
// //             </div>
// //             <div className="card cardborder">
// //               <table className="table table-striped responsive">
// //                 <thead>
// //                   <tr>
// //                     <th>S.No</th>
// //                     <th>Date</th>
// //                     <th>Contact Type</th>
// //                     {/* <th>Source Type</th> */}

// //                     <th>Staff</th>
// //                     <th>Notes</th>
// //                     <th>Status</th>
// //                     <th>Action</th>
// //                   </tr>
// //                 </thead>
// //                 <tbody>
// //                   {lead.map((item, index) => (
// //                     <tr key={index}>
// //                       <td>{index + 1}</td>
// //                       <td>{formatDatefitching(item.Enquiry_date)}</td>
// //                       <td>{item.contact_type}</td>
// //                       <td>{idlocal || ""}</td>

// //                       <td>{item.notes}</td>
// //                       <td>{item.status}</td>
// //                       <td className="text-center">
// //                         {/* <button
// //                           className="btn delete change"
// //                           onClick={() => handleDelete(item.id)}
// //                         >
// //                           <i className="fa-solid fa-trash-can"></i>
// //                         </button> */}

// // {item.role_detail[0]?.rolename !== "superadmin" &&
// //                       previllages.delete && (
// //                         <button
// //                           className="btn delete"
// //                           onClick={() =>
// //                             handleOpenModal(item._id, item.username)
// //                           }
// //                         >
// //                           <i className="fa-solid fa-trash-can"></i>
// //                         </button>
// //                       )}
// //                       </td>
// //                     </tr>
// //                   ))}
// //                 </tbody>
// //               </table>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default Leadhistroy;





// import React, { useEffect, useState } from "react";
// import "../../assets/css/Studentinfo.css";
// import axios from "axios";
// import apiUrl from "../../environment/Api";
// import { useParams, Link } from "react-router-dom";
// import { toast } from "react-toastify";

// function Leadhistroy() {
//   const { id } = useParams();
//   const [lead, setLead] = useState([]);
//   const [studentss, setStudent] = useState([]);
//   const [idlocal, setLocalId] = useState("");
//   const [previllages, setPrevillages] = useState({});
//   const [roleId, setRoleId] = useState("");
//   const [getDeleteId, setDeleteId] = useState("");
//   const [getDeleteName, setDeleteName] = useState("");

//   useEffect(() => {
//     fetchDataed();
//     const username = localStorage.getItem("username");
//     setLocalId(username);
//   }, [id]);

//   useEffect(() => {
//     fetchRoleDetails();
//   }, []);

//   const fetchDataed = async () => {
//     try {
//       const response = await axios.get(`${apiUrl}/leadhistroy/${id}`);
//       const responsed = await axios.get(`${apiUrl}/leads/${id}`);
//       setLead(response.data);
//       setStudent(responsed.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const fetchRoleDetails = async () => {
//     const token = localStorage.getItem("token");
//     try {
//       const response = await axios.post(`${apiUrl}/get_detail`, { token });
//       const roleName = response.data.detail.rolename;
//       setRoleId(roleName);
//       localStorage.setItem("role_id", roleName);

//       const roleResponse = await axios.get(`${apiUrl}/role_2/${roleName}`);
//       const modules = roleResponse.data.modules;
//       const branchAccess = modules.find((module) => module.name === "Branch");
//       if (branchAccess) {
//         setPrevillages(branchAccess.actions);
//       }
//     } catch (error) {
//       console.error("Error fetching role details:", error.message);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`${apiUrl}/leadhistroy/${id}`);
//       fetchDataed();
//     } catch (error) {
//       console.error("Error deleting entry:", error.message);
//     }
//   };

//   const handleOpenModal = (id, username) => {
//     if (previllages.delete || previllages.all) {
//       setDeleteId(id);
//       setDeleteName(username);
              
//             } else {
//                 toast.error("You don't have the permission");
//             }
//   };


  

//   const formatDate = (isoDate) => {
//     if (!isoDate) return "";
//     const date = new Date(isoDate);
//     return date.toISOString().split("T")[0];
//   };

//   return (
//     <div className="marginup">
//       <div className="row">
//         <div className="col-lg-11">
//           <Link to="/dashboard" className="text-secondary dashBoard_text">
//             <b>Dashboard / </b>
//           </Link>
//           <Link to="/student" className="text-secondary dashBoard_text">
//             <b>Leads / </b>
//           </Link>
//           <Link to="" className="text-danger dashBoard_text">
//             <b>History</b>
//           </Link>
//         </div>
//       </div>

//       <div className="row mt-2">
//         <div className="col-lg-3">
//           <h5>
//             <b>Student Profile</b>
//           </h5>
//         </div>
//       </div>

//       <div className="row mt-2">
//         <div className="col-lg-3 col-sm-3 col-md-3">
//           <div className="card carddetails p-2">
//             <div className="imgdetails">
//               <img
//                 className="profileimg"
//                 src={require("../../assets/img/icon.jpg")}
//                 alt="Profile"
//               />
//             </div>
//             <div className="text-start mt-2 text-white">
//               <h6 className="profile-detailsed">
//                 <i className="fa-solid fa-user px-3"></i>
//                 <b>{studentss[0]?.student_name}</b>
//               </h6>
//               <h6 className="profile-detailsed">
//                 <i className="fa-solid fa-phone px-3"></i>
//                 {studentss[0]?.mobile_no}
//               </h6>
//               <h6 className="profile-detailsed">
//                 <i className="fa-solid fa-id-card px-3"></i>
//                 {studentss[0]?.Prefered_course}
//               </h6>
//               <h6 className="profile-detailsed">
//                 <i className="fa-solid fa-book px-3"></i>
//                 {studentss[0]?.Source_type}
//               </h6>
//               <h6 className="profile-detailsed">
//                 <i className="fa-solid fa-calendar-days px-3"></i>
//                 {formatDate(studentss[0]?.Enquiry_date)}
//               </h6>
//               <h6 className="profile-detailsed">
//                 <i className="fa-solid fa-recycle px-3"></i> No. of Attempts: {lead.length}
//               </h6>
//             </div>
//           </div>
//         </div>

//         <div className="col-lg-9 col-sm-9 col-md-9">
//           <div className="row cardborder">
//             <div className="card cardborder p-1">
//               <div className="row p-2">
//                 <div className="col-lg-6">
//                   <b>History</b>
//                 </div>
//               </div>
//             </div>

//             <div className="card cardborder">
//               <table className="table table-striped responsive">
//                 <thead>
//                   <tr>
//                     <th>S.No</th>
//                     <th>Date</th>
//                     <th>Contact Type</th>
//                     <th>Staff</th>
//                     <th>Notes</th>
//                     <th>Status</th>
//                     <th>Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {lead.map((item, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{formatDate(item.Enquiry_date)}</td>
//                       <td>{item.contact_type}</td>
//                       <td>{idlocal || ""}</td>
//                       <td>{item.notes}</td>
//                       <td>{item.status}</td>
//                       <td className="text-center">
//                       {item.role_detail?.rolename !== "superadmin" &&
//                       previllages.delete && (
//                         <button
//                           className="btn delete"
//                           onClick={() =>
//                             handleDelete(item._id, item.username)
//                           }
//                         >
//                           <i className="fa-solid fa-trash-can"></i>
//                         </button>
//                       )}
//                     {/* { item.role_detail[0]?.rolename === 'superadmin' &&( <button  onClick={passwordsubmit}><i class="fa-solid fa-lock"></i></button>)} */}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Leadhistroy;



import React, { useEffect, useState } from "react";
import "../../assets/css/Studentinfo.css";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

function Leadhistroy() {
  const { id } = useParams();
  const [lead, setLead] = useState([]);
  const [studentss, setStudent] = useState([]);
  const [idlocal, setLocalId] = useState("");
  const [previllages, setPrevillages] = useState({});
  const [roleId, setRoleId] = useState("");
  const [getDeleteId, setDeleteId] = useState("");
  const [getDeleteName, setDeleteName] = useState("");

  useEffect(() => {
    fetchDataed();
    const username = localStorage.getItem("username");
    setLocalId(username);
  }, [id]);

  useEffect(() => {
    fetchRoleDetails();
  }, []);

  const fetchDataed = async () => {
    try {
      const response = await axios.get(`${apiUrl}/leadhistroy/${id}`);
      const responsed = await axios.get(`${apiUrl}/leads/${id}`);
      setLead(response.data);
      setStudent(responsed.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchRoleDetails = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${apiUrl}/get_detail`, { token });
      const roleName = response.data.detail.rolename;
      setRoleId(roleName);
      localStorage.setItem("role_id", roleName);

      const roleResponse = await axios.get(`${apiUrl}/role_2/${roleName}`);
      const modules = roleResponse.data.modules;
      const branchAccess = modules.find((module) => module.name === "Branch");
      if (branchAccess) {
        setPrevillages(branchAccess.actions);
      }
    } catch (error) {
      console.error("Error fetching role details:", error.message);
    }
  };

  const handleDelete = async (id) => {
    // Check if the role is 'superadmin'
    if (roleId === "superadmin") {
      try {
        await axios.delete(`${apiUrl}/leadhistroy/${id}`);
        fetchDataed();
      } catch (error) {
        console.error("Error deleting entry:", error.message);
      }
    } else {
      toast.error("You don't have the permission");
    }
  };

  const handleOpenModal = (id, username) => {
    setDeleteId(id);
    setDeleteName(username);
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0];
  };

  return (
    <div className="marginup">
      <div className="row">
        <div className="col-lg-11">
          <Link to="/dashboard" className="text-secondary dashBoard_text">
            <b>Dashboard / </b>
          </Link>
          <Link to="/student" className="text-secondary dashBoard_text">
            <b>Leads / </b>
          </Link>
          <Link to="" className="text-danger dashBoard_text">
            <b>History</b>
          </Link>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-3">
          <h5>
            <b>Student Profile</b>
          </h5>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-3 col-sm-3 col-md-3">
          <div className="card carddetails p-2">
            <div className="imgdetails">
              <img
                className="profileimg"
                src={require("../../assets/img/icon.jpg")}
                alt="Profile"
              />
            </div>
            <div className="text-start mt-2 text-white">
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-user px-3"></i>
                <b>{studentss[0]?.student_name}</b>
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-phone px-3"></i>
                {studentss[0]?.mobile_no}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-id-card px-3"></i>
                {studentss[0]?.Prefered_course}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-book px-3"></i>
                {studentss[0]?.Source_type}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-calendar-days px-3"></i>
                {formatDate(studentss[0]?.Enquiry_date)}
              </h6>
              <h6 className="profile-detailsed">
                <i className="fa-solid fa-recycle px-3"></i> No. of Attempts: {lead.length}
              </h6>
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-sm-9 col-md-9">
          <div className="row cardborder">
            <div className="card cardborder p-1">
              <div className="row p-2">
                <div className="col-lg-6">
                  <b>History</b>
                </div>
              </div>
            </div>

            <div className="card cardborder">
              <table className="table table-striped responsive">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Contact Type</th>
                    <th>Staff</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {lead.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{formatDate(item.Enquiry_date)}</td>
                      <td>{item.contact_type}</td>
                      <td>{idlocal || ""}</td>
                      <td>{item.notes}</td>
                      <td>{item.status}</td>
                      <td className="text-center">
                        {/* Check if the role is not 'superadmin' */}
                        {item.role_detail?.rolename !== "superadmin" &&
                          previllages.delete && (
                            <button
                              className="btn delete"
                              onClick={() =>
                                handleDelete(item._id, item.username)
                              }
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leadhistroy;
