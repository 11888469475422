// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import apiUrl from "../../environment/Api";
// import "../../assets/css/Addstudent.css";
// import { useNavigate, useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import { Modal, Button } from "react-bootstrap";
// import { useLocation } from "react-router-dom";

// const steps = [
//   "Step 1: Personal Details",
//   "Step 2: Address Details",
//   "Step 3: Confirmation",
// ];

// function Registration() {
//   const [responseDatas, setResponseDatas] = useState([]);
//   const [responseData, setResponseData] = useState([]);
//   const [responseOldData, setResponseOldData] = useState([]);
//   const [branch, setbranch] = useState([]);
//   const [generateregisterData, setgenerateregisterData] = useState([]);
//   // const [updaatedDatass, setupdatedDatass] = useState([]);
//   const [updaatedDatass, setupdatedDatass] = useState({});
//   const [roleId, setroleId] = useState({});
//   const navigate = useNavigate();
//   const handlecancel = () => {
//     navigate("/student");
//   };
//   const location = useLocation();
//   const updatedDatas = location.state?.updatedData;


//   const { id } = useParams();

//   useEffect(() => {
//     setupdatedDatass(updatedDatas)
//     console.log("Received UpdatedData:", updatedDatas);
//     console.log("Received UpdatedData:", updaatedDatass);
//     console.log("Received UpdatedData:", updaatedDatass.Prefered_course);
//     fetchData();
//     fetchD();
//     const roleId = localStorage.getItem("assignId");
//     console.log("Retrieved role_id:", roleId);
//     setroleId(roleId)
//   }, [updatedDatas]);



//   const fetchD = async () => {
//     console.log("The console is working fine 11111111111");
//     try {
//       const response = await axios.get(apiUrl + "/generate_register_no");

//       console.log(
//         "Response from server generate register_no:",
//         response.data.register_no
//       );

//       // Update the state correctly using setAddData
//       setAddData((prevState) => ({
//         ...prevState,
//         register_no: response.data.register_no, // Set the fetched register_no
//       }));
//       setgenerateregisterData(response.data.register_no); // Optionally store it in another state if needed

//       console.log("Schedule", response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   console.log("id is there" + id);
//   const [courseData, getCourseData] = useState([]);
//   const [courseDatas, getCourseDatas] = useState([]);

//   const [coursedata, getCoursedata] = useState([]);

//   const [duration, setduration] = useState([]);

//   const [fees, setfees] = useState([]);

//   const [discount, setdiscount] = useState([]);


//   const fetchData = async () => {
//     try {
//       const response = await axios.get(apiUrl + `/leads/${id}`);
//       const responsecourse = await axios.get(apiUrl + `/course`);
//       const responsecoursees = await axios.get(apiUrl + `/category_list`);
//       getCourseDatas(responsecourse.data);
//       getCourseData(responsecourse.data);
//       getCoursedata(responsecoursees.data);
//       const data = responsecourse.data;

//       if (responsecourse.status === 200) {
//         const filteredCourses = data.filter(
//           (course) => course.category_list === updatedDatas.category
//         );

//         setFilteredData(filteredCourses);




//       }

//       if (responsecourse.status === 200) {

//         const filteredCourses = data.filter(
//           (course) => course.course_name === updatedDatas.Prefered_course
//         );


//         setduration(filteredCourses[0]?.duration)
//         setfees(filteredCourses[0]?.fees)
//         setdiscount(filteredCourses[0]?.discount)
//         console.log("Response from server:filteredCourses.duration", data);
//         console.log("Response from server:filteredCourses.duration", data);
//         console.log("Response from server:filteredCourses.duration", filteredCourses);
//         console.log("Response from server:filteredCourses.duration", filteredCourses[0]?.duration);
//       }


//       console.log("Response from server:", response.data);


//       if (response && response.data) {
//         const data = response.data;
//         const filteredData = data.filter((item) => item.status !== "Converted");
//         setResponseOldData(filteredData[0]);

//         console.log("Response from server:responsecourse.data", filteredData);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   useEffect(() => {
//     fetchDatas();
//     fetchDatased();
//     filterduration();
//   }, [updatedDatas.Prefered_course]);

//   const formatDate = (date) => {
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

//   console.log("data is there", responseData);

//   const [addData, setAddData] = useState({
//     admission_date: "",
//     qualification: "",
//     duractions: "",
//     batch_timings: "",
//     fees: "",
//     discount: "",
//     student_type: "",
//     payment_type: "",
//     father_name: "",
//     Source_type: "",
//     roll_no: "",
//     photo_upload: null, // File input
//     id_proof: null, // File input
//     leads_id: "",
//     //new field
//     date_of_birth: "",
//     trainer: "",
//     mobile_no: "",
//     email: "",
//     Enquiry_date: "",
//     Prefered_course: "",
//     course_id: "",
//     register_no: "",
//     placement: "",
//   });

//   // Use useEffect to set state when responseData is available
//   useEffect(() => {
//     if (responseOldData) {
//       console.log(responseOldData, "responseOldData--");

//       // Find the selected course based on course_id from responseOldData
//       const selectedCourse = courseData.find(
//         (item) => item.course_id === responseOldData.course_id
//       );

//       setAddData((prevState) => ({
//         ...prevState,
//         branch_id: responseOldData.branch_id,
//         course_id: responseOldData.course_id, // Only keep this instance
//         student_name: responseOldData.student_name,
//         qualification: responseOldData.qualification,
//         Source_type: responseOldData.Source_type,
//         assign_staff: responseOldData.assign_staff,
//         mobile_no: responseOldData.mobile_no,
//         email: responseOldData.email,
//         Enquiry_date: responseOldData.Enquiry_date,
//         Prefered_course: responseOldData.Prefered_course,
//         leads_id: responseOldData._id,
//         purpose_enquiry: responseOldData.purpose_enquiry,
//         // Set these fields only if selectedCourse is found
//         fees: selectedCourse ? selectedCourse.fees : "",
//         discount: selectedCourse ? selectedCourse.discount : "",
//         duractions: selectedCourse ? selectedCourse.duration : "",
//       }));
//       console.log("Data is there", responseOldData);
//     }
//   }, [responseOldData, courseData]); // Make sure courseData is included in the dependency array

//   console.log("llolllll", addData);
//   const handlesubmit = async (e) => {
//     // console.log("llolllll handlesubmit");

//     e.preventDefault();

//     const formData = new FormData();
//     formData.append("admission_date", addData.admission_date);
//     formData.append("qualification", addData.qualification);
//     formData.append("duraction", duration);
//     formData.append("batch_timings", addData.batch_timings);
//     formData.append("fees", fees);
//     formData.append("discount", discount);
//     formData.append("student_type", addData.purpose_enquiry);
//     formData.append("payment_type", addData.payment_type);
//     formData.append("roll_no", addData.roll_no);
//     formData.append("leads_id", id.toString().trim());
//     formData.append("father_name", addData.father_name);
//     formData.append("date_of_birth", addData.date_of_birth);
//     formData.append("trainer", addData.trainer);
//     formData.append("course_id", addData.course_id);
//     formData.append("register_no", addData.register_no);
//     formData.append("placement", addData.placement);
//     // //////

//     formData.append("branch_lead", updatedDatas.branch_id);
//     formData.append("student_name_lead", updatedDatas.student_name);
//     formData.append("mobile_no_lead", updatedDatas.mobile_no);
//     formData.append("email_lead", updatedDatas.email);
//     formData.append("Prefered_course_lead", updaatedDatass.Prefered_course);
//     formData.append("category", updaatedDatass.category);
//     formData.append("purpose_enquiry_lead", updatedDatas.purpose_enquiry);
//     formData.append("Enquiry_date_lead", updatedDatas.Enquiry_date);
//     formData.append("Source_type_lead", updatedDatas.Source_type);



//     formData.append("contact_type_lead", updatedDatas.contact_type);
//     formData.append("assign_staff_lead", roleId);
//     formData.append("referal_candidate_name_lead", updatedDatas.referal_candidate_name);
//     formData.append("referal_candidate_lead", updatedDatas.referal_candidate);
//     formData.append("followups_time_lead", updatedDatas.followups_time);
//     formData.append("followups_date_lead", updatedDatas.followups_date);
//     // formData.append("status_date_lead", updatedDatas.status_date);
//     formData.append("status_time_lead", updatedDatas.status_time);
//     formData.append("followups_status_lead", updatedDatas.followups_status);
//     formData.append("followups_notes_lead", updatedDatas.followups_notes);
//     formData.append("interest_lead", updatedDatas.interest);



//     // //////
//     if (addData.photo_upload)
//       formData.append("photo_upload", addData.photo_upload);
//     if (addData.id_proof) formData.append("id_proof", addData.id_proof);

//     const leadsdata = {
//       branch_id: addData.branch_id,
//       student_name: addData.student_name,
//       Source_type: addData.Source_type,
//       assign_staff: addData.assign_staff,
//       qualification: addData.qualification,
//       mobile_no: addData.mobile_no,
//       email: addData.email,
//       Enquiry_date: addData.Enquiry_date,
//       Prefered_course: addData.Prefered_course,
//       followups_status: "converted",
//     };

//     console.log("formdata", leadsdata);

//     // console.log("formdata 22", formData);

//     try {
//       console.log("this is my alla data consolem", formData)
//       const response = await axios.post(`${apiUrl}/student`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       const responseleads = await axios.put(
//         `${apiUrl}/leads/${addData.leads_id}`,
//         leadsdata
//       );



//       console.log("the response : ", response);

//       const student_Id = response.data._id;
//       const duration_time = response.data.duration_time;
//       console.log("the history update post", student_Id);
//       const formDataaa = {
//         student_Id: student_Id,
//         Source_type: "",
//         Enquiry_date: "",
//         contact_type: updatedDatas.contact_type,
//         assign_staff: updatedDatas.assign_staff,
//         notes: updatedDatas.followups_notes,
//         status: updatedDatas.followups_status,
//         duration_time: updatedDatas.duration_time
//       };

//       if (response.status === 200) {
//         console.log("the history formDataaa", formDataaa);
//         const response12 = await axios.post(
//           apiUrl + "/leadhistroy",
//           formDataaa
//         );
//         console.log("the history follow-up", response12);
//         fetchData();
//         navigate("/Studyingstudents");

//       }






//       console.log("Updated:", response, responseleads);

//       toast.success("added successfully!");

//       setAddData({
//         admission_date: "",
//         qualification: "",
//         duractions: "",
//         batch_timings: "",
//         fees: "",
//         discount: "",
//         student_type: "",
//         father_name: "",
//         payment_type: "",
//         date_of_birth: "",
//         roll_no: "",
//         photo_upload: null, // File input
//         id_proof: null, // File input
//         leads_id: "",
//         trainer: "",
//         course_id: "",
//       });

//     } catch (error) {
//       console.error("Error updating data:", error.message);
//     }
//   };

//   const fetchDatas = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/leads");

//       if (response && response.data) {
//         const data = response.data;

//         setResponseDatas(data);
//         console.log("Response from servers63s3s:", data);
//       } else {
//         console.error("No data received from server.");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const handleStatusChange = (e) => {
//     const selectedStatus = e.target.value;
//     console.log("the select status", selectedStatus);
//     setAddData({ ...addData, status: selectedStatus });

//     if (selectedStatus === "Callback") {
//       setShowCallbackDate(true);
//     } else if (selectedStatus === "Converted") {
//       setShowCallbackDate(true);
//     } else {
//       setShowCallbackDate(false);
//       setAddData((prevData) => ({
//         ...prevData,
//         callback_date: "",
//         converted_date: "",
//       }));
//     }
//   };

//   const [studentId, setSutudentId] = useState([]);
//   const [stafftId, setStaffId] = useState([]);
//   const [responsesource, setResponsesource] = useState([]);
//   const [responsepayment, setResponsepayment] = useState([]);
//   const [responsepurpose, setResponsepurpose] = useState([]);
//   const fetchDatased = async () => {
//     try {
//       const response = await axios.get(apiUrl + "/Branch");
//       const responsestudent = await axios.get(apiUrl + "/student");
//       const responsestaff = await axios.get(apiUrl + "/staff");
//       const responsesources = await axios.get(apiUrl + "/source_type");
//       const responsepayments = await axios.get(apiUrl + "/paytype");
//       const responsepurpose = await axios.get(apiUrl + "/enquiry_purpose");

//       console.log("Response from server:", response.data);
//       setResponsesource(responsesources.data);
//       setResponsepurpose(responsepurpose.data);
//       setResponsepayment(responsepayments.data);
//       setbranch(response.data);
//       setSutudentId(responsestudent.data);
//       setStaffId(responsestaff.data);
//     } catch (error) {
//       console.error("Error fetching data:", error.message);
//     }
//   };

//   const [showCallbackDate, setShowCallbackDate] = useState(false);

//   const [referaltype, setreferaltype] = useState("");

//   const handlereferal = (e) => {
//     console.log(e.target.value);

//     setreferaltype(e.target.value);
//   };

//   const [activeStep, setActiveStep] = useState(0);

//   const handleNext = () => {
//     console.log("hi mob", setActiveStep);
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   // Helper function to format ISO date to YYYY-MM-DD
//   const formatDatefitching = (dateString) => {
//     if (!dateString) return "";
//     return dateString.split("T")[0]; // This will return only the date part (yyyy-mm-dd)
//   };

//   // Function to handle course fetching
//   function handelecoursefetch(e) {
//     const selectedCourseId = e.target.value;

//     console.log(selectedCourseId, "Selected Course ID");

//     // Use find() to get the matching course object
//     const selectedCourse = courseData.find(
//       (item) => item.course_id == selectedCourseId
//     );

//     console.log("Selected Course:", selectedCourse);

//     if (selectedCourse) {
//       // Update addData state with selected course details
//       setAddData({
//         ...addData, // Retain other addData values
//         Prefered_course: selectedCourseId, // Set the course name
//         fees: selectedCourse.fees, // Set the fees
//         discount: selectedCourse.discount, // Set the discount
//         duractions: selectedCourse.duration,
//         course_id: selectedCourse.course_id,
//         // Set the duration
//       });
//     } else {
//       console.error("Course not found");
//     }
//   }
//   const [selectedOption, setSelectedOption] = useState("");

//   const handleChangereg = (e) => {
//     setAddData({ ...addData, register_no: e.target.value });
//   };
//   //web Cam
//   const videoRef = useRef(null);
//   const fileInputRef = useRef(null);
//   const canvasRef = useRef(null);
//   const [image, setImage] = useState(null);
//   const [imageFile, setImageFile] = useState(null);
//   const [isWebcamActive, setIsWebcamActive] = useState(false);

//   // Open file input dialog
//   const openFileUpload = () => {
//     setIsWebcamActive(false); // Ensure webcam is turned off when file upload is selected
//     setImage(false);

//     fileInputRef.current.click();
//   };

//   // Handle file input change
//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     setAddData({ ...addData, photo_upload: file });
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImage(reader.result); // Display the uploaded image
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   // Start webcam stream
//   const startWebcam = async () => {
//     setIsWebcamActive(true);
//     setImage(false);

//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//       }
//     } catch (err) {
//       console.error("Error accessing webcam: ", err);
//     }
//   };

//   const captureImage = () => {
//     setImage(false);

//     const video = videoRef.current;
//     const canvas = canvasRef.current;
//     const context = canvas.getContext("2d");

//     if (video && canvas) {
//       // Set canvas size to match video dimensions
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);

//       // Get the data URL of the captured image
//       const imageUrl = canvas.toDataURL("image/png");
//       setImage(imageUrl); // Optional: if you want to display the image in the UI

//       // Convert the data URL to a Blob
//       const byteString = atob(imageUrl.split(",")[1]);
//       const mimeString = imageUrl.split(",")[0].split(":")[1].split(";")[0];
//       const arrayBuffer = new ArrayBuffer(byteString.length);
//       const uintArray = new Uint8Array(arrayBuffer);

//       for (let i = 0; i < byteString.length; i++) {
//         uintArray[i] = byteString.charCodeAt(i);
//       }

//       const blob = new Blob([uintArray], { type: mimeString });

//       // Create a File object from the Blob
//       const file = new File([blob], "captured_image.png", {
//         type: "image/png",
//       });

//       // Set the file in your state
//       setAddData({ ...addData, photo_upload: file });

//       // Stop the webcam after capturing the image
//       stopWebcam();
//     }
//   };

//   // Stop the webcam stream
//   const stopWebcam = () => {
//     if (videoRef.current && videoRef.current.srcObject) {
//       const stream = videoRef.current.srcObject;
//       const tracks = stream.getTracks();
//       tracks.forEach((track) => track.stop()); // Stop each track
//       videoRef.current.srcObject = null;
//       setIsWebcamActive(false);
//     }
//   };
//   function handleIdProof(e) {
//     const file = e.target.files[0];

//     if (file) {
//       setAddData({ ...addData, id_proof: file });

//       const reader = new FileReader();
//       reader.onload = () => {
//         setImageFile(reader.result); // Display the uploaded image as base64
//       };
//       reader.readAsDataURL(file);
//     }
//   }

//   const [filteredData, setFilteredData] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");





//   const handleCourseFetch = (e) => {
//     const selectedCourse = e.target.value;

//     setupdatedDatass((prevData) => ({
//       ...prevData,
//       category: selectedCourse,
//     }));
//     console.log("Filtered Courses:selectedCourse", selectedCourse);
//     console.log("Filtered Courses:course.category_list", updatedDatas.category);
//     const filteredCourses = courseDatas.filter(
//       (course) => course.category_list === selectedCourse
//     );

//     setFilteredData(filteredCourses);
//     console.log("Filtered Courses:", filteredData);
//   };

//   const filterfunction = (e) => {

//     console.log("Filtered Courses:filteredDatafilteredData", filteredData);
//   }


//   const [filteredDatas, setFilteredDatas] = useState([]);

//   const handlecourses = (e) => {
//     const selectedCourse = e.target.value;
//     setupdatedDatass((prevData) => ({
//       ...prevData,
//       Prefered_course: selectedCourse,
//     }));

//     const filteredCourses = courseData.filter(
//       (course) => course.course_name === selectedCourse
//     );


//     setduration(filteredCourses[0]?.duration)
//     setfees(filteredCourses[0]?.fees)
//     setdiscount(filteredCourses[0]?.discount)

//     //     setupdatedDatass((prevData) => ({
//     //       ...prevData,
//     //       duraction: duration,
//     //       fees: fees,
//     //       discount: discount,
//     //     }));
//     // console.log("this is dur",updaatedDatass.duraction)
//   }


//   // 
//   const [filteredDataduration, setFilteredDataduration] = useState([]);

//   const filterduration = (e) => {
//     console.log("Filtered Courses:course.category_list", updaatedDatass.Prefered_course);
//     const filteredCourses = courseData.filter(
//       (course) => course.course_name === updatedDatas.Prefered_course
//     );

//     setFilteredDataduration(filteredCourses);
//     console.log("Filtered Courses:filteredDatafilteredData", filteredDataduration);
//     console.log("Filtered Courses:filteredDatas1212", filteredDataduration[0]?.duration)
//   }


//   const changetype = (e) => {
//     const selectedtype = e.target.value
//     console.log("Filtered Courses:selectedtype", selectedtype);
//     setAddData((prevData) => ({
//       ...prevData,
//       purpose_enquiry: selectedtype

//     }))
//   }
//   return (
//     <>
//       <div
//         className="style"
//         style={{ marginTop: "-80px", marginBottom: "30px" }}
//       >
//         <div className="insermodal">
//           <div>
//             <h5>Registration</h5>
//             {/* <form onSubmit={handlesubmit}> */}
//             {/* Other input fields */}
//             <div className="row mt-5 ">
//               {activeStep === 0 && (
//                 <div className="col-lg-12" style={{ marginTop: "-25px" }}>
//                   <div className="card  p-4 pb-0">
//                     <h4 className="mt-1 mb-3"> Step 1</h4>
//                     <div className="form-group">
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="floating-label mt-1">
//                             <label className="form-label">
//                               <b></b>
//                             </label>
//                             <select
//                               className="form-select"
//                               placeholder=""
//                               value={addData.branch_id}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   branch_id: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               <option value="">Select Branch </option>
//                               {branch.map((item, index) => (
//                                 <option key={index} value={item._id}>
//                                   {item.branch_name}
//                                 </option>
//                               ))}
//                             </select>
//                             <label
//                               className={addData.branch_id ? "filled" : ""}
//                             ></label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={addData.father_name}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   father_name: e.target.value,
//                                 })
//                               }
//                             />
//                             <label
//                               className={addData.father_name ? "filled" : ""}
//                             >
//                               Father Name
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={addData.student_name} // Controlled input
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   student_name: e.target.value,
//                                 })
//                               } // Handle onChange event
//                               required
//                             />
//                             {/* Conditionally apply the "filled" class to the label if student_name exists */}
//                             <label
//                               className={addData.student_name ? "filled" : ""}
//                             >
//                               Student Name
//                             </label>
//                           </div>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={addData.qualification} // Controlled input
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   qualification: e.target.value,
//                                 })
//                               } // Handle onChange event
//                               required
//                             />
//                             {/* Conditionally apply the "filled" class to the label if student_name exists */}
//                             <label
//                               className={addData.qualification ? "filled" : ""}
//                             >
//                               Qualification
//                             </label>
//                           </div>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="number"
//                               maxLength={10}
//                               value={addData.mobile_no}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   mobile_no: e.target.value,
//                                 })
//                               } // Store the file object
//                               required
//                             />
//                             <label
//                               className={addData.mobile_no ? "filled" : ""}
//                             >
//                               Mobile No
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="date"
//                               value={addData.date_of_birth || ""}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   date_of_birth: e.target.value,
//                                 })
//                               } // Store the file object
//                               required
//                             />
//                             <label
//                               className={addData.date_of_birth ? "filled" : ""}
//                             >
//                               Date_of_birth
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="email"
//                               value={addData.email}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   email: e.target.value,
//                                 })
//                               } // Store the file object
//                               required
//                             />
//                             <label className={addData.email ? "filled" : ""}>
//                               Email
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <select
//                               className="form-control"
//                               //   value={addData._id}
//                               //   onChange={(e) =>
//                               //     setAddData({
//                               //       ...addData,
//                               //       _id: e.target.value,
//                               //     })
//                               //   }
//                               //   required
//                               // >
//                               //   <option value=""> Select Student Type</option>
//                               //   {responsepurpose.map((item, index) => (
//                               //     <option
//                               //       key={index}
//                               //       value={item.enquiry_purpose}
//                               //     >
//                               //       {item.enquiry_purpose}
//                               //     </option>
//                               //   ))}
//                               // </select>
//                               value={addData.purpose_enquiry}
//                               // onChange={(e) =>
//                               //   setAddData({
//                               //     ...addData,
//                               //     purpose_enquiry: e.target.value,
//                               //   })
//                               // }
//                               onChange={changetype}
//                               required
//                             >
//                               <option value="">Select Student Type </option>
//                               {responsepurpose.map((item, index) => (
//                                 <option
//                                   key={index}
//                                   value={item.enquiry_purpose}
//                                 >
//                                   {item.enquiry_purpose}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div>
//                             <p>Upload Image</p>

//                             {/* Buttons for selecting file or webcam */}
//                             <button
//                               onClick={openFileUpload}
//                               className="btn border btn-secondary mx-2"
//                               aria-label="Upload from file"
//                             >
//                               <i className="fa fa-file mx-2 bg-transparent" />
//                               Upload Image
//                             </button>
//                             <button
//                               onClick={startWebcam}
//                               className="mx-2 btn border btn-secondary"
//                               aria-label="Open webcam"
//                             >
//                               <i className="fa fa-camera mx-2" />
//                               Live Take
//                             </button>

//                             {/* Hidden file input for image upload */}
//                             <input
//                               type="file"
//                               ref={fileInputRef}
//                               onChange={handleFileUpload}
//                               accept="image/*"
//                               style={{ display: "none" }}
//                             />

//                             {/* Video element to show webcam stream */}
//                             {isWebcamActive && (
//                               <div>
//                                 <video
//                                   ref={videoRef}
//                                   autoPlay
//                                   width="200"
//                                   height="200"
//                                 />
//                                 <button
//                                   className="mx-2 fs-2 bg-transparent border-0"
//                                   onClick={(e) => captureImage(e)}
//                                   aria-label="Capture image from webcam"
//                                 >
//                                   <i className="fa-solid fa-computer-mouse"></i>
//                                 </button>
//                               </div>
//                             )}

//                             {/* Hidden canvas to capture webcam image */}
//                             <canvas
//                               ref={canvasRef}
//                               style={{ display: "none" }}
//                             />

//                             {/* Display captured or uploaded image */}
//                             {image && (
//                               <div>
//                                 <p>Upload Image Preview</p>
//                                 <img
//                                   src={image}
//                                   alt="Uploaded or Captured"
//                                   width="200"
//                                   height="200"
//                                   className="object-fit-cover"
//                                 />
//                               </div>
//                             )}
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="file"
//                               onChange={handleIdProof} // Store the file object
//                               required
//                             />
//                             <label className={addData.id_proof ? "filled" : ""}>
//                               ID Proof
//                             </label>
//                             {imageFile && (
//                               <div>
//                                 <p>Your Image</p>
//                                 <img
//                                   src={imageFile} // Use imageFile, not image
//                                   alt="Uploaded or Captured"
//                                   width="200"
//                                   height="200"
//                                   className="object-fit-cover"
//                                 />
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}

//               {/* ////////////////////////////////////////////////// */}
//               {activeStep === 1 && (
//                 <div className="col-lg-12" style={{ marginTop: "-25px" }}>
//                   <div className="card p-2 pb-0">
//                     <div className="form-group">
//                       <div className="row">
//                         <h4 className="mt-2 mb-4"> Step 2</h4>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={addData.roll_no}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   roll_no: e.target.value,
//                                 })
//                               }
//                             />
//                             <label className={addData.roll_no ? "filled" : ""}>
//                               Roll Number
//                             </label>
//                           </div>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               disabled
//                               placeholder=""
//                               value={fees}
//                             // value={addData.fees}
//                             // onChange={(e) =>
//                             //   setAddData({ ...addData, fees: e.target.value })
//                             // }
//                             />
//                             <label className={addData.fees ? "filled" : ""}>
//                               fees
//                             </label>
//                           </div>
//                         </div>

//                         {/* <div className="col-lg-6">
//                           <div className="floating-label">
//                             <select
//                               className="form-select"
//                               value={addData.course_id || ""}
//                               onChange={(e) => handelecoursefetch(e)}
//                               required
//                             >
//                               <option disabled value="">
//                                 Select Course
//                               </option>{" "}
                             
//                               {courseData.map((item, index) => (
//                                 <option key={index} value={item.course_id}>
//                                   {item.course_id}-{item.course_name}
//                                 </option>
//                               ))}
//                             </select>
//                             <label
//                               className={
//                                 addData.Prefered_course ? "filled" : ""
//                               }
//                             >
//                               Preferred Course
//                             </label>
//                           </div>
//                         </div> */}

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <select
//                               className="form-select"
//                               value={updaatedDatass.category || ""}
//                               onChange={handleCourseFetch}
//                               required
//                             >
//                               {coursedata.map((item, index) => (
//                                 <option key={index} value={item.category_name}>
//                                   {item.prefix}-{item.category_name}
//                                 </option>
//                               ))}
//                             </select>
//                             {/* <label className={addData.category ? "filled" : ""}>
//                               Course Category
//                             </label> */}
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               disabled
//                               placeholder=""
//                               value={discount}
//                             // value={addData.discount}
//                             // onChange={(e) =>
//                             //   setAddData({
//                             //     ...addData,
//                             //     discount: e.target.value,
//                             //   })
//                             // }
//                             />
//                             <label className={addData.discount ? "filled" : ""}>
//                               Discount
//                             </label>
//                           </div>
//                         </div>

//                         {/* <div className="col-lg-6">
//                           <div className="floating-label">
//                             <select
//                               className="form-select"
//                               value={addData.prefix || ""}
//                               onChange={(e) => handelecoursefetch(e)}
//                               required
//                             >
//                               <option disabled value="">
//                                 Select Course
//                               </option>{" "}
//                               {coursedata.map((item, index) => (
//                                 <option key={index} value={item.prefix}>
//                                   {item.prefix}-{item.category_name}
//                                 </option>
//                               ))}
//                             </select>
//                             <label
//                               className={
//                                 addData.Prefered_course ? "filled" : ""
//                               }
//                             >
//                               Course Category
//                             </label>
//                           </div>
//                         </div> */}

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <select
//                               className="form-select"
//                               value={updaatedDatass.Prefered_course}
//                               onChange={(e) => handlecourses(e)}
//                               required
//                             >

//                               {filteredData.map((item, index) => (
//                                 <option key={index} value={item._id}>
//                                   {item.course_id}-{item.course_name}
//                                 </option>
//                               ))}
//                             </select>
//                             <label
//                               className={
//                                 addData.Prefered_course ? "filled" : ""
//                               }
//                             >

//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="date"
//                               value={
//                                 addData.Enquiry_date
//                                   ? formatDatefitching(addData.Enquiry_date)
//                                   : ""
//                               } // Format the date correctly
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   Enquiry_date: e.target.value,
//                                 })
//                               } // Update the date as entered by the user
//                               required
//                             />
//                             <label
//                               className={addData.Enquiry_date ? "filled" : ""}
//                             >
//                               Enquiry date
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             {/* <select
//                               className="form-select"
//                               value={addData.duration || ""}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   duration: e.target.value,
//                                 })
//                               }
//                               disabled={false}
//                               required
//                             >
//                               <option disabled value="">
//                                 Select a duration
//                               </option>{" "}

//                               {courseData.map((item, index) => (
//                                 <option key={index} value={item.duration}>
//                                   {item.duration}
//                                 </option>
//                               ))}
//                             </select> */}
//                             <input value={duration}
//                               className="form-control"
//                               disabled>
//                             </input>
//                             <label className={addData.duration ? "filled" : ""}>Duration</label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="date"
//                               placeholder=""
//                               value={addData.admission_date}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   admission_date: e.target.value,
//                                 })
//                               }
//                               required
//                             />
//                             <label
//                               className={addData.admission_date ? "filled" : ""}
//                             >
//                               Admission Date
//                             </label>
//                           </div>
//                         </div>

//                         <div className="col-lg-6">



//                           <div className="floating-label">
//                             <input
//                               className="form-control"
//                               type="text"
//                               placeholder=""
//                               value={addData.batch_timings}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   batch_timings: e.target.value,
//                                 })
//                               }
//                             />
//                             <label
//                               className={addData.batch_timings ? "filled" : ""}
//                             >
//                               Batch Timing
//                             </label>
//                           </div>




//                         </div>

//                         <div className="col-lg-6">
//                           {console.log(
//                             addData.Source_type,
//                             "addData.Source_type ---"
//                           )}
//                           {/* <select
//                           className="form-control"
//                           value={addData.Source_type || ""} // Dynamically link to state
//                           onChange={(e) =>
//                             setAddData({
//                               ...addData,
//                               Source_type: e.target.value,
//                             })
//                           }
//                         >
//                            <option value="">Select Source Type </option>
//                                 {responsesource.map((item, index) => (
//                                   <option key={index} value={item.source_type}>
//                                     {item.source_type}
//                                   </option>
//                                 ))}
//                           ))
                        
//                         </select> */}
//                           <select
//                             className="form-select"
//                             placeholder=""
//                             value={addData.Source_type}
//                             onChange={(e) =>
//                               setAddData({
//                                 ...addData,
//                                 Source_type: e.target.value,
//                               })
//                             }
//                             required
//                           >
//                             <option value="">Select Source Type </option>
//                             {responsesource.map((item, index) => (
//                               <option key={index} value={item.source_type}>
//                                 {item.source_type}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <select
//                               className="form-select"
//                               value={addData.payment_type}
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   payment_type: e.target.value,
//                                 })
//                               }
//                               required
//                             >
//                               <option value="">Payment Type</option>

//                               {responsepayment.map((item, index) => (
//                                 <option key={index} value={item.Payment_type}>
//                                   {item.Payment_type}
//                                 </option>
//                               ))}
//                               {/* <option value="G-pay">G-pay</option>
//                               <option value="Phone pay">Phone Pay</option>
//                               <option value="Cash">Cash</option>
//                               <option value="Depite card">Debit Card</option> */}
//                             </select>
//                             <label
//                               className={addData.payment_type ? "filled" : ""}
//                             ></label>
//                           </div>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="floating-label">
//                             <select
//                               className="form-control"
//                               value={addData.trainer || ""} // Ensure it starts with an empty string if not set
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   trainer: e.target.value,
//                                 })
//                               }
//                             >
//                               <option value="" disabled>
//                                 Select Trainer
//                               </option>{" "}
//                               {/* Ensure value is empty for disabled option */}
//                               {stafftId.map((data, index) => (
//                                 <option key={index} value={data.name}>
//                                   {data.name} - {data.empid}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>
//                         </div>

//                         {generateregisterData && (
//                           <div className="col-lg-6">
//                             <div className="floating-label">
//                               <input
//                                 className="form-control"
//                                 type="text"
//                                 placeholder=""
//                                 value={addData.register_no} // Bind the value of register_no to the state
//                                 onChange={(e) =>
//                                   setAddData({
//                                     ...addData,
//                                     register_no: e.target.value, // Update register_no in the state
//                                   })
//                                 }
//                                 required
//                               />
//                               <label
//                                 className={addData.register_no ? "filled" : ""}
//                               >
//                                 Registration No
//                               </label>
//                             </div>
//                           </div>
//                         )}

//                         <div className="col-lg-6">
//                           <label>Placement</label>
//                           <br />
//                           <label>
//                             <input
//                               type="radio"
//                               name="option"
//                               value="yes" // Set the correct value for "yes"
//                               checked={addData.placement === "yes"} // Check if the placement is "yes"
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   placement: e.target.value,
//                                 })
//                               } // Update placement state
//                             />
//                             &nbsp; Yes &nbsp;
//                           </label>
//                           <label>
//                             <input
//                               type="radio"
//                               name="option"
//                               value="no" // Set the correct value for "no"
//                               checked={addData.placement === "no"} // Check if the placement is "no"
//                               onChange={(e) =>
//                                 setAddData({
//                                   ...addData,
//                                   placement: e.target.value,
//                                 })
//                               } // Update placement state
//                             />
//                             &nbsp; No &nbsp;
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>

//         <div style={{ marginTop: "20px" }}>
//           {activeStep === steps.length - 1 ? (
//             <div>
//               <div className="container">
//                 <div className="row" style={{ marginTop: "-60px" }}>
//                   <div className="col-lg-3">
//                     <div className="card carddetails p-2">
//                       <div>
//                         <img
//                           src={image}
//                           alt="Uploaded or Captured"
//                           width="100%"
//                           height="200"
//                           className="object-fit-cover mb-2"
//                         />
//                       </div>
//                       <div className="text-center text-white">
//                         <h6 className="profile-details bg-secondary">
//                           <div className="row text-center">
//                             <div className="col-lg-1">
//                               <i class="fa-solid fa-user "></i>
//                             </div>
//                             <div className="col-lg-10 text-center">
//                               <b>{addData.student_name || ""}</b>
//                             </div>
//                           </div>
//                         </h6>
//                         <h6 className="profile-details bg-secondary">
//                           <div className="row text-center">
//                             <div className="col-lg-1">
//                               <i class="fa-solid fa-phone"></i>
//                             </div>
//                             <div className="col-lg-10 text-center">
//                               <b>{addData.mobile_no || ""}</b>
//                             </div>
//                           </div>
//                         </h6>
//                         <h6 className="profile-details bg-secondary">
//                           <div className="row text-center">
//                             <div className="col-lg-1">
//                               <i class="fa-solid fa-id-card"></i>
//                             </div>
//                             <div className="col-lg-10 text-center">
//                               <b> {addData.register_no || ""}</b>
//                             </div>
//                           </div>
//                         </h6>
//                         <h6 className="profile-details bg-secondary">
//                           <div className="row text-center">
//                             <div className="col-lg-1">
//                               <i class="fa-solid fa-book"></i>
//                             </div>
//                             <div className="col-lg-10 text-center">
//                               <b>{addData.Prefered_course || ""}</b>
//                             </div>
//                           </div>
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-5">
//                     <div className="card cardborder">
//                       <div className="lightblued p-2">
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Branch </strong>
//                           </div>
//                           <div className="col-lg-7 ">
//                             <div className="">
//                               <label className="form-label bg-transparent border-0 text-secondary">
//                                 <b></b>
//                               </label>
//                               <select
//                                 className="form-label bg-transparent border-0 text-secondary"
//                                 disabled
//                                 placeholder=""
//                                 value={addData.branch_id}
//                                 onChange={(e) =>
//                                   setAddData({
//                                     ...addData,
//                                     branch_id: e.target.value,
//                                   })
//                                 }
//                                 required
//                               >
//                                 {branch.map((item, index) => (
//                                   <option key={index} value={item._id}>
//                                     {item.branch_name}
//                                   </option>
//                                 ))}
//                               </select>
//                               <label
//                                 className={addData.branch_id ? "filled" : ""}
//                               ></label>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Roll No </strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.roll_no}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Father Name </strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.father_name}
//                           </div>
//                         </div>
//                         <div className="row mt-1 p-1">
//                           <div className="col-lg-5">
//                             <strong>Qualification</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.qualification || ""}
//                           </div>
//                         </div>

//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Date of Birth</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.date_of_birth || ""}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Email</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.email || ""}
//                           </div>
//                         </div>
//                         <div className="row mt-1 p-1">
//                           <div className="col-lg-5">
//                             <strong>Enquiry Purpose</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.purpose_enquiry || ""}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Source Type</strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.Source_type || ""}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Batch Timing </strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.batch_timings}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Placement </strong>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.placement || ""}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-4">
//                             <strong className="">Trainer</strong>
//                           </div>
//                           <div className="col-lg-8  text-secondary">
//                             {/* {addData.trainer} */}
//                             <div className="">
//                               <select
//                                 className="mx-4 form-control bg-transparent border-0 text-secondary"
//                                 disabled
//                                 value={addData.trainer || ""} // Ensure it starts with an empty string if not set
//                                 onChange={(e) =>
//                                   setAddData({
//                                     ...addData,
//                                     trainer: e.target.value,
//                                   })
//                                 }
//                               >
//                                 {/* Ensure value is empty for disabled option */}
//                                 {stafftId.map((data, index) => (
//                                   <option key={index} value={data.empid}>
//                                     {data.name} - {data.empid}
//                                   </option>
//                                 ))}
//                               </select>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Admission Date</strong>
//                           </div>
//                           <div className="col-lg-7 text-secondary">
//                             {addData.admission_date}
//                           </div>
//                         </div>
//                         <div className="row p-1">
//                           <div className="col-lg-5">
//                             <strong>Enquiry Date </strong>
//                           </div>
//                           <div className="col-lg-7 text-secondary">
//                             {addData.Enquiry_date}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-lg-4">
//                     <div className="card carddetails p-1">
//                       <div className="row p-1">
//                         <div className="col-lg-12 text-danger">
//                           <b>ID Proof</b>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="card carddetails details-data_overall p-1">
//                       <div>
//                         <img
//                           src={imageFile} // Use imageFile, not image
//                           alt="Uploaded or Captured"
//                           width="100%"
//                           height="200vh"
//                           className="object-fit-full"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
//                       <div className="card cardborder ">
//                         {/* <b className="text-danger p-1">Topics Covered</b> */}
//                       </div>
//                       <div className="card cardborder p-1 Completed-profile ">
//                         <div className="row details-datas ">
//                           <div className="col-lg-6 mt-1">
//                             <b> Fees</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {/* {addData.fees} */}
//                             {fees}
//                           </div>
//                         </div>
//                         <div className="row mt-2 details-datas">
//                           <div className="col-lg-6 mt-1">
//                             <b>Discount</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {/* {addData.discount} */}
//                             {discount}
//                           </div>
//                         </div>
//                         <div className="row mt-2 details-datas">
//                           <div className="col-lg-6 mt-1">
//                             <b>Payment Type</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {addData.payment_type || ""}
//                           </div>
//                         </div>
//                         <div className="row mt-2 details-datas">
//                           <div className="col-lg-6 mt-1">
//                             <b>Duration</b>
//                           </div>
//                           <div className="col-lg-6 text-secondary">
//                             {/* {addData.duration_time} */}
//                             {duration}
//                           </div>
//                         </div>
//                       </div>

//                       <div className="row p-3 ">
//                         <div className="text-center ">
//                           <input type="checkbox" id="agree" required />
//                           <label htmlFor="agree " className="mx-2">
//                             I Agree to all conditions
//                           </label>
//                         </div>

//                         <div className="col-lg-4 text-center d-flex justify-content-end">
//                           <button
//                             className="Cancelbut "
//                             variant="secondary"
//                             onClick={handlecancel}
//                           >
//                             Cancel
//                           </button>
//                         </div>
//                         <div className="col-lg-4 d-flex ">
//                           <Button
//                             variant="contained"
//                             style={{
//                               backgroundColor: "blue",
//                               color: "white",
//                             }} // Inline style for background color
//                             disabled={activeStep === 0}
//                             onClick={handleBack}
//                           >
//                             Back
//                           </Button>
//                         </div>
//                         <div className="col-lg-4 text-center d-flex justify-content-start">
//                           <button
//                             className="Submitbut"
//                             type="submit"
//                             variant="primary"
//                             onClick={handlesubmit}
//                           >
//                             Submit
//                           </button>
//                         </div>

//                         {/* <div className="text-center mt-3"></div> */}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <>
//               <Button
//                 variant="contained"
//                 style={{
//                   backgroundColor: "gray",
//                   marginRight: "10px",
//                   color: "white",
//                 }} // Inline style for background color
//                 disabled={activeStep === 0}
//                 onClick={handleBack}
//               >
//                 Back
//               </Button>

//               <Button variant="contained" color="primary" onClick={handleNext}>
//                 Next
//               </Button>
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Registration;



import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import apiUrl from "../../environment/Api";
import "../../assets/css/Addstudent.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const steps = [
  "Step 1: Personal Details",
  "Step 2: Address Details",
  "Step 3: Confirmation",
];

function Registration() {
  const [responseDatas, setResponseDatas] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [responseOldData, setResponseOldData] = useState([]);
  const [branch, setbranch] = useState([]);
  const [generateregisterData, setgenerateregisterData] = useState([]);
  // const [updaatedDatass, setupdatedDatass] = useState([]);
  const [updaatedDatass, setupdatedDatass] = useState({});
  const [roleId, setroleId] = useState({});
  const navigate = useNavigate();
  const handlecancel = () => {
    navigate("/Walkin");
  };
  const location = useLocation();
  const updatedDatas = location.state?.updatedData;


  const { id } = useParams();

  useEffect(() => {
    setupdatedDatass(updatedDatas)
    console.log("Received UpdatedData:", updatedDatas);
    console.log("Received UpdatedData:", updaatedDatass);
    console.log("Received UpdatedData:", updaatedDatass.Prefered_course);
    fetchData();
    fetchD();
    const roleId = localStorage.getItem("assignId");
    console.log("Retrieved role_id:", roleId);
    setroleId(roleId)
  }, [updatedDatas]);



  const fetchD = async () => {
    console.log("The console is working fine 11111111111");
    try {
      const response = await axios.get(apiUrl + "/generate_register_no");

      console.log(
        "Response from server generate register_no:",
        response.data.register_no
      );

      // Update the state correctly using setAddData
      setAddData((prevState) => ({
        ...prevState,
        register_no: response.data.register_no, // Set the fetched register_no
      }));
      setgenerateregisterData(response.data.register_no); // Optionally store it in another state if needed

      console.log("Schedule", response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  console.log("id is there" + id);
  const [courseData, getCourseData] = useState([]);
  const [courseDatas, getCourseDatas] = useState([]);

  const [coursedata, getCoursedata] = useState([]);

  const [duration, setduration] = useState([]);

  const [fees, setfees] = useState([]);

  const [discount, setdiscount] = useState([]);


  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + `/leads/${id}`);
      const responsecourse = await axios.get(apiUrl + `/course`);
      const responsecoursees = await axios.get(apiUrl + `/category_list`);
      getCourseDatas(responsecourse.data);
      getCourseData(responsecourse.data);
      getCoursedata(responsecoursees.data);
      const data = responsecourse.data;

      if (responsecourse.status === 200) {
        const filteredCourses = data.filter(
          (course) => course.category_list === updatedDatas.category
        );

        setFilteredData(filteredCourses);




      }

      if (responsecourse.status === 200) {

        const filteredCourses = data.filter(
          (course) => course.course_name === updatedDatas.Prefered_course
        );


        setduration(filteredCourses[0]?.duration)
        setfees(filteredCourses[0]?.fees)
        setdiscount(filteredCourses[0]?.discount)
        console.log("Response from server:filteredCourses.duration", data);
        console.log("Response from server:filteredCourses.duration", data);
        console.log("Response from server:filteredCourses.duration", filteredCourses);
        console.log("Response from server:filteredCourses.duration", filteredCourses[0]?.duration);
      }


      console.log("Response from server:", response.data);


      if (response && response.data) {
        const data = response.data;
        const filteredData = data.filter((item) => item.status !== "Converted");
        setResponseOldData(filteredData[0]);

        console.log("Response from server:responsecourse.data", filteredData);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchDatased();
    filterduration();
  }, [updatedDatas.Prefered_course]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  console.log("data is there", responseData);

  const [addData, setAddData] = useState({
    admission_date: "",
    qualification: "",
    duractions: "",
    batch_timings: "",
    fees: "",
    discount: "",
    student_type: "",
    payment_type: "",
    father_name: "",
    Source_type: "",
    roll_no: "",
    photo_upload: null, // File input
    id_proof: null, // File input
    leads_id: "",
    //new field
    date_of_birth: "",
    trainer: "",
    mobile_no: "",
    email: "",
    Enquiry_date: "",
    Prefered_course: "",
    course_id: "",
    register_no: "",
    placement: "",

    student_user_id:""
  });

  // Use useEffect to set state when responseData is available
  useEffect(() => {
    if (responseOldData) {
      console.log(responseOldData, "responseOldData--");

      // Find the selected course based on course_id from responseOldData
      const selectedCourse = courseData.find(
        (item) => item.course_id === responseOldData.course_id
      );

      setAddData((prevState) => ({
        ...prevState,
        branch_id: responseOldData.branch_id,
        course_id: responseOldData.course_id, // Only keep this instance
        student_name: responseOldData.student_name,
        qualification: responseOldData.qualification,
        Source_type: responseOldData.Source_type,
        assign_staff: responseOldData.assign_staff,
        mobile_no: responseOldData.mobile_no,
        email: responseOldData.email,
        Enquiry_date: responseOldData.Enquiry_date,
        Prefered_course: responseOldData.Prefered_course,
        leads_id: responseOldData._id,
        purpose_enquiry: responseOldData.purpose_enquiry,
        // Set these fields only if selectedCourse is found
        fees: selectedCourse ? selectedCourse.fees : "",
        discount: selectedCourse ? selectedCourse.discount : "",
        duractions: selectedCourse ? selectedCourse.duration : "",
      }));
      console.log("Data is there", responseOldData);
    }
  }, [responseOldData, courseData]); // Make sure courseData is included in the dependency array

  console.log("llolllll", addData);
  const handlesubmit = async (e) => {
    // console.log("llolllll handlesubmit");

    e.preventDefault();

    const formData = new FormData();
    formData.append("admission_date", addData.admission_date);
    formData.append("qualification", addData.qualification);
    formData.append("duraction", duration);
    formData.append("batch_timings", addData.batch_timings);
    formData.append("fees", fees);
    formData.append("discount", discount);
    formData.append("student_type", addData.purpose_enquiry);
    formData.append("payment_type", addData.payment_type);
    formData.append("roll_no", addData.roll_no);
    formData.append("leads_id", id.toString().trim());
    formData.append("father_name", addData.father_name);
    formData.append("date_of_birth", addData.date_of_birth);
    formData.append("trainer", addData.trainer);
    formData.append("course_id", addData.course_id);
    formData.append("register_no", addData.register_no);
    formData.append("placement", addData.placement);
    formData.append("student_user_id", updatedDatas.student_user_id)
    // //////

    formData.append("branch_lead", updatedDatas.branch_id);
    formData.append("student_name_lead", updatedDatas.student_name);
    formData.append("mobile_no_lead", updatedDatas.mobile_no);
    formData.append("email_lead", updatedDatas.email);
    formData.append("Prefered_course_lead", updaatedDatass.Prefered_course);
    formData.append("category", updaatedDatass.category);
    formData.append("purpose_enquiry_lead", updatedDatas.purpose_enquiry);
    formData.append("Enquiry_date_lead", updatedDatas.Enquiry_date);
    formData.append("Source_type_lead", updatedDatas.Source_type);



    formData.append("contact_type_lead", updatedDatas.contact_type);
    formData.append("assign_staff_lead", roleId);
    formData.append("referal_candidate_name_lead", updatedDatas.referal_candidate_name);
    formData.append("referal_candidate_lead", updatedDatas.referal_candidate);
    formData.append("followups_time_lead", updatedDatas.followups_time);
    formData.append("followups_date_lead", updatedDatas.followups_date);
    // formData.append("status_date_lead", updatedDatas.status_date);
    formData.append("status_time_lead", updatedDatas.status_time);
    formData.append("followups_status_lead", updatedDatas.followups_status);
    formData.append("followups_notes_lead", updatedDatas.followups_notes);
    formData.append("interest_lead", updatedDatas.interest);



    // //////
    if (addData.photo_upload)
      formData.append("photo_upload", addData.photo_upload);
    if (addData.id_proof) formData.append("id_proof", addData.id_proof);

    const leadsdata = {
      branch_id: addData.branch_id,
      student_name: addData.student_name,
      Source_type: addData.Source_type,
      assign_staff: addData.assign_staff,
      qualification: addData.qualification,
      mobile_no: addData.mobile_no,
      email: addData.email,
      Enquiry_date: addData.Enquiry_date,
      Prefered_course: addData.Prefered_course,
      followups_status: "converted",
    };

    console.log("formdata", leadsdata);

    // console.log("formdata 22", formData);

    try {
      console.log("this is my alla data consolem", formData)
      const response = await axios.post(`${apiUrl}/student`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const responseleads = await axios.put(
        `${apiUrl}/leads/${addData.leads_id}`,
        leadsdata
      );



      console.log("the response : ", response);

      const student_Id = response.data._id;
      const duration_time = response.data.duration_time;
      console.log("the history update post", student_Id);
      const formDataaa = {
        student_Id: student_Id,
        Source_type: "",
        Enquiry_date: "",
        contact_type: updatedDatas.contact_type,
        assign_staff: updatedDatas.assign_staff,
        notes: updatedDatas.followups_notes,
        status: updatedDatas.followups_status,
        duration_time: updatedDatas.duration_time
      };

      if (response.status === 200) {
        console.log("the history formDataaa", formDataaa);
        const response12 = await axios.post(
          apiUrl + "/leadhistroy",
          formDataaa
        );
        console.log("the history follow-up", response12);
        fetchData();
        navigate("/Studyingstudents");

      }






      console.log("Updated:", response, responseleads);

      toast.success("added successfully!");

      setAddData({
        admission_date: "",
        qualification: "",
        duractions: "",
        batch_timings: "",
        fees: "",
        discount: "",
        student_type: "",
        father_name: "",
        payment_type: "",
        date_of_birth: "",
        roll_no: "",
        student_user_id:"",
        photo_upload: null, // File input
        id_proof: null, // File input
        leads_id: "",
        trainer: "",
        course_id: "",
      });

    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/leads");

      if (response && response.data) {
        const data = response.data;

        setResponseDatas(data);
        console.log("Response from servers63s3s:", data);
      } else {
        console.error("No data received from server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    console.log("the select status", selectedStatus);
    setAddData({ ...addData, status: selectedStatus });

    if (selectedStatus === "Callback") {
      setShowCallbackDate(true);
    } else if (selectedStatus === "Converted") {
      setShowCallbackDate(true);
    } else {
      setShowCallbackDate(false);
      setAddData((prevData) => ({
        ...prevData,
        callback_date: "",
        converted_date: "",
      }));
    }
  };

  const [studentId, setSutudentId] = useState([]);
  const [stafftId, setStaffId] = useState([]);
  const [responsesource, setResponsesource] = useState([]);
  const [responsepayment, setResponsepayment] = useState([]);
  const [responsepurpose, setResponsepurpose] = useState([]);
  const fetchDatased = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      const responsestudent = await axios.get(apiUrl + "/student");
      const responsestaff = await axios.get(apiUrl + "/staff");
      const responsesources = await axios.get(apiUrl + "/source_type");
      const responsepayments = await axios.get(apiUrl + "/paytype");
      const responsepurpose = await axios.get(apiUrl + "/enquiry_purpose");

      console.log("Response from server:", response.data);
      setResponsesource(responsesources.data);
      setResponsepurpose(responsepurpose.data);
      setResponsepayment(responsepayments.data);
      setbranch(response.data);
      setSutudentId(responsestudent.data);
      setStaffId(responsestaff.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [showCallbackDate, setShowCallbackDate] = useState(false);

  const [referaltype, setreferaltype] = useState("");

  const handlereferal = (e) => {
    console.log(e.target.value);

    setreferaltype(e.target.value);
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    console.log("hi mob", setActiveStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (dateString) => {
    if (!dateString) return "";
    return dateString.split("T")[0]; // This will return only the date part (yyyy-mm-dd)
  };

  // Function to handle course fetching
  function handelecoursefetch(e) {
    const selectedCourseId = e.target.value;

    console.log(selectedCourseId, "Selected Course ID");

    // Use find() to get the matching course object
    const selectedCourse = courseData.find(
      (item) => item.course_id == selectedCourseId
    );

    console.log("Selected Course:", selectedCourse);

    if (selectedCourse) {
      // Update addData state with selected course details
      setAddData({
        ...addData, // Retain other addData values
        Prefered_course: selectedCourseId, // Set the course name
        fees: selectedCourse.fees, // Set the fees
        discount: selectedCourse.discount, // Set the discount
        duractions: selectedCourse.duration,
        course_id: selectedCourse.course_id,
        // Set the duration
      });
    } else {
      console.error("Course not found");
    }
  }
  const [selectedOption, setSelectedOption] = useState("");

  const handleChangereg = (e) => {
    setAddData({ ...addData, register_no: e.target.value });
  };
  //web Cam
  const videoRef = useRef(null);
  const fileInputRef = useRef(null);
  const canvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isWebcamActive, setIsWebcamActive] = useState(false);

  // Open file input dialog
  const openFileUpload = () => {
    setIsWebcamActive(false); // Ensure webcam is turned off when file upload is selected
    setImage(false);

    fileInputRef.current.click();
  };

  // Handle file input change
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setAddData({ ...addData, photo_upload: file });
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // Display the uploaded image
      };
      reader.readAsDataURL(file);
    }
  };

  // Start webcam stream
  const startWebcam = async () => {
    setIsWebcamActive(true);
    setImage(false);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error("Error accessing webcam: ", err);
    }
  };

  const captureImage = () => {
    setImage(false);

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (video && canvas) {
      // Set canvas size to match video dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the data URL of the captured image
      const imageUrl = canvas.toDataURL("image/png");
      setImage(imageUrl); // Optional: if you want to display the image in the UI

      // Convert the data URL to a Blob
      const byteString = atob(imageUrl.split(",")[1]);
      const mimeString = imageUrl.split(",")[0].split(":")[1].split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([uintArray], { type: mimeString });

      // Create a File object from the Blob
      const file = new File([blob], "captured_image.png", {
        type: "image/png",
      });

      // Set the file in your state
      setAddData({ ...addData, photo_upload: file });

      // Stop the webcam after capturing the image
      stopWebcam();
    }
  };

  // Stop the webcam stream
  const stopWebcam = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop()); // Stop each track
      videoRef.current.srcObject = null;
      setIsWebcamActive(false);
    }
  };
  function handleIdProof(e) {
    const file = e.target.files[0];

    if (file) {
      setAddData({ ...addData, id_proof: file });

      const reader = new FileReader();
      reader.onload = () => {
        setImageFile(reader.result); // Display the uploaded image as base64
      };
      reader.readAsDataURL(file);
    }
  }

  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");





  const handleCourseFetch = (e) => {
    const selectedCourse = e.target.value;

    setupdatedDatass((prevData) => ({
      ...prevData,
      category: selectedCourse,
    }));
    console.log("Filtered Courses:selectedCourse", selectedCourse);
    console.log("Filtered Courses:course.category_list", updatedDatas.category);
    const filteredCourses = courseDatas.filter(
      (course) => course.category_list === selectedCourse
    );

    setFilteredData(filteredCourses);
    console.log("Filtered Courses:", filteredData);
  };

  const filterfunction = (e) => {

    console.log("Filtered Courses:filteredDatafilteredData", filteredData);
  }


  const [filteredDatas, setFilteredDatas] = useState([]);

  const handlecourses = (e) => {
    const selectedCourse = e.target.value;
    setupdatedDatass((prevData) => ({
      ...prevData,
      Prefered_course: selectedCourse,
    }));

    const filteredCourses = courseData.filter(
      (course) => course.course_name === selectedCourse
    );


    setduration(filteredCourses[0]?.duration)
    setfees(filteredCourses[0]?.fees)
    setdiscount(filteredCourses[0]?.discount)

    //     setupdatedDatass((prevData) => ({
    //       ...prevData,
    //       duraction: duration,
    //       fees: fees,
    //       discount: discount,
    //     }));
    // console.log("this is dur",updaatedDatass.duraction)
  }


  // 
  const [filteredDataduration, setFilteredDataduration] = useState([]);

  const filterduration = (e) => {
    console.log("Filtered Courses:course.category_list", updaatedDatass.Prefered_course);
    const filteredCourses = courseData.filter(
      (course) => course.course_name === updatedDatas.Prefered_course
    );

    setFilteredDataduration(filteredCourses);
    console.log("Filtered Courses:filteredDatafilteredData", filteredDataduration);
    console.log("Filtered Courses:filteredDatas1212", filteredDataduration[0]?.duration)
  }


  const changetype = (e) => {
    const selectedtype = e.target.value
    console.log("Filtered Courses:selectedtype", selectedtype);
    setAddData((prevData) => ({
      ...prevData,
      purpose_enquiry: selectedtype

    }))
  }


  const [isCheckBox, setIsCheckBox] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsCheckBox(e.target.checked); // Updates the state based on the checkbox status
  };




  return (
    <>
      <div
        className="style"
        style={{ marginTop: "-80px", marginBottom: "30px" }}
      >
        <div className="insermodal">
          <div>
            <h5>Registration</h5>
            {/* <form onSubmit={handlesubmit}> */}
            {/* Other input fields */}
            <div className="row mt-5 ">
              {activeStep === 0 && (
                <div className="col-lg-12" style={{ marginTop: "-25px" }}>
                  <div className="card  p-4 pb-0">
                    <h4 className="mt-1 mb-3"> Step 1</h4>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="floating-label mt-1">
                            <label className="form-label">
                              <b></b>
                            </label>
                            <select
                              className="form-select"
                              placeholder=""
                              value={addData.branch_id}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  branch_id: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="">Select Branch </option>
                              {branch.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.branch_name}
                                </option>
                              ))}
                            </select>
                            <label
                              className={addData.branch_id ? "filled" : ""}
                            ></label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.father_name}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  father_name: e.target.value,
                                })
                              }
                            />
                            <label
                              className={addData.father_name ? "filled" : ""}
                            >
                              Father Name
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.student_name} // Controlled input
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  student_name: e.target.value,
                                })
                              } // Handle onChange event
                              required
                            />
                            {/* Conditionally apply the "filled" class to the label if student_name exists */}
                            <label
                              className={addData.student_name ? "filled" : ""}
                            >
                              Student Name
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.qualification} // Controlled input
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  qualification: e.target.value,
                                })
                              } // Handle onChange event
                              required
                            />
                            {/* Conditionally apply the "filled" class to the label if student_name exists */}
                            <label
                              className={addData.qualification ? "filled" : ""}
                            >
                              Qualification
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="number"
                              maxLength={10}
                              value={addData.mobile_no}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  mobile_no: e.target.value,
                                })
                              } // Store the file object
                              required
                            />
                            <label
                              className={addData.mobile_no ? "filled" : ""}
                            >
                              Mobile No
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              value={addData.date_of_birth || ""}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  date_of_birth: e.target.value,
                                })
                              } // Store the file object
                              required
                            />
                            <label
                              className={addData.date_of_birth ? "filled" : ""}
                            >
                              Date_of_birth
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="email"
                              value={addData.email}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  email: e.target.value,
                                })
                              } // Store the file object
                              required
                            />
                            <label className={addData.email ? "filled" : ""}>
                              Email
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-control"
                              //   value={addData._id}
                              //   onChange={(e) =>
                              //     setAddData({
                              //       ...addData,
                              //       _id: e.target.value,
                              //     })
                              //   }
                              //   required
                              // >
                              //   <option value=""> Select Student Type</option>
                              //   {responsepurpose.map((item, index) => (
                              //     <option
                              //       key={index}
                              //       value={item.enquiry_purpose}
                              //     >
                              //       {item.enquiry_purpose}
                              //     </option>
                              //   ))}
                              // </select>
                              value={addData.purpose_enquiry}
                              // onChange={(e) =>
                              //   setAddData({
                              //     ...addData,
                              //     purpose_enquiry: e.target.value,
                              //   })
                              // }
                              onChange={changetype}
                              required
                            >
                              <option value="">Select Student Type </option>
                              {responsepurpose.map((item, index) => (
                                <option
                                  key={index}
                                  value={item.enquiry_purpose}
                                >
                                  {item.enquiry_purpose}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div>
                            <p>Upload Image</p>

                            {/* Buttons for selecting file or webcam */}
                            <button
                              onClick={openFileUpload}
                              className="btn border btn-secondary mx-2"
                              aria-label="Upload from file"
                            >
                              <i className="fa fa-file mx-2 bg-transparent" />
                              Upload Image
                            </button>
                            <button
                              onClick={startWebcam}
                              className="mx-2 btn border btn-secondary"
                              aria-label="Open webcam"
                            >
                              <i className="fa fa-camera mx-2" />
                              Live Take
                            </button>

                            {/* Hidden file input for image upload */}
                            <input
                              type="file"
                              ref={fileInputRef}
                              onChange={handleFileUpload}
                              accept="image/*"
                              style={{ display: "none" }}
                            />

                            {/* Video element to show webcam stream */}
                            {isWebcamActive && (
                              <div>
                                <video
                                  ref={videoRef}
                                  autoPlay
                                  width="200"
                                  height="200"
                                />
                                <button
                                  className="mx-2 fs-2 bg-transparent border-0"
                                  onClick={(e) => captureImage(e)}
                                  aria-label="Capture image from webcam"
                                >
                                  <i className="fa-solid fa-computer-mouse"></i>
                                </button>
                              </div>
                            )}

                            {/* Hidden canvas to capture webcam image */}
                            <canvas
                              ref={canvasRef}
                              style={{ display: "none" }}
                            />

                            {/* Display captured or uploaded image */}
                            {image && (
                              <div>
                                <p>Upload Image Preview</p>
                                <img
                                  src={image}
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="file"
                              onChange={handleIdProof} // Store the file object
                              required
                            />
                            <label className={addData.id_proof ? "filled" : ""}>
                              ID Proof
                            </label>
                            {imageFile && (
                              <div>
                                <p>Your Image</p>
                                <img
                                  src={imageFile} // Use imageFile, not image
                                  alt="Uploaded or Captured"
                                  width="200"
                                  height="200"
                                  className="object-fit-cover"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ////////////////////////////////////////////////// */}
              {activeStep === 1 && (
                <div className="col-lg-12" style={{ marginTop: "-25px" }}>
                  <div className="card p-2 pb-0">
                    <div className="form-group">
                      <div className="row">
                        <h4 className="mt-2 mb-4"> Step 2</h4>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.roll_no}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  roll_no: e.target.value,
                                })
                              }
                            />
                            <label className={addData.roll_no ? "filled" : ""}>
                              Roll Number
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              disabled
                              placeholder=""
                              value={fees}
                            // value={addData.fees}
                            // onChange={(e) =>
                            //   setAddData({ ...addData, fees: e.target.value })
                            // }
                            />
                            <label className={addData.fees ? "filled" : ""}>
                              fees
                            </label>
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.course_id || ""}
                              onChange={(e) => handelecoursefetch(e)}
                              required
                            >
                              <option disabled value="">
                                Select Course
                              </option>{" "}
                             
                              {courseData.map((item, index) => (
                                <option key={index} value={item.course_id}>
                                  {item.course_id}-{item.course_name}
                                </option>
                              ))}
                            </select>
                            <label
                              className={
                                addData.Prefered_course ? "filled" : ""
                              }
                            >
                              Preferred Course
                            </label>
                          </div>
                        </div> */}

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={updaatedDatass.category || ""}
                              onChange={handleCourseFetch}
                              required
                            >
                              {coursedata.map((item, index) => (
                                <option key={index} value={item.category_name}>
                                  {item.prefix}-{item.category_name}
                                </option>
                              ))}
                            </select>
                            {/* <label className={addData.category ? "filled" : ""}>
                              Course Category
                            </label> */}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              disabled
                              placeholder=""
                              value={discount}
                            // value={addData.discount}
                            // onChange={(e) =>
                            //   setAddData({
                            //     ...addData,
                            //     discount: e.target.value,
                            //   })
                            // }
                            />
                            <label className={addData.discount ? "filled" : ""}>
                              Discount
                            </label>
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.prefix || ""}
                              onChange={(e) => handelecoursefetch(e)}
                              required
                            >
                              <option disabled value="">
                                Select Course
                              </option>{" "}
                              {coursedata.map((item, index) => (
                                <option key={index} value={item.prefix}>
                                  {item.prefix}-{item.category_name}
                                </option>
                              ))}
                            </select>
                            <label
                              className={
                                addData.Prefered_course ? "filled" : ""
                              }
                            >
                              Course Category
                            </label>
                          </div>
                        </div> */}

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={updaatedDatass.Prefered_course}
                              onChange={(e) => handlecourses(e)}
                              required
                            >

                              {filteredData.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.course_id}-{item.course_name}
                                </option>
                              ))}
                            </select>
                            <label
                              className={
                                addData.Prefered_course ? "filled" : ""
                              }
                            >

                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              value={
                                addData.Enquiry_date
                                  ? formatDatefitching(addData.Enquiry_date)
                                  : ""
                              } // Format the date correctly
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  Enquiry_date: e.target.value,
                                })
                              } // Update the date as entered by the user
                              required
                            />
                            <label
                              className={addData.Enquiry_date ? "filled" : ""}
                            >
                              Enquiry date
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            {/* <select
                              className="form-select"
                              value={addData.duration || ""}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  duration: e.target.value,
                                })
                              }
                              disabled={false}
                              required
                            >
                              <option disabled value="">
                                Select a duration
                              </option>{" "}

                              {courseData.map((item, index) => (
                                <option key={index} value={item.duration}>
                                  {item.duration}
                                </option>
                              ))}
                            </select> */}
                            <input value={duration}
                              className="form-control"
                              disabled>
                            </input>
                            <label className={addData.duration ? "filled" : ""}>Duration</label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="date"
                              placeholder=""
                              value={addData.admission_date}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  admission_date: e.target.value,
                                })
                              }
                              required
                            />
                            <label
                              className={addData.admission_date ? "filled" : ""}
                            >
                              Admission Date
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-6">



                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={addData.batch_timings}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  batch_timings: e.target.value,
                                })
                              }
                            />
                            <label
                              className={addData.batch_timings ? "filled" : ""}
                            >
                              Batch Timing
                            </label>
                          </div>

                          <div className="floating-label">
                            <input
                              className="form-control"
                              type="email"
                              value={addData.student_user_id}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  student_user_id: e.target.value,
                                })
                              } // Store the file object
                              required
                            />
                            <label className={addData.student_user_id ? "filled" : ""}>
                            Student userId
                            </label>
                          </div>


                        </div>

                        <div className="col-lg-6">
                          {console.log(
                            addData.Source_type,
                            "addData.Source_type ---"
                          )}
                          {/* <select
                          className="form-control"
                          value={addData.Source_type || ""} // Dynamically link to state
                          onChange={(e) =>
                            setAddData({
                              ...addData,
                              Source_type: e.target.value,
                            })
                          }
                        >
                           <option value="">Select Source Type </option>
                                {responsesource.map((item, index) => (
                                  <option key={index} value={item.source_type}>
                                    {item.source_type}
                                  </option>
                                ))}
                          ))
                        
                        </select> */}
                          <select
                            className="form-select"
                            placeholder=""
                            value={addData.Source_type}
                            onChange={(e) =>
                              setAddData({
                                ...addData,
                                Source_type: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select Source Type </option>
                            {responsesource.map((item, index) => (
                              <option key={index} value={item.source_type}>
                                {item.source_type}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-select"
                              value={addData.payment_type}
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  payment_type: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="">Payment Type</option>

                              {responsepayment.map((item, index) => (
                                <option key={index} value={item.Payment_type}>
                                  {item.Payment_type}
                                </option>
                              ))}
                              {/* <option value="G-pay">G-pay</option>
                              <option value="Phone pay">Phone Pay</option>
                              <option value="Cash">Cash</option>
                              <option value="Depite card">Debit Card</option> */}
                            </select>
                            <label
                              className={addData.payment_type ? "filled" : ""}
                            ></label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="floating-label">
                            <select
                              className="form-control"
                              value={addData.trainer || ""} // Ensure it starts with an empty string if not set
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  trainer: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                Select Trainer
                              </option>{" "}
                              {/* Ensure value is empty for disabled option */}
                              {stafftId.map((data, index) => (
                                <option key={index} value={data.name}>
                                  {data.name} - {data.empid}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {generateregisterData && (
                          <div className="col-lg-6">
                            <div className="floating-label">
                              <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={addData.register_no} // Bind the value of register_no to the state
                                onChange={(e) =>
                                  setAddData({
                                    ...addData,
                                    register_no: e.target.value, // Update register_no in the state
                                  })
                                }
                                required
                              />
                              <label
                                className={addData.register_no ? "filled" : ""}
                              >
                                Registration No
                              </label>
                            </div>
                          </div>
                        )}

                        <div className="col-lg-6">
                          <label>Placement</label>
                          <br />
                          <label>
                            <input
                              type="radio"
                              name="option"
                              value="yes" // Set the correct value for "yes"
                              checked={addData.placement === "Yes"} // Check if the placement is "yes"
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  placement: e.target.value,
                                })
                              } // Update placement state
                            />
                            &nbsp; Yes &nbsp;
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="option"
                              value="no" // Set the correct value for "no"
                              checked={addData.placement === "No"} // Check if the placement is "no"
                              onChange={(e) =>
                                setAddData({
                                  ...addData,
                                  placement: e.target.value,
                                })
                              } // Update placement state
                            />
                            &nbsp; No &nbsp;
                          </label>
                        </div>

                        <div className="col-lg-6">
                            <div className="floating-label">
                              <input
                                className="form-control"
                                type="text"
                                placeholder=""
                                value={addData.student_user_id} // Bind the value of register_no to the state
                                onChange={(e) =>
                                  setAddData({
                                    ...addData,
                                    student_user_id: e.target.value, // Update register_no in the state
                                  })
                                }
                                required
                              />
                              <label
                                className={addData.student_user_id ? "filled" : ""}
                              >
                                Student User ID
                              </label>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          {activeStep === steps.length - 1 ? (
            <div>
              <div className="container">
                <div className="row" style={{ marginTop: "-60px" }}>
                  <div className="col-lg-3">
                    <div className="card carddetails p-2">
                      <div>
                        <img
                          src={image}
                          alt="Uploaded or Captured"
                          width="100%"
                          height="200"
                          className="object-fit-cover mb-2"
                        />
                      </div>
                      <div className="text-center text-white">
                        <h6 className="profile-details bg-secondary">
                          <div className="row text-center">
                            <div className="col-lg-1">
                              <i class="fa-solid fa-user "></i>
                            </div>
                            <div className="col-lg-10 text-center">
                              <b>{addData.student_name || ""}</b>
                            </div>
                          </div>
                        </h6>
                        <h6 className="profile-details bg-secondary">
                          <div className="row text-center">
                            <div className="col-lg-1">
                              <i class="fa-solid fa-phone"></i>
                            </div>
                            <div className="col-lg-10 text-center">
                              <b>{addData.mobile_no || ""}</b>
                            </div>
                          </div>
                        </h6>
                        <h6 className="profile-details bg-secondary">
                          <div className="row text-center">
                            <div className="col-lg-1">
                              <i class="fa-solid fa-id-card"></i>
                            </div>
                            <div className="col-lg-10 text-center">
                              <b> {addData.register_no || ""}</b>
                            </div>
                          </div>
                        </h6>
                        <h6 className="profile-details bg-secondary">
                          <div className="row text-center">
                            <div className="col-lg-1">
                              <i class="fa-solid fa-book"></i>
                            </div>
                            <div className="col-lg-10 text-center">
                              <b>{addData.Prefered_course || ""}</b>
                            </div>
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="card cardborder">
                      <div className="lightblued p-2">
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Branch </strong>
                          </div>
                          <div className="col-lg-7 ">
                            <div className="">
                              <label className="form-label bg-transparent border-0 text-secondary">
                                <b></b>
                              </label>
                              <select
                                className="form-label bg-transparent border-0 text-secondary"
                                disabled
                                placeholder=""
                                value={addData.branch_id}
                                onChange={(e) =>
                                  setAddData({
                                    ...addData,
                                    branch_id: e.target.value,
                                  })
                                }
                                required
                              >
                                {branch.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.branch_name}
                                  </option>
                                ))}
                              </select>
                              <label
                                className={addData.branch_id ? "filled" : ""}
                              ></label>
                            </div>
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Roll No </strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.roll_no}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Father Name </strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.father_name}
                          </div>
                        </div>
                        <div className="row mt-1 p-1">
                          <div className="col-lg-5">
                            <strong>Qualification</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.qualification || ""}
                          </div>
                        </div>

                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Date of Birth</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.date_of_birth || ""}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Email</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.email || ""}
                          </div>
                        </div>
                        <div className="row mt-1 p-1">
                          <div className="col-lg-5">
                            <strong>Enquiry Purpose</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.purpose_enquiry || ""}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Source Type</strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.Source_type || ""}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Batch Timing </strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.batch_timings}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Placement </strong>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.placement || ""}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-4">
                            <strong className="">Trainer</strong>
                          </div>
                          <div className="col-lg-8  text-secondary">
                            {/* {addData.trainer} */}
                            <div className="">
                              <select
                                className="mx-4 form-control bg-transparent border-0 text-secondary"
                                disabled
                                value={addData.trainer || ""} // Ensure it starts with an empty string if not set
                                onChange={(e) =>
                                  setAddData({
                                    ...addData,
                                    trainer: e.target.value,
                                  })
                                }
                              >
                                {/* Ensure value is empty for disabled option */}
                                {stafftId.map((data, index) => (
                                  <option key={index} value={data.empid}>
                                    {data.name} - {data.empid}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Admission Date</strong>
                          </div>
                          <div className="col-lg-7 text-secondary">
                            {addData.admission_date}
                          </div>
                        </div>
                        <div className="row p-1">
                          <div className="col-lg-5">
                            <strong>Enquiry Date </strong>
                          </div>
                          <div className="col-lg-7 text-secondary">
                            {addData.Enquiry_date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="card carddetails p-1">
                      <div className="row p-1">
                        <div className="col-lg-12 text-danger">
                          <b>ID Proof</b>
                        </div>
                      </div>
                    </div>
                    <div className="card carddetails details-data_overall p-1">
                      <div>
                        <img
                          src={imageFile} // Use imageFile, not image
                          alt="Uploaded or Captured"
                          width="100%"
                          height="200vh"
                          className="object-fit-full"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
                      <div className="card cardborder ">
                        {/* <b className="text-danger p-1">Topics Covered</b> */}
                      </div>
                      <div className="card cardborder p-1 Completed-profile ">
                        <div className="row details-datas ">
                          <div className="col-lg-6 mt-1">
                            <b> Fees</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {/* {addData.fees} */}
                            {fees}
                          </div>
                        </div>
                        <div className="row mt-2 details-datas">
                          <div className="col-lg-6 mt-1">
                            <b>Discount</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {/* {addData.discount} */}
                            {discount}
                          </div>
                        </div>
                        <div className="row mt-2 details-datas">
                          <div className="col-lg-6 mt-1">
                            <b>Payment Type</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {addData.payment_type || ""}
                          </div>
                        </div>
                        <div className="row mt-2 details-datas">
                          <div className="col-lg-6 mt-1">
                            <b>Duration</b>
                          </div>
                          <div className="col-lg-6 text-secondary">
                            {/* {addData.duration_time} */}
                            {duration}
                          </div>
                        </div>
                      </div>

                      <div className="row p-3 ">
                     
                        <div className="text-center " >
                          <input type="checkbox" id="agree"  checked={isCheckBox}  required onClick={handleCheckboxChange} />
                          <label htmlFor="agree " className="mx-2">
                            I Agree to all conditions
                          </label>
                        </div>

                        <div className="col-lg-4 text-center d-flex justify-content-end">
                          <button
                            className="Cancelbut "
                            variant="secondary"
                            onClick={handlecancel}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-lg-4 d-flex ">
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "blue",
                              color: "white",
                            }} // Inline style for background color
                            disabled={activeStep === 0}
                            onClick={handleBack}
                          >
                            Back
                          </Button>
                        </div>
                        <div className="col-lg-4 text-center d-flex justify-content-start">
                          <button
                            className="btn btn-success"
                            type="submit"
                            variant="primary"
                            onClick={handlesubmit}
                            disabled={!isCheckBox}
                          >
                            Submit
                          </button>
                        </div>

                        {/* <div className="text-center mt-3"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "gray",
                  marginRight: "10px",
                  color: "white",
                }} // Inline style for background color
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>

              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Registration;





