import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import apiUrl from "../../environment/Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function Imcomelist() {
  const [responseData, setResponseData] = useState([]);
  const [responseDatas, setResponseDatas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [branch, setbranch] = useState([]);
  const [student, setstudent] = useState([]);
  const [paymenttype, setpaymenttype] = useState([]);
  const [responseDatased, setResponseDatased] = useState([]);



  const [role_id, setroleid] = useState('');

  const [previllages, setprevillages] = useState({})
  // current date//////////////////////////////
  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date); // Convert input to Date if it's not already
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };


  const [addData, setAddData] = useState({
    branch_id: "",
    type: "",
    date: formatDate(new Date()),
    description: "",
    paid_by: "",
    paid_to: "",
    paytype: "",
    amount: "",
    paid_till_date: "",
    payment_due_date: "",
    next_due_date: "",
    person_name: "",
    contact: "",
  });

  const handleDateChange = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      date: e.target.value, // Input type=date handles the format
    }));
  };



  const handleDateChanged = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      paid_till_date: e.target.value, // Input type=date handles the format
    }));
  };


  const handleDateChangedd = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      payment_due_date: e.target.value, // Input type=date handles the format
    }));
  };


  const handleDateChangeddd = (e) => {
    setUpdateData((prevData) => ({
      ...prevData,
      next_due_date: e.target.value, // Input type=date handles the format
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + "/income_list", {
        params: { data: localStorage.getItem("branch_id") },
      });
      console.log("Response from server:12", response.data);
      setResponseData(response.data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const [updateData, setUpdateData] = useState({
    branch_id: "",
    type: "",
    date: formatDate(new Date()),
    description: "",
    paid_by: "",
    paid_to: "",
    paytype: "",
    amount: "",
    paid_till_date: "",
    payment_due_date: "",
    next_due_date: "",
    person_name: "",
    contact: "",
  });

  useEffect(() => {
    fetchDatas();
    fetchDatased();
    gettoken();
    studyingstudent();
    paymenttyperoute();
  }, []);


  // const fetchDatas = async () => {
  //   try {
  //     const response = await axios.get(apiUrl + "/income_type");
  //     console.log("Response from server type:", response.data);
  //     setResponseDatas(response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //   }
  // };
  const fetchDatas = async () => {
    try {
      const response = await axios.get(apiUrl + "/income_type");
      console.log("Response from server type:", response.data);
      // Ensure the response.data is an array before setting it
      if (Array.isArray(response.data)) {
        setResponseDatas(response.data);
      } else {
        setResponseDatas([]); // Fallback to an empty array if data is not an array
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setResponseDatas([]); // Optionally handle this case by setting an empty array
    }
  };


  const fetchDatased = async () => {
    try {
      const response = await axios.get(apiUrl + "/Branch");
      console.log("Response from server brach :", response.data);
      setResponseDatased(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };



  const studyingstudent = async () => {
    try {
      const response = await axios.get(apiUrl + "/studingstudent");
      console.log("Response from server:", response.data);
      setstudent(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };


  const paymenttyperoute = async () => {
    try {
      const response = await axios.get(apiUrl + "/paytype");
      console.log("Response from server:", response.data);
      setpaymenttype(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };



  // const handleNewButtonClick = () => {
  //   setShowModal(true);
  // };



  const gettoken = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(apiUrl + "/get_detail", { "token": token });
      console.log("token detail from server:", response.data);


      setroleid(response.data.detail.rolename);

      localStorage.setItem('role_id', response.data.detail.rolename);

      const id = response.data.detail.rolename

      if (id) {
        try {
          const response = await axios.get(apiUrl + `/role_2/${id}`);


          const modules = response.data.modules;

          const filtered_access = modules.filter(item => item.name == "Branch")


          setprevillages(filtered_access[0].actions);



        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }


  const handlesubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {

    }

    try {
      const formData = {
        // branch_id: addData.branch_id,
        // type: addData.type,
        // date: addData.date,
        // description: addData.description,
        // paid_by: addData.paid_by,
        // paid_to: addData.paid_to,

        // paytype: addData.paytype,
        // amount: addData.amount,


        branch_id: selectedbranch.branch_name,
        type: addData.type || "",  // Ensure type is never undefined
        date: addData.date || formatDate(new Date()),
        description: addData.description || "",
        paid_by: addData.paid_by || "",
        paid_to: addData.paid_to || "",
        paytype: addData.paytype || "",
        amount: addData.amount || "",
        paid_till_date: addData.paid_till_date,
        payment_due_date: addData.payment_due_date,
        next_due_date: addData.next_due_date,
        person_name: addData.person_name,
        contact: addData.contact,


      };
      console.log("the post Formdata:", formData);
      const response = await axios.post(apiUrl + "/income_list", formData);
      console.log("the post income list:", response);
      setShowModal(false);
      fetchData();
      toast.success("added successfully!");
      setAddData({
        branch_id: "",
        type: "",
        date: formatDate(new Date()),
        description: "",
        paid_by: "",
        paid_to: "",
        paytype: "",
        amount: "",
        paid_till_date: "",
        payment_due_date: "",
        next_due_date: "",
        person_name: "",
        contact: "",
      });
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const [updateid, setUpdateId] = useState("");

  const handleUpdate = (_id, data) => {
    setUpdateId(_id);
    console.log(" the data to updata", data);
    setUpdateShowModal(true);
    setUpdateData({
      branch_id: data.branch_id,
      branch_name: data.branch_id,
      // type: data.type[0].type,
      type: data.type[0]._id,
      date: data.date,
      description: data.description,
      paid_by: data.paid_by,
      paid_to: data.paid_to,
      paytype: data.paytype,
      amount: data.amount,
      paid_till_date: data.paid_till_date,
      payment_due_date: data.payment_due_date,
      next_due_date: data.next_due_date,
      person_name: data.person_name,
      contact: data.contact
    });
  };

  const handleupdatesubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        branch_id: updateData.branch_id,
        type: updateData.type,
        date: updateData.date,
        description: updateData.description,
        paid_by: updateData.paid_by,
        paid_to: updateData.paid_to,
        paytype: updateData.paytype,
        amount: updateData.amount,
        paid_till_date: updateData.paid_till_date,
        payment_due_date: updateData.payment_due_date,
        next_due_date: updateData.next_due_date,
        person_name: updateData.person_name,
        contact: updateData.contact
      };
      console.log("the update submit data", formData);
      const response = await axios.put(
        apiUrl + `/income_list/${updateid}`,
        formData
      );
      console.log("response for update", response);
      setUpdateShowModal(false);
      toast.success("Update successfully!");
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(apiUrl + `/income_list/${getdeleteid}`);
      toast.error("Delete successfully!");
      setDeleteShowModals(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting role:", error.message);
    }
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const [deleteShowModals, setDeleteShowModals] = useState(false);
  const handleCloseModal = () => {
    setDeleteShowModals(false);
  };

  const [getdeleteid, setdeleteid] = useState("");
  const [getdeletename, setdeletename] = useState("");
  const handleOpenModal = (_id, description) => {
    console.log(" the id", _id);
    console.log(" the name", description);
    setdeleteid(_id);
    setdeletename(description);
    setDeleteShowModals(true);
  };

  // the pagination ---------------------------------------------------------------------
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = responseData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Search
  const filtered = currentItems.filter((item) =>
    Object.values(item).some(
      (value) =>
        (typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(searchQuery))
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(responseData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };
  // the pagination  end ---------------------------------------------------------------------

  const navigate = useNavigate();

  const handleIncomeType = () => {
    navigate("/incometype");
  };

  // Helper function to format ISO date to YYYY-MM-DD
  const formatDatefitching = (isoDate) => {
    if (!isoDate) return ""; // Return an empty string if no date is provided
    const date = new Date(isoDate);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  };



  const handleBranchSelection = (e) => {
    console.log(responseDatased, "responseDatased", e.target.value);

    // Ensure the types of e.target.value and branch_id are the same
    const selectedBranch = responseDatased.find(
      (item) => item.branch_name === e.target.value.toString()
    );

    if (selectedBranch) {
      console.log("The selected branch is:", selectedBranch);
      setselectedbranch({
        branch_id: selectedBranch.branch_id,
        branch_name: selectedBranch.branch_name,
      });
    } else {
      console.log("No matching branch found.");
    }
  };


  const [selectedbranch, setselectedbranch] = useState({
    branch_id: "",
    branch_name: "",
  });



  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    let isValid = true;

    if (!addData.branch_id) {
      tempErrors.branch_id = "Branch Name is required.";
      isValid = false;
    }

    if (!addData.type) {
      tempErrors.type = "Type is required.";
      isValid = false;
    }

    if (!addData.date) {
      tempErrors.date = "Date is required.";
      isValid = false;
    }

    if (!addData.description) {
      tempErrors.description = "Description is required.";
      isValid = false;
    }

    if (!addData.paid_by) {
      tempErrors.paid_by = "Paid By is required.";
      isValid = false;
    }

    if (!addData.paid_to) {
      tempErrors.paid_to = "Paid To is required.";
      isValid = false;
    }

    if (!addData.paytype) {
      tempErrors.paytype = "Paytype is required.";
      isValid = false;
    }


    if (!addData.amount) {
      tempErrors.amount = "Amount is required.";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  }





  // branch_id: addData.branch_id,
  // type: addData.type,
  // date: addData.date,
  // description: addData.description,
  // paid_by: addData.paid_by,
  // paid_to: addData.paid_to,

  // paytype: addData.paytype,
  // amount: addData.amount,










  return (
    <div className="top">
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Income List</b>
            </Link>
            {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10">
          <div className="d-flex">
            <h5 className="mt-2">Income List</h5>
            <div className="mx-3">

              {previllages.add && (
                <button className="btn  add" onClick={() => setShowModal(true)}>
                  Add
                </button>
              )}


            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-secondary" onClick={handleIncomeType}>
            Income Type
          </button>
        </div>
      </div>

      {/* ------------ search and per page ----------------------------*/}

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show : </label>
            <div>
              <select
                className="form-control w-100 mx-2"
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                {/* <option value='5'>5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <div className="w-full flex-1">
              <form>
                <div className="relative">
                  <input
                    className="form-control w-full bg-white  appearance-none pl-8  dark:bg-gray-950"
                    placeholder="Search ..."
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------end------------------------------------------- */}

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Branch</th>
                <th>Type</th>
                <th>Date</th>
                {/* <th>Notes</th> */}
                <th>Paid By</th>
                <th>Paid To</th>
                <th>Pay Type</th>
                <th>Amount</th>

                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.branch_id}</td>
                  {/* <td>{item.branch_name}</td> */}
                  <td>{item.type}</td>
                  <td>{new Date(item.date).toLocaleDateString()}</td>
                  {/* <td>{item.description}</td> */}
                  <td>{item.paid_by}</td>
                  <td>{item.paid_to}</td>
                  <td>{item.paytype}</td>
                  <td>{item.amount}</td>

                  <td className="text-center">
                    {previllages.edit && (
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleUpdate(item._id, item)}
                      >
                        <i class="fa-solid fa-pen-to-square"></i>
                      </button>)}
                    {previllages.delete && (
                      <button
                        className="btn delete"
                        onClick={() =>
                          handleOpenModal(item._id, item.description)
                        }
                      >
                        <i class="fa-solid fa-trash-can"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Income List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                {/* <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={addData.branch_id}
                    onChange={(e) =>
                      setAddData({ ...addData, branch_id: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Branch </option>
                    {branch.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.branch_name}
                      </option>
                    ))}
                  </select>
                  <label className={addData.branch_id ? "filled" : ""}></label>

                  {errors.branch_id && (
                    <div className="error-text">{errors.branch_id}</div>
                  )}
                </div> */}

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>

                  <select
                    className="form-select"
                    value={selectedbranch.branch_name} // Controlled by state
                    onChange={(e) => handleBranchSelection(e)} // Handles branch selection
                    required
                  >
                    <option value="">Select Branch</option>
                    {responseDatased.map((item, index) => (
                      <option key={index} value={item.branch_name}>
                        {`${item.branch_id}-${item.branch_name}`}
                      </option>
                    ))}
                  </select>

                  <label className={selectedbranch.branch_name ? "filled" : ""}>
                    Select Branch
                  </label>
                </div>

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    placeholder=""
                    value={addData.type}
                    onChange={(e) => setAddData({ ...addData, type: e.target.value })}
                  >
                    <option value="">Select Type</option>
                    {Array.isArray(responseDatas) &&
                      responseDatas.map((item, index) => (
                        <option key={index} value={item.type}>
                          {item.type}
                        </option>
                      ))}
                  </select>

                  <label className={addData.type ? "filled" : ""}>
                    Select type
                  </label>
                  {errors.type && (
                    <div className="error-text">{errors.type}</div>
                  )}
                </div>


                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={addData.date}
                    onChange={handleDateChange}
                    required
                  />
                  <label className={addData.date ? "filled" : ""}>Date</label>
                  {errors.date && (
                    <div className="error-text">{errors.date}</div>
                  )}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.description}
                    onChange={(e) =>
                      setAddData({ ...addData, description: e.target.value })
                    }
                    required
                  />
                  <label className={addData.description ? "filled" : ""}>
                    Notes
                  </label>
                  {errors.description && (
                    <div className="error-text">{errors.description}</div>
                  )}
                </div>


                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={addData.paid_till_date}
                    onChange={(e) =>
                      setAddData({ ...addData, paid_till_date: e.target.value })
                    }
                    required
                  />
                  <label className={addData.paid_till_date ? "filled" : ""}>
                    Paid Till Date
                  </label>

                </div>


                {/* payment_due_date: { type: Date }, */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={addData.payment_due_date}
                    onChange={(e) =>
                      setAddData({ ...addData, payment_due_date: e.target.value })
                    }
                    required
                  />
                  <label className={addData.payment_due_date ? "filled" : ""}>
                    Payment Due Date
                  </label>

                </div>


                {/* next_due_date: { type: Date }, */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={addData.next_due_date}
                    onChange={(e) =>
                      setAddData({ ...addData, next_due_date: e.target.value })
                    }
                    required
                  />
                  <label className={addData.next_due_date ? "filled" : ""}>
                    Next Due Date
                  </label>

                </div>








              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                {/* <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.paid_by}
                    onChange={(e) =>
                      setAddData({ ...addData, paid_by: e.target.value })
                    }
                    required
                  />
                  <label className={addData.paid_by ? "filled" : ""}>Paid By</label>
                </div> */}


                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={addData.paid_by}
                    onChange={(e) =>
                      setAddData({ ...addData, paid_by: e.target.value })
                    }
                  >
                    <option value="">Select Paid By</option>
                    <option value="Parent">Parent</option>
                    <option value="Student">Student</option>
                    <option value="Gardian">Gardian</option>
                    <option value="Others">Others</option>

                  </select>

                  <label className={addData.paid_by ? "filled" : ""}>
                    Select Paid By
                  </label>
                  {errors.paid_by && (
                    <div className="error-text">{errors.paid_by}</div>
                  )}
                </div>


                {/* 
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.paid_to}
                    onChange={(e) =>
                      setAddData({ ...addData, paid_to: e.target.value })
                    }
                    required
                  />
                  <label className={addData.paid_to ? "filled" : ""}>Paid To</label>
                </div> */}

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={addData.paid_to}
                    onChange={(e) =>
                      setAddData({ ...addData, paid_to: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Paid To </option>
                    {student.map((item, index) => (
                      <option key={index} value={item.leads_id[0]?.student_name}>
                        {item.leads_id[0]?.student_name} - {item.roll_no}
                      </option>
                    ))}
                  </select>
                  <label className={addData.paid_to ? "filled" : ""}></label>
                  {errors.paid_to && (
                    <div className="error-text">{errors.paid_to}</div>
                  )}
                </div>



                {/* <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={addData.paytype}
                    onChange={(e) =>
                      setAddData({ ...addData, paytype: e.target.value })
                    }
                  >
                    <option value="">Select Paytype</option>
                    <option value="Googlepay">Google Pay</option>
                    <option value="Phonepay">Phone Pay</option>
                    <option value="Paytm">Paytm</option>
                    <option value="Cash">Cash</option>
                  </select>

                  <label className={addData.paytype ? "filled" : ""}>
                    Select Paytype
                  </label>
                </div> */}

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={addData.paytype}
                    onChange={(e) =>
                      setAddData({ ...addData, paytype: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Paytype </option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={addData.paytype ? "filled" : ""}></label>
                  {errors.paytype && (
                    <div className="error-text">{errors.paytype}</div>
                  )}
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.amount}
                    onChange={(e) =>
                      setAddData({ ...addData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={addData.amount ? "filled" : ""}>
                    Amount
                  </label>
                  {errors.amount && (
                    <div className="error-text">{errors.amount}</div>
                  )}
                </div>








                {/* ========================================================= */}


                {/* paid_till_date: { type: Date }, */}



                {/* person_name: { type: String }, */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={addData.person_name}
                    onChange={(e) =>
                      setAddData({ ...addData, person_name: e.target.value })
                    }
                    required
                  />
                  <label className={addData.person_name ? "filled" : ""}>
                    Person Name
                  </label>

                </div>


                {/* contact: { type: String }, */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=""
                    value={addData.contact}
                    onChange={(e) =>
                      setAddData({ ...addData, contact: e.target.value })
                    }
                    required
                  />
                  <label className={addData.contact ? "filled" : ""}>
                    Contact
                  </label>

                </div>



              </div>
            </div>
          </div>

          {/* Other input fields */}
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handlesubmit}
          >
            {" "}
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isUpdateshowModal}
        onHide={() => setUpdateShowModal(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Income List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.branch_name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        branch_id: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.branch_id ? "filled" : ""}>
                    branch
                  </label>
                </div>

                <div className="floating-label">
                  <select
                    className="form-select"
                    value={updateData.type}
                    placeholder=""
                    onChange={(e) =>
                      setUpdateData({ ...updateData, type: e.target.value })
                    }
                  >
                    {responseDatas.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.type ? "filled" : ""}>

                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={formatDatefitching(updateData.date)}
                    onChange={handleDateChange}
                    required
                  />
                  <label className={updateData.date ? "filled" : ""}>
                    Date
                  </label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.description}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                  <label className={updateData.description ? "filled" : ""}>
                    Notes
                  </label>
                </div>


                {/* paid_till_date */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                 
                    value={formatDatefitching(updateData.paid_till_date)}
                    onChange={handleDateChanged}
                    required
                  />
                  <label className={updateData.paid_till_date ? "filled" : ""}>
                    paid Till Date
                  </label>
                </div>


                {/* payment_due_date */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""
                    value={formatDatefitching(updateData.payment_due_date)}
                    onChange={handleDateChangedd}

                    required
                  />
                  <label className={updateData.payment_due_date ? "filled" : ""}>
                    Payment Due Date
                  </label>
                </div>
                {/*next_due_date*/}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="date"
                    placeholder=""

                    value={formatDatefitching(updateData.next_due_date)}
                    onChange={handleDateChangeddd}
                    required
                  />
                  <label className={updateData.next_due_date ? "filled" : ""}>
                    Next Due Date
                  </label>
                </div>




              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                {/* Other input fields */}

                {/* <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.paid_by}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_by: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.paid_by ? "filled" : ""}>
                    Paid by
                  </label>
                </div> */}

                {/* <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-control"
                    value={updateData.paid_by}
                    onChange={(e) =>
                      setAddData({ ...updateData, paid_by: e.target.value })
                    }
                  >
                    <option value="">Select Paid By</option>
                    <option value="Parent">Parent</option>
                    <option value="Student">Student</option>
                    <option value="Others">Others</option>

                  </select>

                  <label className={updateData.paid_by ? "filled" : ""}>
                    Select Paid By
                  </label>
                </div> */}


                <div className="floating-label">
                  <select
                    className="form-control"
                    value={updateData.paid_by}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_by: e.target.value })
                    }
                  >
                    <option value="Parent">Parent</option>
                    <option value="Student">Student</option>
                    <option value="Others">Others</option>

                  </select>
                  <label className={updateData.paid_by ? "filled" : ""}>
                    Paid By
                  </label>
                </div>

                {/* <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.paid_to}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_to: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.paid_to ? "filled" : ""}>Paid To</label>
                </div> */}

                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={updateData.paid_to}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paid_to: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Paid To </option>
                    {student.map((item, index) => (
                      <option key={index} value={item.leads_id[0]?.student_name}>
                        {item.leads_id[0]?.student_name}- {item.roll_no}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.paid_to ? "filled" : ""}></label>
                </div>



                <div className="floating-label">
                  <label className="form-label">
                    <b></b>
                  </label>
                  <select
                    className="form-select"
                    value={updateData.paytype}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, paytype: e.target.value })
                    }
                    required
                  >
                    <option value="">Select Paid To</option>
                    {paymenttype.map((item, index) => (
                      <option key={index} value={item.Payment_type}>
                        {item.Payment_type}
                      </option>
                    ))}
                  </select>
                  <label className={updateData.paytype ? "filled" : ""}></label>
                </div>





                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.amount}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, amount: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.amount ? "filled" : ""}>
                    Amount
                  </label>
                </div>


                {/* \\\\\\\\\\\\\\\\\\\\\\\\\ */}


                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.person_name}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, person_name: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.person_name ? "filled" : ""}>
                    Person Name
                  </label>
                </div>

                {/* contact */}

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={updateData.contact}
                    onChange={(e) =>
                      setUpdateData({ ...updateData, contact: e.target.value })
                    }
                    required
                  />
                  <label className={updateData.contact ? "filled" : ""}>
                    Contact
                  </label>
                </div>





              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={() => setUpdateShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleupdatesubmit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ----------------delete */}

      <Modal
        className="modal"
        show={deleteShowModals}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <p>Are you sure you want to delete?</p>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label d-flex">
            <h5 className="mx-1">Description :</h5> <h5>{getdeletename} </h5>
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancelbut"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="submitbut"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* _------------------------------pagination button -------------------------------- */}
      <div className="mt-3 pb-5">
        <div className="row">
          <div className="col-lg-10 col-sm-12">
            <nav className="d-flex float-right">
              <ul className="pagination justify-content-center">
                <li
                  className={` page-item ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <button className="page-link" onClick={goToPrevPage}>
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => (
                  <li
                    key={i}
                    className={`mx-2 page-item ${currentPage === i + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link "
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button className="page-link " onClick={goToNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>{" "}
          </div>
          <div className="col-lg-2 col-sm-12 mt-1">
            <div className="float-end">
              <span className="text-dark ">
                Page {currentPage} of {totalPages}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------end ---------------------------------- */}
    </div>
  );
}

export default Imcomelist;
