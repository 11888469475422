import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { Modal, Button } from 'react-bootstrap';
import { useParams, useNavigate, Link } from "react-router-dom";
import * as XLSX from "xlsx";
import excelFile from "../../assets/course_demo.xlsx"; // Update this path to match your file location

function CourseInfo() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    "10000000000000000000000000"
  );
  const [topics, setTopics] = useState("");
  const [modules, setModules] = useState("");
  const [subtopic, setsubtopic] = useState("");
  const [duration, setDuration] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isUpdateshowModal, setUpdateShowModal] = useState(false);
  const { id, course_id } = useParams();
  const navigate = useNavigate();

  const onclickback = () => {
    navigate(`/course`);
  };

  useEffect(() => {
    fetchData();
    fetchdataes();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + `/course_get/${id}`);
      console.log("response from the server  course", response.data);
      setResponseData(response.data[0]);
    } catch (error) {
      console.log("Error Fetching Data: ", error.message);
    }
  };



  const fetchdataes = async () => {
    try {
      const courseinfo = await axios.get(apiUrl + `/courseinfo/${course_id}`);
      console.log("data moved: ", courseinfo.data[0]);
      courseinfo.data.forEach((element) => {
        console.log(element);
        setCourseList((prevList) => [...prevList, element]);
      });
    } catch (error) {
      console.log("Error Fetching Data: ", error.message);
    }
  };

  const handleSubmitCourse = () => {
    if (topics) {
      const newCourse = {
        modules: modules || "",
        topics,
        subtopic,
        duration: duration || "",
        course_object_id: id,
        course_id: responseData.course_id,
        course_name: responseData.course_name,
      };

      if (editingIndex !== null) {
        const updatedCourses = courseList.map((course, index) =>
          index === editingIndex ? newCourse : course
        );
        setCourseList(updatedCourses);
        toast.success("Course updated successfully!");
      } else {
        setCourseList([...courseList, newCourse]);
        toast.success("Course added successfully!");
      }
      console.log("this is add data", courseList);
      setModules("");
      setTopics("");
      setsubtopic("");
      setDuration("");
      setEditingIndex(null);
    } else {
      toast.error("Please fill in both fields.");
    }
  };

  const handleEditCourse = (index) => {
    const courseToEdit = courseList[index];
    setModules(courseToEdit.modules);
    setTopics(courseToEdit.topics);
    setsubtopic(courseToEdit.subtopic);
    setDuration(courseToEdit.duration);
    setEditingIndex(index);
  };

  const handleDeleteCourse = (index) => {
    const updatedCourses = courseList.filter((_, i) => i !== index);
    setCourseList(updatedCourses);
    toast.success("Course deleted successfully!");
  };

  const handleSubmit = async () => {
    const courseListToSubmit = courseList.map((course) => ({
      ...course,
      duration: course.duration || "",
    }));

    try {
      const responseSubmit = await axios.post(
        apiUrl + "/courseinfo",
        courseListToSubmit
      );
      toast.success(responseSubmit.data.message);
      navigate("/course");
    } catch (error) {
      toast.error("Failed to submit courses: " + error.message);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const [headers, ...rows] = jsonData;
        const requiredColumns = ["topics", "subtopic", "duration"]; // Define required columns
        const newCourses = rows
          .map((row) => {
            const course = {
              modules: row[1] || "",
              topics: row[2] || "",
              subtopic: row[3] || "",
              duration: row[4] || "",
              course_object_id: id,
              course_id: responseData.course_id,
              course_name: responseData.course_name,
            };
            return requiredColumns.every(
              (col, index) => row[index] !== undefined
            )
              ? course
              : null;
          })
          .filter(Boolean);

        setCourseList((prevCourses) => [...prevCourses, ...newCourses]);
        console.log("Courses added from file successfully!", newCourses);
        toast.success("Courses added from file successfully!");
      };
      reader.readAsArrayBuffer(file);
    }
  };

  // Function to download the image
  const handleDownload = () => {
    // Create a link element
    const link = document.createElement("a");
    link.href = excelFile;
    link.download = "downloaded-file-name.xlsx"; // The name that the downloaded file will have
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };





const [deleteShowModalmulti, setDeleteShowModalmulti] = useState(false);  // For showing/hiding modal
const [deleteIdmulti, setDeleteIdmulti] = useState('');  // To store the ID of the item to delete
 // To store the name of the item to delete (optional)
const handleOpenModal = () => {
  setDeleteIdmulti(id);  // Set the ID of the item to delete
  // setDeleteName(name);  // Optionally set the name of the item to display in the modal
  setDeleteShowModalmulti(true);  // Show the modal
};


const handleDelete = async (deleteIdmulti) => {
  try {
    console.log("deleteIdmulti",deleteIdmulti)
      const response = await axios.delete(`${apiUrl}/courseinfodelete/${course_id}`);
      console.log("delete data ", response);
      toast.success("Delete successfully!");
      setDeleteShowModalmulti(false);  // Close the modal after deletion
      fetchData();
      window.location.reload();  // Refresh the data after delete
  } catch (error) {
      console.error("Error deleting data:", error.message);
      toast.error("Error deleting item.");
  }
};



  return (
    <>
      <div className="top">
        <div className="" style={{ marginTop: "-80px" }}>
          <div className="row mb-3">
            <div className="col-lg-11">
              <Link to="/dashboard" className="text-secondary dashBoard_text">
                <b>Dashboard/</b>
              </Link>
              <Link to="/course" className="text-secondary dashBoard_text">
                <b>Course/</b>
              </Link>

              <Link to="/courseinfo" className="text-danger dashBoard_text">
                <b>Course Info</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex">
              <h5 className="mt-2">Course Details</h5>
            </div>
          </div>

          <div className="col-lg-2">
            <div className="card p-1 bg-success text-white text-center">
              {responseData.course_name}
            </div>
          </div>

          <div
            className="col-lg-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <input
                type="file"
                id="file-upload"
                accept=".xls,.xlsx"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <label htmlFor="file-upload" className="btn btn-secondary">
                File Upload
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <button onClick={handleDownload} className="btn btn-primary">
              Excel <i class="fa-solid fa-cloud-arrow-down text-white"></i>
            </button>

            
          </div>

          <div className="col-lg-2">
            <button onClick={ handleOpenModal} className="btn btn-danger">
              Delete All  <i class="fa-solid fa-trash-can"></i>
            </button>

    
          </div>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
        </div>
      </div>

      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-3">
            <label>Modules</label>
            <input
              className="form-control"
              value={modules}
              onChange={(e) => setModules(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <label>Topics</label>
            <input
              className="form-control"
              value={topics}
              onChange={(e) => setTopics(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <label>Sub Topic</label>
            <input
              className="form-control"
              value={subtopic}
              onChange={(e) => setsubtopic(e.target.value)}
            />
          </div>
          <div className="col-lg-2">
            <label>Durations</label>
            <input
              type="text"
              className="form-control"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>
          <div className="col-lg-1">
            <button
              className="btn btn-success mt-4"
              onClick={handleSubmitCourse}
            >
              {editingIndex !== null ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>

      {/* <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead>
              <tr className="text-center">
                <th>S.No</th>
                <th>Modules</th>
                <th>Topics</th>
                <th>Sub Topics</th>
                <th>Duration</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {courseList
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((course, index) => (
                  <tr className="text-center" key={index}>
                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                    <td>{course.modules}</td>
                    <td>{course.topics}</td>
                    <td>{course.subtopic}</td>
                    <td>{course.duration}</td>
                    <td className="text-center">
                      <button
                        className="btn updelete mx-2"
                        onClick={() => handleEditCourse(index)}
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        className="btn delete "
                        onClick={() => handleDeleteCourse(index)}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="card">
        <div className="mt-1">
          <table className="table table-striped">
            <thead className="">
              <tr className="">
                <th className="text-start">S.No</th>
                <th className="text-start">Modules</th>
                <th className="text-start">Topics</th>
                <th className="text-start">Sub Topics</th>
                <th className="text-start">Duration</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
          </table>
          {/* Scrollable table body */}
          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            <table className="table table-striped">
              <tbody className="text-start">
                {courseList
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((course, index) => (
                    <tr className="text-center" key={index}>
                      <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                      <td className="text-end">{course.modules}</td>
                      <td className="text-end">{course.topics}</td>
                      <td>{course.subtopic}</td>
                      <td>{course.duration}</td>
                      <td className="text-center">
                        <button
                          className="btn updelete mx-2"
                          onClick={() => handleEditCourse(index)}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button
                          className="btn delete"
                          onClick={() => handleDeleteCourse(index)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-1">
            <button className=" btn btn-secondary" onClick={onclickback}>
              Back
            </button>
          </div>
          <div className="col-lg-9"></div>
          <div className="col-lg-2 text-end">
            <button className="btn btn-secondary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>


      <Modal className="modal" show={deleteShowModalmulti} >
                <Modal.Header>
                    <p>Are you sure you want to delete All Data?</p>
                </Modal.Header>
                <Modal.Body>

{/* 
                    <label className="form-label d-flex">
                        <h5 className='mx-1'>Topics :</h5> <h5>{getdeletename} </h5>
                    </label> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancelbut' variant="secondary" >
                        Close
                    </Button>
                    <Button className='submitbut' variant="primary" onClick={handleDelete} >Delete</Button>
                </Modal.Footer>
            </Modal>
    </>
  );
}

export default CourseInfo;
