import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../assets/css/Calender.css';
import '../../assets/css/Studentinvoice.css'; // Import your CSS styles
import axios from "axios";
import apiUrl from "../../environment/Api";
import { Link, useParams } from "react-router-dom";



const Calender = () => {
  const [calendarData, setCalendarData] = useState([]);
  const [ResponseData, setResponseData] = useState([]);
  const { empid } = useParams();

  useEffect(() => {
    const fetchAllData = async () => {
      await fetchAttendance();
      await fetchLeave();
      await fetchHoliday();
      fetchData();
      addWeekoffs(); // Add week-offs for Saturdays and Sundays
    };

    fetchAllData();
  }, [empid]);


  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl + `/stafff/${empid}`);
      console.log("response from the server  calender", response.data);
      setResponseData(response.data);
    } catch (error) {
      console.log("Error Fetching Data: ", error.message);
    }
  };





  const fetchAttendance = async () => {
    try {
      const response = await axios.get(`${apiUrl}/attendance_overall/${empid}`);
      const formatTime = (time) => {
        if (!time) return 'N/A'; // Handle missing time
        const [hour, minute] = time.split(':');
        const isPM = hour >= 12;
        const formattedHour = isPM ? (hour % 12 || 12) : parseInt(hour, 10);
        const ampm = isPM ? 'PM' : 'AM';
        return `${formattedHour}:${minute} ${ampm}`;
      };

      const formattedAttendance = response.data.map((entry) => ({
        date: entry.date.split('T')[0], // Format date as 'YYYY-MM-DD'
        type: 'attendance',
        details: {
          login: formatTime(entry.login),
          logout: formatTime(entry.logout),
        },
      }));

      setCalendarData((prev) => [...prev, ...formattedAttendance]);
    } catch (error) {
      console.error('Error fetching attendance data:', error.message);
    }
  };

  const fetchLeave = async () => {
    try {
      const response = await axios.get(`${apiUrl}/leave/${empid}`);
      const formattedLeave = response.data.map((entry) => ({
        date: entry.from_date.split('T')[0],
        type: 'leave',
        details: {
          reason: entry.reason || 'No reason provided',
        },
      }));

      setCalendarData((prev) => [...prev, ...formattedLeave]);
    } catch (error) {
      console.error('Error fetching leave data:', error.message);
    }
  };

  const fetchHoliday = async () => {
    try {
      const response = await axios.get(`${apiUrl}/holiday`);
      const formattedHoliday = response.data.map((entry) => ({
        date: entry.Date.split('T')[0],
        type: 'holiday',
        details: {
          name: entry.Holiday_name || 'Unnamed Holiday',
        },
      }));

      setCalendarData((prev) => [...prev, ...formattedHoliday]);
    } catch (error) {
      console.error('Error fetching holiday data:', error.message);
    }
  };

  const addWeekoffs = () => {
    const weekoffs = [];
    const currentYear = new Date().getFullYear();
    const startDate = new Date(`${currentYear}-01-01`);
    const endDate = new Date(`${currentYear}-12-31`);

    const existingDates = new Set(calendarData.map((entry) => entry.date));

    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = date.toISOString().split('T')[0];
      if (!existingDates.has(formattedDate)) {
        const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        // Mark all days (Monday through Sunday) as week-offs
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekoffs.push({
            date: formattedDate,
            type: 'weekoff',
          });
        }
      }
    }

    setCalendarData((prev) => [...prev, ...weekoffs]);
  };



  const formatDate = (date) => date.toLocaleDateString('en-CA');

  const getTileClassName = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = formatDate(date);
      const data = calendarData.find((entry) => entry.date === formattedDate);

      if (data) {
        return `tile-${data.type}`; // Assign class based on type
      }
    }
    return ''; // Default style
  };

  const getTileContent = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = formatDate(date);
      const data = calendarData.find((entry) => entry.date === formattedDate);

      if (data) {
        if (data.type === 'attendance') {
          return (
            <div className="tile-content">
              <span style={{ fontSize: '12px' }}>Login: {data.details.login}</span>
              <br />
              <span style={{ fontSize: '12px' }}>Logout: {data.details.logout}</span>
            </div>
          );
        }
        if (data.type === 'leave') {
          return (
            <div className="tile-content leave">
              <span style={{ fontSize: '12px' }}>{data.details.reason}</span>
            </div>
          );
        }
        if (data.type === 'holiday') {
          return (
            <div className="tile-content holiday">
              <span style={{ fontSize: '12px' }}>{data.details.name}</span>
            </div>
          );
        }
        if (data.type === 'weekoff') {
          return (
            <div className="tile-content weekoff">
              <span style={{ fontSize: '12px' }}>Weekoff</span>
            </div>
          );
        }
      }
    }
    return null;
  };


  return (
    <>
      <div className="" style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/attendance" className="text-secondary dashBoard_text">
              <b>Attendance /</b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b> Calender</b>
            </Link>
          </div>
        </div>

        <h1 className="calendar-title">Staff Details</h1>
        <div className=' card p-3'>
          <div className='row '>
            <div className='col-lg-2'>
              {/* <h1>Branch</h1> */}
              <h1>StaffID</h1>
              <h1>Name</h1>
            </div>
            <div className='col-lg-3'>
              {/* <h1>{ResponseData.branch_id ? ResponseData.branch_id[0].branch_name : '-'}</h1> */}
              <h1> {ResponseData.empid}</h1>
              <h1> {ResponseData.name}</h1>
            </div>
            <div className='col-lg-2'></div>

            <div className='col-lg-2'>
              <div>
                <h1>Department</h1>
                <h1>Designation</h1>
                <h1>Mobile</h1>
              </div>
            </div>

            <div className='col-lg-3'>
              <h1>{ResponseData.designation ? ResponseData.designation[0].department : '-'}</h1>
              <h1>{ResponseData.designation ? ResponseData.designation[0].designation : '-'}</h1>
              <h1> {ResponseData.mobile}</h1>
            </div>
          </div>
        </div>


        <div className="calendar-container">
          <h1 className="calendar-title">Staff Details Calendar</h1>
          <Calendar tileClassName={getTileClassName} tileContent={getTileContent} />
        </div>

      </div>

    </>
  );
};


export default Calender;
