import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import apiUrl from '../../environment/Api';
import { toast } from 'react-toastify';
import { Link, useParams } from "react-router-dom";
import {  useNavigate } from "react-router-dom";

function JobDetails() {

    const [JobDetails, setJobDetails] = useState([]); 
    const [responseData, setResponseData] = useState([]);

    const { id } = useParams();

    useEffect(() => {
      fetchDatas();
      fetchData();
    }, [id]);


    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  
    const fetchDatas = async () => {
      try {
        const responseStaff = await axios.get(apiUrl + `/jobportalinfo/${id}`);
        setJobDetails(responseStaff.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    const fetchData = async () => {
        try {
          const response = await axios.get(apiUrl + "/jobapplication");
          console.log("Response from server:", response.data);
          setResponseData(response.data.reverse());
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      };


    return (
        <>

            <div className="" style={{ marginTop: '-80px' }}>
                <div className="row mb-3">
                    <div className="col-lg-11">
                        <Link to="/dashboard" className="text-secondary dashBoard_text">
                            <b>Dashboard / </b>
                        </Link>
                        <Link to="/jobportal" className="text-secondary dashBoard_text">
                            <b>JobPortal / </b>
                        </Link>
                        <Link to="" className="text-danger dashBoard_text">
                            <b>JobDetails</b>
                        </Link>
                        {/* <Link to="" className="text-danger dashBoard_text">
                            <b>Profile</b>
                        </Link> */}
                    </div>
                </div>
            </div>

            {/* //////=========== */}
            {/* <div className="row">
                <div className="col-lg-10">
                    <h5 className="mt-2">Job Detail </h5>
                </div>

            </div> */}

            <div className='row mt-5'>
                {/* <div className='col-lg-2'></div> */}
                <div className='col-lg-12'>
                <h4><b>Job Details</b></h4>
                    <div className='card'>
                        <div className="col-lg-6 col-md-6 col-sm-6 text-danger">
                        
                        </div>
                        <div className='row mt-1'>
                            <div className='col-lg-6'>

                                <div className="row p-1">
                                    <div className="col-lg-5">
                                        <strong>Title :</strong>
                                    </div>
                                    <div className="col-lg-6 text-secondary">
                                        {JobDetails.designation|| ""}
                                    </div>
                                </div>

                                <div className="row p-1">
                                    <div className="col-lg-5">
                                        <strong>Start :</strong>
                                    </div>
                                    <div className="col-lg-6 text-secondary">
                                  
                                    {JobDetails.start_date
                    ? new Date(JobDetails.start_date).toLocaleDateString(
                        "en-CA"
                      )
                    : ""}
                                    
                                    </div>
                                </div>

                                <div className="row p-1">
                                    <div className="col-lg-5">
                                        <strong>DeadLine :</strong>
                                    </div>
                                    <div className="col-lg-6 text-secondary">
                                    {/* {JobDetails.deadline|| ""} */}
                                    {JobDetails.deadline
                    ? new Date(JobDetails.deadline).toLocaleDateString(
                        "en-CA"
                      )
                    : ""}
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6'>

                            <div className="row p-1">
                                    <div className="col-lg-5">
                                        <strong>Vacancies :</strong>
                                    </div>
                                    <div className="col-lg-6 text-secondary">
                                    {JobDetails.no_of_vacancies|| ""}
                                    </div>
                                </div>


                                <div className="row p-1">
                                    <div className="col-lg-5">
                                        <strong>Location :</strong>
                                    </div>
                                    <div className="col-lg-6 text-secondary">
                                    {JobDetails.location|| ""}
                                    
                                    </div>
                                </div>



                                <div className="row p-1">
                                    <div className="col-lg-5">
                                        <strong>Applied :</strong>
                                    </div>
                                    <div className="col-lg-6 text-secondary">
                                    {JobDetails.applied_candidate|| ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-lg-2'></div> */}
            </div>

<div className='row mt-5'>
<h4><b>Job Application</b></h4>
            <div className='card mt-1'>
              
                <div className="mt-1">
                    <table className="table table-striped "  >
                        <thead >
                            <tr>
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile No </th>
                                <th>Exp</th>
                                <th>Status</th>

                                <th className='text-center'>Action</th>
                            </tr>
                        </thead>

                         <tbody>
                            {responseData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{new Date(item.date).toLocaleDateString()}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.mobile_no}</td>
                                    <td>{item.experience}</td>
                                    <td className="text-center hideres">
                                        <p
                                            className={`text-white ${item.status === "Active" ? "green-label" : "red-label"
                                                }`}
                                        >
                                            {item.status}
                                        </p>
                                    </td>



                            


                                     <td className='text-center'>

                    <button className='btn updelete mx-2' 
                    onClick=''><i class="fa-solid fa-pen-to-square"></i></button>
                    <button className='btn delete'
                      onClick=''
                    > <i class="fa-solid fa-trash-can"></i></button>
                    <button
                      className="btn"
                      onClick=''
                    >
                      <i class="fa-solid fa-circle-info"></i>
                    </button>


                  </td> 
                                </tr>
                            ))}
                        </tbody> 
                    </table>
                </div>
            </div>
            </div>

        </>
    )
}

export default JobDetails
