// import { Modal, Button } from "react-bootstrap";
// import { useState, useEffect } from "react";
// import axios from "axios";
// import apiUrl from "../../environment/Api";
// import { Link } from "react-router-dom";

// function Salary() {
//   const [showModal, setShowModal] = useState(false);
//   const [salaryData, setSalaryData] = useState([]);
//   const [status, setStatus] = useState("");
//   const [reason, setReason] = useState("");
//   const [modalData, setModalData] = useState({
//     staffId: "",
//     staffName: "",
//     designation: "",
//     monthYear: "",
//     salaryPerDay: "",
//     actualSalary: "",
//     paidSalary: "",
//     paidDate: "",
//     detection: "",
//     presentDay: "",
//   });
//   const [searchQuery, setSearchQuery] = useState("");
//   const [showCount, setShowCount] = useState(10);

//   const toggleModal = () => setShowModal(!showModal);

//   const handleRowClick = (data) => {
//     setModalData({
//       staffId: data.empid || "",
//       staffName: data.name || "",
//       designation: data.designation_detail[0]?.designation || "",
//       monthYear: "",
//       salaryPerDay: data.salaryPerDay || "",
//       actualSalary: data.employee[0]?.salary || "",
//       paidSalary: data.paidSalary || "",
//       paidDate: data.paidDate || "",
//       detection: data.detection || "",
//       presentDay: data.presentDay || "",
//     });
//     setStatus(data.status || "");
//     setReason("");
//     toggleModal();
//   };

//   const handleFormSubmit = () => {
//     console.log({ ...modalData, status, reason });
//     setShowModal(false);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const leadsResponse = await axios.get(
//           apiUrl + "/get_current_month_attendance"
//         );
//         setSalaryData(leadsResponse.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const filteredData = salaryData.filter((data) => {
//     return (
//       (data.staffId &&
//         data.staffId.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       (data.empid &&
//         data.empid.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       (data.name &&
//         data.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
//       (data.designation_detail[0]?.designation &&
//         data.designation_detail[0]?.designation
//           .toLowerCase()
//           .includes(searchQuery.toLowerCase()))
//     );
//   });

//   return (
//     <>
//       <div style={{ marginTop: "-80px" }}>
//         <div className="row mb-3">
//           <div className="col-lg-11">
//             <Link to="/dashboard" className="text-secondary dashBoard_text">
//               <b>Dashboard / </b>
//             </Link>
//             <Link to="" className="text-secondary dashBoard_text">
//               <b>Staff / </b>
//             </Link>
//             <Link to="" className="text-danger dashBoard_text">
//               <b>Salary</b>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="row">
//         <div className="col-lg-9 d-flex">
//           <h5 className="mt-2">Salary Details</h5>
//         </div>
//       </div>

//       <div className="mb-3 mt-3">
//         <div className="row">
//           <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
//             <label className="form-label text-dark mt-2">Show: </label>
//             <select
//               className="form-control w-100 mx-2"
//               value={showCount}
//               onChange={(e) => setShowCount(e.target.value)}
//             >
//               <option value="10">10</option>
//               <option value="20">20</option>
//               <option value="50">50</option>
//             </select>
//           </div>
//           <div className="col-lg-7 col-md-4 col-sm-1"></div>
//           <div className="col-lg-3 col-md-4 col-sm-7">
//             <form>
//               <input
//                 className="form-control w-full bg-white pl-8"
//                 placeholder="Search ..."
//                 type="search"
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//               />
//             </form>
//           </div>
//         </div>
//       </div>

//       <div className="card">
//         <table className="table table-striped">
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>Staff ID</th>
//               <th>Staff Name</th>
//               <th>Designation</th>
//               <th>Month & Year</th>
//               <th>Actual Salary</th>
//               <th>Paid Salary</th>
//               <th>Status</th>
//               <th className="text-center">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.length > 0 ? (
//               filteredData.slice(0, showCount).map((data, index) => (
//                 <tr key={index}>
//                   <td>{data.staffId}</td>
//                   <td>{data.empid}</td>
//                   <td>{data.name}</td>
//                   <td>{data.designation_detail[0]?.designation}</td>
//                   <td>None</td>
//                   <td>{data.employee[0]?.salary}</td>
//                   <td>{data.salaryPerDay}</td>
//                   <td>{data.paidSalary}</td>
//                   <td>{data.status}</td>
//                   <td className="text-center">
//                     <button
//                       className="btn btn-sm btn-primary"
//                       onClick={() => handleRowClick(data)}
//                     >
//                       <i className="fa-solid fa-pen-to-square"></i>
//                     </button>
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="10" className="text-center">
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       <Modal show={showModal} onHide={toggleModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Update Salary</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.staffId}
//                     onChange={(e) =>
//                       setModalData({ ...modalData, staffId: e.target.value })
//                     }
//                     required
//                   />
//                   <label className="">Staff ID</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.staffName}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         staffName: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Staff Name</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.designation}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         designation: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Designation</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.monthYear}
//                     onChange={(e) =>
//                       setModalData({ ...modalData, monthYear: e.target.value })
//                     }
//                     required
//                   />
//                   <label className="">Month & Year</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.salaryPerDay}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         salaryPerDay: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Salary PerDay</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.actualSalary}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         actualSalary: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Actual Salary</label>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6">
//               <div className="form-group">
//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.paidSalary}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         paidSalary: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Paid Salary</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.paidDate}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         paidDate: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Paid Date</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.detection}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         detection: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Detection</label>
//                 </div>

//                 <div className="floating-label">
//                   <input
//                     className="form-control"
//                     type="text"
//                     placeholder=""
//                     value={modalData.presentDay}
//                     onChange={(e) =>
//                       setModalData({
//                         ...modalData,
//                         presentDay: e.target.value,
//                       })
//                     }
//                     required
//                   />
//                   <label className="">Present Day</label>
//                 </div>

//                 <div className="floating-label">
//                   {" "}
//                   <label className="form-label">Select Status</label>{" "}
//                   <select
//                     className="form-control"
//                     value={status}
//                     onChange={(e) => setStatus(e.target.value)}
//                   >
//                     {" "}
//                     <option value="">Select Status</option>{" "}
//                     <option value="Approved">Paid</option>{" "}
//                     <option value="Pending">Pending</option>{" "}
//                     <option value="Rejected">Process</option>{" "}
//                   </select>{" "}
//                 </div>

//                 {status === "Pending" && (
//                   <div className="floating-label">
//                     <input
//                       className="form-control"
//                       type="text"
//                       placeholder=""
//                       value={reason}
//                       onChange={(e) => setReason(e.target.value)}
//                       required
//                     />
//                     <label className="">Reason</label>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </Modal.Body>

//         <Modal.Footer>
//           <Button variant="secondary" onClick={toggleModal}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleFormSubmit}>
//             Submit
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

// export default Salary;

// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../environment/Api";
import { Link } from "react-router-dom";

function Salary() {
  const [showModal, setShowModal] = useState(false);
  const [salaryData, setSalaryData] = useState([]);
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [modalData, setModalData] = useState({
    staffId: "",
    staffName: "",
    designation: "",
    monthYear: "",
    salaryPerDay: "",
    actualSalary: "",
    paidSalary: "",
    paidDate: "",
    detection: "",
    status: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [showCount, setShowCount] = useState(10);

  const toggleModal = () => setShowModal(!showModal);

  // const handleRowClick = (data) => {
  //   setModalData({
  //     staffId: data.empid || "",
  //     staffName: data.name || "",
  //     designation: data.designation_detail[0]?.designation || "",
  //     monthYear: "",
  //     salaryPerDay: data.salaryPerDay || "",
  //     actualSalary: data.employee[0]?.salary || "",
  //     paidSalary: data.paidSalary || "",
  //     paidDate: data.paidDate || "",
  //     detection: data.detection || "",
  //     presentDay: data.presentDay || "",
  //   });
  //   setStatus(data.status || "");
  //   setReason("");
  //   toggleModal();
  // };



  const handleRowClick = (data) => {
    setModalData({
      staffId: data.staffId || "",
      staffName: data.staffName || "",
      designation: data.designation || "",
      monthYear: data.monthYear,
      salaryPerDay: data.salaryPerDay || "",
      actualSalary: data.actualSalary || "",
      paidSalary: data.paidSalary || "",
      paidDate: data.paidDate || "",
      detection: data.detection || "",

    });
    setStatus(data.status || "");
    setReason("");
    toggleModal();
  };



  // const handleFormSubmit = async () => {
  //   const updatedData = {
  //     ...modalData,
  //     status,
  //     reason,
  //   };

  //   try {
  //     const response = await axios.get(apiUrl + "/salary", updatedData);
  //     console.log("Salary updated successfully", response.data);

  //     setSalaryData((prevData) =>
  //       prevData.map((data) =>
  //         data.empid === updatedData.staffId
  //           ? { ...data, ...updatedData }
  //           : data
  //       )
  //     );
  //     setShowModal(false);
  //   } catch (error) {
  //     console.error("Error updating salary data:", error);
  //   }
  // };


  const handleFormSubmit = async () => {
    const updatedData = {
      ...modalData,
      status,
      reason, // Assuming `reason` is a part of your form data
    };
  
    try {
      // Send data in the body of the PUT request
      const response = await axios.put(apiUrl + `/salary`, updatedData); 
  
      console.log("Salary updated successfully", response.data);
  
      // Update the UI based on the response data
      setSalaryData((prevData) =>
        prevData.map((data) =>
          data.staffId === updatedData.staffId
            ? { ...data, ...response.data } // Assume response.data has the full updated data
            : data
        )
      );
  
      // Close the modal after updating
      setShowModal(false);
    } catch (error) {
      console.error("Error updating salary:", error);
      // Optionally show an error message or handle error cases
    }
  };
  


  

  

  // useEffect(() => {
  //   const fetchData = async () => {
  //       try {
  //           // Fetch the current month's attendance data from the API
  //           const leadsResponse = await axios.get(apiUrl + "/calculate_salary");
  //           setSalaryData(leadsResponse.data);  // Store the data in state
  //       } catch (error) {
  //           console.error("Error fetching data:", error);
  //           // Optionally, set some error state to display an error message to the user
  //       }
  //   };

  //   // Call fetchData when the component mounts
  //   fetchData();
  // }, []); // Empty dependency array means this runs only on mount


  useEffect(() => {
    const fetchData = async () => {
      try {
        const leadsResponse = await axios.get(
          apiUrl + "/salary_data"
        );
        setSalaryData(leadsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);




  const filteredData = salaryData.filter((data) => {
    return (
      (data.staffId &&
        data.staffId.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.empid &&
        data.empid.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.name &&
        data.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (data.designation_detail[0]?.designation &&
        data.designation_detail[0]?.designation
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
    );
  });

  return (
    <>
      <div style={{ marginTop: "-80px" }}>
        <div className="row mb-3">
          <div className="col-lg-11">
            <Link to="/dashboard" className="text-secondary dashBoard_text">
              <b>Dashboard / </b>
            </Link>
            <Link to="/staff" className="text-secondary dashBoard_text">
              <b>Staff / </b>
            </Link>
            <Link to="" className="text-danger dashBoard_text">
              <b>Salary</b>
            </Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-9 d-flex">
          <h5 className="mt-2">Salary Details</h5>
        </div>
      </div>

      <div className="mb-3 mt-3">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
            <label className="form-label text-dark mt-2">Show: </label>
            <select
              className="form-control w-100 mx-2"
              value={showCount}
              onChange={(e) => setShowCount(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </div>
          <div className="col-lg-7 col-md-4 col-sm-1"></div>
          <div className="col-lg-3 col-md-4 col-sm-7">
            <form>
              <input
                className="form-control w-full bg-white pl-8"
                placeholder="Search ..."
                type="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
        </div>
      </div>

      <div className="card">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Staff ID</th>

              <th>Staff Name</th>
              <th>Designation</th>
              <th>Month & Year</th>
              <th>Actual Salary</th>
              <th>Paid Salary</th>
              <th>Status</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* {filteredData.length > 0 ? (
              filteredData.slice(0, showCount).map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>{data.empid}</td>
                  <td>{data.name}</td>
                  <td>{data.designation_detail[0]?.designation}</td>
                  <td>None</td>
                  <td>{data.employee[0]?.salary}</td>
                  <td>{data.salaryPerDay}</td>
                  <td>{data.paidSalary}</td>
                  <td>{data.status}</td>
                  <td className="text-center">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleRowClick(data)}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  No data available
                </td>
              </tr>
            )} */}

            {filteredData.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.staffId}</td>
                <td>{data.staffName}</td>
                <td>{data.designation}</td>
                <td>{data.monthYear}</td>
                <td>{data.actualSalary}</td>
                <td>{data.paidSalary}</td>

                <td>
                  <p className={data.status === 'Paid' ? 'active-row' : 'inactive-row'}>
                    {data.status}
                  </p>
                </td>

                <td className="">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleRowClick(data)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                </td>



              </tr>
            ))

            }

          </tbody>
        </table>
      </div>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Salary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.staffId}
                    onChange={(e) =>
                      setModalData({ ...modalData, staffId: e.target.value })
                    }
                    required
                  />
                  <label className="">Staff ID</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.staffName}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        staffName: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Staff Name</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.designation}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        designation: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Designation</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.monthYear}
                    onChange={(e) =>
                      setModalData({ ...modalData, monthYear: e.target.value })
                    }
                    required
                  />
                  <label className="">Month & Year</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.salaryPerDay}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        salaryPerDay: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Salary Per Day</label>
                </div>

                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={modalData.actualSalary}
                    onChange={(e) =>
                      setModalData({
                        ...modalData,
                        actualSalary: e.target.value,
                      })
                    }
                    required
                  />
                  <label className="">Actual Salary</label>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={modalData.paidSalary}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      paidSalary: e.target.value,
                    })
                  }
                  required
                />
                <label className="">Paid Salary</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="date"
                  placeholder=""
                  value={modalData.paidDate}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      paidDate: e.target.value,
                    })
                  }
                  required
                />
                <label className="">Paid Date</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={modalData.detection}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      detection: e.target.value,
                    })
                  }
                  required
                />
                <label className="">Detection</label>
              </div>

              <div className="floating-label">
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  value={modalData.presentdays}
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      presentdays: e.target.value,
                    })
                  }
                  required
                />
                <label className="">Present Day</label>
              </div>
              <div className="floating-label">
                {" "}
                <label className="form-label"></label>{" "}
                <select
                  className="form-control"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >

                  <option value="">Select Status</option>{" "}
                  <option value="Approved">Paid</option>{" "}
                  <option value="Pending">Pending</option>{" "}
                  <option value="Rejected">Process</option>{" "}
                </select>{" "}
              </div>

              {status === "Pending" && (
                <div className="floating-label">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                  <label className="">Reason</label>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Salary;
